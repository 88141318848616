.m-0 {
  margin: 0 !important
}

.m-4 {
  margin: 4px !important
}

.m-6 {
  margin: 6px !important
}

.m-8 {
  margin: 8px !important
}

.m-12 {
  margin: 12px !important
}

.m-16 {
  margin: 16px !important
}

.m-20 {
  margin: 20px !important
}

.m-24 {
  margin: 24px !important
}

.m-28 {
  margin: 28px !important
}

.m-32 {
  margin: 32px !important
}

.m-36 {
  margin: 36px !important
}

.m-40 {
  margin: 40px !important
}

.m-44 {
  margin: 44px !important
}

.m-48 {
  margin: 48px !important
}

.m-52 {
  margin: 52px !important
}

.m-56 {
  margin: 56px !important
}

.m-60 {
  margin: 60px !important
}

.m-64 {
  margin: 64px !important
}

.m-68 {
  margin: 68px !important
}

.m-72 {
  margin: 72px !important
}

.m-76 {
  margin: 76px !important
}

.m-80 {
  margin: 80px !important
}

.m-84 {
  margin: 84px !important
}

.m-88 {
  margin: 88px !important
}

.m-92 {
  margin: 92px !important
}

.m-96 {
  margin: 96px !important
}

.m-128 {
  margin: 128px !important
}

.m-160 {
  margin: 160px !important
}

.m-192 {
  margin: 192px !important
}

.m-224 {
  margin: 224px !important
}

.m-256 {
  margin: 256px !important
}

.m-320 {
  margin: 320px !important
}

.m-auto {
  margin: auto !important
}

.m-px {
  margin: 1px !important
}

.-m-4 {
  margin: -4px !important
}

.-m-6 {
  margin: -6px !important
}

.-m-8 {
  margin: -8px !important
}

.-m-12 {
  margin: -12px !important
}

.-m-16 {
  margin: -16px !important
}

.-m-20 {
  margin: -20px !important
}

.-m-24 {
  margin: -24px !important
}

.-m-28 {
  margin: -28px !important
}

.-m-32 {
  margin: -32px !important
}

.-m-36 {
  margin: -36px !important
}

.-m-40 {
  margin: -40px !important
}

.-m-44 {
  margin: -44px !important
}

.-m-48 {
  margin: -48px !important
}

.-m-52 {
  margin: -52px !important
}

.-m-56 {
  margin: -56px !important
}

.-m-60 {
  margin: -60px !important
}

.-m-64 {
  margin: -64px !important
}

.-m-68 {
  margin: -68px !important
}

.-m-72 {
  margin: -72px !important
}

.-m-76 {
  margin: -76px !important
}

.-m-80 {
  margin: -80px !important
}

.-m-84 {
  margin: -84px !important
}

.-m-88 {
  margin: -88px !important
}

.-m-92 {
  margin: -92px !important
}

.-m-96 {
  margin: -96px !important
}

.-m-128 {
  margin: -128px !important
}

.-m-160 {
  margin: -160px !important
}

.-m-192 {
  margin: -192px !important
}

.-m-224 {
  margin: -224px !important
}

.-m-256 {
  margin: -256px !important
}

.-m-320 {
  margin: -320px !important
}

.-m-px {
  margin: -1px !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important
}

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important
}

.my-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important
}

.mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important
}

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important
}

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important
}

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important
}

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important
}

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important
}

.mx-44 {
  margin-left: 44px !important;
  margin-right: 44px !important
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important
}

.mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important
}

.my-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important
}

.mx-52 {
  margin-left: 52px !important;
  margin-right: 52px !important
}

.my-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important
}

.mx-56 {
  margin-left: 56px !important;
  margin-right: 56px !important
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important
}

.mx-64 {
  margin-left: 64px !important;
  margin-right: 64px !important
}

.my-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important
}

.mx-68 {
  margin-left: 68px !important;
  margin-right: 68px !important
}

.my-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important
}

.mx-72 {
  margin-left: 72px !important;
  margin-right: 72px !important
}

.my-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important
}

.mx-76 {
  margin-left: 76px !important;
  margin-right: 76px !important
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important
}

.my-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important
}

.mx-84 {
  margin-left: 84px !important;
  margin-right: 84px !important
}

.my-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important
}

.mx-88 {
  margin-left: 88px !important;
  margin-right: 88px !important
}

.my-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important
}

.mx-92 {
  margin-left: 92px !important;
  margin-right: 92px !important
}

.my-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important
}

.mx-96 {
  margin-left: 96px !important;
  margin-right: 96px !important
}

.my-128 {
  margin-top: 128px !important;
  margin-bottom: 128px !important
}

.mx-128 {
  margin-left: 128px !important;
  margin-right: 128px !important
}

.my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important
}

.mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important
}

.my-192 {
  margin-top: 192px !important;
  margin-bottom: 192px !important
}

.mx-192 {
  margin-left: 192px !important;
  margin-right: 192px !important
}

.my-224 {
  margin-top: 224px !important;
  margin-bottom: 224px !important
}

.mx-224 {
  margin-left: 224px !important;
  margin-right: 224px !important
}

.my-256 {
  margin-top: 256px !important;
  margin-bottom: 256px !important
}

.mx-256 {
  margin-left: 256px !important;
  margin-right: 256px !important
}

.my-320 {
  margin-top: 320px !important;
  margin-bottom: 320px !important
}

.mx-320 {
  margin-left: 320px !important;
  margin-right: 320px !important
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important
}

.-my-4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important
}

.-mx-4 {
  margin-left: -4px !important;
  margin-right: -4px !important
}

.-my-6 {
  margin-top: -6px !important;
  margin-bottom: -6px !important
}

.-mx-6 {
  margin-left: -6px !important;
  margin-right: -6px !important
}

.-my-8 {
  margin-top: -8px !important;
  margin-bottom: -8px !important
}

.-mx-8 {
  margin-left: -8px !important;
  margin-right: -8px !important
}

.-my-12 {
  margin-top: -12px !important;
  margin-bottom: -12px !important
}

.-mx-12 {
  margin-left: -12px !important;
  margin-right: -12px !important
}

.-my-16 {
  margin-top: -16px !important;
  margin-bottom: -16px !important
}

.-mx-16 {
  margin-left: -16px !important;
  margin-right: -16px !important
}

.-my-20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important
}

.-mx-20 {
  margin-left: -20px !important;
  margin-right: -20px !important
}

.-my-24 {
  margin-top: -24px !important;
  margin-bottom: -24px !important
}

.-mx-24 {
  margin-left: -24px !important;
  margin-right: -24px !important
}

.-my-28 {
  margin-top: -28px !important;
  margin-bottom: -28px !important
}

.-mx-28 {
  margin-left: -28px !important;
  margin-right: -28px !important
}

.-my-32 {
  margin-top: -32px !important;
  margin-bottom: -32px !important
}

.-mx-32 {
  margin-left: -32px !important;
  margin-right: -32px !important
}

.-my-36 {
  margin-top: -36px !important;
  margin-bottom: -36px !important
}

.-mx-36 {
  margin-left: -36px !important;
  margin-right: -36px !important
}

.-my-40 {
  margin-top: -40px !important;
  margin-bottom: -40px !important
}

.-mx-40 {
  margin-left: -40px !important;
  margin-right: -40px !important
}

.-my-44 {
  margin-top: -44px !important;
  margin-bottom: -44px !important
}

.-mx-44 {
  margin-left: -44px !important;
  margin-right: -44px !important
}

.-my-48 {
  margin-top: -48px !important;
  margin-bottom: -48px !important
}

.-mx-48 {
  margin-left: -48px !important;
  margin-right: -48px !important
}

.-my-52 {
  margin-top: -52px !important;
  margin-bottom: -52px !important
}

.-mx-52 {
  margin-left: -52px !important;
  margin-right: -52px !important
}

.-my-56 {
  margin-top: -56px !important;
  margin-bottom: -56px !important
}

.-mx-56 {
  margin-left: -56px !important;
  margin-right: -56px !important
}

.-my-60 {
  margin-top: -60px !important;
  margin-bottom: -60px !important
}

.-mx-60 {
  margin-left: -60px !important;
  margin-right: -60px !important
}

.-my-64 {
  margin-top: -64px !important;
  margin-bottom: -64px !important
}

.-mx-64 {
  margin-left: -64px !important;
  margin-right: -64px !important
}

.-my-68 {
  margin-top: -68px !important;
  margin-bottom: -68px !important
}

.-mx-68 {
  margin-left: -68px !important;
  margin-right: -68px !important
}

.-my-72 {
  margin-top: -72px !important;
  margin-bottom: -72px !important
}

.-mx-72 {
  margin-left: -72px !important;
  margin-right: -72px !important
}

.-my-76 {
  margin-top: -76px !important;
  margin-bottom: -76px !important
}

.-mx-76 {
  margin-left: -76px !important;
  margin-right: -76px !important
}

.-my-80 {
  margin-top: -80px !important;
  margin-bottom: -80px !important
}

.-mx-80 {
  margin-left: -80px !important;
  margin-right: -80px !important
}

.-my-84 {
  margin-top: -84px !important;
  margin-bottom: -84px !important
}

.-mx-84 {
  margin-left: -84px !important;
  margin-right: -84px !important
}

.-my-88 {
  margin-top: -88px !important;
  margin-bottom: -88px !important
}

.-mx-88 {
  margin-left: -88px !important;
  margin-right: -88px !important
}

.-my-92 {
  margin-top: -92px !important;
  margin-bottom: -92px !important
}

.-mx-92 {
  margin-left: -92px !important;
  margin-right: -92px !important
}

.-my-96 {
  margin-top: -96px !important;
  margin-bottom: -96px !important
}

.-mx-96 {
  margin-left: -96px !important;
  margin-right: -96px !important
}

.-my-128 {
  margin-top: -128px !important;
  margin-bottom: -128px !important
}

.-mx-128 {
  margin-left: -128px !important;
  margin-right: -128px !important
}

.-my-160 {
  margin-top: -160px !important;
  margin-bottom: -160px !important
}

.-mx-160 {
  margin-left: -160px !important;
  margin-right: -160px !important
}

.-my-192 {
  margin-top: -192px !important;
  margin-bottom: -192px !important
}

.-mx-192 {
  margin-left: -192px !important;
  margin-right: -192px !important
}

.-my-224 {
  margin-top: -224px !important;
  margin-bottom: -224px !important
}

.-mx-224 {
  margin-left: -224px !important;
  margin-right: -224px !important
}

.-my-256 {
  margin-top: -256px !important;
  margin-bottom: -256px !important
}

.-mx-256 {
  margin-left: -256px !important;
  margin-right: -256px !important
}

.-my-320 {
  margin-top: -320px !important;
  margin-bottom: -320px !important
}

.-mx-320 {
  margin-left: -320px !important;
  margin-right: -320px !important
}

.-my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important
}

.-mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important
}

.mt-0 {
  margin-top: 0 !important
}

.mr-0 {
  margin-right: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.ml-0 {
  margin-left: 0 !important
}

.mt-4 {
  margin-top: 4px !important
}

.mr-4 {
  margin-right: 4px !important
}

.mb-4 {
  margin-bottom: 4px !important
}

.ml-4 {
  margin-left: 4px !important
}

.mt-6 {
  margin-top: 6px !important
}

.mr-6 {
  margin-right: 6px !important
}

.mb-6 {
  margin-bottom: 6px !important
}

.ml-6 {
  margin-left: 6px !important
}

.mt-8 {
  margin-top: 8px !important
}

.mr-8 {
  margin-right: 8px !important
}

.mb-8 {
  margin-bottom: 8px !important
}

.ml-8 {
  margin-left: 8px !important
}

.mt-12 {
  margin-top: 12px !important
}

.mr-12 {
  margin-right: 12px !important
}

.mb-12 {
  margin-bottom: 12px !important
}

.ml-12 {
  margin-left: 12px !important
}

.mt-16 {
  margin-top: 16px !important
}

.mr-16 {
  margin-right: 16px !important
}

.mb-16 {
  margin-bottom: 16px !important
}

.ml-16 {
  margin-left: 16px !important
}

.mt-20 {
  margin-top: 20px !important
}

.mr-20 {
  margin-right: 20px !important
}

.mb-20 {
  margin-bottom: 20px !important
}

.ml-20 {
  margin-left: 20px !important
}

.mt-24 {
  margin-top: 24px !important
}

.mr-24 {
  margin-right: 24px !important
}

.mb-24 {
  margin-bottom: 24px !important
}

.ml-24 {
  margin-left: 24px !important
}

.mt-28 {
  margin-top: 28px !important
}

.mr-28 {
  margin-right: 28px !important
}

.mb-28 {
  margin-bottom: 28px !important
}

.ml-28 {
  margin-left: 28px !important
}

.mt-32 {
  margin-top: 32px !important
}

.mr-32 {
  margin-right: 32px !important
}

.mb-32 {
  margin-bottom: 32px !important
}

.ml-32 {
  margin-left: 32px !important
}

.mt-36 {
  margin-top: 36px !important
}

.mr-36 {
  margin-right: 36px !important
}

.mb-36 {
  margin-bottom: 36px !important
}

.ml-36 {
  margin-left: 36px !important
}

.mt-40 {
  margin-top: 40px !important
}

.mr-40 {
  margin-right: 40px !important
}

.mb-40 {
  margin-bottom: 40px !important
}

.ml-40 {
  margin-left: 40px !important
}

.mt-44 {
  margin-top: 44px !important
}

.mr-44 {
  margin-right: 44px !important
}

.mb-44 {
  margin-bottom: 44px !important
}

.ml-44 {
  margin-left: 44px !important
}

.mt-48 {
  margin-top: 48px !important
}

.mr-48 {
  margin-right: 48px !important
}

.mb-48 {
  margin-bottom: 48px !important
}

.ml-48 {
  margin-left: 48px !important
}

.mt-52 {
  margin-top: 52px !important
}

.mr-52 {
  margin-right: 52px !important
}

.mb-52 {
  margin-bottom: 52px !important
}

.ml-52 {
  margin-left: 52px !important
}

.mt-56 {
  margin-top: 56px !important
}

.mr-56 {
  margin-right: 56px !important
}

.mb-56 {
  margin-bottom: 56px !important
}

.ml-56 {
  margin-left: 56px !important
}

.mt-60 {
  margin-top: 60px !important
}

.mr-60 {
  margin-right: 60px !important
}

.mb-60 {
  margin-bottom: 60px !important
}

.ml-60 {
  margin-left: 60px !important
}

.mt-64 {
  margin-top: 64px !important
}

.mr-64 {
  margin-right: 64px !important
}

.mb-64 {
  margin-bottom: 64px !important
}

.ml-64 {
  margin-left: 64px !important
}

.mt-68 {
  margin-top: 68px !important
}

.mr-68 {
  margin-right: 68px !important
}

.mb-68 {
  margin-bottom: 68px !important
}

.ml-68 {
  margin-left: 68px !important
}

.mt-72 {
  margin-top: 72px !important
}

.mr-72 {
  margin-right: 72px !important
}

.mb-72 {
  margin-bottom: 72px !important
}

.ml-72 {
  margin-left: 72px !important
}

.mt-76 {
  margin-top: 76px !important
}

.mr-76 {
  margin-right: 76px !important
}

.mb-76 {
  margin-bottom: 76px !important
}

.ml-76 {
  margin-left: 76px !important
}

.mt-80 {
  margin-top: 80px !important
}

.mr-80 {
  margin-right: 80px !important
}

.mb-80 {
  margin-bottom: 80px !important
}

.ml-80 {
  margin-left: 80px !important
}

.mt-84 {
  margin-top: 84px !important
}

.mr-84 {
  margin-right: 84px !important
}

.mb-84 {
  margin-bottom: 84px !important
}

.ml-84 {
  margin-left: 84px !important
}

.mt-88 {
  margin-top: 88px !important
}

.mr-88 {
  margin-right: 88px !important
}

.mb-88 {
  margin-bottom: 88px !important
}

.ml-88 {
  margin-left: 88px !important
}

.mt-92 {
  margin-top: 92px !important
}

.mr-92 {
  margin-right: 92px !important
}

.mb-92 {
  margin-bottom: 92px !important
}

.ml-92 {
  margin-left: 92px !important
}

.mt-96 {
  margin-top: 96px !important
}

.mr-96 {
  margin-right: 96px !important
}

.mb-96 {
  margin-bottom: 96px !important
}

.ml-96 {
  margin-left: 96px !important
}

.mt-128 {
  margin-top: 128px !important
}

.mr-128 {
  margin-right: 128px !important
}

.mb-128 {
  margin-bottom: 128px !important
}

.ml-128 {
  margin-left: 128px !important
}

.mt-160 {
  margin-top: 160px !important
}

.mr-160 {
  margin-right: 160px !important
}

.mb-160 {
  margin-bottom: 160px !important
}

.ml-160 {
  margin-left: 160px !important
}

.mt-192 {
  margin-top: 192px !important
}

.mr-192 {
  margin-right: 192px !important
}

.mb-192 {
  margin-bottom: 192px !important
}

.ml-192 {
  margin-left: 192px !important
}

.mt-224 {
  margin-top: 224px !important
}

.mr-224 {
  margin-right: 224px !important
}

.mb-224 {
  margin-bottom: 224px !important
}

.ml-224 {
  margin-left: 224px !important
}

.mt-256 {
  margin-top: 256px !important
}

.mr-256 {
  margin-right: 256px !important
}

.mb-256 {
  margin-bottom: 256px !important
}

.ml-256 {
  margin-left: 256px !important
}

.mt-320 {
  margin-top: 320px !important
}

.mr-320 {
  margin-right: 320px !important
}

.mb-320 {
  margin-bottom: 320px !important
}

.ml-320 {
  margin-left: 320px !important
}

.mt-auto {
  margin-top: auto !important
}

.mr-auto {
  margin-right: auto !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ml-auto {
  margin-left: auto !important
}

.mt-px {
  margin-top: 1px !important
}

.mr-px {
  margin-right: 1px !important
}

.mb-px {
  margin-bottom: 1px !important
}

.ml-px {
  margin-left: 1px !important
}

.-mt-4 {
  margin-top: -4px !important
}

.-mr-4 {
  margin-right: -4px !important
}

.-mb-4 {
  margin-bottom: -4px !important
}

.-ml-4 {
  margin-left: -4px !important
}

.-mt-6 {
  margin-top: -6px !important
}

.-mr-6 {
  margin-right: -6px !important
}

.-mb-6 {
  margin-bottom: -6px !important
}

.-ml-6 {
  margin-left: -6px !important
}

.-mt-8 {
  margin-top: -8px !important
}

.-mr-8 {
  margin-right: -8px !important
}

.-mb-8 {
  margin-bottom: -8px !important
}

.-ml-8 {
  margin-left: -8px !important
}

.-mt-12 {
  margin-top: -12px !important
}

.-mr-12 {
  margin-right: -12px !important
}

.-mb-12 {
  margin-bottom: -12px !important
}

.-ml-12 {
  margin-left: -12px !important
}

.-mt-16 {
  margin-top: -16px !important
}

.-mr-16 {
  margin-right: -16px !important
}

.-mb-16 {
  margin-bottom: -16px !important
}

.-ml-16 {
  margin-left: -16px !important
}

.-mt-20 {
  margin-top: -20px !important
}

.-mr-20 {
  margin-right: -20px !important
}

.-mb-20 {
  margin-bottom: -20px !important
}

.-ml-20 {
  margin-left: -20px !important
}

.-mt-24 {
  margin-top: -24px !important
}

.-mr-24 {
  margin-right: -24px !important
}

.-mb-24 {
  margin-bottom: -24px !important
}

.-ml-24 {
  margin-left: -24px !important
}

.-mt-28 {
  margin-top: -28px !important
}

.-mr-28 {
  margin-right: -28px !important
}

.-mb-28 {
  margin-bottom: -28px !important
}

.-ml-28 {
  margin-left: -28px !important
}

.-mt-32 {
  margin-top: -32px !important
}

.-mr-32 {
  margin-right: -32px !important
}

.-mb-32 {
  margin-bottom: -32px !important
}

.-ml-32 {
  margin-left: -32px !important
}

.-mt-36 {
  margin-top: -36px !important
}

.-mr-36 {
  margin-right: -36px !important
}

.-mb-36 {
  margin-bottom: -36px !important
}

.-ml-36 {
  margin-left: -36px !important
}

.-mt-40 {
  margin-top: -40px !important
}

.-mr-40 {
  margin-right: -40px !important
}

.-mb-40 {
  margin-bottom: -40px !important
}

.-ml-40 {
  margin-left: -40px !important
}

.-mt-44 {
  margin-top: -44px !important
}

.-mr-44 {
  margin-right: -44px !important
}

.-mb-44 {
  margin-bottom: -44px !important
}

.-ml-44 {
  margin-left: -44px !important
}

.-mt-48 {
  margin-top: -48px !important
}

.-mr-48 {
  margin-right: -48px !important
}

.-mb-48 {
  margin-bottom: -48px !important
}

.-ml-48 {
  margin-left: -48px !important
}

.-mt-52 {
  margin-top: -52px !important
}

.-mr-52 {
  margin-right: -52px !important
}

.-mb-52 {
  margin-bottom: -52px !important
}

.-ml-52 {
  margin-left: -52px !important
}

.-mt-56 {
  margin-top: -56px !important
}

.-mr-56 {
  margin-right: -56px !important
}

.-mb-56 {
  margin-bottom: -56px !important
}

.-ml-56 {
  margin-left: -56px !important
}

.-mt-60 {
  margin-top: -60px !important
}

.-mr-60 {
  margin-right: -60px !important
}

.-mb-60 {
  margin-bottom: -60px !important
}

.-ml-60 {
  margin-left: -60px !important
}

.-mt-64 {
  margin-top: -64px !important
}

.-mr-64 {
  margin-right: -64px !important
}

.-mb-64 {
  margin-bottom: -64px !important
}

.-ml-64 {
  margin-left: -64px !important
}

.-mt-68 {
  margin-top: -68px !important
}

.-mr-68 {
  margin-right: -68px !important
}

.-mb-68 {
  margin-bottom: -68px !important
}

.-ml-68 {
  margin-left: -68px !important
}

.-mt-72 {
  margin-top: -72px !important
}

.-mr-72 {
  margin-right: -72px !important
}

.-mb-72 {
  margin-bottom: -72px !important
}

.-ml-72 {
  margin-left: -72px !important
}

.-mt-76 {
  margin-top: -76px !important
}

.-mr-76 {
  margin-right: -76px !important
}

.-mb-76 {
  margin-bottom: -76px !important
}

.-ml-76 {
  margin-left: -76px !important
}

.-mt-80 {
  margin-top: -80px !important
}

.-mr-80 {
  margin-right: -80px !important
}

.-mb-80 {
  margin-bottom: -80px !important
}

.-ml-80 {
  margin-left: -80px !important
}

.-mt-84 {
  margin-top: -84px !important
}

.-mr-84 {
  margin-right: -84px !important
}

.-mb-84 {
  margin-bottom: -84px !important
}

.-ml-84 {
  margin-left: -84px !important
}

.-mt-88 {
  margin-top: -88px !important
}

.-mr-88 {
  margin-right: -88px !important
}

.-mb-88 {
  margin-bottom: -88px !important
}

.-ml-88 {
  margin-left: -88px !important
}

.-mt-92 {
  margin-top: -92px !important
}

.-mr-92 {
  margin-right: -92px !important
}

.-mb-92 {
  margin-bottom: -92px !important
}

.-ml-92 {
  margin-left: -92px !important
}

.-mt-96 {
  margin-top: -96px !important
}

.-mr-96 {
  margin-right: -96px !important
}

.-mb-96 {
  margin-bottom: -96px !important
}

.-ml-96 {
  margin-left: -96px !important
}

.-mt-128 {
  margin-top: -128px !important
}

.-mr-128 {
  margin-right: -128px !important
}

.-mb-128 {
  margin-bottom: -128px !important
}

.-ml-128 {
  margin-left: -128px !important
}

.-mt-160 {
  margin-top: -160px !important
}

.-mr-160 {
  margin-right: -160px !important
}

.-mb-160 {
  margin-bottom: -160px !important
}

.-ml-160 {
  margin-left: -160px !important
}

.-mt-192 {
  margin-top: -192px !important
}

.-mr-192 {
  margin-right: -192px !important
}

.-mb-192 {
  margin-bottom: -192px !important
}

.-ml-192 {
  margin-left: -192px !important
}

.-mt-224 {
  margin-top: -224px !important
}

.-mr-224 {
  margin-right: -224px !important
}

.-mb-224 {
  margin-bottom: -224px !important
}

.-ml-224 {
  margin-left: -224px !important
}

.-mt-256 {
  margin-top: -256px !important
}

.-mr-256 {
  margin-right: -256px !important
}

.-mb-256 {
  margin-bottom: -256px !important
}

.-ml-256 {
  margin-left: -256px !important
}

.-mt-320 {
  margin-top: -320px !important
}

.-mr-320 {
  margin-right: -320px !important
}

.-mb-320 {
  margin-bottom: -320px !important
}

.-ml-320 {
  margin-left: -320px !important
}

.-mt-px {
  margin-top: -1px !important
}

.-mr-px {
  margin-right: -1px !important
}

.-mb-px {
  margin-bottom: -1px !important
}

.-ml-px {
  margin-left: -1px !important
}

.p-0 {
  padding: 0 !important
}

.p-4 {
  padding: 4px !important
}

.p-6 {
  padding: 6px !important
}

.p-8 {
  padding: 8px !important
}

.p-12 {
  padding: 12px !important
}

.p-16 {
  padding: 16px !important
}

.p-20 {
  padding: 20px !important
}

.p-24 {
  padding: 24px !important
}

.p-28 {
  padding: 28px !important
}

.p-32 {
  padding: 32px !important
}

.p-36 {
  padding: 36px !important
}

.p-40 {
  padding: 40px !important
}

.p-44 {
  padding: 44px !important
}

.p-48 {
  padding: 48px !important
}

.p-52 {
  padding: 52px !important
}

.p-56 {
  padding: 56px !important
}

.p-60 {
  padding: 60px !important
}

.p-64 {
  padding: 64px !important
}

.p-68 {
  padding: 68px !important
}

.p-72 {
  padding: 72px !important
}

.p-76 {
  padding: 76px !important
}

.p-80 {
  padding: 80px !important
}

.p-84 {
  padding: 84px !important
}

.p-88 {
  padding: 88px !important
}

.p-92 {
  padding: 92px !important
}

.p-96 {
  padding: 96px !important
}

.p-128 {
  padding: 128px !important
}

.p-160 {
  padding: 160px !important
}

.p-192 {
  padding: 192px !important
}

.p-224 {
  padding: 224px !important
}

.p-256 {
  padding: 256px !important
}

.p-320 {
  padding: 320px !important
}

.p-px {
  padding: 1px !important
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important
}

.py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important
}

.px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important
}

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important
}

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important
}

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important
}

.px-44 {
  padding-left: 44px !important;
  padding-right: 44px !important
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important
}

.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important
}

.py-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important
}

.px-52 {
  padding-left: 52px !important;
  padding-right: 52px !important
}

.py-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important
}

.px-56 {
  padding-left: 56px !important;
  padding-right: 56px !important
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important
}

.px-64 {
  padding-left: 64px !important;
  padding-right: 64px !important
}

.py-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important
}

.px-68 {
  padding-left: 68px !important;
  padding-right: 68px !important
}

.py-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important
}

.px-72 {
  padding-left: 72px !important;
  padding-right: 72px !important
}

.py-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important
}

.px-76 {
  padding-left: 76px !important;
  padding-right: 76px !important
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important
}

.py-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important
}

.px-84 {
  padding-left: 84px !important;
  padding-right: 84px !important
}

.py-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important
}

.px-88 {
  padding-left: 88px !important;
  padding-right: 88px !important
}

.py-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important
}

.px-92 {
  padding-left: 92px !important;
  padding-right: 92px !important
}

.py-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important
}

.px-96 {
  padding-left: 96px !important;
  padding-right: 96px !important
}

.py-128 {
  padding-top: 128px !important;
  padding-bottom: 128px !important
}

.px-128 {
  padding-left: 128px !important;
  padding-right: 128px !important
}

.py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important
}

.px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important
}

.py-192 {
  padding-top: 192px !important;
  padding-bottom: 192px !important
}

.px-192 {
  padding-left: 192px !important;
  padding-right: 192px !important
}

.py-224 {
  padding-top: 224px !important;
  padding-bottom: 224px !important
}

.px-224 {
  padding-left: 224px !important;
  padding-right: 224px !important
}

.py-256 {
  padding-top: 256px !important;
  padding-bottom: 256px !important
}

.px-256 {
  padding-left: 256px !important;
  padding-right: 256px !important
}

.py-320 {
  padding-top: 320px !important;
  padding-bottom: 320px !important
}

.px-320 {
  padding-left: 320px !important;
  padding-right: 320px !important
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important
}

.pt-0 {
  padding-top: 0 !important
}

.pr-0 {
  padding-right: 0 !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pl-0 {
  padding-left: 0 !important
}

.pt-4 {
  padding-top: 4px !important
}

.pr-4 {
  padding-right: 4px !important
}

.pb-4 {
  padding-bottom: 4px !important
}

.pl-4 {
  padding-left: 4px !important
}

.pt-6 {
  padding-top: 6px !important
}

.pr-6 {
  padding-right: 6px !important
}

.pb-6 {
  padding-bottom: 6px !important
}

.pl-6 {
  padding-left: 6px !important
}

.pt-8 {
  padding-top: 8px !important
}

.pr-8 {
  padding-right: 8px !important
}

.pb-8 {
  padding-bottom: 8px !important
}

.pl-8 {
  padding-left: 8px !important
}

.pt-12 {
  padding-top: 12px !important
}

.pr-12 {
  padding-right: 12px !important
}

.pb-12 {
  padding-bottom: 12px !important
}

.pl-12 {
  padding-left: 12px !important
}

.pt-16 {
  padding-top: 16px !important
}

.pr-16 {
  padding-right: 16px !important
}

.pb-16 {
  padding-bottom: 16px !important
}

.pl-16 {
  padding-left: 16px !important
}

.pt-20 {
  padding-top: 20px !important
}

.pr-20 {
  padding-right: 20px !important
}

.pb-20 {
  padding-bottom: 20px !important
}

.pl-20 {
  padding-left: 20px !important
}

.pt-24 {
  padding-top: 24px !important
}

.pr-24 {
  padding-right: 24px !important
}

.pb-24 {
  padding-bottom: 24px !important
}

.pl-24 {
  padding-left: 24px !important
}

.pt-28 {
  padding-top: 28px !important
}

.pr-28 {
  padding-right: 28px !important
}

.pb-28 {
  padding-bottom: 28px !important
}

.pl-28 {
  padding-left: 28px !important
}

.pt-32 {
  padding-top: 32px !important
}

.pr-32 {
  padding-right: 32px !important
}

.pb-32 {
  padding-bottom: 32px !important
}

.pl-32 {
  padding-left: 32px !important
}

.pt-36 {
  padding-top: 36px !important
}

.pr-36 {
  padding-right: 36px !important
}

.pb-36 {
  padding-bottom: 36px !important
}

.pl-36 {
  padding-left: 36px !important
}

.pt-40 {
  padding-top: 40px !important
}

.pr-40 {
  padding-right: 40px !important
}

.pb-40 {
  padding-bottom: 40px !important
}

.pl-40 {
  padding-left: 40px !important
}

.pt-44 {
  padding-top: 44px !important
}

.pr-44 {
  padding-right: 44px !important
}

.pb-44 {
  padding-bottom: 44px !important
}

.pl-44 {
  padding-left: 44px !important
}

.pt-48 {
  padding-top: 48px !important
}

.pr-48 {
  padding-right: 48px !important
}

.pb-48 {
  padding-bottom: 48px !important
}

.pl-48 {
  padding-left: 48px !important
}

.pt-52 {
  padding-top: 52px !important
}

.pr-52 {
  padding-right: 52px !important
}

.pb-52 {
  padding-bottom: 52px !important
}

.pl-52 {
  padding-left: 52px !important
}

.pt-56 {
  padding-top: 56px !important
}

.pr-56 {
  padding-right: 56px !important
}

.pb-56 {
  padding-bottom: 56px !important
}

.pl-56 {
  padding-left: 56px !important
}

.pt-60 {
  padding-top: 60px !important
}

.pr-60 {
  padding-right: 60px !important
}

.pb-60 {
  padding-bottom: 60px !important
}

.pl-60 {
  padding-left: 60px !important
}

.pt-64 {
  padding-top: 64px !important
}

.pr-64 {
  padding-right: 64px !important
}

.pb-64 {
  padding-bottom: 64px !important
}

.pl-64 {
  padding-left: 64px !important
}

.pt-68 {
  padding-top: 68px !important
}

.pr-68 {
  padding-right: 68px !important
}

.pb-68 {
  padding-bottom: 68px !important
}

.pl-68 {
  padding-left: 68px !important
}

.pt-72 {
  padding-top: 72px !important
}

.pr-72 {
  padding-right: 72px !important
}

.pb-72 {
  padding-bottom: 72px !important
}

.pl-72 {
  padding-left: 72px !important
}

.pt-76 {
  padding-top: 76px !important
}

.pr-76 {
  padding-right: 76px !important
}

.pb-76 {
  padding-bottom: 76px !important
}

.pl-76 {
  padding-left: 76px !important
}

.pt-80 {
  padding-top: 80px !important
}

.pr-80 {
  padding-right: 80px !important
}

.pb-80 {
  padding-bottom: 80px !important
}

.pl-80 {
  padding-left: 80px !important
}

.pt-84 {
  padding-top: 84px !important
}

.pr-84 {
  padding-right: 84px !important
}

.pb-84 {
  padding-bottom: 84px !important
}

.pl-84 {
  padding-left: 84px !important
}

.pt-88 {
  padding-top: 88px !important
}

.pr-88 {
  padding-right: 88px !important
}

.pb-88 {
  padding-bottom: 88px !important
}

.pl-88 {
  padding-left: 88px !important
}

.pt-92 {
  padding-top: 92px !important
}

.pr-92 {
  padding-right: 92px !important
}

.pb-92 {
  padding-bottom: 92px !important
}

.pl-92 {
  padding-left: 92px !important
}

.pt-96 {
  padding-top: 96px !important
}

.pr-96 {
  padding-right: 96px !important
}

.pb-96 {
  padding-bottom: 96px !important
}

.pl-96 {
  padding-left: 96px !important
}

.pt-128 {
  padding-top: 128px !important
}

.pr-128 {
  padding-right: 128px !important
}

.pb-128 {
  padding-bottom: 128px !important
}

.pl-128 {
  padding-left: 128px !important
}

.pt-160 {
  padding-top: 160px !important
}

.pr-160 {
  padding-right: 160px !important
}

.pb-160 {
  padding-bottom: 160px !important
}

.pl-160 {
  padding-left: 160px !important
}

.pt-192 {
  padding-top: 192px !important
}

.pr-192 {
  padding-right: 192px !important
}

.pb-192 {
  padding-bottom: 192px !important
}

.pl-192 {
  padding-left: 192px !important
}

.pt-224 {
  padding-top: 224px !important
}

.pr-224 {
  padding-right: 224px !important
}

.pb-224 {
  padding-bottom: 224px !important
}

.pl-224 {
  padding-left: 224px !important
}

.pt-256 {
  padding-top: 256px !important
}

.pr-256 {
  padding-right: 256px !important
}

.pb-256 {
  padding-bottom: 256px !important
}

.pl-256 {
  padding-left: 256px !important
}

.pt-320 {
  padding-top: 320px !important
}

.pr-320 {
  padding-right: 320px !important
}

.pb-320 {
  padding-bottom: 320px !important
}

.pl-320 {
  padding-left: 320px !important
}

.pt-px {
  padding-top: 1px !important
}

.pr-px {
  padding-right: 1px !important
}

.pb-px {
  padding-bottom: 1px !important
}

.pl-px {
  padding-left: 1px !important
}

.flex-1 {
  flex: 1 1 0% !important
}

.flex-auto {
  flex: 1 1 auto !important
}

.flex-initial {
  flex: 0 1 auto !important
}

.flex-none {
  flex: none !important
}

.items-start {
  align-items: flex-start !important
}

.items-end {
  align-items: flex-end !important
}

.items-center {
  align-items: center !important
}

.items-baseline {
  align-items: baseline !important
}

.items-stretch {
  align-items: stretch !important
}

.flex-row {
  flex-direction: row !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-col {
  flex-direction: column !important
}

.flex-col-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.flex-no-wrap {
  flex-wrap: nowrap !important
}

.justify-start {
  justify-content: flex-start !important
}

.justify-end {
  justify-content: flex-end !important
}

.justify-center {
  justify-content: center !important
}

.justify-between {
  justify-content: space-between !important
}

.justify-around {
  justify-content: space-around !important
}

.justify-evenly {
  justify-content: space-evenly !important
}

.text-xs {
  font-size: 0.75rem !important
}

.text-sm {
  font-size: 0.875rem !important
}

.text-base {
  font-size: 1rem !important
}

.text-lg {
  font-size: 1.125rem !important
}

.text-xl {
  font-size: 1.25rem !important
}

.text-2xl {
  font-size: 1.5rem !important
}

.text-3xl {
  font-size: 1.875rem !important
}

.text-4xl {
  font-size: 2.25rem !important
}

.text-5xl {
  font-size: 3rem !important
}

.text-6xl {
  font-size: 4rem !important
}

.font-hairline {
  font-weight: 100 !important
}

.font-thin {
  font-weight: 200 !important
}

.font-light {
  font-weight: 300 !important
}

.font-normal {
  font-weight: 400 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700 !important
}

.font-extrabold {
  font-weight: 800 !important
}

.font-black {
  font-weight: 900 !important
}

.hover\:font-hairline:hover {
  font-weight: 100 !important
}

.hover\:font-thin:hover {
  font-weight: 200 !important
}

.hover\:font-light:hover {
  font-weight: 300 !important
}

.hover\:font-normal:hover {
  font-weight: 400 !important
}

.hover\:font-medium:hover {
  font-weight: 500 !important
}

.hover\:font-semibold:hover {
  font-weight: 600 !important
}

.hover\:font-bold:hover {
  font-weight: 700 !important
}

.hover\:font-extrabold:hover {
  font-weight: 800 !important
}

.hover\:font-black:hover {
  font-weight: 900 !important
}

.focus\:font-hairline:focus {
  font-weight: 100 !important
}

.focus\:font-thin:focus {
  font-weight: 200 !important
}

.focus\:font-light:focus {
  font-weight: 300 !important
}

.focus\:font-normal:focus {
  font-weight: 400 !important
}

.focus\:font-medium:focus {
  font-weight: 500 !important
}

.focus\:font-semibold:focus {
  font-weight: 600 !important
}

.focus\:font-bold:focus {
  font-weight: 700 !important
}

.focus\:font-extrabold:focus {
  font-weight: 800 !important
}

.focus\:font-black:focus {
  font-weight: 900 !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-right {
  text-align: right !important
}

.text-justify {
  text-align: justify !important
}

.text-transparent {
  color: transparent !important
}

.text-current {
  color: currentColor !important
}

.text-black {
  color: #000 !important
}

.text-white {
  color: #fff !important
}

.text-gray-100 {
  color: #f7fafc !important
}

.text-gray-200 {
  color: #edf2f7 !important
}

.text-gray-300 {
  color: #e2e8f0 !important
}

.text-gray-400 {
  color: #cbd5e0 !important
}

.text-gray-500 {
  color: #a0aec0 !important
}

.text-gray-600 {
  color: #718096 !important
}

.text-gray-700 {
  color: #4a5568 !important
}

.text-gray-800 {
  color: #2d3748 !important
}

.text-gray-900 {
  color: #1a202c !important
}

.text-red-100 {
  color: #fff5f5 !important
}

.text-red-200 {
  color: #fed7d7 !important
}

.text-red-300 {
  color: #feb2b2 !important
}

.text-red-400 {
  color: #fc8181 !important
}

.text-red-500 {
  color: #f56565 !important
}

.text-red-600 {
  color: #e53e3e !important
}

.text-red-700 {
  color: #c53030 !important
}

.text-red-800 {
  color: #9b2c2c !important
}

.text-red-900 {
  color: #742a2a !important
}

.text-orange-100 {
  color: #fffaf0 !important
}

.text-orange-200 {
  color: #feebc8 !important
}

.text-orange-300 {
  color: #fbd38d !important
}

.text-orange-400 {
  color: #f6ad55 !important
}

.text-orange-500 {
  color: #ed8936 !important
}

.text-orange-600 {
  color: #dd6b20 !important
}

.text-orange-700 {
  color: #c05621 !important
}

.text-orange-800 {
  color: #9c4221 !important
}

.text-orange-900 {
  color: #7b341e !important
}

.text-yellow-100 {
  color: #fffff0 !important
}

.text-yellow-200 {
  color: #fefcbf !important
}

.text-yellow-300 {
  color: #faf089 !important
}

.text-yellow-400 {
  color: #f6e05e !important
}

.text-yellow-500 {
  color: #ecc94b !important
}

.text-yellow-600 {
  color: #d69e2e !important
}

.text-yellow-700 {
  color: #b7791f !important
}

.text-yellow-800 {
  color: #975a16 !important
}

.text-yellow-900 {
  color: #744210 !important
}

.text-green-100 {
  color: #f0fff4 !important
}

.text-green-200 {
  color: #c6f6d5 !important
}

.text-green-300 {
  color: #9ae6b4 !important
}

.text-green-400 {
  color: #68d391 !important
}

.text-green-500 {
  color: #48bb78 !important
}

.text-green-600 {
  color: #38a169 !important
}

.text-green-700 {
  color: #2f855a !important
}

.text-green-800 {
  color: #276749 !important
}

.text-green-900 {
  color: #22543d !important
}

.text-teal-100 {
  color: #e6fffa !important
}

.text-teal-200 {
  color: #b2f5ea !important
}

.text-teal-300 {
  color: #81e6d9 !important
}

.text-teal-400 {
  color: #4fd1c5 !important
}

.text-teal-500 {
  color: #38b2ac !important
}

.text-teal-600 {
  color: #319795 !important
}

.text-teal-700 {
  color: #2c7a7b !important
}

.text-teal-800 {
  color: #285e61 !important
}

.text-teal-900 {
  color: #234e52 !important
}

.text-blue-100 {
  color: #ebf8ff !important
}

.text-blue-200 {
  color: #bee3f8 !important
}

.text-blue-300 {
  color: #90cdf4 !important
}

.text-blue-400 {
  color: #63b3ed !important
}

.text-blue-500 {
  color: #4299e1 !important
}

.text-blue-600 {
  color: #3182ce !important
}

.text-blue-700 {
  color: #2b6cb0 !important
}

.text-blue-800 {
  color: #2c5282 !important
}

.text-blue-900 {
  color: #2a4365 !important
}

.text-indigo-100 {
  color: #ebf4ff !important
}

.text-indigo-200 {
  color: #c3dafe !important
}

.text-indigo-300 {
  color: #a3bffa !important
}

.text-indigo-400 {
  color: #7f9cf5 !important
}

.text-indigo-500 {
  color: #667eea !important
}

.text-indigo-600 {
  color: #5a67d8 !important
}

.text-indigo-700 {
  color: #4c51bf !important
}

.text-indigo-800 {
  color: #434190 !important
}

.text-indigo-900 {
  color: #3c366b !important
}

.text-purple-100 {
  color: #faf5ff !important
}

.text-purple-200 {
  color: #e9d8fd !important
}

.text-purple-300 {
  color: #d6bcfa !important
}

.text-purple-400 {
  color: #b794f4 !important
}

.text-purple-500 {
  color: #9f7aea !important
}

.text-purple-600 {
  color: #805ad5 !important
}

.text-purple-700 {
  color: #6b46c1 !important
}

.text-purple-800 {
  color: #553c9a !important
}

.text-purple-900 {
  color: #44337a !important
}

.text-pink-100 {
  color: #fff5f7 !important
}

.text-pink-200 {
  color: #fed7e2 !important
}

.text-pink-300 {
  color: #fbb6ce !important
}

.text-pink-400 {
  color: #f687b3 !important
}

.text-pink-500 {
  color: #ed64a6 !important
}

.text-pink-600 {
  color: #d53f8c !important
}

.text-pink-700 {
  color: #b83280 !important
}

.text-pink-800 {
  color: #97266d !important
}

.text-pink-900 {
  color: #702459 !important
}

.hover\:text-transparent:hover {
  color: transparent !important
}

.hover\:text-current:hover {
  color: currentColor !important
}

.hover\:text-black:hover {
  color: #000 !important
}

.hover\:text-white:hover {
  color: #fff !important
}

.hover\:text-gray-100:hover {
  color: #f7fafc !important
}

.hover\:text-gray-200:hover {
  color: #edf2f7 !important
}

.hover\:text-gray-300:hover {
  color: #e2e8f0 !important
}

.hover\:text-gray-400:hover {
  color: #cbd5e0 !important
}

.hover\:text-gray-500:hover {
  color: #a0aec0 !important
}

.hover\:text-gray-600:hover {
  color: #718096 !important
}

.hover\:text-gray-700:hover {
  color: #4a5568 !important
}

.hover\:text-gray-800:hover {
  color: #2d3748 !important
}

.hover\:text-gray-900:hover {
  color: #1a202c !important
}

.hover\:text-red-100:hover {
  color: #fff5f5 !important
}

.hover\:text-red-200:hover {
  color: #fed7d7 !important
}

.hover\:text-red-300:hover {
  color: #feb2b2 !important
}

.hover\:text-red-400:hover {
  color: #fc8181 !important
}

.hover\:text-red-500:hover {
  color: #f56565 !important
}

.hover\:text-red-600:hover {
  color: #e53e3e !important
}

.hover\:text-red-700:hover {
  color: #c53030 !important
}

.hover\:text-red-800:hover {
  color: #9b2c2c !important
}

.hover\:text-red-900:hover {
  color: #742a2a !important
}

.hover\:text-orange-100:hover {
  color: #fffaf0 !important
}

.hover\:text-orange-200:hover {
  color: #feebc8 !important
}

.hover\:text-orange-300:hover {
  color: #fbd38d !important
}

.hover\:text-orange-400:hover {
  color: #f6ad55 !important
}

.hover\:text-orange-500:hover {
  color: #ed8936 !important
}

.hover\:text-orange-600:hover {
  color: #dd6b20 !important
}

.hover\:text-orange-700:hover {
  color: #c05621 !important
}

.hover\:text-orange-800:hover {
  color: #9c4221 !important
}

.hover\:text-orange-900:hover {
  color: #7b341e !important
}

.hover\:text-yellow-100:hover {
  color: #fffff0 !important
}

.hover\:text-yellow-200:hover {
  color: #fefcbf !important
}

.hover\:text-yellow-300:hover {
  color: #faf089 !important
}

.hover\:text-yellow-400:hover {
  color: #f6e05e !important
}

.hover\:text-yellow-500:hover {
  color: #ecc94b !important
}

.hover\:text-yellow-600:hover {
  color: #d69e2e !important
}

.hover\:text-yellow-700:hover {
  color: #b7791f !important
}

.hover\:text-yellow-800:hover {
  color: #975a16 !important
}

.hover\:text-yellow-900:hover {
  color: #744210 !important
}

.hover\:text-green-100:hover {
  color: #f0fff4 !important
}

.hover\:text-green-200:hover {
  color: #c6f6d5 !important
}

.hover\:text-green-300:hover {
  color: #9ae6b4 !important
}

.hover\:text-green-400:hover {
  color: #68d391 !important
}

.hover\:text-green-500:hover {
  color: #48bb78 !important
}

.hover\:text-green-600:hover {
  color: #38a169 !important
}

.hover\:text-green-700:hover {
  color: #2f855a !important
}

.hover\:text-green-800:hover {
  color: #276749 !important
}

.hover\:text-green-900:hover {
  color: #22543d !important
}

.hover\:text-teal-100:hover {
  color: #e6fffa !important
}

.hover\:text-teal-200:hover {
  color: #b2f5ea !important
}

.hover\:text-teal-300:hover {
  color: #81e6d9 !important
}

.hover\:text-teal-400:hover {
  color: #4fd1c5 !important
}

.hover\:text-teal-500:hover {
  color: #38b2ac !important
}

.hover\:text-teal-600:hover {
  color: #319795 !important
}

.hover\:text-teal-700:hover {
  color: #2c7a7b !important
}

.hover\:text-teal-800:hover {
  color: #285e61 !important
}

.hover\:text-teal-900:hover {
  color: #234e52 !important
}

.hover\:text-blue-100:hover {
  color: #ebf8ff !important
}

.hover\:text-blue-200:hover {
  color: #bee3f8 !important
}

.hover\:text-blue-300:hover {
  color: #90cdf4 !important
}

.hover\:text-blue-400:hover {
  color: #63b3ed !important
}

.hover\:text-blue-500:hover {
  color: #4299e1 !important
}

.hover\:text-blue-600:hover {
  color: #3182ce !important
}

.hover\:text-blue-700:hover {
  color: #2b6cb0 !important
}

.hover\:text-blue-800:hover {
  color: #2c5282 !important
}

.hover\:text-blue-900:hover {
  color: #2a4365 !important
}

.hover\:text-indigo-100:hover {
  color: #ebf4ff !important
}

.hover\:text-indigo-200:hover {
  color: #c3dafe !important
}

.hover\:text-indigo-300:hover {
  color: #a3bffa !important
}

.hover\:text-indigo-400:hover {
  color: #7f9cf5 !important
}

.hover\:text-indigo-500:hover {
  color: #667eea !important
}

.hover\:text-indigo-600:hover {
  color: #5a67d8 !important
}

.hover\:text-indigo-700:hover {
  color: #4c51bf !important
}

.hover\:text-indigo-800:hover {
  color: #434190 !important
}

.hover\:text-indigo-900:hover {
  color: #3c366b !important
}

.hover\:text-purple-100:hover {
  color: #faf5ff !important
}

.hover\:text-purple-200:hover {
  color: #e9d8fd !important
}

.hover\:text-purple-300:hover {
  color: #d6bcfa !important
}

.hover\:text-purple-400:hover {
  color: #b794f4 !important
}

.hover\:text-purple-500:hover {
  color: #9f7aea !important
}

.hover\:text-purple-600:hover {
  color: #805ad5 !important
}

.hover\:text-purple-700:hover {
  color: #6b46c1 !important
}

.hover\:text-purple-800:hover {
  color: #553c9a !important
}

.hover\:text-purple-900:hover {
  color: #44337a !important
}

.hover\:text-pink-100:hover {
  color: #fff5f7 !important
}

.hover\:text-pink-200:hover {
  color: #fed7e2 !important
}

.hover\:text-pink-300:hover {
  color: #fbb6ce !important
}

.hover\:text-pink-400:hover {
  color: #f687b3 !important
}

.hover\:text-pink-500:hover {
  color: #ed64a6 !important
}

.hover\:text-pink-600:hover {
  color: #d53f8c !important
}

.hover\:text-pink-700:hover {
  color: #b83280 !important
}

.hover\:text-pink-800:hover {
  color: #97266d !important
}

.hover\:text-pink-900:hover {
  color: #702459 !important
}

.focus\:text-transparent:focus {
  color: transparent !important
}

.focus\:text-current:focus {
  color: currentColor !important
}

.focus\:text-black:focus {
  color: #000 !important
}

.focus\:text-white:focus {
  color: #fff !important
}

.focus\:text-gray-100:focus {
  color: #f7fafc !important
}

.focus\:text-gray-200:focus {
  color: #edf2f7 !important
}

.focus\:text-gray-300:focus {
  color: #e2e8f0 !important
}

.focus\:text-gray-400:focus {
  color: #cbd5e0 !important
}

.focus\:text-gray-500:focus {
  color: #a0aec0 !important
}

.focus\:text-gray-600:focus {
  color: #718096 !important
}

.focus\:text-gray-700:focus {
  color: #4a5568 !important
}

.focus\:text-gray-800:focus {
  color: #2d3748 !important
}

.focus\:text-gray-900:focus {
  color: #1a202c !important
}

.focus\:text-red-100:focus {
  color: #fff5f5 !important
}

.focus\:text-red-200:focus {
  color: #fed7d7 !important
}

.focus\:text-red-300:focus {
  color: #feb2b2 !important
}

.focus\:text-red-400:focus {
  color: #fc8181 !important
}

.focus\:text-red-500:focus {
  color: #f56565 !important
}

.focus\:text-red-600:focus {
  color: #e53e3e !important
}

.focus\:text-red-700:focus {
  color: #c53030 !important
}

.focus\:text-red-800:focus {
  color: #9b2c2c !important
}

.focus\:text-red-900:focus {
  color: #742a2a !important
}

.focus\:text-orange-100:focus {
  color: #fffaf0 !important
}

.focus\:text-orange-200:focus {
  color: #feebc8 !important
}

.focus\:text-orange-300:focus {
  color: #fbd38d !important
}

.focus\:text-orange-400:focus {
  color: #f6ad55 !important
}

.focus\:text-orange-500:focus {
  color: #ed8936 !important
}

.focus\:text-orange-600:focus {
  color: #dd6b20 !important
}

.focus\:text-orange-700:focus {
  color: #c05621 !important
}

.focus\:text-orange-800:focus {
  color: #9c4221 !important
}

.focus\:text-orange-900:focus {
  color: #7b341e !important
}

.focus\:text-yellow-100:focus {
  color: #fffff0 !important
}

.focus\:text-yellow-200:focus {
  color: #fefcbf !important
}

.focus\:text-yellow-300:focus {
  color: #faf089 !important
}

.focus\:text-yellow-400:focus {
  color: #f6e05e !important
}

.focus\:text-yellow-500:focus {
  color: #ecc94b !important
}

.focus\:text-yellow-600:focus {
  color: #d69e2e !important
}

.focus\:text-yellow-700:focus {
  color: #b7791f !important
}

.focus\:text-yellow-800:focus {
  color: #975a16 !important
}

.focus\:text-yellow-900:focus {
  color: #744210 !important
}

.focus\:text-green-100:focus {
  color: #f0fff4 !important
}

.focus\:text-green-200:focus {
  color: #c6f6d5 !important
}

.focus\:text-green-300:focus {
  color: #9ae6b4 !important
}

.focus\:text-green-400:focus {
  color: #68d391 !important
}

.focus\:text-green-500:focus {
  color: #48bb78 !important
}

.focus\:text-green-600:focus {
  color: #38a169 !important
}

.focus\:text-green-700:focus {
  color: #2f855a !important
}

.focus\:text-green-800:focus {
  color: #276749 !important
}

.focus\:text-green-900:focus {
  color: #22543d !important
}

.focus\:text-teal-100:focus {
  color: #e6fffa !important
}

.focus\:text-teal-200:focus {
  color: #b2f5ea !important
}

.focus\:text-teal-300:focus {
  color: #81e6d9 !important
}

.focus\:text-teal-400:focus {
  color: #4fd1c5 !important
}

.focus\:text-teal-500:focus {
  color: #38b2ac !important
}

.focus\:text-teal-600:focus {
  color: #319795 !important
}

.focus\:text-teal-700:focus {
  color: #2c7a7b !important
}

.focus\:text-teal-800:focus {
  color: #285e61 !important
}

.focus\:text-teal-900:focus {
  color: #234e52 !important
}

.focus\:text-blue-100:focus {
  color: #ebf8ff !important
}

.focus\:text-blue-200:focus {
  color: #bee3f8 !important
}

.focus\:text-blue-300:focus {
  color: #90cdf4 !important
}

.focus\:text-blue-400:focus {
  color: #63b3ed !important
}

.focus\:text-blue-500:focus {
  color: #4299e1 !important
}

.focus\:text-blue-600:focus {
  color: #3182ce !important
}

.focus\:text-blue-700:focus {
  color: #2b6cb0 !important
}

.focus\:text-blue-800:focus {
  color: #2c5282 !important
}

.focus\:text-blue-900:focus {
  color: #2a4365 !important
}

.focus\:text-indigo-100:focus {
  color: #ebf4ff !important
}

.focus\:text-indigo-200:focus {
  color: #c3dafe !important
}

.focus\:text-indigo-300:focus {
  color: #a3bffa !important
}

.focus\:text-indigo-400:focus {
  color: #7f9cf5 !important
}

.focus\:text-indigo-500:focus {
  color: #667eea !important
}

.focus\:text-indigo-600:focus {
  color: #5a67d8 !important
}

.focus\:text-indigo-700:focus {
  color: #4c51bf !important
}

.focus\:text-indigo-800:focus {
  color: #434190 !important
}

.focus\:text-indigo-900:focus {
  color: #3c366b !important
}

.focus\:text-purple-100:focus {
  color: #faf5ff !important
}

.focus\:text-purple-200:focus {
  color: #e9d8fd !important
}

.focus\:text-purple-300:focus {
  color: #d6bcfa !important
}

.focus\:text-purple-400:focus {
  color: #b794f4 !important
}

.focus\:text-purple-500:focus {
  color: #9f7aea !important
}

.focus\:text-purple-600:focus {
  color: #805ad5 !important
}

.focus\:text-purple-700:focus {
  color: #6b46c1 !important
}

.focus\:text-purple-800:focus {
  color: #553c9a !important
}

.focus\:text-purple-900:focus {
  color: #44337a !important
}

.focus\:text-pink-100:focus {
  color: #fff5f7 !important
}

.focus\:text-pink-200:focus {
  color: #fed7e2 !important
}

.focus\:text-pink-300:focus {
  color: #fbb6ce !important
}

.focus\:text-pink-400:focus {
  color: #f687b3 !important
}

.focus\:text-pink-500:focus {
  color: #ed64a6 !important
}

.focus\:text-pink-600:focus {
  color: #d53f8c !important
}

.focus\:text-pink-700:focus {
  color: #b83280 !important
}

.focus\:text-pink-800:focus {
  color: #97266d !important
}

.focus\:text-pink-900:focus {
  color: #702459 !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-current {
  background-color: currentColor !important
}

.bg-black {
  background-color: #000 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-gray-100 {
  background-color: #f7fafc !important
}

.bg-gray-200 {
  background-color: #edf2f7 !important
}

.bg-gray-300 {
  background-color: #e2e8f0 !important
}

.bg-gray-400 {
  background-color: #cbd5e0 !important
}

.bg-gray-500 {
  background-color: #a0aec0 !important
}

.bg-gray-600 {
  background-color: #718096 !important
}

.bg-gray-700 {
  background-color: #4a5568 !important
}

.bg-gray-800 {
  background-color: #2d3748 !important
}

.bg-gray-900 {
  background-color: #1a202c !important
}

.bg-red-100 {
  background-color: #fff5f5 !important
}

.bg-red-200 {
  background-color: #fed7d7 !important
}

.bg-red-300 {
  background-color: #feb2b2 !important
}

.bg-red-400 {
  background-color: #fc8181 !important
}

.bg-red-500 {
  background-color: #f56565 !important
}

.bg-red-600 {
  background-color: #e53e3e !important
}

.bg-red-700 {
  background-color: #c53030 !important
}

.bg-red-800 {
  background-color: #9b2c2c !important
}

.bg-red-900 {
  background-color: #742a2a !important
}

.bg-orange-100 {
  background-color: #fffaf0 !important
}

.bg-orange-200 {
  background-color: #feebc8 !important
}

.bg-orange-300 {
  background-color: #fbd38d !important
}

.bg-orange-400 {
  background-color: #f6ad55 !important
}

.bg-orange-500 {
  background-color: #ed8936 !important
}

.bg-orange-600 {
  background-color: #dd6b20 !important
}

.bg-orange-700 {
  background-color: #c05621 !important
}

.bg-orange-800 {
  background-color: #9c4221 !important
}

.bg-orange-900 {
  background-color: #7b341e !important
}

.bg-yellow-100 {
  background-color: #fffff0 !important
}

.bg-yellow-200 {
  background-color: #fefcbf !important
}

.bg-yellow-300 {
  background-color: #faf089 !important
}

.bg-yellow-400 {
  background-color: #f6e05e !important
}

.bg-yellow-500 {
  background-color: #ecc94b !important
}

.bg-yellow-600 {
  background-color: #d69e2e !important
}

.bg-yellow-700 {
  background-color: #b7791f !important
}

.bg-yellow-800 {
  background-color: #975a16 !important
}

.bg-yellow-900 {
  background-color: #744210 !important
}

.bg-green-100 {
  background-color: #f0fff4 !important
}

.bg-green-200 {
  background-color: #c6f6d5 !important
}

.bg-green-300 {
  background-color: #9ae6b4 !important
}

.bg-green-400 {
  background-color: #68d391 !important
}

.bg-green-500 {
  background-color: #48bb78 !important
}

.bg-green-600 {
  background-color: #38a169 !important
}

.bg-green-700 {
  background-color: #2f855a !important
}

.bg-green-800 {
  background-color: #276749 !important
}

.bg-green-900 {
  background-color: #22543d !important
}

.bg-teal-100 {
  background-color: #e6fffa !important
}

.bg-teal-200 {
  background-color: #b2f5ea !important
}

.bg-teal-300 {
  background-color: #81e6d9 !important
}

.bg-teal-400 {
  background-color: #4fd1c5 !important
}

.bg-teal-500 {
  background-color: #38b2ac !important
}

.bg-teal-600 {
  background-color: #319795 !important
}

.bg-teal-700 {
  background-color: #2c7a7b !important
}

.bg-teal-800 {
  background-color: #285e61 !important
}

.bg-teal-900 {
  background-color: #234e52 !important
}

.bg-blue-100 {
  background-color: #ebf8ff !important
}

.bg-blue-200 {
  background-color: #bee3f8 !important
}

.bg-blue-300 {
  background-color: #90cdf4 !important
}

.bg-blue-400 {
  background-color: #63b3ed !important
}

.bg-blue-500 {
  background-color: #4299e1 !important
}

.bg-blue-600 {
  background-color: #3182ce !important
}

.bg-blue-700 {
  background-color: #2b6cb0 !important
}

.bg-blue-800 {
  background-color: #2c5282 !important
}

.bg-blue-900 {
  background-color: #2a4365 !important
}

.bg-indigo-100 {
  background-color: #ebf4ff !important
}

.bg-indigo-200 {
  background-color: #c3dafe !important
}

.bg-indigo-300 {
  background-color: #a3bffa !important
}

.bg-indigo-400 {
  background-color: #7f9cf5 !important
}

.bg-indigo-500 {
  background-color: #667eea !important
}

.bg-indigo-600 {
  background-color: #5a67d8 !important
}

.bg-indigo-700 {
  background-color: #4c51bf !important
}

.bg-indigo-800 {
  background-color: #434190 !important
}

.bg-indigo-900 {
  background-color: #3c366b !important
}

.bg-purple-100 {
  background-color: #faf5ff !important
}

.bg-purple-200 {
  background-color: #e9d8fd !important
}

.bg-purple-300 {
  background-color: #d6bcfa !important
}

.bg-purple-400 {
  background-color: #b794f4 !important
}

.bg-purple-500 {
  background-color: #9f7aea !important
}

.bg-purple-600 {
  background-color: #805ad5 !important
}

.bg-purple-700 {
  background-color: #6b46c1 !important
}

.bg-purple-800 {
  background-color: #553c9a !important
}

.bg-purple-900 {
  background-color: #44337a !important
}

.bg-pink-100 {
  background-color: #fff5f7 !important
}

.bg-pink-200 {
  background-color: #fed7e2 !important
}

.bg-pink-300 {
  background-color: #fbb6ce !important
}

.bg-pink-400 {
  background-color: #f687b3 !important
}

.bg-pink-500 {
  background-color: #ed64a6 !important
}

.bg-pink-600 {
  background-color: #d53f8c !important
}

.bg-pink-700 {
  background-color: #b83280 !important
}

.bg-pink-800 {
  background-color: #97266d !important
}

.bg-pink-900 {
  background-color: #702459 !important
}

.hover\:bg-transparent:hover {
  background-color: transparent !important
}

.hover\:bg-current:hover {
  background-color: currentColor !important
}

.hover\:bg-black:hover {
  background-color: #000 !important
}

.hover\:bg-white:hover {
  background-color: #fff !important
}

.hover\:bg-gray-100:hover {
  background-color: #f7fafc !important
}

.hover\:bg-gray-200:hover {
  background-color: #edf2f7 !important
}

.hover\:bg-gray-300:hover {
  background-color: #e2e8f0 !important
}

.hover\:bg-gray-400:hover {
  background-color: #cbd5e0 !important
}

.hover\:bg-gray-500:hover {
  background-color: #a0aec0 !important
}

.hover\:bg-gray-600:hover {
  background-color: #718096 !important
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568 !important
}

.hover\:bg-gray-800:hover {
  background-color: #2d3748 !important
}

.hover\:bg-gray-900:hover {
  background-color: #1a202c !important
}

.hover\:bg-red-100:hover {
  background-color: #fff5f5 !important
}

.hover\:bg-red-200:hover {
  background-color: #fed7d7 !important
}

.hover\:bg-red-300:hover {
  background-color: #feb2b2 !important
}

.hover\:bg-red-400:hover {
  background-color: #fc8181 !important
}

.hover\:bg-red-500:hover {
  background-color: #f56565 !important
}

.hover\:bg-red-600:hover {
  background-color: #e53e3e !important
}

.hover\:bg-red-700:hover {
  background-color: #c53030 !important
}

.hover\:bg-red-800:hover {
  background-color: #9b2c2c !important
}

.hover\:bg-red-900:hover {
  background-color: #742a2a !important
}

.hover\:bg-orange-100:hover {
  background-color: #fffaf0 !important
}

.hover\:bg-orange-200:hover {
  background-color: #feebc8 !important
}

.hover\:bg-orange-300:hover {
  background-color: #fbd38d !important
}

.hover\:bg-orange-400:hover {
  background-color: #f6ad55 !important
}

.hover\:bg-orange-500:hover {
  background-color: #ed8936 !important
}

.hover\:bg-orange-600:hover {
  background-color: #dd6b20 !important
}

.hover\:bg-orange-700:hover {
  background-color: #c05621 !important
}

.hover\:bg-orange-800:hover {
  background-color: #9c4221 !important
}

.hover\:bg-orange-900:hover {
  background-color: #7b341e !important
}

.hover\:bg-yellow-100:hover {
  background-color: #fffff0 !important
}

.hover\:bg-yellow-200:hover {
  background-color: #fefcbf !important
}

.hover\:bg-yellow-300:hover {
  background-color: #faf089 !important
}

.hover\:bg-yellow-400:hover {
  background-color: #f6e05e !important
}

.hover\:bg-yellow-500:hover {
  background-color: #ecc94b !important
}

.hover\:bg-yellow-600:hover {
  background-color: #d69e2e !important
}

.hover\:bg-yellow-700:hover {
  background-color: #b7791f !important
}

.hover\:bg-yellow-800:hover {
  background-color: #975a16 !important
}

.hover\:bg-yellow-900:hover {
  background-color: #744210 !important
}

.hover\:bg-green-100:hover {
  background-color: #f0fff4 !important
}

.hover\:bg-green-200:hover {
  background-color: #c6f6d5 !important
}

.hover\:bg-green-300:hover {
  background-color: #9ae6b4 !important
}

.hover\:bg-green-400:hover {
  background-color: #68d391 !important
}

.hover\:bg-green-500:hover {
  background-color: #48bb78 !important
}

.hover\:bg-green-600:hover {
  background-color: #38a169 !important
}

.hover\:bg-green-700:hover {
  background-color: #2f855a !important
}

.hover\:bg-green-800:hover {
  background-color: #276749 !important
}

.hover\:bg-green-900:hover {
  background-color: #22543d !important
}

.hover\:bg-teal-100:hover {
  background-color: #e6fffa !important
}

.hover\:bg-teal-200:hover {
  background-color: #b2f5ea !important
}

.hover\:bg-teal-300:hover {
  background-color: #81e6d9 !important
}

.hover\:bg-teal-400:hover {
  background-color: #4fd1c5 !important
}

.hover\:bg-teal-500:hover {
  background-color: #38b2ac !important
}

.hover\:bg-teal-600:hover {
  background-color: #319795 !important
}

.hover\:bg-teal-700:hover {
  background-color: #2c7a7b !important
}

.hover\:bg-teal-800:hover {
  background-color: #285e61 !important
}

.hover\:bg-teal-900:hover {
  background-color: #234e52 !important
}

.hover\:bg-blue-100:hover {
  background-color: #ebf8ff !important
}

.hover\:bg-blue-200:hover {
  background-color: #bee3f8 !important
}

.hover\:bg-blue-300:hover {
  background-color: #90cdf4 !important
}

.hover\:bg-blue-400:hover {
  background-color: #63b3ed !important
}

.hover\:bg-blue-500:hover {
  background-color: #4299e1 !important
}

.hover\:bg-blue-600:hover {
  background-color: #3182ce !important
}

.hover\:bg-blue-700:hover {
  background-color: #2b6cb0 !important
}

.hover\:bg-blue-800:hover {
  background-color: #2c5282 !important
}

.hover\:bg-blue-900:hover {
  background-color: #2a4365 !important
}

.hover\:bg-indigo-100:hover {
  background-color: #ebf4ff !important
}

.hover\:bg-indigo-200:hover {
  background-color: #c3dafe !important
}

.hover\:bg-indigo-300:hover {
  background-color: #a3bffa !important
}

.hover\:bg-indigo-400:hover {
  background-color: #7f9cf5 !important
}

.hover\:bg-indigo-500:hover {
  background-color: #667eea !important
}

.hover\:bg-indigo-600:hover {
  background-color: #5a67d8 !important
}

.hover\:bg-indigo-700:hover {
  background-color: #4c51bf !important
}

.hover\:bg-indigo-800:hover {
  background-color: #434190 !important
}

.hover\:bg-indigo-900:hover {
  background-color: #3c366b !important
}

.hover\:bg-purple-100:hover {
  background-color: #faf5ff !important
}

.hover\:bg-purple-200:hover {
  background-color: #e9d8fd !important
}

.hover\:bg-purple-300:hover {
  background-color: #d6bcfa !important
}

.hover\:bg-purple-400:hover {
  background-color: #b794f4 !important
}

.hover\:bg-purple-500:hover {
  background-color: #9f7aea !important
}

.hover\:bg-purple-600:hover {
  background-color: #805ad5 !important
}

.hover\:bg-purple-700:hover {
  background-color: #6b46c1 !important
}

.hover\:bg-purple-800:hover {
  background-color: #553c9a !important
}

.hover\:bg-purple-900:hover {
  background-color: #44337a !important
}

.hover\:bg-pink-100:hover {
  background-color: #fff5f7 !important
}

.hover\:bg-pink-200:hover {
  background-color: #fed7e2 !important
}

.hover\:bg-pink-300:hover {
  background-color: #fbb6ce !important
}

.hover\:bg-pink-400:hover {
  background-color: #f687b3 !important
}

.hover\:bg-pink-500:hover {
  background-color: #ed64a6 !important
}

.hover\:bg-pink-600:hover {
  background-color: #d53f8c !important
}

.hover\:bg-pink-700:hover {
  background-color: #b83280 !important
}

.hover\:bg-pink-800:hover {
  background-color: #97266d !important
}

.hover\:bg-pink-900:hover {
  background-color: #702459 !important
}

.focus\:bg-transparent:focus {
  background-color: transparent !important
}

.focus\:bg-current:focus {
  background-color: currentColor !important
}

.focus\:bg-black:focus {
  background-color: #000 !important
}

.focus\:bg-white:focus {
  background-color: #fff !important
}

.focus\:bg-gray-100:focus {
  background-color: #f7fafc !important
}

.focus\:bg-gray-200:focus {
  background-color: #edf2f7 !important
}

.focus\:bg-gray-300:focus {
  background-color: #e2e8f0 !important
}

.focus\:bg-gray-400:focus {
  background-color: #cbd5e0 !important
}

.focus\:bg-gray-500:focus {
  background-color: #a0aec0 !important
}

.focus\:bg-gray-600:focus {
  background-color: #718096 !important
}

.focus\:bg-gray-700:focus {
  background-color: #4a5568 !important
}

.focus\:bg-gray-800:focus {
  background-color: #2d3748 !important
}

.focus\:bg-gray-900:focus {
  background-color: #1a202c !important
}

.focus\:bg-red-100:focus {
  background-color: #fff5f5 !important
}

.focus\:bg-red-200:focus {
  background-color: #fed7d7 !important
}

.focus\:bg-red-300:focus {
  background-color: #feb2b2 !important
}

.focus\:bg-red-400:focus {
  background-color: #fc8181 !important
}

.focus\:bg-red-500:focus {
  background-color: #f56565 !important
}

.focus\:bg-red-600:focus {
  background-color: #e53e3e !important
}

.focus\:bg-red-700:focus {
  background-color: #c53030 !important
}

.focus\:bg-red-800:focus {
  background-color: #9b2c2c !important
}

.focus\:bg-red-900:focus {
  background-color: #742a2a !important
}

.focus\:bg-orange-100:focus {
  background-color: #fffaf0 !important
}

.focus\:bg-orange-200:focus {
  background-color: #feebc8 !important
}

.focus\:bg-orange-300:focus {
  background-color: #fbd38d !important
}

.focus\:bg-orange-400:focus {
  background-color: #f6ad55 !important
}

.focus\:bg-orange-500:focus {
  background-color: #ed8936 !important
}

.focus\:bg-orange-600:focus {
  background-color: #dd6b20 !important
}

.focus\:bg-orange-700:focus {
  background-color: #c05621 !important
}

.focus\:bg-orange-800:focus {
  background-color: #9c4221 !important
}

.focus\:bg-orange-900:focus {
  background-color: #7b341e !important
}

.focus\:bg-yellow-100:focus {
  background-color: #fffff0 !important
}

.focus\:bg-yellow-200:focus {
  background-color: #fefcbf !important
}

.focus\:bg-yellow-300:focus {
  background-color: #faf089 !important
}

.focus\:bg-yellow-400:focus {
  background-color: #f6e05e !important
}

.focus\:bg-yellow-500:focus {
  background-color: #ecc94b !important
}

.focus\:bg-yellow-600:focus {
  background-color: #d69e2e !important
}

.focus\:bg-yellow-700:focus {
  background-color: #b7791f !important
}

.focus\:bg-yellow-800:focus {
  background-color: #975a16 !important
}

.focus\:bg-yellow-900:focus {
  background-color: #744210 !important
}

.focus\:bg-green-100:focus {
  background-color: #f0fff4 !important
}

.focus\:bg-green-200:focus {
  background-color: #c6f6d5 !important
}

.focus\:bg-green-300:focus {
  background-color: #9ae6b4 !important
}

.focus\:bg-green-400:focus {
  background-color: #68d391 !important
}

.focus\:bg-green-500:focus {
  background-color: #48bb78 !important
}

.focus\:bg-green-600:focus {
  background-color: #38a169 !important
}

.focus\:bg-green-700:focus {
  background-color: #2f855a !important
}

.focus\:bg-green-800:focus {
  background-color: #276749 !important
}

.focus\:bg-green-900:focus {
  background-color: #22543d !important
}

.focus\:bg-teal-100:focus {
  background-color: #e6fffa !important
}

.focus\:bg-teal-200:focus {
  background-color: #b2f5ea !important
}

.focus\:bg-teal-300:focus {
  background-color: #81e6d9 !important
}

.focus\:bg-teal-400:focus {
  background-color: #4fd1c5 !important
}

.focus\:bg-teal-500:focus {
  background-color: #38b2ac !important
}

.focus\:bg-teal-600:focus {
  background-color: #319795 !important
}

.focus\:bg-teal-700:focus {
  background-color: #2c7a7b !important
}

.focus\:bg-teal-800:focus {
  background-color: #285e61 !important
}

.focus\:bg-teal-900:focus {
  background-color: #234e52 !important
}

.focus\:bg-blue-100:focus {
  background-color: #ebf8ff !important
}

.focus\:bg-blue-200:focus {
  background-color: #bee3f8 !important
}

.focus\:bg-blue-300:focus {
  background-color: #90cdf4 !important
}

.focus\:bg-blue-400:focus {
  background-color: #63b3ed !important
}

.focus\:bg-blue-500:focus {
  background-color: #4299e1 !important
}

.focus\:bg-blue-600:focus {
  background-color: #3182ce !important
}

.focus\:bg-blue-700:focus {
  background-color: #2b6cb0 !important
}

.focus\:bg-blue-800:focus {
  background-color: #2c5282 !important
}

.focus\:bg-blue-900:focus {
  background-color: #2a4365 !important
}

.focus\:bg-indigo-100:focus {
  background-color: #ebf4ff !important
}

.focus\:bg-indigo-200:focus {
  background-color: #c3dafe !important
}

.focus\:bg-indigo-300:focus {
  background-color: #a3bffa !important
}

.focus\:bg-indigo-400:focus {
  background-color: #7f9cf5 !important
}

.focus\:bg-indigo-500:focus {
  background-color: #667eea !important
}

.focus\:bg-indigo-600:focus {
  background-color: #5a67d8 !important
}

.focus\:bg-indigo-700:focus {
  background-color: #4c51bf !important
}

.focus\:bg-indigo-800:focus {
  background-color: #434190 !important
}

.focus\:bg-indigo-900:focus {
  background-color: #3c366b !important
}

.focus\:bg-purple-100:focus {
  background-color: #faf5ff !important
}

.focus\:bg-purple-200:focus {
  background-color: #e9d8fd !important
}

.focus\:bg-purple-300:focus {
  background-color: #d6bcfa !important
}

.focus\:bg-purple-400:focus {
  background-color: #b794f4 !important
}

.focus\:bg-purple-500:focus {
  background-color: #9f7aea !important
}

.focus\:bg-purple-600:focus {
  background-color: #805ad5 !important
}

.focus\:bg-purple-700:focus {
  background-color: #6b46c1 !important
}

.focus\:bg-purple-800:focus {
  background-color: #553c9a !important
}

.focus\:bg-purple-900:focus {
  background-color: #44337a !important
}

.focus\:bg-pink-100:focus {
  background-color: #fff5f7 !important
}

.focus\:bg-pink-200:focus {
  background-color: #fed7e2 !important
}

.focus\:bg-pink-300:focus {
  background-color: #fbb6ce !important
}

.focus\:bg-pink-400:focus {
  background-color: #f687b3 !important
}

.focus\:bg-pink-500:focus {
  background-color: #ed64a6 !important
}

.focus\:bg-pink-600:focus {
  background-color: #d53f8c !important
}

.focus\:bg-pink-700:focus {
  background-color: #b83280 !important
}

.focus\:bg-pink-800:focus {
  background-color: #97266d !important
}

.focus\:bg-pink-900:focus {
  background-color: #702459 !important
}

.cursor-auto {
  cursor: auto !important
}

.cursor-default {
  cursor: default !important
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-wait {
  cursor: wait !important
}

.cursor-text {
  cursor: text !important
}

.cursor-move {
  cursor: move !important
}

.cursor-not-allowed {
  cursor: not-allowed !important
}

.h-0 {
  height: 0 !important
}

.h-4 {
  height: 4px !important
}

.h-6 {
  height: 6px !important
}

.h-8 {
  height: 8px !important
}

.h-12 {
  height: 12px !important
}

.h-16 {
  height: 16px !important
}

.h-20 {
  height: 20px !important
}

.h-24 {
  height: 24px !important
}

.h-28 {
  height: 28px !important
}

.h-32 {
  height: 32px !important
}

.h-36 {
  height: 36px !important
}

.h-40 {
  height: 40px !important
}

.h-44 {
  height: 44px !important
}

.h-48 {
  height: 48px !important
}

.h-52 {
  height: 52px !important
}

.h-56 {
  height: 56px !important
}

.h-60 {
  height: 60px !important
}

.h-64 {
  height: 64px !important
}

.h-68 {
  height: 68px !important
}

.h-72 {
  height: 72px !important
}

.h-76 {
  height: 76px !important
}

.h-80 {
  height: 80px !important
}

.h-84 {
  height: 84px !important
}

.h-88 {
  height: 88px !important
}

.h-92 {
  height: 92px !important
}

.h-96 {
  height: 96px !important
}

.h-128 {
  height: 128px !important
}

.h-160 {
  height: 160px !important
}

.h-192 {
  height: 192px !important
}

.h-224 {
  height: 224px !important
}

.h-256 {
  height: 256px !important
}

.h-320 {
  height: 320px !important
}

.h-auto {
  height: auto !important
}

.h-px {
  height: 1px !important
}

.h-full {
  height: 100% !important
}

.h-screen {
  height: 100vh !important
}

.w-0 {
  width: 0 !important
}

.w-4 {
  width: 4px !important
}

.w-6 {
  width: 6px !important
}

.w-8 {
  width: 8px !important
}

.w-12 {
  width: 12px !important
}

.w-16 {
  width: 16px !important
}

.w-20 {
  width: 20px !important
}

.w-24 {
  width: 24px !important
}

.w-28 {
  width: 28px !important
}

.w-32 {
  width: 32px !important
}

.w-36 {
  width: 36px !important
}

.w-40 {
  width: 40px !important
}

.w-44 {
  width: 44px !important
}

.w-48 {
  width: 48px !important
}

.w-52 {
  width: 52px !important
}

.w-56 {
  width: 56px !important
}

.w-60 {
  width: 60px !important
}

.w-64 {
  width: 64px !important
}

.w-68 {
  width: 68px !important
}

.w-72 {
  width: 72px !important
}

.w-76 {
  width: 76px !important
}

.w-80 {
  width: 80px !important
}

.w-84 {
  width: 84px !important
}

.w-88 {
  width: 88px !important
}

.w-92 {
  width: 92px !important
}

.w-96 {
  width: 96px !important
}

.w-128 {
  width: 128px !important
}

.w-160 {
  width: 160px !important
}

.w-192 {
  width: 192px !important
}

.w-224 {
  width: 224px !important
}

.w-256 {
  width: 256px !important
}

.w-320 {
  width: 320px !important
}

.w-auto {
  width: auto !important
}

.w-px {
  width: 1px !important
}

.w-1\/2 {
  width: 50% !important
}

.w-1\/3 {
  width: 33.333333% !important
}

.w-2\/3 {
  width: 66.666667% !important
}

.w-1\/4 {
  width: 25% !important
}

.w-2\/4 {
  width: 50% !important
}

.w-3\/4 {
  width: 75% !important
}

.w-1\/5 {
  width: 20% !important
}

.w-2\/5 {
  width: 40% !important
}

.w-3\/5 {
  width: 60% !important
}

.w-4\/5 {
  width: 80% !important
}

.w-1\/6 {
  width: 16.666667% !important
}

.w-2\/6 {
  width: 33.333333% !important
}

.w-3\/6 {
  width: 50% !important
}

.w-4\/6 {
  width: 66.666667% !important
}

.w-5\/6 {
  width: 83.333333% !important
}

.w-1\/12 {
  width: 8.333333% !important
}

.w-2\/12 {
  width: 16.666667% !important
}

.w-3\/12 {
  width: 25% !important
}

.w-4\/12 {
  width: 33.333333% !important
}

.w-5\/12 {
  width: 41.666667% !important
}

.w-6\/12 {
  width: 50% !important
}

.w-7\/12 {
  width: 58.333333% !important
}

.w-8\/12 {
  width: 66.666667% !important
}

.w-9\/12 {
  width: 75% !important
}

.w-10\/12 {
  width: 83.333333% !important
}

.w-11\/12 {
  width: 91.666667% !important
}

.w-full {
  width: 100% !important
}

.w-screen {
  width: 100vw !important
}

.max-h-full {
  max-height: 100% !important
}

.max-h-screen {
  max-height: 100vh !important
}

.max-w-none {
  max-width: none !important
}

.max-w-xs {
  max-width: 20rem !important
}

.max-w-sm {
  max-width: 24rem !important
}

.max-w-md {
  max-width: 28rem !important
}

.max-w-lg {
  max-width: 32rem !important
}

.max-w-xl {
  max-width: 36rem !important
}

.max-w-2xl {
  max-width: 42rem !important
}

.max-w-3xl {
  max-width: 48rem !important
}

.max-w-4xl {
  max-width: 56rem !important
}

.max-w-5xl {
  max-width: 64rem !important
}

.max-w-6xl {
  max-width: 72rem !important
}

.max-w-full {
  max-width: 100% !important
}

.max-w-screen-sm {
  max-width: 640px !important
}

.max-w-screen-md {
  max-width: 768px !important
}

.max-w-screen-lg {
  max-width: 1024px !important
}

.max-w-screen-xl {
  max-width: 1280px !important
}

.min-h-0 {
  min-height: 0 !important
}

.min-h-full {
  min-height: 100% !important
}

.min-h-screen {
  min-height: 100vh !important
}

.min-w-0 {
  min-width: 0 !important
}

.min-w-full {
  min-width: 100% !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-scroll {
  overflow: scroll !important
}

.overflow-x-auto {
  overflow-x: auto !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.overflow-x-hidden {
  overflow-x: hidden !important
}

.overflow-y-hidden {
  overflow-y: hidden !important
}

.overflow-x-visible {
  overflow-x: visible !important
}

.overflow-y-visible {
  overflow-y: visible !important
}

.overflow-x-scroll {
  overflow-x: scroll !important
}

.overflow-y-scroll {
  overflow-y: scroll !important
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto !important
}

.static {
  position: static !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.z-0 {
  z-index: 0 !important
}

.z-10 {
  z-index: 10 !important
}

.z-20 {
  z-index: 20 !important
}

.z-30 {
  z-index: 30 !important
}

.z-40 {
  z-index: 40 !important
}

.z-50 {
  z-index: 50 !important
}

.z-auto {
  z-index: auto !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: flex !important
}

.inline-flex {
  display: inline-flex !important
}

.table {
  display: table !important
}

.table-caption {
  display: table-caption !important
}

.table-cell {
  display: table-cell !important
}

.table-column {
  display: table-column !important
}

.table-column-group {
  display: table-column-group !important
}

.table-footer-group {
  display: table-footer-group !important
}

.table-header-group {
  display: table-header-group !important
}

.table-row-group {
  display: table-row-group !important
}

.table-row {
  display: table-row !important
}

.flow-root {
  display: flow-root !important
}

.grid {
  display: grid !important
}

.inline-grid {
  display: inline-grid !important
}

.contents {
  display: contents !important
}

.hidden {
  display: none !important
}

.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important
}

.inset-auto {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important
}

.inset-x-0 {
  right: 0 !important;
  left: 0 !important
}

.inset-y-auto {
  top: auto !important;
  bottom: auto !important
}

.inset-x-auto {
  right: auto !important;
  left: auto !important
}

.top-0 {
  top: 0 !important
}

.right-0 {
  right: 0 !important
}

.bottom-0 {
  bottom: 0 !important
}

.left-0 {
  left: 0 !important
}

.top-auto {
  top: auto !important
}

.right-auto {
  right: auto !important
}

.bottom-auto {
  bottom: auto !important
}

.left-auto {
  left: auto !important
}

@media (min-width: 640px) {
  .sm\:m-0 {
    margin: 0 !important
  }

  .sm\:m-4 {
    margin: 4px !important
  }

  .sm\:m-6 {
    margin: 6px !important
  }

  .sm\:m-8 {
    margin: 8px !important
  }

  .sm\:m-12 {
    margin: 12px !important
  }

  .sm\:m-16 {
    margin: 16px !important
  }

  .sm\:m-20 {
    margin: 20px !important
  }

  .sm\:m-24 {
    margin: 24px !important
  }

  .sm\:m-28 {
    margin: 28px !important
  }

  .sm\:m-32 {
    margin: 32px !important
  }

  .sm\:m-36 {
    margin: 36px !important
  }

  .sm\:m-40 {
    margin: 40px !important
  }

  .sm\:m-44 {
    margin: 44px !important
  }

  .sm\:m-48 {
    margin: 48px !important
  }

  .sm\:m-52 {
    margin: 52px !important
  }

  .sm\:m-56 {
    margin: 56px !important
  }

  .sm\:m-60 {
    margin: 60px !important
  }

  .sm\:m-64 {
    margin: 64px !important
  }

  .sm\:m-68 {
    margin: 68px !important
  }

  .sm\:m-72 {
    margin: 72px !important
  }

  .sm\:m-76 {
    margin: 76px !important
  }

  .sm\:m-80 {
    margin: 80px !important
  }

  .sm\:m-84 {
    margin: 84px !important
  }

  .sm\:m-88 {
    margin: 88px !important
  }

  .sm\:m-92 {
    margin: 92px !important
  }

  .sm\:m-96 {
    margin: 96px !important
  }

  .sm\:m-128 {
    margin: 128px !important
  }

  .sm\:m-160 {
    margin: 160px !important
  }

  .sm\:m-192 {
    margin: 192px !important
  }

  .sm\:m-224 {
    margin: 224px !important
  }

  .sm\:m-256 {
    margin: 256px !important
  }

  .sm\:m-320 {
    margin: 320px !important
  }

  .sm\:m-auto {
    margin: auto !important
  }

  .sm\:m-px {
    margin: 1px !important
  }

  .sm\:-m-4 {
    margin: -4px !important
  }

  .sm\:-m-6 {
    margin: -6px !important
  }

  .sm\:-m-8 {
    margin: -8px !important
  }

  .sm\:-m-12 {
    margin: -12px !important
  }

  .sm\:-m-16 {
    margin: -16px !important
  }

  .sm\:-m-20 {
    margin: -20px !important
  }

  .sm\:-m-24 {
    margin: -24px !important
  }

  .sm\:-m-28 {
    margin: -28px !important
  }

  .sm\:-m-32 {
    margin: -32px !important
  }

  .sm\:-m-36 {
    margin: -36px !important
  }

  .sm\:-m-40 {
    margin: -40px !important
  }

  .sm\:-m-44 {
    margin: -44px !important
  }

  .sm\:-m-48 {
    margin: -48px !important
  }

  .sm\:-m-52 {
    margin: -52px !important
  }

  .sm\:-m-56 {
    margin: -56px !important
  }

  .sm\:-m-60 {
    margin: -60px !important
  }

  .sm\:-m-64 {
    margin: -64px !important
  }

  .sm\:-m-68 {
    margin: -68px !important
  }

  .sm\:-m-72 {
    margin: -72px !important
  }

  .sm\:-m-76 {
    margin: -76px !important
  }

  .sm\:-m-80 {
    margin: -80px !important
  }

  .sm\:-m-84 {
    margin: -84px !important
  }

  .sm\:-m-88 {
    margin: -88px !important
  }

  .sm\:-m-92 {
    margin: -92px !important
  }

  .sm\:-m-96 {
    margin: -96px !important
  }

  .sm\:-m-128 {
    margin: -128px !important
  }

  .sm\:-m-160 {
    margin: -160px !important
  }

  .sm\:-m-192 {
    margin: -192px !important
  }

  .sm\:-m-224 {
    margin: -224px !important
  }

  .sm\:-m-256 {
    margin: -256px !important
  }

  .sm\:-m-320 {
    margin: -320px !important
  }

  .sm\:-m-px {
    margin: -1px !important
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .sm\:my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important
  }

  .sm\:mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important
  }

  .sm\:my-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important
  }

  .sm\:mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .sm\:my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important
  }

  .sm\:mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .sm\:my-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important
  }

  .sm\:mx-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .sm\:my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important
  }

  .sm\:mx-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .sm\:my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
  }

  .sm\:mx-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .sm\:my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important
  }

  .sm\:mx-24 {
    margin-left: 24px !important;
    margin-right: 24px !important
  }

  .sm\:my-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important
  }

  .sm\:mx-28 {
    margin-left: 28px !important;
    margin-right: 28px !important
  }

  .sm\:my-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important
  }

  .sm\:mx-32 {
    margin-left: 32px !important;
    margin-right: 32px !important
  }

  .sm\:my-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important
  }

  .sm\:mx-36 {
    margin-left: 36px !important;
    margin-right: 36px !important
  }

  .sm\:my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important
  }

  .sm\:mx-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .sm\:my-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important
  }

  .sm\:mx-44 {
    margin-left: 44px !important;
    margin-right: 44px !important
  }

  .sm\:my-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important
  }

  .sm\:mx-48 {
    margin-left: 48px !important;
    margin-right: 48px !important
  }

  .sm\:my-52 {
    margin-top: 52px !important;
    margin-bottom: 52px !important
  }

  .sm\:mx-52 {
    margin-left: 52px !important;
    margin-right: 52px !important
  }

  .sm\:my-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important
  }

  .sm\:mx-56 {
    margin-left: 56px !important;
    margin-right: 56px !important
  }

  .sm\:my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important
  }

  .sm\:mx-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .sm\:my-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important
  }

  .sm\:mx-64 {
    margin-left: 64px !important;
    margin-right: 64px !important
  }

  .sm\:my-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important
  }

  .sm\:mx-68 {
    margin-left: 68px !important;
    margin-right: 68px !important
  }

  .sm\:my-72 {
    margin-top: 72px !important;
    margin-bottom: 72px !important
  }

  .sm\:mx-72 {
    margin-left: 72px !important;
    margin-right: 72px !important
  }

  .sm\:my-76 {
    margin-top: 76px !important;
    margin-bottom: 76px !important
  }

  .sm\:mx-76 {
    margin-left: 76px !important;
    margin-right: 76px !important
  }

  .sm\:my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important
  }

  .sm\:mx-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .sm\:my-84 {
    margin-top: 84px !important;
    margin-bottom: 84px !important
  }

  .sm\:mx-84 {
    margin-left: 84px !important;
    margin-right: 84px !important
  }

  .sm\:my-88 {
    margin-top: 88px !important;
    margin-bottom: 88px !important
  }

  .sm\:mx-88 {
    margin-left: 88px !important;
    margin-right: 88px !important
  }

  .sm\:my-92 {
    margin-top: 92px !important;
    margin-bottom: 92px !important
  }

  .sm\:mx-92 {
    margin-left: 92px !important;
    margin-right: 92px !important
  }

  .sm\:my-96 {
    margin-top: 96px !important;
    margin-bottom: 96px !important
  }

  .sm\:mx-96 {
    margin-left: 96px !important;
    margin-right: 96px !important
  }

  .sm\:my-128 {
    margin-top: 128px !important;
    margin-bottom: 128px !important
  }

  .sm\:mx-128 {
    margin-left: 128px !important;
    margin-right: 128px !important
  }

  .sm\:my-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important
  }

  .sm\:mx-160 {
    margin-left: 160px !important;
    margin-right: 160px !important
  }

  .sm\:my-192 {
    margin-top: 192px !important;
    margin-bottom: 192px !important
  }

  .sm\:mx-192 {
    margin-left: 192px !important;
    margin-right: 192px !important
  }

  .sm\:my-224 {
    margin-top: 224px !important;
    margin-bottom: 224px !important
  }

  .sm\:mx-224 {
    margin-left: 224px !important;
    margin-right: 224px !important
  }

  .sm\:my-256 {
    margin-top: 256px !important;
    margin-bottom: 256px !important
  }

  .sm\:mx-256 {
    margin-left: 256px !important;
    margin-right: 256px !important
  }

  .sm\:my-320 {
    margin-top: 320px !important;
    margin-bottom: 320px !important
  }

  .sm\:mx-320 {
    margin-left: 320px !important;
    margin-right: 320px !important
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .sm\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important
  }

  .sm\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
  }

  .sm\:-my-4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important
  }

  .sm\:-mx-4 {
    margin-left: -4px !important;
    margin-right: -4px !important
  }

  .sm\:-my-6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important
  }

  .sm\:-mx-6 {
    margin-left: -6px !important;
    margin-right: -6px !important
  }

  .sm\:-my-8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important
  }

  .sm\:-mx-8 {
    margin-left: -8px !important;
    margin-right: -8px !important
  }

  .sm\:-my-12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important
  }

  .sm\:-mx-12 {
    margin-left: -12px !important;
    margin-right: -12px !important
  }

  .sm\:-my-16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important
  }

  .sm\:-mx-16 {
    margin-left: -16px !important;
    margin-right: -16px !important
  }

  .sm\:-my-20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important
  }

  .sm\:-mx-20 {
    margin-left: -20px !important;
    margin-right: -20px !important
  }

  .sm\:-my-24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important
  }

  .sm\:-mx-24 {
    margin-left: -24px !important;
    margin-right: -24px !important
  }

  .sm\:-my-28 {
    margin-top: -28px !important;
    margin-bottom: -28px !important
  }

  .sm\:-mx-28 {
    margin-left: -28px !important;
    margin-right: -28px !important
  }

  .sm\:-my-32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important
  }

  .sm\:-mx-32 {
    margin-left: -32px !important;
    margin-right: -32px !important
  }

  .sm\:-my-36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important
  }

  .sm\:-mx-36 {
    margin-left: -36px !important;
    margin-right: -36px !important
  }

  .sm\:-my-40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important
  }

  .sm\:-mx-40 {
    margin-left: -40px !important;
    margin-right: -40px !important
  }

  .sm\:-my-44 {
    margin-top: -44px !important;
    margin-bottom: -44px !important
  }

  .sm\:-mx-44 {
    margin-left: -44px !important;
    margin-right: -44px !important
  }

  .sm\:-my-48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important
  }

  .sm\:-mx-48 {
    margin-left: -48px !important;
    margin-right: -48px !important
  }

  .sm\:-my-52 {
    margin-top: -52px !important;
    margin-bottom: -52px !important
  }

  .sm\:-mx-52 {
    margin-left: -52px !important;
    margin-right: -52px !important
  }

  .sm\:-my-56 {
    margin-top: -56px !important;
    margin-bottom: -56px !important
  }

  .sm\:-mx-56 {
    margin-left: -56px !important;
    margin-right: -56px !important
  }

  .sm\:-my-60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important
  }

  .sm\:-mx-60 {
    margin-left: -60px !important;
    margin-right: -60px !important
  }

  .sm\:-my-64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important
  }

  .sm\:-mx-64 {
    margin-left: -64px !important;
    margin-right: -64px !important
  }

  .sm\:-my-68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important
  }

  .sm\:-mx-68 {
    margin-left: -68px !important;
    margin-right: -68px !important
  }

  .sm\:-my-72 {
    margin-top: -72px !important;
    margin-bottom: -72px !important
  }

  .sm\:-mx-72 {
    margin-left: -72px !important;
    margin-right: -72px !important
  }

  .sm\:-my-76 {
    margin-top: -76px !important;
    margin-bottom: -76px !important
  }

  .sm\:-mx-76 {
    margin-left: -76px !important;
    margin-right: -76px !important
  }

  .sm\:-my-80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important
  }

  .sm\:-mx-80 {
    margin-left: -80px !important;
    margin-right: -80px !important
  }

  .sm\:-my-84 {
    margin-top: -84px !important;
    margin-bottom: -84px !important
  }

  .sm\:-mx-84 {
    margin-left: -84px !important;
    margin-right: -84px !important
  }

  .sm\:-my-88 {
    margin-top: -88px !important;
    margin-bottom: -88px !important
  }

  .sm\:-mx-88 {
    margin-left: -88px !important;
    margin-right: -88px !important
  }

  .sm\:-my-92 {
    margin-top: -92px !important;
    margin-bottom: -92px !important
  }

  .sm\:-mx-92 {
    margin-left: -92px !important;
    margin-right: -92px !important
  }

  .sm\:-my-96 {
    margin-top: -96px !important;
    margin-bottom: -96px !important
  }

  .sm\:-mx-96 {
    margin-left: -96px !important;
    margin-right: -96px !important
  }

  .sm\:-my-128 {
    margin-top: -128px !important;
    margin-bottom: -128px !important
  }

  .sm\:-mx-128 {
    margin-left: -128px !important;
    margin-right: -128px !important
  }

  .sm\:-my-160 {
    margin-top: -160px !important;
    margin-bottom: -160px !important
  }

  .sm\:-mx-160 {
    margin-left: -160px !important;
    margin-right: -160px !important
  }

  .sm\:-my-192 {
    margin-top: -192px !important;
    margin-bottom: -192px !important
  }

  .sm\:-mx-192 {
    margin-left: -192px !important;
    margin-right: -192px !important
  }

  .sm\:-my-224 {
    margin-top: -224px !important;
    margin-bottom: -224px !important
  }

  .sm\:-mx-224 {
    margin-left: -224px !important;
    margin-right: -224px !important
  }

  .sm\:-my-256 {
    margin-top: -256px !important;
    margin-bottom: -256px !important
  }

  .sm\:-mx-256 {
    margin-left: -256px !important;
    margin-right: -256px !important
  }

  .sm\:-my-320 {
    margin-top: -320px !important;
    margin-bottom: -320px !important
  }

  .sm\:-mx-320 {
    margin-left: -320px !important;
    margin-right: -320px !important
  }

  .sm\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important
  }

  .sm\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important
  }

  .sm\:mt-0 {
    margin-top: 0 !important
  }

  .sm\:mr-0 {
    margin-right: 0 !important
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important
  }

  .sm\:ml-0 {
    margin-left: 0 !important
  }

  .sm\:mt-4 {
    margin-top: 4px !important
  }

  .sm\:mr-4 {
    margin-right: 4px !important
  }

  .sm\:mb-4 {
    margin-bottom: 4px !important
  }

  .sm\:ml-4 {
    margin-left: 4px !important
  }

  .sm\:mt-6 {
    margin-top: 6px !important
  }

  .sm\:mr-6 {
    margin-right: 6px !important
  }

  .sm\:mb-6 {
    margin-bottom: 6px !important
  }

  .sm\:ml-6 {
    margin-left: 6px !important
  }

  .sm\:mt-8 {
    margin-top: 8px !important
  }

  .sm\:mr-8 {
    margin-right: 8px !important
  }

  .sm\:mb-8 {
    margin-bottom: 8px !important
  }

  .sm\:ml-8 {
    margin-left: 8px !important
  }

  .sm\:mt-12 {
    margin-top: 12px !important
  }

  .sm\:mr-12 {
    margin-right: 12px !important
  }

  .sm\:mb-12 {
    margin-bottom: 12px !important
  }

  .sm\:ml-12 {
    margin-left: 12px !important
  }

  .sm\:mt-16 {
    margin-top: 16px !important
  }

  .sm\:mr-16 {
    margin-right: 16px !important
  }

  .sm\:mb-16 {
    margin-bottom: 16px !important
  }

  .sm\:ml-16 {
    margin-left: 16px !important
  }

  .sm\:mt-20 {
    margin-top: 20px !important
  }

  .sm\:mr-20 {
    margin-right: 20px !important
  }

  .sm\:mb-20 {
    margin-bottom: 20px !important
  }

  .sm\:ml-20 {
    margin-left: 20px !important
  }

  .sm\:mt-24 {
    margin-top: 24px !important
  }

  .sm\:mr-24 {
    margin-right: 24px !important
  }

  .sm\:mb-24 {
    margin-bottom: 24px !important
  }

  .sm\:ml-24 {
    margin-left: 24px !important
  }

  .sm\:mt-28 {
    margin-top: 28px !important
  }

  .sm\:mr-28 {
    margin-right: 28px !important
  }

  .sm\:mb-28 {
    margin-bottom: 28px !important
  }

  .sm\:ml-28 {
    margin-left: 28px !important
  }

  .sm\:mt-32 {
    margin-top: 32px !important
  }

  .sm\:mr-32 {
    margin-right: 32px !important
  }

  .sm\:mb-32 {
    margin-bottom: 32px !important
  }

  .sm\:ml-32 {
    margin-left: 32px !important
  }

  .sm\:mt-36 {
    margin-top: 36px !important
  }

  .sm\:mr-36 {
    margin-right: 36px !important
  }

  .sm\:mb-36 {
    margin-bottom: 36px !important
  }

  .sm\:ml-36 {
    margin-left: 36px !important
  }

  .sm\:mt-40 {
    margin-top: 40px !important
  }

  .sm\:mr-40 {
    margin-right: 40px !important
  }

  .sm\:mb-40 {
    margin-bottom: 40px !important
  }

  .sm\:ml-40 {
    margin-left: 40px !important
  }

  .sm\:mt-44 {
    margin-top: 44px !important
  }

  .sm\:mr-44 {
    margin-right: 44px !important
  }

  .sm\:mb-44 {
    margin-bottom: 44px !important
  }

  .sm\:ml-44 {
    margin-left: 44px !important
  }

  .sm\:mt-48 {
    margin-top: 48px !important
  }

  .sm\:mr-48 {
    margin-right: 48px !important
  }

  .sm\:mb-48 {
    margin-bottom: 48px !important
  }

  .sm\:ml-48 {
    margin-left: 48px !important
  }

  .sm\:mt-52 {
    margin-top: 52px !important
  }

  .sm\:mr-52 {
    margin-right: 52px !important
  }

  .sm\:mb-52 {
    margin-bottom: 52px !important
  }

  .sm\:ml-52 {
    margin-left: 52px !important
  }

  .sm\:mt-56 {
    margin-top: 56px !important
  }

  .sm\:mr-56 {
    margin-right: 56px !important
  }

  .sm\:mb-56 {
    margin-bottom: 56px !important
  }

  .sm\:ml-56 {
    margin-left: 56px !important
  }

  .sm\:mt-60 {
    margin-top: 60px !important
  }

  .sm\:mr-60 {
    margin-right: 60px !important
  }

  .sm\:mb-60 {
    margin-bottom: 60px !important
  }

  .sm\:ml-60 {
    margin-left: 60px !important
  }

  .sm\:mt-64 {
    margin-top: 64px !important
  }

  .sm\:mr-64 {
    margin-right: 64px !important
  }

  .sm\:mb-64 {
    margin-bottom: 64px !important
  }

  .sm\:ml-64 {
    margin-left: 64px !important
  }

  .sm\:mt-68 {
    margin-top: 68px !important
  }

  .sm\:mr-68 {
    margin-right: 68px !important
  }

  .sm\:mb-68 {
    margin-bottom: 68px !important
  }

  .sm\:ml-68 {
    margin-left: 68px !important
  }

  .sm\:mt-72 {
    margin-top: 72px !important
  }

  .sm\:mr-72 {
    margin-right: 72px !important
  }

  .sm\:mb-72 {
    margin-bottom: 72px !important
  }

  .sm\:ml-72 {
    margin-left: 72px !important
  }

  .sm\:mt-76 {
    margin-top: 76px !important
  }

  .sm\:mr-76 {
    margin-right: 76px !important
  }

  .sm\:mb-76 {
    margin-bottom: 76px !important
  }

  .sm\:ml-76 {
    margin-left: 76px !important
  }

  .sm\:mt-80 {
    margin-top: 80px !important
  }

  .sm\:mr-80 {
    margin-right: 80px !important
  }

  .sm\:mb-80 {
    margin-bottom: 80px !important
  }

  .sm\:ml-80 {
    margin-left: 80px !important
  }

  .sm\:mt-84 {
    margin-top: 84px !important
  }

  .sm\:mr-84 {
    margin-right: 84px !important
  }

  .sm\:mb-84 {
    margin-bottom: 84px !important
  }

  .sm\:ml-84 {
    margin-left: 84px !important
  }

  .sm\:mt-88 {
    margin-top: 88px !important
  }

  .sm\:mr-88 {
    margin-right: 88px !important
  }

  .sm\:mb-88 {
    margin-bottom: 88px !important
  }

  .sm\:ml-88 {
    margin-left: 88px !important
  }

  .sm\:mt-92 {
    margin-top: 92px !important
  }

  .sm\:mr-92 {
    margin-right: 92px !important
  }

  .sm\:mb-92 {
    margin-bottom: 92px !important
  }

  .sm\:ml-92 {
    margin-left: 92px !important
  }

  .sm\:mt-96 {
    margin-top: 96px !important
  }

  .sm\:mr-96 {
    margin-right: 96px !important
  }

  .sm\:mb-96 {
    margin-bottom: 96px !important
  }

  .sm\:ml-96 {
    margin-left: 96px !important
  }

  .sm\:mt-128 {
    margin-top: 128px !important
  }

  .sm\:mr-128 {
    margin-right: 128px !important
  }

  .sm\:mb-128 {
    margin-bottom: 128px !important
  }

  .sm\:ml-128 {
    margin-left: 128px !important
  }

  .sm\:mt-160 {
    margin-top: 160px !important
  }

  .sm\:mr-160 {
    margin-right: 160px !important
  }

  .sm\:mb-160 {
    margin-bottom: 160px !important
  }

  .sm\:ml-160 {
    margin-left: 160px !important
  }

  .sm\:mt-192 {
    margin-top: 192px !important
  }

  .sm\:mr-192 {
    margin-right: 192px !important
  }

  .sm\:mb-192 {
    margin-bottom: 192px !important
  }

  .sm\:ml-192 {
    margin-left: 192px !important
  }

  .sm\:mt-224 {
    margin-top: 224px !important
  }

  .sm\:mr-224 {
    margin-right: 224px !important
  }

  .sm\:mb-224 {
    margin-bottom: 224px !important
  }

  .sm\:ml-224 {
    margin-left: 224px !important
  }

  .sm\:mt-256 {
    margin-top: 256px !important
  }

  .sm\:mr-256 {
    margin-right: 256px !important
  }

  .sm\:mb-256 {
    margin-bottom: 256px !important
  }

  .sm\:ml-256 {
    margin-left: 256px !important
  }

  .sm\:mt-320 {
    margin-top: 320px !important
  }

  .sm\:mr-320 {
    margin-right: 320px !important
  }

  .sm\:mb-320 {
    margin-bottom: 320px !important
  }

  .sm\:ml-320 {
    margin-left: 320px !important
  }

  .sm\:mt-auto {
    margin-top: auto !important
  }

  .sm\:mr-auto {
    margin-right: auto !important
  }

  .sm\:mb-auto {
    margin-bottom: auto !important
  }

  .sm\:ml-auto {
    margin-left: auto !important
  }

  .sm\:mt-px {
    margin-top: 1px !important
  }

  .sm\:mr-px {
    margin-right: 1px !important
  }

  .sm\:mb-px {
    margin-bottom: 1px !important
  }

  .sm\:ml-px {
    margin-left: 1px !important
  }

  .sm\:-mt-4 {
    margin-top: -4px !important
  }

  .sm\:-mr-4 {
    margin-right: -4px !important
  }

  .sm\:-mb-4 {
    margin-bottom: -4px !important
  }

  .sm\:-ml-4 {
    margin-left: -4px !important
  }

  .sm\:-mt-6 {
    margin-top: -6px !important
  }

  .sm\:-mr-6 {
    margin-right: -6px !important
  }

  .sm\:-mb-6 {
    margin-bottom: -6px !important
  }

  .sm\:-ml-6 {
    margin-left: -6px !important
  }

  .sm\:-mt-8 {
    margin-top: -8px !important
  }

  .sm\:-mr-8 {
    margin-right: -8px !important
  }

  .sm\:-mb-8 {
    margin-bottom: -8px !important
  }

  .sm\:-ml-8 {
    margin-left: -8px !important
  }

  .sm\:-mt-12 {
    margin-top: -12px !important
  }

  .sm\:-mr-12 {
    margin-right: -12px !important
  }

  .sm\:-mb-12 {
    margin-bottom: -12px !important
  }

  .sm\:-ml-12 {
    margin-left: -12px !important
  }

  .sm\:-mt-16 {
    margin-top: -16px !important
  }

  .sm\:-mr-16 {
    margin-right: -16px !important
  }

  .sm\:-mb-16 {
    margin-bottom: -16px !important
  }

  .sm\:-ml-16 {
    margin-left: -16px !important
  }

  .sm\:-mt-20 {
    margin-top: -20px !important
  }

  .sm\:-mr-20 {
    margin-right: -20px !important
  }

  .sm\:-mb-20 {
    margin-bottom: -20px !important
  }

  .sm\:-ml-20 {
    margin-left: -20px !important
  }

  .sm\:-mt-24 {
    margin-top: -24px !important
  }

  .sm\:-mr-24 {
    margin-right: -24px !important
  }

  .sm\:-mb-24 {
    margin-bottom: -24px !important
  }

  .sm\:-ml-24 {
    margin-left: -24px !important
  }

  .sm\:-mt-28 {
    margin-top: -28px !important
  }

  .sm\:-mr-28 {
    margin-right: -28px !important
  }

  .sm\:-mb-28 {
    margin-bottom: -28px !important
  }

  .sm\:-ml-28 {
    margin-left: -28px !important
  }

  .sm\:-mt-32 {
    margin-top: -32px !important
  }

  .sm\:-mr-32 {
    margin-right: -32px !important
  }

  .sm\:-mb-32 {
    margin-bottom: -32px !important
  }

  .sm\:-ml-32 {
    margin-left: -32px !important
  }

  .sm\:-mt-36 {
    margin-top: -36px !important
  }

  .sm\:-mr-36 {
    margin-right: -36px !important
  }

  .sm\:-mb-36 {
    margin-bottom: -36px !important
  }

  .sm\:-ml-36 {
    margin-left: -36px !important
  }

  .sm\:-mt-40 {
    margin-top: -40px !important
  }

  .sm\:-mr-40 {
    margin-right: -40px !important
  }

  .sm\:-mb-40 {
    margin-bottom: -40px !important
  }

  .sm\:-ml-40 {
    margin-left: -40px !important
  }

  .sm\:-mt-44 {
    margin-top: -44px !important
  }

  .sm\:-mr-44 {
    margin-right: -44px !important
  }

  .sm\:-mb-44 {
    margin-bottom: -44px !important
  }

  .sm\:-ml-44 {
    margin-left: -44px !important
  }

  .sm\:-mt-48 {
    margin-top: -48px !important
  }

  .sm\:-mr-48 {
    margin-right: -48px !important
  }

  .sm\:-mb-48 {
    margin-bottom: -48px !important
  }

  .sm\:-ml-48 {
    margin-left: -48px !important
  }

  .sm\:-mt-52 {
    margin-top: -52px !important
  }

  .sm\:-mr-52 {
    margin-right: -52px !important
  }

  .sm\:-mb-52 {
    margin-bottom: -52px !important
  }

  .sm\:-ml-52 {
    margin-left: -52px !important
  }

  .sm\:-mt-56 {
    margin-top: -56px !important
  }

  .sm\:-mr-56 {
    margin-right: -56px !important
  }

  .sm\:-mb-56 {
    margin-bottom: -56px !important
  }

  .sm\:-ml-56 {
    margin-left: -56px !important
  }

  .sm\:-mt-60 {
    margin-top: -60px !important
  }

  .sm\:-mr-60 {
    margin-right: -60px !important
  }

  .sm\:-mb-60 {
    margin-bottom: -60px !important
  }

  .sm\:-ml-60 {
    margin-left: -60px !important
  }

  .sm\:-mt-64 {
    margin-top: -64px !important
  }

  .sm\:-mr-64 {
    margin-right: -64px !important
  }

  .sm\:-mb-64 {
    margin-bottom: -64px !important
  }

  .sm\:-ml-64 {
    margin-left: -64px !important
  }

  .sm\:-mt-68 {
    margin-top: -68px !important
  }

  .sm\:-mr-68 {
    margin-right: -68px !important
  }

  .sm\:-mb-68 {
    margin-bottom: -68px !important
  }

  .sm\:-ml-68 {
    margin-left: -68px !important
  }

  .sm\:-mt-72 {
    margin-top: -72px !important
  }

  .sm\:-mr-72 {
    margin-right: -72px !important
  }

  .sm\:-mb-72 {
    margin-bottom: -72px !important
  }

  .sm\:-ml-72 {
    margin-left: -72px !important
  }

  .sm\:-mt-76 {
    margin-top: -76px !important
  }

  .sm\:-mr-76 {
    margin-right: -76px !important
  }

  .sm\:-mb-76 {
    margin-bottom: -76px !important
  }

  .sm\:-ml-76 {
    margin-left: -76px !important
  }

  .sm\:-mt-80 {
    margin-top: -80px !important
  }

  .sm\:-mr-80 {
    margin-right: -80px !important
  }

  .sm\:-mb-80 {
    margin-bottom: -80px !important
  }

  .sm\:-ml-80 {
    margin-left: -80px !important
  }

  .sm\:-mt-84 {
    margin-top: -84px !important
  }

  .sm\:-mr-84 {
    margin-right: -84px !important
  }

  .sm\:-mb-84 {
    margin-bottom: -84px !important
  }

  .sm\:-ml-84 {
    margin-left: -84px !important
  }

  .sm\:-mt-88 {
    margin-top: -88px !important
  }

  .sm\:-mr-88 {
    margin-right: -88px !important
  }

  .sm\:-mb-88 {
    margin-bottom: -88px !important
  }

  .sm\:-ml-88 {
    margin-left: -88px !important
  }

  .sm\:-mt-92 {
    margin-top: -92px !important
  }

  .sm\:-mr-92 {
    margin-right: -92px !important
  }

  .sm\:-mb-92 {
    margin-bottom: -92px !important
  }

  .sm\:-ml-92 {
    margin-left: -92px !important
  }

  .sm\:-mt-96 {
    margin-top: -96px !important
  }

  .sm\:-mr-96 {
    margin-right: -96px !important
  }

  .sm\:-mb-96 {
    margin-bottom: -96px !important
  }

  .sm\:-ml-96 {
    margin-left: -96px !important
  }

  .sm\:-mt-128 {
    margin-top: -128px !important
  }

  .sm\:-mr-128 {
    margin-right: -128px !important
  }

  .sm\:-mb-128 {
    margin-bottom: -128px !important
  }

  .sm\:-ml-128 {
    margin-left: -128px !important
  }

  .sm\:-mt-160 {
    margin-top: -160px !important
  }

  .sm\:-mr-160 {
    margin-right: -160px !important
  }

  .sm\:-mb-160 {
    margin-bottom: -160px !important
  }

  .sm\:-ml-160 {
    margin-left: -160px !important
  }

  .sm\:-mt-192 {
    margin-top: -192px !important
  }

  .sm\:-mr-192 {
    margin-right: -192px !important
  }

  .sm\:-mb-192 {
    margin-bottom: -192px !important
  }

  .sm\:-ml-192 {
    margin-left: -192px !important
  }

  .sm\:-mt-224 {
    margin-top: -224px !important
  }

  .sm\:-mr-224 {
    margin-right: -224px !important
  }

  .sm\:-mb-224 {
    margin-bottom: -224px !important
  }

  .sm\:-ml-224 {
    margin-left: -224px !important
  }

  .sm\:-mt-256 {
    margin-top: -256px !important
  }

  .sm\:-mr-256 {
    margin-right: -256px !important
  }

  .sm\:-mb-256 {
    margin-bottom: -256px !important
  }

  .sm\:-ml-256 {
    margin-left: -256px !important
  }

  .sm\:-mt-320 {
    margin-top: -320px !important
  }

  .sm\:-mr-320 {
    margin-right: -320px !important
  }

  .sm\:-mb-320 {
    margin-bottom: -320px !important
  }

  .sm\:-ml-320 {
    margin-left: -320px !important
  }

  .sm\:-mt-px {
    margin-top: -1px !important
  }

  .sm\:-mr-px {
    margin-right: -1px !important
  }

  .sm\:-mb-px {
    margin-bottom: -1px !important
  }

  .sm\:-ml-px {
    margin-left: -1px !important
  }

  .sm\:p-0 {
    padding: 0 !important
  }

  .sm\:p-4 {
    padding: 4px !important
  }

  .sm\:p-6 {
    padding: 6px !important
  }

  .sm\:p-8 {
    padding: 8px !important
  }

  .sm\:p-12 {
    padding: 12px !important
  }

  .sm\:p-16 {
    padding: 16px !important
  }

  .sm\:p-20 {
    padding: 20px !important
  }

  .sm\:p-24 {
    padding: 24px !important
  }

  .sm\:p-28 {
    padding: 28px !important
  }

  .sm\:p-32 {
    padding: 32px !important
  }

  .sm\:p-36 {
    padding: 36px !important
  }

  .sm\:p-40 {
    padding: 40px !important
  }

  .sm\:p-44 {
    padding: 44px !important
  }

  .sm\:p-48 {
    padding: 48px !important
  }

  .sm\:p-52 {
    padding: 52px !important
  }

  .sm\:p-56 {
    padding: 56px !important
  }

  .sm\:p-60 {
    padding: 60px !important
  }

  .sm\:p-64 {
    padding: 64px !important
  }

  .sm\:p-68 {
    padding: 68px !important
  }

  .sm\:p-72 {
    padding: 72px !important
  }

  .sm\:p-76 {
    padding: 76px !important
  }

  .sm\:p-80 {
    padding: 80px !important
  }

  .sm\:p-84 {
    padding: 84px !important
  }

  .sm\:p-88 {
    padding: 88px !important
  }

  .sm\:p-92 {
    padding: 92px !important
  }

  .sm\:p-96 {
    padding: 96px !important
  }

  .sm\:p-128 {
    padding: 128px !important
  }

  .sm\:p-160 {
    padding: 160px !important
  }

  .sm\:p-192 {
    padding: 192px !important
  }

  .sm\:p-224 {
    padding: 224px !important
  }

  .sm\:p-256 {
    padding: 256px !important
  }

  .sm\:p-320 {
    padding: 320px !important
  }

  .sm\:p-px {
    padding: 1px !important
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .sm\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important
  }

  .sm\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important
  }

  .sm\:py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important
  }

  .sm\:px-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .sm\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important
  }

  .sm\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .sm\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important
  }

  .sm\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .sm\:py-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
  }

  .sm\:px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .sm\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important
  }

  .sm\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .sm\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
  }

  .sm\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important
  }

  .sm\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important
  }

  .sm\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important
  }

  .sm\:py-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important
  }

  .sm\:px-32 {
    padding-left: 32px !important;
    padding-right: 32px !important
  }

  .sm\:py-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important
  }

  .sm\:px-36 {
    padding-left: 36px !important;
    padding-right: 36px !important
  }

  .sm\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important
  }

  .sm\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .sm\:py-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important
  }

  .sm\:px-44 {
    padding-left: 44px !important;
    padding-right: 44px !important
  }

  .sm\:py-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important
  }

  .sm\:px-48 {
    padding-left: 48px !important;
    padding-right: 48px !important
  }

  .sm\:py-52 {
    padding-top: 52px !important;
    padding-bottom: 52px !important
  }

  .sm\:px-52 {
    padding-left: 52px !important;
    padding-right: 52px !important
  }

  .sm\:py-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important
  }

  .sm\:px-56 {
    padding-left: 56px !important;
    padding-right: 56px !important
  }

  .sm\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important
  }

  .sm\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .sm\:py-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important
  }

  .sm\:px-64 {
    padding-left: 64px !important;
    padding-right: 64px !important
  }

  .sm\:py-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important
  }

  .sm\:px-68 {
    padding-left: 68px !important;
    padding-right: 68px !important
  }

  .sm\:py-72 {
    padding-top: 72px !important;
    padding-bottom: 72px !important
  }

  .sm\:px-72 {
    padding-left: 72px !important;
    padding-right: 72px !important
  }

  .sm\:py-76 {
    padding-top: 76px !important;
    padding-bottom: 76px !important
  }

  .sm\:px-76 {
    padding-left: 76px !important;
    padding-right: 76px !important
  }

  .sm\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important
  }

  .sm\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .sm\:py-84 {
    padding-top: 84px !important;
    padding-bottom: 84px !important
  }

  .sm\:px-84 {
    padding-left: 84px !important;
    padding-right: 84px !important
  }

  .sm\:py-88 {
    padding-top: 88px !important;
    padding-bottom: 88px !important
  }

  .sm\:px-88 {
    padding-left: 88px !important;
    padding-right: 88px !important
  }

  .sm\:py-92 {
    padding-top: 92px !important;
    padding-bottom: 92px !important
  }

  .sm\:px-92 {
    padding-left: 92px !important;
    padding-right: 92px !important
  }

  .sm\:py-96 {
    padding-top: 96px !important;
    padding-bottom: 96px !important
  }

  .sm\:px-96 {
    padding-left: 96px !important;
    padding-right: 96px !important
  }

  .sm\:py-128 {
    padding-top: 128px !important;
    padding-bottom: 128px !important
  }

  .sm\:px-128 {
    padding-left: 128px !important;
    padding-right: 128px !important
  }

  .sm\:py-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important
  }

  .sm\:px-160 {
    padding-left: 160px !important;
    padding-right: 160px !important
  }

  .sm\:py-192 {
    padding-top: 192px !important;
    padding-bottom: 192px !important
  }

  .sm\:px-192 {
    padding-left: 192px !important;
    padding-right: 192px !important
  }

  .sm\:py-224 {
    padding-top: 224px !important;
    padding-bottom: 224px !important
  }

  .sm\:px-224 {
    padding-left: 224px !important;
    padding-right: 224px !important
  }

  .sm\:py-256 {
    padding-top: 256px !important;
    padding-bottom: 256px !important
  }

  .sm\:px-256 {
    padding-left: 256px !important;
    padding-right: 256px !important
  }

  .sm\:py-320 {
    padding-top: 320px !important;
    padding-bottom: 320px !important
  }

  .sm\:px-320 {
    padding-left: 320px !important;
    padding-right: 320px !important
  }

  .sm\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important
  }

  .sm\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
  }

  .sm\:pt-0 {
    padding-top: 0 !important
  }

  .sm\:pr-0 {
    padding-right: 0 !important
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important
  }

  .sm\:pl-0 {
    padding-left: 0 !important
  }

  .sm\:pt-4 {
    padding-top: 4px !important
  }

  .sm\:pr-4 {
    padding-right: 4px !important
  }

  .sm\:pb-4 {
    padding-bottom: 4px !important
  }

  .sm\:pl-4 {
    padding-left: 4px !important
  }

  .sm\:pt-6 {
    padding-top: 6px !important
  }

  .sm\:pr-6 {
    padding-right: 6px !important
  }

  .sm\:pb-6 {
    padding-bottom: 6px !important
  }

  .sm\:pl-6 {
    padding-left: 6px !important
  }

  .sm\:pt-8 {
    padding-top: 8px !important
  }

  .sm\:pr-8 {
    padding-right: 8px !important
  }

  .sm\:pb-8 {
    padding-bottom: 8px !important
  }

  .sm\:pl-8 {
    padding-left: 8px !important
  }

  .sm\:pt-12 {
    padding-top: 12px !important
  }

  .sm\:pr-12 {
    padding-right: 12px !important
  }

  .sm\:pb-12 {
    padding-bottom: 12px !important
  }

  .sm\:pl-12 {
    padding-left: 12px !important
  }

  .sm\:pt-16 {
    padding-top: 16px !important
  }

  .sm\:pr-16 {
    padding-right: 16px !important
  }

  .sm\:pb-16 {
    padding-bottom: 16px !important
  }

  .sm\:pl-16 {
    padding-left: 16px !important
  }

  .sm\:pt-20 {
    padding-top: 20px !important
  }

  .sm\:pr-20 {
    padding-right: 20px !important
  }

  .sm\:pb-20 {
    padding-bottom: 20px !important
  }

  .sm\:pl-20 {
    padding-left: 20px !important
  }

  .sm\:pt-24 {
    padding-top: 24px !important
  }

  .sm\:pr-24 {
    padding-right: 24px !important
  }

  .sm\:pb-24 {
    padding-bottom: 24px !important
  }

  .sm\:pl-24 {
    padding-left: 24px !important
  }

  .sm\:pt-28 {
    padding-top: 28px !important
  }

  .sm\:pr-28 {
    padding-right: 28px !important
  }

  .sm\:pb-28 {
    padding-bottom: 28px !important
  }

  .sm\:pl-28 {
    padding-left: 28px !important
  }

  .sm\:pt-32 {
    padding-top: 32px !important
  }

  .sm\:pr-32 {
    padding-right: 32px !important
  }

  .sm\:pb-32 {
    padding-bottom: 32px !important
  }

  .sm\:pl-32 {
    padding-left: 32px !important
  }

  .sm\:pt-36 {
    padding-top: 36px !important
  }

  .sm\:pr-36 {
    padding-right: 36px !important
  }

  .sm\:pb-36 {
    padding-bottom: 36px !important
  }

  .sm\:pl-36 {
    padding-left: 36px !important
  }

  .sm\:pt-40 {
    padding-top: 40px !important
  }

  .sm\:pr-40 {
    padding-right: 40px !important
  }

  .sm\:pb-40 {
    padding-bottom: 40px !important
  }

  .sm\:pl-40 {
    padding-left: 40px !important
  }

  .sm\:pt-44 {
    padding-top: 44px !important
  }

  .sm\:pr-44 {
    padding-right: 44px !important
  }

  .sm\:pb-44 {
    padding-bottom: 44px !important
  }

  .sm\:pl-44 {
    padding-left: 44px !important
  }

  .sm\:pt-48 {
    padding-top: 48px !important
  }

  .sm\:pr-48 {
    padding-right: 48px !important
  }

  .sm\:pb-48 {
    padding-bottom: 48px !important
  }

  .sm\:pl-48 {
    padding-left: 48px !important
  }

  .sm\:pt-52 {
    padding-top: 52px !important
  }

  .sm\:pr-52 {
    padding-right: 52px !important
  }

  .sm\:pb-52 {
    padding-bottom: 52px !important
  }

  .sm\:pl-52 {
    padding-left: 52px !important
  }

  .sm\:pt-56 {
    padding-top: 56px !important
  }

  .sm\:pr-56 {
    padding-right: 56px !important
  }

  .sm\:pb-56 {
    padding-bottom: 56px !important
  }

  .sm\:pl-56 {
    padding-left: 56px !important
  }

  .sm\:pt-60 {
    padding-top: 60px !important
  }

  .sm\:pr-60 {
    padding-right: 60px !important
  }

  .sm\:pb-60 {
    padding-bottom: 60px !important
  }

  .sm\:pl-60 {
    padding-left: 60px !important
  }

  .sm\:pt-64 {
    padding-top: 64px !important
  }

  .sm\:pr-64 {
    padding-right: 64px !important
  }

  .sm\:pb-64 {
    padding-bottom: 64px !important
  }

  .sm\:pl-64 {
    padding-left: 64px !important
  }

  .sm\:pt-68 {
    padding-top: 68px !important
  }

  .sm\:pr-68 {
    padding-right: 68px !important
  }

  .sm\:pb-68 {
    padding-bottom: 68px !important
  }

  .sm\:pl-68 {
    padding-left: 68px !important
  }

  .sm\:pt-72 {
    padding-top: 72px !important
  }

  .sm\:pr-72 {
    padding-right: 72px !important
  }

  .sm\:pb-72 {
    padding-bottom: 72px !important
  }

  .sm\:pl-72 {
    padding-left: 72px !important
  }

  .sm\:pt-76 {
    padding-top: 76px !important
  }

  .sm\:pr-76 {
    padding-right: 76px !important
  }

  .sm\:pb-76 {
    padding-bottom: 76px !important
  }

  .sm\:pl-76 {
    padding-left: 76px !important
  }

  .sm\:pt-80 {
    padding-top: 80px !important
  }

  .sm\:pr-80 {
    padding-right: 80px !important
  }

  .sm\:pb-80 {
    padding-bottom: 80px !important
  }

  .sm\:pl-80 {
    padding-left: 80px !important
  }

  .sm\:pt-84 {
    padding-top: 84px !important
  }

  .sm\:pr-84 {
    padding-right: 84px !important
  }

  .sm\:pb-84 {
    padding-bottom: 84px !important
  }

  .sm\:pl-84 {
    padding-left: 84px !important
  }

  .sm\:pt-88 {
    padding-top: 88px !important
  }

  .sm\:pr-88 {
    padding-right: 88px !important
  }

  .sm\:pb-88 {
    padding-bottom: 88px !important
  }

  .sm\:pl-88 {
    padding-left: 88px !important
  }

  .sm\:pt-92 {
    padding-top: 92px !important
  }

  .sm\:pr-92 {
    padding-right: 92px !important
  }

  .sm\:pb-92 {
    padding-bottom: 92px !important
  }

  .sm\:pl-92 {
    padding-left: 92px !important
  }

  .sm\:pt-96 {
    padding-top: 96px !important
  }

  .sm\:pr-96 {
    padding-right: 96px !important
  }

  .sm\:pb-96 {
    padding-bottom: 96px !important
  }

  .sm\:pl-96 {
    padding-left: 96px !important
  }

  .sm\:pt-128 {
    padding-top: 128px !important
  }

  .sm\:pr-128 {
    padding-right: 128px !important
  }

  .sm\:pb-128 {
    padding-bottom: 128px !important
  }

  .sm\:pl-128 {
    padding-left: 128px !important
  }

  .sm\:pt-160 {
    padding-top: 160px !important
  }

  .sm\:pr-160 {
    padding-right: 160px !important
  }

  .sm\:pb-160 {
    padding-bottom: 160px !important
  }

  .sm\:pl-160 {
    padding-left: 160px !important
  }

  .sm\:pt-192 {
    padding-top: 192px !important
  }

  .sm\:pr-192 {
    padding-right: 192px !important
  }

  .sm\:pb-192 {
    padding-bottom: 192px !important
  }

  .sm\:pl-192 {
    padding-left: 192px !important
  }

  .sm\:pt-224 {
    padding-top: 224px !important
  }

  .sm\:pr-224 {
    padding-right: 224px !important
  }

  .sm\:pb-224 {
    padding-bottom: 224px !important
  }

  .sm\:pl-224 {
    padding-left: 224px !important
  }

  .sm\:pt-256 {
    padding-top: 256px !important
  }

  .sm\:pr-256 {
    padding-right: 256px !important
  }

  .sm\:pb-256 {
    padding-bottom: 256px !important
  }

  .sm\:pl-256 {
    padding-left: 256px !important
  }

  .sm\:pt-320 {
    padding-top: 320px !important
  }

  .sm\:pr-320 {
    padding-right: 320px !important
  }

  .sm\:pb-320 {
    padding-bottom: 320px !important
  }

  .sm\:pl-320 {
    padding-left: 320px !important
  }

  .sm\:pt-px {
    padding-top: 1px !important
  }

  .sm\:pr-px {
    padding-right: 1px !important
  }

  .sm\:pb-px {
    padding-bottom: 1px !important
  }

  .sm\:pl-px {
    padding-left: 1px !important
  }

  .sm\:flex-1 {
    flex: 1 1 0% !important
  }

  .sm\:flex-auto {
    flex: 1 1 auto !important
  }

  .sm\:flex-initial {
    flex: 0 1 auto !important
  }

  .sm\:flex-none {
    flex: none !important
  }

  .sm\:items-start {
    align-items: flex-start !important
  }

  .sm\:items-end {
    align-items: flex-end !important
  }

  .sm\:items-center {
    align-items: center !important
  }

  .sm\:items-baseline {
    align-items: baseline !important
  }

  .sm\:items-stretch {
    align-items: stretch !important
  }

  .sm\:flex-row {
    flex-direction: row !important
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .sm\:flex-col {
    flex-direction: column !important
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .sm\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .sm\:flex-grow {
    flex-grow: 1 !important
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .sm\:flex-shrink {
    flex-shrink: 1 !important
  }

  .sm\:flex-wrap {
    flex-wrap: wrap !important
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .sm\:justify-start {
    justify-content: flex-start !important
  }

  .sm\:justify-end {
    justify-content: flex-end !important
  }

  .sm\:justify-center {
    justify-content: center !important
  }

  .sm\:justify-between {
    justify-content: space-between !important
  }

  .sm\:justify-around {
    justify-content: space-around !important
  }

  .sm\:justify-evenly {
    justify-content: space-evenly !important
  }

  .sm\:text-xs {
    font-size: 0.75rem !important
  }

  .sm\:text-sm {
    font-size: 0.875rem !important
  }

  .sm\:text-base {
    font-size: 1rem !important
  }

  .sm\:text-lg {
    font-size: 1.125rem !important
  }

  .sm\:text-xl {
    font-size: 1.25rem !important
  }

  .sm\:text-2xl {
    font-size: 1.5rem !important
  }

  .sm\:text-3xl {
    font-size: 1.875rem !important
  }

  .sm\:text-4xl {
    font-size: 2.25rem !important
  }

  .sm\:text-5xl {
    font-size: 3rem !important
  }

  .sm\:text-6xl {
    font-size: 4rem !important
  }

  .sm\:font-hairline {
    font-weight: 100 !important
  }

  .sm\:font-thin {
    font-weight: 200 !important
  }

  .sm\:font-light {
    font-weight: 300 !important
  }

  .sm\:font-normal {
    font-weight: 400 !important
  }

  .sm\:font-medium {
    font-weight: 500 !important
  }

  .sm\:font-semibold {
    font-weight: 600 !important
  }

  .sm\:font-bold {
    font-weight: 700 !important
  }

  .sm\:font-extrabold {
    font-weight: 800 !important
  }

  .sm\:font-black {
    font-weight: 900 !important
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .sm\:text-left {
    text-align: left !important
  }

  .sm\:text-center {
    text-align: center !important
  }

  .sm\:text-right {
    text-align: right !important
  }

  .sm\:text-justify {
    text-align: justify !important
  }

  .sm\:text-transparent {
    color: transparent !important
  }

  .sm\:text-current {
    color: currentColor !important
  }

  .sm\:text-black {
    color: #000 !important
  }

  .sm\:text-white {
    color: #fff !important
  }

  .sm\:text-gray-100 {
    color: #f7fafc !important
  }

  .sm\:text-gray-200 {
    color: #edf2f7 !important
  }

  .sm\:text-gray-300 {
    color: #e2e8f0 !important
  }

  .sm\:text-gray-400 {
    color: #cbd5e0 !important
  }

  .sm\:text-gray-500 {
    color: #a0aec0 !important
  }

  .sm\:text-gray-600 {
    color: #718096 !important
  }

  .sm\:text-gray-700 {
    color: #4a5568 !important
  }

  .sm\:text-gray-800 {
    color: #2d3748 !important
  }

  .sm\:text-gray-900 {
    color: #1a202c !important
  }

  .sm\:text-red-100 {
    color: #fff5f5 !important
  }

  .sm\:text-red-200 {
    color: #fed7d7 !important
  }

  .sm\:text-red-300 {
    color: #feb2b2 !important
  }

  .sm\:text-red-400 {
    color: #fc8181 !important
  }

  .sm\:text-red-500 {
    color: #f56565 !important
  }

  .sm\:text-red-600 {
    color: #e53e3e !important
  }

  .sm\:text-red-700 {
    color: #c53030 !important
  }

  .sm\:text-red-800 {
    color: #9b2c2c !important
  }

  .sm\:text-red-900 {
    color: #742a2a !important
  }

  .sm\:text-orange-100 {
    color: #fffaf0 !important
  }

  .sm\:text-orange-200 {
    color: #feebc8 !important
  }

  .sm\:text-orange-300 {
    color: #fbd38d !important
  }

  .sm\:text-orange-400 {
    color: #f6ad55 !important
  }

  .sm\:text-orange-500 {
    color: #ed8936 !important
  }

  .sm\:text-orange-600 {
    color: #dd6b20 !important
  }

  .sm\:text-orange-700 {
    color: #c05621 !important
  }

  .sm\:text-orange-800 {
    color: #9c4221 !important
  }

  .sm\:text-orange-900 {
    color: #7b341e !important
  }

  .sm\:text-yellow-100 {
    color: #fffff0 !important
  }

  .sm\:text-yellow-200 {
    color: #fefcbf !important
  }

  .sm\:text-yellow-300 {
    color: #faf089 !important
  }

  .sm\:text-yellow-400 {
    color: #f6e05e !important
  }

  .sm\:text-yellow-500 {
    color: #ecc94b !important
  }

  .sm\:text-yellow-600 {
    color: #d69e2e !important
  }

  .sm\:text-yellow-700 {
    color: #b7791f !important
  }

  .sm\:text-yellow-800 {
    color: #975a16 !important
  }

  .sm\:text-yellow-900 {
    color: #744210 !important
  }

  .sm\:text-green-100 {
    color: #f0fff4 !important
  }

  .sm\:text-green-200 {
    color: #c6f6d5 !important
  }

  .sm\:text-green-300 {
    color: #9ae6b4 !important
  }

  .sm\:text-green-400 {
    color: #68d391 !important
  }

  .sm\:text-green-500 {
    color: #48bb78 !important
  }

  .sm\:text-green-600 {
    color: #38a169 !important
  }

  .sm\:text-green-700 {
    color: #2f855a !important
  }

  .sm\:text-green-800 {
    color: #276749 !important
  }

  .sm\:text-green-900 {
    color: #22543d !important
  }

  .sm\:text-teal-100 {
    color: #e6fffa !important
  }

  .sm\:text-teal-200 {
    color: #b2f5ea !important
  }

  .sm\:text-teal-300 {
    color: #81e6d9 !important
  }

  .sm\:text-teal-400 {
    color: #4fd1c5 !important
  }

  .sm\:text-teal-500 {
    color: #38b2ac !important
  }

  .sm\:text-teal-600 {
    color: #319795 !important
  }

  .sm\:text-teal-700 {
    color: #2c7a7b !important
  }

  .sm\:text-teal-800 {
    color: #285e61 !important
  }

  .sm\:text-teal-900 {
    color: #234e52 !important
  }

  .sm\:text-blue-100 {
    color: #ebf8ff !important
  }

  .sm\:text-blue-200 {
    color: #bee3f8 !important
  }

  .sm\:text-blue-300 {
    color: #90cdf4 !important
  }

  .sm\:text-blue-400 {
    color: #63b3ed !important
  }

  .sm\:text-blue-500 {
    color: #4299e1 !important
  }

  .sm\:text-blue-600 {
    color: #3182ce !important
  }

  .sm\:text-blue-700 {
    color: #2b6cb0 !important
  }

  .sm\:text-blue-800 {
    color: #2c5282 !important
  }

  .sm\:text-blue-900 {
    color: #2a4365 !important
  }

  .sm\:text-indigo-100 {
    color: #ebf4ff !important
  }

  .sm\:text-indigo-200 {
    color: #c3dafe !important
  }

  .sm\:text-indigo-300 {
    color: #a3bffa !important
  }

  .sm\:text-indigo-400 {
    color: #7f9cf5 !important
  }

  .sm\:text-indigo-500 {
    color: #667eea !important
  }

  .sm\:text-indigo-600 {
    color: #5a67d8 !important
  }

  .sm\:text-indigo-700 {
    color: #4c51bf !important
  }

  .sm\:text-indigo-800 {
    color: #434190 !important
  }

  .sm\:text-indigo-900 {
    color: #3c366b !important
  }

  .sm\:text-purple-100 {
    color: #faf5ff !important
  }

  .sm\:text-purple-200 {
    color: #e9d8fd !important
  }

  .sm\:text-purple-300 {
    color: #d6bcfa !important
  }

  .sm\:text-purple-400 {
    color: #b794f4 !important
  }

  .sm\:text-purple-500 {
    color: #9f7aea !important
  }

  .sm\:text-purple-600 {
    color: #805ad5 !important
  }

  .sm\:text-purple-700 {
    color: #6b46c1 !important
  }

  .sm\:text-purple-800 {
    color: #553c9a !important
  }

  .sm\:text-purple-900 {
    color: #44337a !important
  }

  .sm\:text-pink-100 {
    color: #fff5f7 !important
  }

  .sm\:text-pink-200 {
    color: #fed7e2 !important
  }

  .sm\:text-pink-300 {
    color: #fbb6ce !important
  }

  .sm\:text-pink-400 {
    color: #f687b3 !important
  }

  .sm\:text-pink-500 {
    color: #ed64a6 !important
  }

  .sm\:text-pink-600 {
    color: #d53f8c !important
  }

  .sm\:text-pink-700 {
    color: #b83280 !important
  }

  .sm\:text-pink-800 {
    color: #97266d !important
  }

  .sm\:text-pink-900 {
    color: #702459 !important
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent !important
  }

  .sm\:hover\:text-current:hover {
    color: currentColor !important
  }

  .sm\:hover\:text-black:hover {
    color: #000 !important
  }

  .sm\:hover\:text-white:hover {
    color: #fff !important
  }

  .sm\:hover\:text-gray-100:hover {
    color: #f7fafc !important
  }

  .sm\:hover\:text-gray-200:hover {
    color: #edf2f7 !important
  }

  .sm\:hover\:text-gray-300:hover {
    color: #e2e8f0 !important
  }

  .sm\:hover\:text-gray-400:hover {
    color: #cbd5e0 !important
  }

  .sm\:hover\:text-gray-500:hover {
    color: #a0aec0 !important
  }

  .sm\:hover\:text-gray-600:hover {
    color: #718096 !important
  }

  .sm\:hover\:text-gray-700:hover {
    color: #4a5568 !important
  }

  .sm\:hover\:text-gray-800:hover {
    color: #2d3748 !important
  }

  .sm\:hover\:text-gray-900:hover {
    color: #1a202c !important
  }

  .sm\:hover\:text-red-100:hover {
    color: #fff5f5 !important
  }

  .sm\:hover\:text-red-200:hover {
    color: #fed7d7 !important
  }

  .sm\:hover\:text-red-300:hover {
    color: #feb2b2 !important
  }

  .sm\:hover\:text-red-400:hover {
    color: #fc8181 !important
  }

  .sm\:hover\:text-red-500:hover {
    color: #f56565 !important
  }

  .sm\:hover\:text-red-600:hover {
    color: #e53e3e !important
  }

  .sm\:hover\:text-red-700:hover {
    color: #c53030 !important
  }

  .sm\:hover\:text-red-800:hover {
    color: #9b2c2c !important
  }

  .sm\:hover\:text-red-900:hover {
    color: #742a2a !important
  }

  .sm\:hover\:text-orange-100:hover {
    color: #fffaf0 !important
  }

  .sm\:hover\:text-orange-200:hover {
    color: #feebc8 !important
  }

  .sm\:hover\:text-orange-300:hover {
    color: #fbd38d !important
  }

  .sm\:hover\:text-orange-400:hover {
    color: #f6ad55 !important
  }

  .sm\:hover\:text-orange-500:hover {
    color: #ed8936 !important
  }

  .sm\:hover\:text-orange-600:hover {
    color: #dd6b20 !important
  }

  .sm\:hover\:text-orange-700:hover {
    color: #c05621 !important
  }

  .sm\:hover\:text-orange-800:hover {
    color: #9c4221 !important
  }

  .sm\:hover\:text-orange-900:hover {
    color: #7b341e !important
  }

  .sm\:hover\:text-yellow-100:hover {
    color: #fffff0 !important
  }

  .sm\:hover\:text-yellow-200:hover {
    color: #fefcbf !important
  }

  .sm\:hover\:text-yellow-300:hover {
    color: #faf089 !important
  }

  .sm\:hover\:text-yellow-400:hover {
    color: #f6e05e !important
  }

  .sm\:hover\:text-yellow-500:hover {
    color: #ecc94b !important
  }

  .sm\:hover\:text-yellow-600:hover {
    color: #d69e2e !important
  }

  .sm\:hover\:text-yellow-700:hover {
    color: #b7791f !important
  }

  .sm\:hover\:text-yellow-800:hover {
    color: #975a16 !important
  }

  .sm\:hover\:text-yellow-900:hover {
    color: #744210 !important
  }

  .sm\:hover\:text-green-100:hover {
    color: #f0fff4 !important
  }

  .sm\:hover\:text-green-200:hover {
    color: #c6f6d5 !important
  }

  .sm\:hover\:text-green-300:hover {
    color: #9ae6b4 !important
  }

  .sm\:hover\:text-green-400:hover {
    color: #68d391 !important
  }

  .sm\:hover\:text-green-500:hover {
    color: #48bb78 !important
  }

  .sm\:hover\:text-green-600:hover {
    color: #38a169 !important
  }

  .sm\:hover\:text-green-700:hover {
    color: #2f855a !important
  }

  .sm\:hover\:text-green-800:hover {
    color: #276749 !important
  }

  .sm\:hover\:text-green-900:hover {
    color: #22543d !important
  }

  .sm\:hover\:text-teal-100:hover {
    color: #e6fffa !important
  }

  .sm\:hover\:text-teal-200:hover {
    color: #b2f5ea !important
  }

  .sm\:hover\:text-teal-300:hover {
    color: #81e6d9 !important
  }

  .sm\:hover\:text-teal-400:hover {
    color: #4fd1c5 !important
  }

  .sm\:hover\:text-teal-500:hover {
    color: #38b2ac !important
  }

  .sm\:hover\:text-teal-600:hover {
    color: #319795 !important
  }

  .sm\:hover\:text-teal-700:hover {
    color: #2c7a7b !important
  }

  .sm\:hover\:text-teal-800:hover {
    color: #285e61 !important
  }

  .sm\:hover\:text-teal-900:hover {
    color: #234e52 !important
  }

  .sm\:hover\:text-blue-100:hover {
    color: #ebf8ff !important
  }

  .sm\:hover\:text-blue-200:hover {
    color: #bee3f8 !important
  }

  .sm\:hover\:text-blue-300:hover {
    color: #90cdf4 !important
  }

  .sm\:hover\:text-blue-400:hover {
    color: #63b3ed !important
  }

  .sm\:hover\:text-blue-500:hover {
    color: #4299e1 !important
  }

  .sm\:hover\:text-blue-600:hover {
    color: #3182ce !important
  }

  .sm\:hover\:text-blue-700:hover {
    color: #2b6cb0 !important
  }

  .sm\:hover\:text-blue-800:hover {
    color: #2c5282 !important
  }

  .sm\:hover\:text-blue-900:hover {
    color: #2a4365 !important
  }

  .sm\:hover\:text-indigo-100:hover {
    color: #ebf4ff !important
  }

  .sm\:hover\:text-indigo-200:hover {
    color: #c3dafe !important
  }

  .sm\:hover\:text-indigo-300:hover {
    color: #a3bffa !important
  }

  .sm\:hover\:text-indigo-400:hover {
    color: #7f9cf5 !important
  }

  .sm\:hover\:text-indigo-500:hover {
    color: #667eea !important
  }

  .sm\:hover\:text-indigo-600:hover {
    color: #5a67d8 !important
  }

  .sm\:hover\:text-indigo-700:hover {
    color: #4c51bf !important
  }

  .sm\:hover\:text-indigo-800:hover {
    color: #434190 !important
  }

  .sm\:hover\:text-indigo-900:hover {
    color: #3c366b !important
  }

  .sm\:hover\:text-purple-100:hover {
    color: #faf5ff !important
  }

  .sm\:hover\:text-purple-200:hover {
    color: #e9d8fd !important
  }

  .sm\:hover\:text-purple-300:hover {
    color: #d6bcfa !important
  }

  .sm\:hover\:text-purple-400:hover {
    color: #b794f4 !important
  }

  .sm\:hover\:text-purple-500:hover {
    color: #9f7aea !important
  }

  .sm\:hover\:text-purple-600:hover {
    color: #805ad5 !important
  }

  .sm\:hover\:text-purple-700:hover {
    color: #6b46c1 !important
  }

  .sm\:hover\:text-purple-800:hover {
    color: #553c9a !important
  }

  .sm\:hover\:text-purple-900:hover {
    color: #44337a !important
  }

  .sm\:hover\:text-pink-100:hover {
    color: #fff5f7 !important
  }

  .sm\:hover\:text-pink-200:hover {
    color: #fed7e2 !important
  }

  .sm\:hover\:text-pink-300:hover {
    color: #fbb6ce !important
  }

  .sm\:hover\:text-pink-400:hover {
    color: #f687b3 !important
  }

  .sm\:hover\:text-pink-500:hover {
    color: #ed64a6 !important
  }

  .sm\:hover\:text-pink-600:hover {
    color: #d53f8c !important
  }

  .sm\:hover\:text-pink-700:hover {
    color: #b83280 !important
  }

  .sm\:hover\:text-pink-800:hover {
    color: #97266d !important
  }

  .sm\:hover\:text-pink-900:hover {
    color: #702459 !important
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent !important
  }

  .sm\:focus\:text-current:focus {
    color: currentColor !important
  }

  .sm\:focus\:text-black:focus {
    color: #000 !important
  }

  .sm\:focus\:text-white:focus {
    color: #fff !important
  }

  .sm\:focus\:text-gray-100:focus {
    color: #f7fafc !important
  }

  .sm\:focus\:text-gray-200:focus {
    color: #edf2f7 !important
  }

  .sm\:focus\:text-gray-300:focus {
    color: #e2e8f0 !important
  }

  .sm\:focus\:text-gray-400:focus {
    color: #cbd5e0 !important
  }

  .sm\:focus\:text-gray-500:focus {
    color: #a0aec0 !important
  }

  .sm\:focus\:text-gray-600:focus {
    color: #718096 !important
  }

  .sm\:focus\:text-gray-700:focus {
    color: #4a5568 !important
  }

  .sm\:focus\:text-gray-800:focus {
    color: #2d3748 !important
  }

  .sm\:focus\:text-gray-900:focus {
    color: #1a202c !important
  }

  .sm\:focus\:text-red-100:focus {
    color: #fff5f5 !important
  }

  .sm\:focus\:text-red-200:focus {
    color: #fed7d7 !important
  }

  .sm\:focus\:text-red-300:focus {
    color: #feb2b2 !important
  }

  .sm\:focus\:text-red-400:focus {
    color: #fc8181 !important
  }

  .sm\:focus\:text-red-500:focus {
    color: #f56565 !important
  }

  .sm\:focus\:text-red-600:focus {
    color: #e53e3e !important
  }

  .sm\:focus\:text-red-700:focus {
    color: #c53030 !important
  }

  .sm\:focus\:text-red-800:focus {
    color: #9b2c2c !important
  }

  .sm\:focus\:text-red-900:focus {
    color: #742a2a !important
  }

  .sm\:focus\:text-orange-100:focus {
    color: #fffaf0 !important
  }

  .sm\:focus\:text-orange-200:focus {
    color: #feebc8 !important
  }

  .sm\:focus\:text-orange-300:focus {
    color: #fbd38d !important
  }

  .sm\:focus\:text-orange-400:focus {
    color: #f6ad55 !important
  }

  .sm\:focus\:text-orange-500:focus {
    color: #ed8936 !important
  }

  .sm\:focus\:text-orange-600:focus {
    color: #dd6b20 !important
  }

  .sm\:focus\:text-orange-700:focus {
    color: #c05621 !important
  }

  .sm\:focus\:text-orange-800:focus {
    color: #9c4221 !important
  }

  .sm\:focus\:text-orange-900:focus {
    color: #7b341e !important
  }

  .sm\:focus\:text-yellow-100:focus {
    color: #fffff0 !important
  }

  .sm\:focus\:text-yellow-200:focus {
    color: #fefcbf !important
  }

  .sm\:focus\:text-yellow-300:focus {
    color: #faf089 !important
  }

  .sm\:focus\:text-yellow-400:focus {
    color: #f6e05e !important
  }

  .sm\:focus\:text-yellow-500:focus {
    color: #ecc94b !important
  }

  .sm\:focus\:text-yellow-600:focus {
    color: #d69e2e !important
  }

  .sm\:focus\:text-yellow-700:focus {
    color: #b7791f !important
  }

  .sm\:focus\:text-yellow-800:focus {
    color: #975a16 !important
  }

  .sm\:focus\:text-yellow-900:focus {
    color: #744210 !important
  }

  .sm\:focus\:text-green-100:focus {
    color: #f0fff4 !important
  }

  .sm\:focus\:text-green-200:focus {
    color: #c6f6d5 !important
  }

  .sm\:focus\:text-green-300:focus {
    color: #9ae6b4 !important
  }

  .sm\:focus\:text-green-400:focus {
    color: #68d391 !important
  }

  .sm\:focus\:text-green-500:focus {
    color: #48bb78 !important
  }

  .sm\:focus\:text-green-600:focus {
    color: #38a169 !important
  }

  .sm\:focus\:text-green-700:focus {
    color: #2f855a !important
  }

  .sm\:focus\:text-green-800:focus {
    color: #276749 !important
  }

  .sm\:focus\:text-green-900:focus {
    color: #22543d !important
  }

  .sm\:focus\:text-teal-100:focus {
    color: #e6fffa !important
  }

  .sm\:focus\:text-teal-200:focus {
    color: #b2f5ea !important
  }

  .sm\:focus\:text-teal-300:focus {
    color: #81e6d9 !important
  }

  .sm\:focus\:text-teal-400:focus {
    color: #4fd1c5 !important
  }

  .sm\:focus\:text-teal-500:focus {
    color: #38b2ac !important
  }

  .sm\:focus\:text-teal-600:focus {
    color: #319795 !important
  }

  .sm\:focus\:text-teal-700:focus {
    color: #2c7a7b !important
  }

  .sm\:focus\:text-teal-800:focus {
    color: #285e61 !important
  }

  .sm\:focus\:text-teal-900:focus {
    color: #234e52 !important
  }

  .sm\:focus\:text-blue-100:focus {
    color: #ebf8ff !important
  }

  .sm\:focus\:text-blue-200:focus {
    color: #bee3f8 !important
  }

  .sm\:focus\:text-blue-300:focus {
    color: #90cdf4 !important
  }

  .sm\:focus\:text-blue-400:focus {
    color: #63b3ed !important
  }

  .sm\:focus\:text-blue-500:focus {
    color: #4299e1 !important
  }

  .sm\:focus\:text-blue-600:focus {
    color: #3182ce !important
  }

  .sm\:focus\:text-blue-700:focus {
    color: #2b6cb0 !important
  }

  .sm\:focus\:text-blue-800:focus {
    color: #2c5282 !important
  }

  .sm\:focus\:text-blue-900:focus {
    color: #2a4365 !important
  }

  .sm\:focus\:text-indigo-100:focus {
    color: #ebf4ff !important
  }

  .sm\:focus\:text-indigo-200:focus {
    color: #c3dafe !important
  }

  .sm\:focus\:text-indigo-300:focus {
    color: #a3bffa !important
  }

  .sm\:focus\:text-indigo-400:focus {
    color: #7f9cf5 !important
  }

  .sm\:focus\:text-indigo-500:focus {
    color: #667eea !important
  }

  .sm\:focus\:text-indigo-600:focus {
    color: #5a67d8 !important
  }

  .sm\:focus\:text-indigo-700:focus {
    color: #4c51bf !important
  }

  .sm\:focus\:text-indigo-800:focus {
    color: #434190 !important
  }

  .sm\:focus\:text-indigo-900:focus {
    color: #3c366b !important
  }

  .sm\:focus\:text-purple-100:focus {
    color: #faf5ff !important
  }

  .sm\:focus\:text-purple-200:focus {
    color: #e9d8fd !important
  }

  .sm\:focus\:text-purple-300:focus {
    color: #d6bcfa !important
  }

  .sm\:focus\:text-purple-400:focus {
    color: #b794f4 !important
  }

  .sm\:focus\:text-purple-500:focus {
    color: #9f7aea !important
  }

  .sm\:focus\:text-purple-600:focus {
    color: #805ad5 !important
  }

  .sm\:focus\:text-purple-700:focus {
    color: #6b46c1 !important
  }

  .sm\:focus\:text-purple-800:focus {
    color: #553c9a !important
  }

  .sm\:focus\:text-purple-900:focus {
    color: #44337a !important
  }

  .sm\:focus\:text-pink-100:focus {
    color: #fff5f7 !important
  }

  .sm\:focus\:text-pink-200:focus {
    color: #fed7e2 !important
  }

  .sm\:focus\:text-pink-300:focus {
    color: #fbb6ce !important
  }

  .sm\:focus\:text-pink-400:focus {
    color: #f687b3 !important
  }

  .sm\:focus\:text-pink-500:focus {
    color: #ed64a6 !important
  }

  .sm\:focus\:text-pink-600:focus {
    color: #d53f8c !important
  }

  .sm\:focus\:text-pink-700:focus {
    color: #b83280 !important
  }

  .sm\:focus\:text-pink-800:focus {
    color: #97266d !important
  }

  .sm\:focus\:text-pink-900:focus {
    color: #702459 !important
  }

  .sm\:bg-transparent {
    background-color: transparent !important
  }

  .sm\:bg-current {
    background-color: currentColor !important
  }

  .sm\:bg-black {
    background-color: #000 !important
  }

  .sm\:bg-white {
    background-color: #fff !important
  }

  .sm\:bg-gray-100 {
    background-color: #f7fafc !important
  }

  .sm\:bg-gray-200 {
    background-color: #edf2f7 !important
  }

  .sm\:bg-gray-300 {
    background-color: #e2e8f0 !important
  }

  .sm\:bg-gray-400 {
    background-color: #cbd5e0 !important
  }

  .sm\:bg-gray-500 {
    background-color: #a0aec0 !important
  }

  .sm\:bg-gray-600 {
    background-color: #718096 !important
  }

  .sm\:bg-gray-700 {
    background-color: #4a5568 !important
  }

  .sm\:bg-gray-800 {
    background-color: #2d3748 !important
  }

  .sm\:bg-gray-900 {
    background-color: #1a202c !important
  }

  .sm\:bg-red-100 {
    background-color: #fff5f5 !important
  }

  .sm\:bg-red-200 {
    background-color: #fed7d7 !important
  }

  .sm\:bg-red-300 {
    background-color: #feb2b2 !important
  }

  .sm\:bg-red-400 {
    background-color: #fc8181 !important
  }

  .sm\:bg-red-500 {
    background-color: #f56565 !important
  }

  .sm\:bg-red-600 {
    background-color: #e53e3e !important
  }

  .sm\:bg-red-700 {
    background-color: #c53030 !important
  }

  .sm\:bg-red-800 {
    background-color: #9b2c2c !important
  }

  .sm\:bg-red-900 {
    background-color: #742a2a !important
  }

  .sm\:bg-orange-100 {
    background-color: #fffaf0 !important
  }

  .sm\:bg-orange-200 {
    background-color: #feebc8 !important
  }

  .sm\:bg-orange-300 {
    background-color: #fbd38d !important
  }

  .sm\:bg-orange-400 {
    background-color: #f6ad55 !important
  }

  .sm\:bg-orange-500 {
    background-color: #ed8936 !important
  }

  .sm\:bg-orange-600 {
    background-color: #dd6b20 !important
  }

  .sm\:bg-orange-700 {
    background-color: #c05621 !important
  }

  .sm\:bg-orange-800 {
    background-color: #9c4221 !important
  }

  .sm\:bg-orange-900 {
    background-color: #7b341e !important
  }

  .sm\:bg-yellow-100 {
    background-color: #fffff0 !important
  }

  .sm\:bg-yellow-200 {
    background-color: #fefcbf !important
  }

  .sm\:bg-yellow-300 {
    background-color: #faf089 !important
  }

  .sm\:bg-yellow-400 {
    background-color: #f6e05e !important
  }

  .sm\:bg-yellow-500 {
    background-color: #ecc94b !important
  }

  .sm\:bg-yellow-600 {
    background-color: #d69e2e !important
  }

  .sm\:bg-yellow-700 {
    background-color: #b7791f !important
  }

  .sm\:bg-yellow-800 {
    background-color: #975a16 !important
  }

  .sm\:bg-yellow-900 {
    background-color: #744210 !important
  }

  .sm\:bg-green-100 {
    background-color: #f0fff4 !important
  }

  .sm\:bg-green-200 {
    background-color: #c6f6d5 !important
  }

  .sm\:bg-green-300 {
    background-color: #9ae6b4 !important
  }

  .sm\:bg-green-400 {
    background-color: #68d391 !important
  }

  .sm\:bg-green-500 {
    background-color: #48bb78 !important
  }

  .sm\:bg-green-600 {
    background-color: #38a169 !important
  }

  .sm\:bg-green-700 {
    background-color: #2f855a !important
  }

  .sm\:bg-green-800 {
    background-color: #276749 !important
  }

  .sm\:bg-green-900 {
    background-color: #22543d !important
  }

  .sm\:bg-teal-100 {
    background-color: #e6fffa !important
  }

  .sm\:bg-teal-200 {
    background-color: #b2f5ea !important
  }

  .sm\:bg-teal-300 {
    background-color: #81e6d9 !important
  }

  .sm\:bg-teal-400 {
    background-color: #4fd1c5 !important
  }

  .sm\:bg-teal-500 {
    background-color: #38b2ac !important
  }

  .sm\:bg-teal-600 {
    background-color: #319795 !important
  }

  .sm\:bg-teal-700 {
    background-color: #2c7a7b !important
  }

  .sm\:bg-teal-800 {
    background-color: #285e61 !important
  }

  .sm\:bg-teal-900 {
    background-color: #234e52 !important
  }

  .sm\:bg-blue-100 {
    background-color: #ebf8ff !important
  }

  .sm\:bg-blue-200 {
    background-color: #bee3f8 !important
  }

  .sm\:bg-blue-300 {
    background-color: #90cdf4 !important
  }

  .sm\:bg-blue-400 {
    background-color: #63b3ed !important
  }

  .sm\:bg-blue-500 {
    background-color: #4299e1 !important
  }

  .sm\:bg-blue-600 {
    background-color: #3182ce !important
  }

  .sm\:bg-blue-700 {
    background-color: #2b6cb0 !important
  }

  .sm\:bg-blue-800 {
    background-color: #2c5282 !important
  }

  .sm\:bg-blue-900 {
    background-color: #2a4365 !important
  }

  .sm\:bg-indigo-100 {
    background-color: #ebf4ff !important
  }

  .sm\:bg-indigo-200 {
    background-color: #c3dafe !important
  }

  .sm\:bg-indigo-300 {
    background-color: #a3bffa !important
  }

  .sm\:bg-indigo-400 {
    background-color: #7f9cf5 !important
  }

  .sm\:bg-indigo-500 {
    background-color: #667eea !important
  }

  .sm\:bg-indigo-600 {
    background-color: #5a67d8 !important
  }

  .sm\:bg-indigo-700 {
    background-color: #4c51bf !important
  }

  .sm\:bg-indigo-800 {
    background-color: #434190 !important
  }

  .sm\:bg-indigo-900 {
    background-color: #3c366b !important
  }

  .sm\:bg-purple-100 {
    background-color: #faf5ff !important
  }

  .sm\:bg-purple-200 {
    background-color: #e9d8fd !important
  }

  .sm\:bg-purple-300 {
    background-color: #d6bcfa !important
  }

  .sm\:bg-purple-400 {
    background-color: #b794f4 !important
  }

  .sm\:bg-purple-500 {
    background-color: #9f7aea !important
  }

  .sm\:bg-purple-600 {
    background-color: #805ad5 !important
  }

  .sm\:bg-purple-700 {
    background-color: #6b46c1 !important
  }

  .sm\:bg-purple-800 {
    background-color: #553c9a !important
  }

  .sm\:bg-purple-900 {
    background-color: #44337a !important
  }

  .sm\:bg-pink-100 {
    background-color: #fff5f7 !important
  }

  .sm\:bg-pink-200 {
    background-color: #fed7e2 !important
  }

  .sm\:bg-pink-300 {
    background-color: #fbb6ce !important
  }

  .sm\:bg-pink-400 {
    background-color: #f687b3 !important
  }

  .sm\:bg-pink-500 {
    background-color: #ed64a6 !important
  }

  .sm\:bg-pink-600 {
    background-color: #d53f8c !important
  }

  .sm\:bg-pink-700 {
    background-color: #b83280 !important
  }

  .sm\:bg-pink-800 {
    background-color: #97266d !important
  }

  .sm\:bg-pink-900 {
    background-color: #702459 !important
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent !important
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor !important
  }

  .sm\:hover\:bg-black:hover {
    background-color: #000 !important
  }

  .sm\:hover\:bg-white:hover {
    background-color: #fff !important
  }

  .sm\:hover\:bg-gray-100:hover {
    background-color: #f7fafc !important
  }

  .sm\:hover\:bg-gray-200:hover {
    background-color: #edf2f7 !important
  }

  .sm\:hover\:bg-gray-300:hover {
    background-color: #e2e8f0 !important
  }

  .sm\:hover\:bg-gray-400:hover {
    background-color: #cbd5e0 !important
  }

  .sm\:hover\:bg-gray-500:hover {
    background-color: #a0aec0 !important
  }

  .sm\:hover\:bg-gray-600:hover {
    background-color: #718096 !important
  }

  .sm\:hover\:bg-gray-700:hover {
    background-color: #4a5568 !important
  }

  .sm\:hover\:bg-gray-800:hover {
    background-color: #2d3748 !important
  }

  .sm\:hover\:bg-gray-900:hover {
    background-color: #1a202c !important
  }

  .sm\:hover\:bg-red-100:hover {
    background-color: #fff5f5 !important
  }

  .sm\:hover\:bg-red-200:hover {
    background-color: #fed7d7 !important
  }

  .sm\:hover\:bg-red-300:hover {
    background-color: #feb2b2 !important
  }

  .sm\:hover\:bg-red-400:hover {
    background-color: #fc8181 !important
  }

  .sm\:hover\:bg-red-500:hover {
    background-color: #f56565 !important
  }

  .sm\:hover\:bg-red-600:hover {
    background-color: #e53e3e !important
  }

  .sm\:hover\:bg-red-700:hover {
    background-color: #c53030 !important
  }

  .sm\:hover\:bg-red-800:hover {
    background-color: #9b2c2c !important
  }

  .sm\:hover\:bg-red-900:hover {
    background-color: #742a2a !important
  }

  .sm\:hover\:bg-orange-100:hover {
    background-color: #fffaf0 !important
  }

  .sm\:hover\:bg-orange-200:hover {
    background-color: #feebc8 !important
  }

  .sm\:hover\:bg-orange-300:hover {
    background-color: #fbd38d !important
  }

  .sm\:hover\:bg-orange-400:hover {
    background-color: #f6ad55 !important
  }

  .sm\:hover\:bg-orange-500:hover {
    background-color: #ed8936 !important
  }

  .sm\:hover\:bg-orange-600:hover {
    background-color: #dd6b20 !important
  }

  .sm\:hover\:bg-orange-700:hover {
    background-color: #c05621 !important
  }

  .sm\:hover\:bg-orange-800:hover {
    background-color: #9c4221 !important
  }

  .sm\:hover\:bg-orange-900:hover {
    background-color: #7b341e !important
  }

  .sm\:hover\:bg-yellow-100:hover {
    background-color: #fffff0 !important
  }

  .sm\:hover\:bg-yellow-200:hover {
    background-color: #fefcbf !important
  }

  .sm\:hover\:bg-yellow-300:hover {
    background-color: #faf089 !important
  }

  .sm\:hover\:bg-yellow-400:hover {
    background-color: #f6e05e !important
  }

  .sm\:hover\:bg-yellow-500:hover {
    background-color: #ecc94b !important
  }

  .sm\:hover\:bg-yellow-600:hover {
    background-color: #d69e2e !important
  }

  .sm\:hover\:bg-yellow-700:hover {
    background-color: #b7791f !important
  }

  .sm\:hover\:bg-yellow-800:hover {
    background-color: #975a16 !important
  }

  .sm\:hover\:bg-yellow-900:hover {
    background-color: #744210 !important
  }

  .sm\:hover\:bg-green-100:hover {
    background-color: #f0fff4 !important
  }

  .sm\:hover\:bg-green-200:hover {
    background-color: #c6f6d5 !important
  }

  .sm\:hover\:bg-green-300:hover {
    background-color: #9ae6b4 !important
  }

  .sm\:hover\:bg-green-400:hover {
    background-color: #68d391 !important
  }

  .sm\:hover\:bg-green-500:hover {
    background-color: #48bb78 !important
  }

  .sm\:hover\:bg-green-600:hover {
    background-color: #38a169 !important
  }

  .sm\:hover\:bg-green-700:hover {
    background-color: #2f855a !important
  }

  .sm\:hover\:bg-green-800:hover {
    background-color: #276749 !important
  }

  .sm\:hover\:bg-green-900:hover {
    background-color: #22543d !important
  }

  .sm\:hover\:bg-teal-100:hover {
    background-color: #e6fffa !important
  }

  .sm\:hover\:bg-teal-200:hover {
    background-color: #b2f5ea !important
  }

  .sm\:hover\:bg-teal-300:hover {
    background-color: #81e6d9 !important
  }

  .sm\:hover\:bg-teal-400:hover {
    background-color: #4fd1c5 !important
  }

  .sm\:hover\:bg-teal-500:hover {
    background-color: #38b2ac !important
  }

  .sm\:hover\:bg-teal-600:hover {
    background-color: #319795 !important
  }

  .sm\:hover\:bg-teal-700:hover {
    background-color: #2c7a7b !important
  }

  .sm\:hover\:bg-teal-800:hover {
    background-color: #285e61 !important
  }

  .sm\:hover\:bg-teal-900:hover {
    background-color: #234e52 !important
  }

  .sm\:hover\:bg-blue-100:hover {
    background-color: #ebf8ff !important
  }

  .sm\:hover\:bg-blue-200:hover {
    background-color: #bee3f8 !important
  }

  .sm\:hover\:bg-blue-300:hover {
    background-color: #90cdf4 !important
  }

  .sm\:hover\:bg-blue-400:hover {
    background-color: #63b3ed !important
  }

  .sm\:hover\:bg-blue-500:hover {
    background-color: #4299e1 !important
  }

  .sm\:hover\:bg-blue-600:hover {
    background-color: #3182ce !important
  }

  .sm\:hover\:bg-blue-700:hover {
    background-color: #2b6cb0 !important
  }

  .sm\:hover\:bg-blue-800:hover {
    background-color: #2c5282 !important
  }

  .sm\:hover\:bg-blue-900:hover {
    background-color: #2a4365 !important
  }

  .sm\:hover\:bg-indigo-100:hover {
    background-color: #ebf4ff !important
  }

  .sm\:hover\:bg-indigo-200:hover {
    background-color: #c3dafe !important
  }

  .sm\:hover\:bg-indigo-300:hover {
    background-color: #a3bffa !important
  }

  .sm\:hover\:bg-indigo-400:hover {
    background-color: #7f9cf5 !important
  }

  .sm\:hover\:bg-indigo-500:hover {
    background-color: #667eea !important
  }

  .sm\:hover\:bg-indigo-600:hover {
    background-color: #5a67d8 !important
  }

  .sm\:hover\:bg-indigo-700:hover {
    background-color: #4c51bf !important
  }

  .sm\:hover\:bg-indigo-800:hover {
    background-color: #434190 !important
  }

  .sm\:hover\:bg-indigo-900:hover {
    background-color: #3c366b !important
  }

  .sm\:hover\:bg-purple-100:hover {
    background-color: #faf5ff !important
  }

  .sm\:hover\:bg-purple-200:hover {
    background-color: #e9d8fd !important
  }

  .sm\:hover\:bg-purple-300:hover {
    background-color: #d6bcfa !important
  }

  .sm\:hover\:bg-purple-400:hover {
    background-color: #b794f4 !important
  }

  .sm\:hover\:bg-purple-500:hover {
    background-color: #9f7aea !important
  }

  .sm\:hover\:bg-purple-600:hover {
    background-color: #805ad5 !important
  }

  .sm\:hover\:bg-purple-700:hover {
    background-color: #6b46c1 !important
  }

  .sm\:hover\:bg-purple-800:hover {
    background-color: #553c9a !important
  }

  .sm\:hover\:bg-purple-900:hover {
    background-color: #44337a !important
  }

  .sm\:hover\:bg-pink-100:hover {
    background-color: #fff5f7 !important
  }

  .sm\:hover\:bg-pink-200:hover {
    background-color: #fed7e2 !important
  }

  .sm\:hover\:bg-pink-300:hover {
    background-color: #fbb6ce !important
  }

  .sm\:hover\:bg-pink-400:hover {
    background-color: #f687b3 !important
  }

  .sm\:hover\:bg-pink-500:hover {
    background-color: #ed64a6 !important
  }

  .sm\:hover\:bg-pink-600:hover {
    background-color: #d53f8c !important
  }

  .sm\:hover\:bg-pink-700:hover {
    background-color: #b83280 !important
  }

  .sm\:hover\:bg-pink-800:hover {
    background-color: #97266d !important
  }

  .sm\:hover\:bg-pink-900:hover {
    background-color: #702459 !important
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent !important
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor !important
  }

  .sm\:focus\:bg-black:focus {
    background-color: #000 !important
  }

  .sm\:focus\:bg-white:focus {
    background-color: #fff !important
  }

  .sm\:focus\:bg-gray-100:focus {
    background-color: #f7fafc !important
  }

  .sm\:focus\:bg-gray-200:focus {
    background-color: #edf2f7 !important
  }

  .sm\:focus\:bg-gray-300:focus {
    background-color: #e2e8f0 !important
  }

  .sm\:focus\:bg-gray-400:focus {
    background-color: #cbd5e0 !important
  }

  .sm\:focus\:bg-gray-500:focus {
    background-color: #a0aec0 !important
  }

  .sm\:focus\:bg-gray-600:focus {
    background-color: #718096 !important
  }

  .sm\:focus\:bg-gray-700:focus {
    background-color: #4a5568 !important
  }

  .sm\:focus\:bg-gray-800:focus {
    background-color: #2d3748 !important
  }

  .sm\:focus\:bg-gray-900:focus {
    background-color: #1a202c !important
  }

  .sm\:focus\:bg-red-100:focus {
    background-color: #fff5f5 !important
  }

  .sm\:focus\:bg-red-200:focus {
    background-color: #fed7d7 !important
  }

  .sm\:focus\:bg-red-300:focus {
    background-color: #feb2b2 !important
  }

  .sm\:focus\:bg-red-400:focus {
    background-color: #fc8181 !important
  }

  .sm\:focus\:bg-red-500:focus {
    background-color: #f56565 !important
  }

  .sm\:focus\:bg-red-600:focus {
    background-color: #e53e3e !important
  }

  .sm\:focus\:bg-red-700:focus {
    background-color: #c53030 !important
  }

  .sm\:focus\:bg-red-800:focus {
    background-color: #9b2c2c !important
  }

  .sm\:focus\:bg-red-900:focus {
    background-color: #742a2a !important
  }

  .sm\:focus\:bg-orange-100:focus {
    background-color: #fffaf0 !important
  }

  .sm\:focus\:bg-orange-200:focus {
    background-color: #feebc8 !important
  }

  .sm\:focus\:bg-orange-300:focus {
    background-color: #fbd38d !important
  }

  .sm\:focus\:bg-orange-400:focus {
    background-color: #f6ad55 !important
  }

  .sm\:focus\:bg-orange-500:focus {
    background-color: #ed8936 !important
  }

  .sm\:focus\:bg-orange-600:focus {
    background-color: #dd6b20 !important
  }

  .sm\:focus\:bg-orange-700:focus {
    background-color: #c05621 !important
  }

  .sm\:focus\:bg-orange-800:focus {
    background-color: #9c4221 !important
  }

  .sm\:focus\:bg-orange-900:focus {
    background-color: #7b341e !important
  }

  .sm\:focus\:bg-yellow-100:focus {
    background-color: #fffff0 !important
  }

  .sm\:focus\:bg-yellow-200:focus {
    background-color: #fefcbf !important
  }

  .sm\:focus\:bg-yellow-300:focus {
    background-color: #faf089 !important
  }

  .sm\:focus\:bg-yellow-400:focus {
    background-color: #f6e05e !important
  }

  .sm\:focus\:bg-yellow-500:focus {
    background-color: #ecc94b !important
  }

  .sm\:focus\:bg-yellow-600:focus {
    background-color: #d69e2e !important
  }

  .sm\:focus\:bg-yellow-700:focus {
    background-color: #b7791f !important
  }

  .sm\:focus\:bg-yellow-800:focus {
    background-color: #975a16 !important
  }

  .sm\:focus\:bg-yellow-900:focus {
    background-color: #744210 !important
  }

  .sm\:focus\:bg-green-100:focus {
    background-color: #f0fff4 !important
  }

  .sm\:focus\:bg-green-200:focus {
    background-color: #c6f6d5 !important
  }

  .sm\:focus\:bg-green-300:focus {
    background-color: #9ae6b4 !important
  }

  .sm\:focus\:bg-green-400:focus {
    background-color: #68d391 !important
  }

  .sm\:focus\:bg-green-500:focus {
    background-color: #48bb78 !important
  }

  .sm\:focus\:bg-green-600:focus {
    background-color: #38a169 !important
  }

  .sm\:focus\:bg-green-700:focus {
    background-color: #2f855a !important
  }

  .sm\:focus\:bg-green-800:focus {
    background-color: #276749 !important
  }

  .sm\:focus\:bg-green-900:focus {
    background-color: #22543d !important
  }

  .sm\:focus\:bg-teal-100:focus {
    background-color: #e6fffa !important
  }

  .sm\:focus\:bg-teal-200:focus {
    background-color: #b2f5ea !important
  }

  .sm\:focus\:bg-teal-300:focus {
    background-color: #81e6d9 !important
  }

  .sm\:focus\:bg-teal-400:focus {
    background-color: #4fd1c5 !important
  }

  .sm\:focus\:bg-teal-500:focus {
    background-color: #38b2ac !important
  }

  .sm\:focus\:bg-teal-600:focus {
    background-color: #319795 !important
  }

  .sm\:focus\:bg-teal-700:focus {
    background-color: #2c7a7b !important
  }

  .sm\:focus\:bg-teal-800:focus {
    background-color: #285e61 !important
  }

  .sm\:focus\:bg-teal-900:focus {
    background-color: #234e52 !important
  }

  .sm\:focus\:bg-blue-100:focus {
    background-color: #ebf8ff !important
  }

  .sm\:focus\:bg-blue-200:focus {
    background-color: #bee3f8 !important
  }

  .sm\:focus\:bg-blue-300:focus {
    background-color: #90cdf4 !important
  }

  .sm\:focus\:bg-blue-400:focus {
    background-color: #63b3ed !important
  }

  .sm\:focus\:bg-blue-500:focus {
    background-color: #4299e1 !important
  }

  .sm\:focus\:bg-blue-600:focus {
    background-color: #3182ce !important
  }

  .sm\:focus\:bg-blue-700:focus {
    background-color: #2b6cb0 !important
  }

  .sm\:focus\:bg-blue-800:focus {
    background-color: #2c5282 !important
  }

  .sm\:focus\:bg-blue-900:focus {
    background-color: #2a4365 !important
  }

  .sm\:focus\:bg-indigo-100:focus {
    background-color: #ebf4ff !important
  }

  .sm\:focus\:bg-indigo-200:focus {
    background-color: #c3dafe !important
  }

  .sm\:focus\:bg-indigo-300:focus {
    background-color: #a3bffa !important
  }

  .sm\:focus\:bg-indigo-400:focus {
    background-color: #7f9cf5 !important
  }

  .sm\:focus\:bg-indigo-500:focus {
    background-color: #667eea !important
  }

  .sm\:focus\:bg-indigo-600:focus {
    background-color: #5a67d8 !important
  }

  .sm\:focus\:bg-indigo-700:focus {
    background-color: #4c51bf !important
  }

  .sm\:focus\:bg-indigo-800:focus {
    background-color: #434190 !important
  }

  .sm\:focus\:bg-indigo-900:focus {
    background-color: #3c366b !important
  }

  .sm\:focus\:bg-purple-100:focus {
    background-color: #faf5ff !important
  }

  .sm\:focus\:bg-purple-200:focus {
    background-color: #e9d8fd !important
  }

  .sm\:focus\:bg-purple-300:focus {
    background-color: #d6bcfa !important
  }

  .sm\:focus\:bg-purple-400:focus {
    background-color: #b794f4 !important
  }

  .sm\:focus\:bg-purple-500:focus {
    background-color: #9f7aea !important
  }

  .sm\:focus\:bg-purple-600:focus {
    background-color: #805ad5 !important
  }

  .sm\:focus\:bg-purple-700:focus {
    background-color: #6b46c1 !important
  }

  .sm\:focus\:bg-purple-800:focus {
    background-color: #553c9a !important
  }

  .sm\:focus\:bg-purple-900:focus {
    background-color: #44337a !important
  }

  .sm\:focus\:bg-pink-100:focus {
    background-color: #fff5f7 !important
  }

  .sm\:focus\:bg-pink-200:focus {
    background-color: #fed7e2 !important
  }

  .sm\:focus\:bg-pink-300:focus {
    background-color: #fbb6ce !important
  }

  .sm\:focus\:bg-pink-400:focus {
    background-color: #f687b3 !important
  }

  .sm\:focus\:bg-pink-500:focus {
    background-color: #ed64a6 !important
  }

  .sm\:focus\:bg-pink-600:focus {
    background-color: #d53f8c !important
  }

  .sm\:focus\:bg-pink-700:focus {
    background-color: #b83280 !important
  }

  .sm\:focus\:bg-pink-800:focus {
    background-color: #97266d !important
  }

  .sm\:focus\:bg-pink-900:focus {
    background-color: #702459 !important
  }

  .sm\:cursor-auto {
    cursor: auto !important
  }

  .sm\:cursor-default {
    cursor: default !important
  }

  .sm\:cursor-pointer {
    cursor: pointer !important
  }

  .sm\:cursor-wait {
    cursor: wait !important
  }

  .sm\:cursor-text {
    cursor: text !important
  }

  .sm\:cursor-move {
    cursor: move !important
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed !important
  }

  .sm\:h-0 {
    height: 0 !important
  }

  .sm\:h-4 {
    height: 4px !important
  }

  .sm\:h-6 {
    height: 6px !important
  }

  .sm\:h-8 {
    height: 8px !important
  }

  .sm\:h-12 {
    height: 12px !important
  }

  .sm\:h-16 {
    height: 16px !important
  }

  .sm\:h-20 {
    height: 20px !important
  }

  .sm\:h-24 {
    height: 24px !important
  }

  .sm\:h-28 {
    height: 28px !important
  }

  .sm\:h-32 {
    height: 32px !important
  }

  .sm\:h-36 {
    height: 36px !important
  }

  .sm\:h-40 {
    height: 40px !important
  }

  .sm\:h-44 {
    height: 44px !important
  }

  .sm\:h-48 {
    height: 48px !important
  }

  .sm\:h-52 {
    height: 52px !important
  }

  .sm\:h-56 {
    height: 56px !important
  }

  .sm\:h-60 {
    height: 60px !important
  }

  .sm\:h-64 {
    height: 64px !important
  }

  .sm\:h-68 {
    height: 68px !important
  }

  .sm\:h-72 {
    height: 72px !important
  }

  .sm\:h-76 {
    height: 76px !important
  }

  .sm\:h-80 {
    height: 80px !important
  }

  .sm\:h-84 {
    height: 84px !important
  }

  .sm\:h-88 {
    height: 88px !important
  }

  .sm\:h-92 {
    height: 92px !important
  }

  .sm\:h-96 {
    height: 96px !important
  }

  .sm\:h-128 {
    height: 128px !important
  }

  .sm\:h-160 {
    height: 160px !important
  }

  .sm\:h-192 {
    height: 192px !important
  }

  .sm\:h-224 {
    height: 224px !important
  }

  .sm\:h-256 {
    height: 256px !important
  }

  .sm\:h-320 {
    height: 320px !important
  }

  .sm\:h-auto {
    height: auto !important
  }

  .sm\:h-px {
    height: 1px !important
  }

  .sm\:h-full {
    height: 100% !important
  }

  .sm\:h-screen {
    height: 100vh !important
  }

  .sm\:w-0 {
    width: 0 !important
  }

  .sm\:w-4 {
    width: 4px !important
  }

  .sm\:w-6 {
    width: 6px !important
  }

  .sm\:w-8 {
    width: 8px !important
  }

  .sm\:w-12 {
    width: 12px !important
  }

  .sm\:w-16 {
    width: 16px !important
  }

  .sm\:w-20 {
    width: 20px !important
  }

  .sm\:w-24 {
    width: 24px !important
  }

  .sm\:w-28 {
    width: 28px !important
  }

  .sm\:w-32 {
    width: 32px !important
  }

  .sm\:w-36 {
    width: 36px !important
  }

  .sm\:w-40 {
    width: 40px !important
  }

  .sm\:w-44 {
    width: 44px !important
  }

  .sm\:w-48 {
    width: 48px !important
  }

  .sm\:w-52 {
    width: 52px !important
  }

  .sm\:w-56 {
    width: 56px !important
  }

  .sm\:w-60 {
    width: 60px !important
  }

  .sm\:w-64 {
    width: 64px !important
  }

  .sm\:w-68 {
    width: 68px !important
  }

  .sm\:w-72 {
    width: 72px !important
  }

  .sm\:w-76 {
    width: 76px !important
  }

  .sm\:w-80 {
    width: 80px !important
  }

  .sm\:w-84 {
    width: 84px !important
  }

  .sm\:w-88 {
    width: 88px !important
  }

  .sm\:w-92 {
    width: 92px !important
  }

  .sm\:w-96 {
    width: 96px !important
  }

  .sm\:w-128 {
    width: 128px !important
  }

  .sm\:w-160 {
    width: 160px !important
  }

  .sm\:w-192 {
    width: 192px !important
  }

  .sm\:w-224 {
    width: 224px !important
  }

  .sm\:w-256 {
    width: 256px !important
  }

  .sm\:w-320 {
    width: 320px !important
  }

  .sm\:w-auto {
    width: auto !important
  }

  .sm\:w-px {
    width: 1px !important
  }

  .sm\:w-1\/2 {
    width: 50% !important
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important
  }

  .sm\:w-2\/3 {
    width: 66.666667% !important
  }

  .sm\:w-1\/4 {
    width: 25% !important
  }

  .sm\:w-2\/4 {
    width: 50% !important
  }

  .sm\:w-3\/4 {
    width: 75% !important
  }

  .sm\:w-1\/5 {
    width: 20% !important
  }

  .sm\:w-2\/5 {
    width: 40% !important
  }

  .sm\:w-3\/5 {
    width: 60% !important
  }

  .sm\:w-4\/5 {
    width: 80% !important
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important
  }

  .sm\:w-3\/6 {
    width: 50% !important
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important
  }

  .sm\:w-1\/12 {
    width: 8.333333% !important
  }

  .sm\:w-2\/12 {
    width: 16.666667% !important
  }

  .sm\:w-3\/12 {
    width: 25% !important
  }

  .sm\:w-4\/12 {
    width: 33.333333% !important
  }

  .sm\:w-5\/12 {
    width: 41.666667% !important
  }

  .sm\:w-6\/12 {
    width: 50% !important
  }

  .sm\:w-7\/12 {
    width: 58.333333% !important
  }

  .sm\:w-8\/12 {
    width: 66.666667% !important
  }

  .sm\:w-9\/12 {
    width: 75% !important
  }

  .sm\:w-10\/12 {
    width: 83.333333% !important
  }

  .sm\:w-11\/12 {
    width: 91.666667% !important
  }

  .sm\:w-full {
    width: 100% !important
  }

  .sm\:w-screen {
    width: 100vw !important
  }

  .sm\:max-h-full {
    max-height: 100% !important
  }

  .sm\:max-h-screen {
    max-height: 100vh !important
  }

  .sm\:max-w-none {
    max-width: none !important
  }

  .sm\:max-w-xs {
    max-width: 20rem !important
  }

  .sm\:max-w-sm {
    max-width: 24rem !important
  }

  .sm\:max-w-md {
    max-width: 28rem !important
  }

  .sm\:max-w-lg {
    max-width: 32rem !important
  }

  .sm\:max-w-xl {
    max-width: 36rem !important
  }

  .sm\:max-w-2xl {
    max-width: 42rem !important
  }

  .sm\:max-w-3xl {
    max-width: 48rem !important
  }

  .sm\:max-w-4xl {
    max-width: 56rem !important
  }

  .sm\:max-w-5xl {
    max-width: 64rem !important
  }

  .sm\:max-w-6xl {
    max-width: 72rem !important
  }

  .sm\:max-w-full {
    max-width: 100% !important
  }

  .sm\:max-w-screen-sm {
    max-width: 640px !important
  }

  .sm\:max-w-screen-md {
    max-width: 768px !important
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px !important
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px !important
  }

  .sm\:min-h-0 {
    min-height: 0 !important
  }

  .sm\:min-h-full {
    min-height: 100% !important
  }

  .sm\:min-h-screen {
    min-height: 100vh !important
  }

  .sm\:min-w-0 {
    min-width: 0 !important
  }

  .sm\:min-w-full {
    min-width: 100% !important
  }

  .sm\:overflow-auto {
    overflow: auto !important
  }

  .sm\:overflow-hidden {
    overflow: hidden !important
  }

  .sm\:overflow-visible {
    overflow: visible !important
  }

  .sm\:overflow-scroll {
    overflow: scroll !important
  }

  .sm\:overflow-x-auto {
    overflow-x: auto !important
  }

  .sm\:overflow-y-auto {
    overflow-y: auto !important
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .sm\:static {
    position: static !important
  }

  .sm\:fixed {
    position: fixed !important
  }

  .sm\:absolute {
    position: absolute !important
  }

  .sm\:relative {
    position: relative !important
  }

  .sm\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important
  }

  .sm\:z-0 {
    z-index: 0 !important
  }

  .sm\:z-10 {
    z-index: 10 !important
  }

  .sm\:z-20 {
    z-index: 20 !important
  }

  .sm\:z-30 {
    z-index: 30 !important
  }

  .sm\:z-40 {
    z-index: 40 !important
  }

  .sm\:z-50 {
    z-index: 50 !important
  }

  .sm\:z-auto {
    z-index: auto !important
  }

  .sm\:block {
    display: block !important
  }

  .sm\:inline-block {
    display: inline-block !important
  }

  .sm\:inline {
    display: inline !important
  }

  .sm\:flex {
    display: flex !important
  }

  .sm\:inline-flex {
    display: inline-flex !important
  }

  .sm\:table {
    display: table !important
  }

  .sm\:table-caption {
    display: table-caption !important
  }

  .sm\:table-cell {
    display: table-cell !important
  }

  .sm\:table-column {
    display: table-column !important
  }

  .sm\:table-column-group {
    display: table-column-group !important
  }

  .sm\:table-footer-group {
    display: table-footer-group !important
  }

  .sm\:table-header-group {
    display: table-header-group !important
  }

  .sm\:table-row-group {
    display: table-row-group !important
  }

  .sm\:table-row {
    display: table-row !important
  }

  .sm\:flow-root {
    display: flow-root !important
  }

  .sm\:grid {
    display: grid !important
  }

  .sm\:inline-grid {
    display: inline-grid !important
  }

  .sm\:contents {
    display: contents !important
  }

  .sm\:hidden {
    display: none !important
  }

  .sm\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important
  }

  .sm\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important
  }

  .sm\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important
  }

  .sm\:inset-x-0 {
    right: 0 !important;
    left: 0 !important
  }

  .sm\:inset-y-auto {
    top: auto !important;
    bottom: auto !important
  }

  .sm\:inset-x-auto {
    right: auto !important;
    left: auto !important
  }

  .sm\:top-0 {
    top: 0 !important
  }

  .sm\:right-0 {
    right: 0 !important
  }

  .sm\:bottom-0 {
    bottom: 0 !important
  }

  .sm\:left-0 {
    left: 0 !important
  }

  .sm\:top-auto {
    top: auto !important
  }

  .sm\:right-auto {
    right: auto !important
  }

  .sm\:bottom-auto {
    bottom: auto !important
  }

  .sm\:left-auto {
    left: auto !important
  }
}

@media (min-width: 768px) {
  .md\:m-0 {
    margin: 0 !important
  }

  .md\:m-4 {
    margin: 4px !important
  }

  .md\:m-6 {
    margin: 6px !important
  }

  .md\:m-8 {
    margin: 8px !important
  }

  .md\:m-12 {
    margin: 12px !important
  }

  .md\:m-16 {
    margin: 16px !important
  }

  .md\:m-20 {
    margin: 20px !important
  }

  .md\:m-24 {
    margin: 24px !important
  }

  .md\:m-28 {
    margin: 28px !important
  }

  .md\:m-32 {
    margin: 32px !important
  }

  .md\:m-36 {
    margin: 36px !important
  }

  .md\:m-40 {
    margin: 40px !important
  }

  .md\:m-44 {
    margin: 44px !important
  }

  .md\:m-48 {
    margin: 48px !important
  }

  .md\:m-52 {
    margin: 52px !important
  }

  .md\:m-56 {
    margin: 56px !important
  }

  .md\:m-60 {
    margin: 60px !important
  }

  .md\:m-64 {
    margin: 64px !important
  }

  .md\:m-68 {
    margin: 68px !important
  }

  .md\:m-72 {
    margin: 72px !important
  }

  .md\:m-76 {
    margin: 76px !important
  }

  .md\:m-80 {
    margin: 80px !important
  }

  .md\:m-84 {
    margin: 84px !important
  }

  .md\:m-88 {
    margin: 88px !important
  }

  .md\:m-92 {
    margin: 92px !important
  }

  .md\:m-96 {
    margin: 96px !important
  }

  .md\:m-128 {
    margin: 128px !important
  }

  .md\:m-160 {
    margin: 160px !important
  }

  .md\:m-192 {
    margin: 192px !important
  }

  .md\:m-224 {
    margin: 224px !important
  }

  .md\:m-256 {
    margin: 256px !important
  }

  .md\:m-320 {
    margin: 320px !important
  }

  .md\:m-auto {
    margin: auto !important
  }

  .md\:m-px {
    margin: 1px !important
  }

  .md\:-m-4 {
    margin: -4px !important
  }

  .md\:-m-6 {
    margin: -6px !important
  }

  .md\:-m-8 {
    margin: -8px !important
  }

  .md\:-m-12 {
    margin: -12px !important
  }

  .md\:-m-16 {
    margin: -16px !important
  }

  .md\:-m-20 {
    margin: -20px !important
  }

  .md\:-m-24 {
    margin: -24px !important
  }

  .md\:-m-28 {
    margin: -28px !important
  }

  .md\:-m-32 {
    margin: -32px !important
  }

  .md\:-m-36 {
    margin: -36px !important
  }

  .md\:-m-40 {
    margin: -40px !important
  }

  .md\:-m-44 {
    margin: -44px !important
  }

  .md\:-m-48 {
    margin: -48px !important
  }

  .md\:-m-52 {
    margin: -52px !important
  }

  .md\:-m-56 {
    margin: -56px !important
  }

  .md\:-m-60 {
    margin: -60px !important
  }

  .md\:-m-64 {
    margin: -64px !important
  }

  .md\:-m-68 {
    margin: -68px !important
  }

  .md\:-m-72 {
    margin: -72px !important
  }

  .md\:-m-76 {
    margin: -76px !important
  }

  .md\:-m-80 {
    margin: -80px !important
  }

  .md\:-m-84 {
    margin: -84px !important
  }

  .md\:-m-88 {
    margin: -88px !important
  }

  .md\:-m-92 {
    margin: -92px !important
  }

  .md\:-m-96 {
    margin: -96px !important
  }

  .md\:-m-128 {
    margin: -128px !important
  }

  .md\:-m-160 {
    margin: -160px !important
  }

  .md\:-m-192 {
    margin: -192px !important
  }

  .md\:-m-224 {
    margin: -224px !important
  }

  .md\:-m-256 {
    margin: -256px !important
  }

  .md\:-m-320 {
    margin: -320px !important
  }

  .md\:-m-px {
    margin: -1px !important
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .md\:my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important
  }

  .md\:mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important
  }

  .md\:my-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important
  }

  .md\:mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .md\:my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important
  }

  .md\:mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .md\:my-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important
  }

  .md\:mx-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .md\:my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important
  }

  .md\:mx-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .md\:my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
  }

  .md\:mx-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .md\:my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important
  }

  .md\:mx-24 {
    margin-left: 24px !important;
    margin-right: 24px !important
  }

  .md\:my-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important
  }

  .md\:mx-28 {
    margin-left: 28px !important;
    margin-right: 28px !important
  }

  .md\:my-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important
  }

  .md\:mx-32 {
    margin-left: 32px !important;
    margin-right: 32px !important
  }

  .md\:my-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important
  }

  .md\:mx-36 {
    margin-left: 36px !important;
    margin-right: 36px !important
  }

  .md\:my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important
  }

  .md\:mx-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .md\:my-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important
  }

  .md\:mx-44 {
    margin-left: 44px !important;
    margin-right: 44px !important
  }

  .md\:my-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important
  }

  .md\:mx-48 {
    margin-left: 48px !important;
    margin-right: 48px !important
  }

  .md\:my-52 {
    margin-top: 52px !important;
    margin-bottom: 52px !important
  }

  .md\:mx-52 {
    margin-left: 52px !important;
    margin-right: 52px !important
  }

  .md\:my-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important
  }

  .md\:mx-56 {
    margin-left: 56px !important;
    margin-right: 56px !important
  }

  .md\:my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important
  }

  .md\:mx-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .md\:my-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important
  }

  .md\:mx-64 {
    margin-left: 64px !important;
    margin-right: 64px !important
  }

  .md\:my-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important
  }

  .md\:mx-68 {
    margin-left: 68px !important;
    margin-right: 68px !important
  }

  .md\:my-72 {
    margin-top: 72px !important;
    margin-bottom: 72px !important
  }

  .md\:mx-72 {
    margin-left: 72px !important;
    margin-right: 72px !important
  }

  .md\:my-76 {
    margin-top: 76px !important;
    margin-bottom: 76px !important
  }

  .md\:mx-76 {
    margin-left: 76px !important;
    margin-right: 76px !important
  }

  .md\:my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important
  }

  .md\:mx-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .md\:my-84 {
    margin-top: 84px !important;
    margin-bottom: 84px !important
  }

  .md\:mx-84 {
    margin-left: 84px !important;
    margin-right: 84px !important
  }

  .md\:my-88 {
    margin-top: 88px !important;
    margin-bottom: 88px !important
  }

  .md\:mx-88 {
    margin-left: 88px !important;
    margin-right: 88px !important
  }

  .md\:my-92 {
    margin-top: 92px !important;
    margin-bottom: 92px !important
  }

  .md\:mx-92 {
    margin-left: 92px !important;
    margin-right: 92px !important
  }

  .md\:my-96 {
    margin-top: 96px !important;
    margin-bottom: 96px !important
  }

  .md\:mx-96 {
    margin-left: 96px !important;
    margin-right: 96px !important
  }

  .md\:my-128 {
    margin-top: 128px !important;
    margin-bottom: 128px !important
  }

  .md\:mx-128 {
    margin-left: 128px !important;
    margin-right: 128px !important
  }

  .md\:my-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important
  }

  .md\:mx-160 {
    margin-left: 160px !important;
    margin-right: 160px !important
  }

  .md\:my-192 {
    margin-top: 192px !important;
    margin-bottom: 192px !important
  }

  .md\:mx-192 {
    margin-left: 192px !important;
    margin-right: 192px !important
  }

  .md\:my-224 {
    margin-top: 224px !important;
    margin-bottom: 224px !important
  }

  .md\:mx-224 {
    margin-left: 224px !important;
    margin-right: 224px !important
  }

  .md\:my-256 {
    margin-top: 256px !important;
    margin-bottom: 256px !important
  }

  .md\:mx-256 {
    margin-left: 256px !important;
    margin-right: 256px !important
  }

  .md\:my-320 {
    margin-top: 320px !important;
    margin-bottom: 320px !important
  }

  .md\:mx-320 {
    margin-left: 320px !important;
    margin-right: 320px !important
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important
  }

  .md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
  }

  .md\:-my-4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important
  }

  .md\:-mx-4 {
    margin-left: -4px !important;
    margin-right: -4px !important
  }

  .md\:-my-6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important
  }

  .md\:-mx-6 {
    margin-left: -6px !important;
    margin-right: -6px !important
  }

  .md\:-my-8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important
  }

  .md\:-mx-8 {
    margin-left: -8px !important;
    margin-right: -8px !important
  }

  .md\:-my-12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important
  }

  .md\:-mx-12 {
    margin-left: -12px !important;
    margin-right: -12px !important
  }

  .md\:-my-16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important
  }

  .md\:-mx-16 {
    margin-left: -16px !important;
    margin-right: -16px !important
  }

  .md\:-my-20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important
  }

  .md\:-mx-20 {
    margin-left: -20px !important;
    margin-right: -20px !important
  }

  .md\:-my-24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important
  }

  .md\:-mx-24 {
    margin-left: -24px !important;
    margin-right: -24px !important
  }

  .md\:-my-28 {
    margin-top: -28px !important;
    margin-bottom: -28px !important
  }

  .md\:-mx-28 {
    margin-left: -28px !important;
    margin-right: -28px !important
  }

  .md\:-my-32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important
  }

  .md\:-mx-32 {
    margin-left: -32px !important;
    margin-right: -32px !important
  }

  .md\:-my-36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important
  }

  .md\:-mx-36 {
    margin-left: -36px !important;
    margin-right: -36px !important
  }

  .md\:-my-40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important
  }

  .md\:-mx-40 {
    margin-left: -40px !important;
    margin-right: -40px !important
  }

  .md\:-my-44 {
    margin-top: -44px !important;
    margin-bottom: -44px !important
  }

  .md\:-mx-44 {
    margin-left: -44px !important;
    margin-right: -44px !important
  }

  .md\:-my-48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important
  }

  .md\:-mx-48 {
    margin-left: -48px !important;
    margin-right: -48px !important
  }

  .md\:-my-52 {
    margin-top: -52px !important;
    margin-bottom: -52px !important
  }

  .md\:-mx-52 {
    margin-left: -52px !important;
    margin-right: -52px !important
  }

  .md\:-my-56 {
    margin-top: -56px !important;
    margin-bottom: -56px !important
  }

  .md\:-mx-56 {
    margin-left: -56px !important;
    margin-right: -56px !important
  }

  .md\:-my-60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important
  }

  .md\:-mx-60 {
    margin-left: -60px !important;
    margin-right: -60px !important
  }

  .md\:-my-64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important
  }

  .md\:-mx-64 {
    margin-left: -64px !important;
    margin-right: -64px !important
  }

  .md\:-my-68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important
  }

  .md\:-mx-68 {
    margin-left: -68px !important;
    margin-right: -68px !important
  }

  .md\:-my-72 {
    margin-top: -72px !important;
    margin-bottom: -72px !important
  }

  .md\:-mx-72 {
    margin-left: -72px !important;
    margin-right: -72px !important
  }

  .md\:-my-76 {
    margin-top: -76px !important;
    margin-bottom: -76px !important
  }

  .md\:-mx-76 {
    margin-left: -76px !important;
    margin-right: -76px !important
  }

  .md\:-my-80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important
  }

  .md\:-mx-80 {
    margin-left: -80px !important;
    margin-right: -80px !important
  }

  .md\:-my-84 {
    margin-top: -84px !important;
    margin-bottom: -84px !important
  }

  .md\:-mx-84 {
    margin-left: -84px !important;
    margin-right: -84px !important
  }

  .md\:-my-88 {
    margin-top: -88px !important;
    margin-bottom: -88px !important
  }

  .md\:-mx-88 {
    margin-left: -88px !important;
    margin-right: -88px !important
  }

  .md\:-my-92 {
    margin-top: -92px !important;
    margin-bottom: -92px !important
  }

  .md\:-mx-92 {
    margin-left: -92px !important;
    margin-right: -92px !important
  }

  .md\:-my-96 {
    margin-top: -96px !important;
    margin-bottom: -96px !important
  }

  .md\:-mx-96 {
    margin-left: -96px !important;
    margin-right: -96px !important
  }

  .md\:-my-128 {
    margin-top: -128px !important;
    margin-bottom: -128px !important
  }

  .md\:-mx-128 {
    margin-left: -128px !important;
    margin-right: -128px !important
  }

  .md\:-my-160 {
    margin-top: -160px !important;
    margin-bottom: -160px !important
  }

  .md\:-mx-160 {
    margin-left: -160px !important;
    margin-right: -160px !important
  }

  .md\:-my-192 {
    margin-top: -192px !important;
    margin-bottom: -192px !important
  }

  .md\:-mx-192 {
    margin-left: -192px !important;
    margin-right: -192px !important
  }

  .md\:-my-224 {
    margin-top: -224px !important;
    margin-bottom: -224px !important
  }

  .md\:-mx-224 {
    margin-left: -224px !important;
    margin-right: -224px !important
  }

  .md\:-my-256 {
    margin-top: -256px !important;
    margin-bottom: -256px !important
  }

  .md\:-mx-256 {
    margin-left: -256px !important;
    margin-right: -256px !important
  }

  .md\:-my-320 {
    margin-top: -320px !important;
    margin-bottom: -320px !important
  }

  .md\:-mx-320 {
    margin-left: -320px !important;
    margin-right: -320px !important
  }

  .md\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important
  }

  .md\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important
  }

  .md\:mt-0 {
    margin-top: 0 !important
  }

  .md\:mr-0 {
    margin-right: 0 !important
  }

  .md\:mb-0 {
    margin-bottom: 0 !important
  }

  .md\:ml-0 {
    margin-left: 0 !important
  }

  .md\:mt-4 {
    margin-top: 4px !important
  }

  .md\:mr-4 {
    margin-right: 4px !important
  }

  .md\:mb-4 {
    margin-bottom: 4px !important
  }

  .md\:ml-4 {
    margin-left: 4px !important
  }

  .md\:mt-6 {
    margin-top: 6px !important
  }

  .md\:mr-6 {
    margin-right: 6px !important
  }

  .md\:mb-6 {
    margin-bottom: 6px !important
  }

  .md\:ml-6 {
    margin-left: 6px !important
  }

  .md\:mt-8 {
    margin-top: 8px !important
  }

  .md\:mr-8 {
    margin-right: 8px !important
  }

  .md\:mb-8 {
    margin-bottom: 8px !important
  }

  .md\:ml-8 {
    margin-left: 8px !important
  }

  .md\:mt-12 {
    margin-top: 12px !important
  }

  .md\:mr-12 {
    margin-right: 12px !important
  }

  .md\:mb-12 {
    margin-bottom: 12px !important
  }

  .md\:ml-12 {
    margin-left: 12px !important
  }

  .md\:mt-16 {
    margin-top: 16px !important
  }

  .md\:mr-16 {
    margin-right: 16px !important
  }

  .md\:mb-16 {
    margin-bottom: 16px !important
  }

  .md\:ml-16 {
    margin-left: 16px !important
  }

  .md\:mt-20 {
    margin-top: 20px !important
  }

  .md\:mr-20 {
    margin-right: 20px !important
  }

  .md\:mb-20 {
    margin-bottom: 20px !important
  }

  .md\:ml-20 {
    margin-left: 20px !important
  }

  .md\:mt-24 {
    margin-top: 24px !important
  }

  .md\:mr-24 {
    margin-right: 24px !important
  }

  .md\:mb-24 {
    margin-bottom: 24px !important
  }

  .md\:ml-24 {
    margin-left: 24px !important
  }

  .md\:mt-28 {
    margin-top: 28px !important
  }

  .md\:mr-28 {
    margin-right: 28px !important
  }

  .md\:mb-28 {
    margin-bottom: 28px !important
  }

  .md\:ml-28 {
    margin-left: 28px !important
  }

  .md\:mt-32 {
    margin-top: 32px !important
  }

  .md\:mr-32 {
    margin-right: 32px !important
  }

  .md\:mb-32 {
    margin-bottom: 32px !important
  }

  .md\:ml-32 {
    margin-left: 32px !important
  }

  .md\:mt-36 {
    margin-top: 36px !important
  }

  .md\:mr-36 {
    margin-right: 36px !important
  }

  .md\:mb-36 {
    margin-bottom: 36px !important
  }

  .md\:ml-36 {
    margin-left: 36px !important
  }

  .md\:mt-40 {
    margin-top: 40px !important
  }

  .md\:mr-40 {
    margin-right: 40px !important
  }

  .md\:mb-40 {
    margin-bottom: 40px !important
  }

  .md\:ml-40 {
    margin-left: 40px !important
  }

  .md\:mt-44 {
    margin-top: 44px !important
  }

  .md\:mr-44 {
    margin-right: 44px !important
  }

  .md\:mb-44 {
    margin-bottom: 44px !important
  }

  .md\:ml-44 {
    margin-left: 44px !important
  }

  .md\:mt-48 {
    margin-top: 48px !important
  }

  .md\:mr-48 {
    margin-right: 48px !important
  }

  .md\:mb-48 {
    margin-bottom: 48px !important
  }

  .md\:ml-48 {
    margin-left: 48px !important
  }

  .md\:mt-52 {
    margin-top: 52px !important
  }

  .md\:mr-52 {
    margin-right: 52px !important
  }

  .md\:mb-52 {
    margin-bottom: 52px !important
  }

  .md\:ml-52 {
    margin-left: 52px !important
  }

  .md\:mt-56 {
    margin-top: 56px !important
  }

  .md\:mr-56 {
    margin-right: 56px !important
  }

  .md\:mb-56 {
    margin-bottom: 56px !important
  }

  .md\:ml-56 {
    margin-left: 56px !important
  }

  .md\:mt-60 {
    margin-top: 60px !important
  }

  .md\:mr-60 {
    margin-right: 60px !important
  }

  .md\:mb-60 {
    margin-bottom: 60px !important
  }

  .md\:ml-60 {
    margin-left: 60px !important
  }

  .md\:mt-64 {
    margin-top: 64px !important
  }

  .md\:mr-64 {
    margin-right: 64px !important
  }

  .md\:mb-64 {
    margin-bottom: 64px !important
  }

  .md\:ml-64 {
    margin-left: 64px !important
  }

  .md\:mt-68 {
    margin-top: 68px !important
  }

  .md\:mr-68 {
    margin-right: 68px !important
  }

  .md\:mb-68 {
    margin-bottom: 68px !important
  }

  .md\:ml-68 {
    margin-left: 68px !important
  }

  .md\:mt-72 {
    margin-top: 72px !important
  }

  .md\:mr-72 {
    margin-right: 72px !important
  }

  .md\:mb-72 {
    margin-bottom: 72px !important
  }

  .md\:ml-72 {
    margin-left: 72px !important
  }

  .md\:mt-76 {
    margin-top: 76px !important
  }

  .md\:mr-76 {
    margin-right: 76px !important
  }

  .md\:mb-76 {
    margin-bottom: 76px !important
  }

  .md\:ml-76 {
    margin-left: 76px !important
  }

  .md\:mt-80 {
    margin-top: 80px !important
  }

  .md\:mr-80 {
    margin-right: 80px !important
  }

  .md\:mb-80 {
    margin-bottom: 80px !important
  }

  .md\:ml-80 {
    margin-left: 80px !important
  }

  .md\:mt-84 {
    margin-top: 84px !important
  }

  .md\:mr-84 {
    margin-right: 84px !important
  }

  .md\:mb-84 {
    margin-bottom: 84px !important
  }

  .md\:ml-84 {
    margin-left: 84px !important
  }

  .md\:mt-88 {
    margin-top: 88px !important
  }

  .md\:mr-88 {
    margin-right: 88px !important
  }

  .md\:mb-88 {
    margin-bottom: 88px !important
  }

  .md\:ml-88 {
    margin-left: 88px !important
  }

  .md\:mt-92 {
    margin-top: 92px !important
  }

  .md\:mr-92 {
    margin-right: 92px !important
  }

  .md\:mb-92 {
    margin-bottom: 92px !important
  }

  .md\:ml-92 {
    margin-left: 92px !important
  }

  .md\:mt-96 {
    margin-top: 96px !important
  }

  .md\:mr-96 {
    margin-right: 96px !important
  }

  .md\:mb-96 {
    margin-bottom: 96px !important
  }

  .md\:ml-96 {
    margin-left: 96px !important
  }

  .md\:mt-128 {
    margin-top: 128px !important
  }

  .md\:mr-128 {
    margin-right: 128px !important
  }

  .md\:mb-128 {
    margin-bottom: 128px !important
  }

  .md\:ml-128 {
    margin-left: 128px !important
  }

  .md\:mt-160 {
    margin-top: 160px !important
  }

  .md\:mr-160 {
    margin-right: 160px !important
  }

  .md\:mb-160 {
    margin-bottom: 160px !important
  }

  .md\:ml-160 {
    margin-left: 160px !important
  }

  .md\:mt-192 {
    margin-top: 192px !important
  }

  .md\:mr-192 {
    margin-right: 192px !important
  }

  .md\:mb-192 {
    margin-bottom: 192px !important
  }

  .md\:ml-192 {
    margin-left: 192px !important
  }

  .md\:mt-224 {
    margin-top: 224px !important
  }

  .md\:mr-224 {
    margin-right: 224px !important
  }

  .md\:mb-224 {
    margin-bottom: 224px !important
  }

  .md\:ml-224 {
    margin-left: 224px !important
  }

  .md\:mt-256 {
    margin-top: 256px !important
  }

  .md\:mr-256 {
    margin-right: 256px !important
  }

  .md\:mb-256 {
    margin-bottom: 256px !important
  }

  .md\:ml-256 {
    margin-left: 256px !important
  }

  .md\:mt-320 {
    margin-top: 320px !important
  }

  .md\:mr-320 {
    margin-right: 320px !important
  }

  .md\:mb-320 {
    margin-bottom: 320px !important
  }

  .md\:ml-320 {
    margin-left: 320px !important
  }

  .md\:mt-auto {
    margin-top: auto !important
  }

  .md\:mr-auto {
    margin-right: auto !important
  }

  .md\:mb-auto {
    margin-bottom: auto !important
  }

  .md\:ml-auto {
    margin-left: auto !important
  }

  .md\:mt-px {
    margin-top: 1px !important
  }

  .md\:mr-px {
    margin-right: 1px !important
  }

  .md\:mb-px {
    margin-bottom: 1px !important
  }

  .md\:ml-px {
    margin-left: 1px !important
  }

  .md\:-mt-4 {
    margin-top: -4px !important
  }

  .md\:-mr-4 {
    margin-right: -4px !important
  }

  .md\:-mb-4 {
    margin-bottom: -4px !important
  }

  .md\:-ml-4 {
    margin-left: -4px !important
  }

  .md\:-mt-6 {
    margin-top: -6px !important
  }

  .md\:-mr-6 {
    margin-right: -6px !important
  }

  .md\:-mb-6 {
    margin-bottom: -6px !important
  }

  .md\:-ml-6 {
    margin-left: -6px !important
  }

  .md\:-mt-8 {
    margin-top: -8px !important
  }

  .md\:-mr-8 {
    margin-right: -8px !important
  }

  .md\:-mb-8 {
    margin-bottom: -8px !important
  }

  .md\:-ml-8 {
    margin-left: -8px !important
  }

  .md\:-mt-12 {
    margin-top: -12px !important
  }

  .md\:-mr-12 {
    margin-right: -12px !important
  }

  .md\:-mb-12 {
    margin-bottom: -12px !important
  }

  .md\:-ml-12 {
    margin-left: -12px !important
  }

  .md\:-mt-16 {
    margin-top: -16px !important
  }

  .md\:-mr-16 {
    margin-right: -16px !important
  }

  .md\:-mb-16 {
    margin-bottom: -16px !important
  }

  .md\:-ml-16 {
    margin-left: -16px !important
  }

  .md\:-mt-20 {
    margin-top: -20px !important
  }

  .md\:-mr-20 {
    margin-right: -20px !important
  }

  .md\:-mb-20 {
    margin-bottom: -20px !important
  }

  .md\:-ml-20 {
    margin-left: -20px !important
  }

  .md\:-mt-24 {
    margin-top: -24px !important
  }

  .md\:-mr-24 {
    margin-right: -24px !important
  }

  .md\:-mb-24 {
    margin-bottom: -24px !important
  }

  .md\:-ml-24 {
    margin-left: -24px !important
  }

  .md\:-mt-28 {
    margin-top: -28px !important
  }

  .md\:-mr-28 {
    margin-right: -28px !important
  }

  .md\:-mb-28 {
    margin-bottom: -28px !important
  }

  .md\:-ml-28 {
    margin-left: -28px !important
  }

  .md\:-mt-32 {
    margin-top: -32px !important
  }

  .md\:-mr-32 {
    margin-right: -32px !important
  }

  .md\:-mb-32 {
    margin-bottom: -32px !important
  }

  .md\:-ml-32 {
    margin-left: -32px !important
  }

  .md\:-mt-36 {
    margin-top: -36px !important
  }

  .md\:-mr-36 {
    margin-right: -36px !important
  }

  .md\:-mb-36 {
    margin-bottom: -36px !important
  }

  .md\:-ml-36 {
    margin-left: -36px !important
  }

  .md\:-mt-40 {
    margin-top: -40px !important
  }

  .md\:-mr-40 {
    margin-right: -40px !important
  }

  .md\:-mb-40 {
    margin-bottom: -40px !important
  }

  .md\:-ml-40 {
    margin-left: -40px !important
  }

  .md\:-mt-44 {
    margin-top: -44px !important
  }

  .md\:-mr-44 {
    margin-right: -44px !important
  }

  .md\:-mb-44 {
    margin-bottom: -44px !important
  }

  .md\:-ml-44 {
    margin-left: -44px !important
  }

  .md\:-mt-48 {
    margin-top: -48px !important
  }

  .md\:-mr-48 {
    margin-right: -48px !important
  }

  .md\:-mb-48 {
    margin-bottom: -48px !important
  }

  .md\:-ml-48 {
    margin-left: -48px !important
  }

  .md\:-mt-52 {
    margin-top: -52px !important
  }

  .md\:-mr-52 {
    margin-right: -52px !important
  }

  .md\:-mb-52 {
    margin-bottom: -52px !important
  }

  .md\:-ml-52 {
    margin-left: -52px !important
  }

  .md\:-mt-56 {
    margin-top: -56px !important
  }

  .md\:-mr-56 {
    margin-right: -56px !important
  }

  .md\:-mb-56 {
    margin-bottom: -56px !important
  }

  .md\:-ml-56 {
    margin-left: -56px !important
  }

  .md\:-mt-60 {
    margin-top: -60px !important
  }

  .md\:-mr-60 {
    margin-right: -60px !important
  }

  .md\:-mb-60 {
    margin-bottom: -60px !important
  }

  .md\:-ml-60 {
    margin-left: -60px !important
  }

  .md\:-mt-64 {
    margin-top: -64px !important
  }

  .md\:-mr-64 {
    margin-right: -64px !important
  }

  .md\:-mb-64 {
    margin-bottom: -64px !important
  }

  .md\:-ml-64 {
    margin-left: -64px !important
  }

  .md\:-mt-68 {
    margin-top: -68px !important
  }

  .md\:-mr-68 {
    margin-right: -68px !important
  }

  .md\:-mb-68 {
    margin-bottom: -68px !important
  }

  .md\:-ml-68 {
    margin-left: -68px !important
  }

  .md\:-mt-72 {
    margin-top: -72px !important
  }

  .md\:-mr-72 {
    margin-right: -72px !important
  }

  .md\:-mb-72 {
    margin-bottom: -72px !important
  }

  .md\:-ml-72 {
    margin-left: -72px !important
  }

  .md\:-mt-76 {
    margin-top: -76px !important
  }

  .md\:-mr-76 {
    margin-right: -76px !important
  }

  .md\:-mb-76 {
    margin-bottom: -76px !important
  }

  .md\:-ml-76 {
    margin-left: -76px !important
  }

  .md\:-mt-80 {
    margin-top: -80px !important
  }

  .md\:-mr-80 {
    margin-right: -80px !important
  }

  .md\:-mb-80 {
    margin-bottom: -80px !important
  }

  .md\:-ml-80 {
    margin-left: -80px !important
  }

  .md\:-mt-84 {
    margin-top: -84px !important
  }

  .md\:-mr-84 {
    margin-right: -84px !important
  }

  .md\:-mb-84 {
    margin-bottom: -84px !important
  }

  .md\:-ml-84 {
    margin-left: -84px !important
  }

  .md\:-mt-88 {
    margin-top: -88px !important
  }

  .md\:-mr-88 {
    margin-right: -88px !important
  }

  .md\:-mb-88 {
    margin-bottom: -88px !important
  }

  .md\:-ml-88 {
    margin-left: -88px !important
  }

  .md\:-mt-92 {
    margin-top: -92px !important
  }

  .md\:-mr-92 {
    margin-right: -92px !important
  }

  .md\:-mb-92 {
    margin-bottom: -92px !important
  }

  .md\:-ml-92 {
    margin-left: -92px !important
  }

  .md\:-mt-96 {
    margin-top: -96px !important
  }

  .md\:-mr-96 {
    margin-right: -96px !important
  }

  .md\:-mb-96 {
    margin-bottom: -96px !important
  }

  .md\:-ml-96 {
    margin-left: -96px !important
  }

  .md\:-mt-128 {
    margin-top: -128px !important
  }

  .md\:-mr-128 {
    margin-right: -128px !important
  }

  .md\:-mb-128 {
    margin-bottom: -128px !important
  }

  .md\:-ml-128 {
    margin-left: -128px !important
  }

  .md\:-mt-160 {
    margin-top: -160px !important
  }

  .md\:-mr-160 {
    margin-right: -160px !important
  }

  .md\:-mb-160 {
    margin-bottom: -160px !important
  }

  .md\:-ml-160 {
    margin-left: -160px !important
  }

  .md\:-mt-192 {
    margin-top: -192px !important
  }

  .md\:-mr-192 {
    margin-right: -192px !important
  }

  .md\:-mb-192 {
    margin-bottom: -192px !important
  }

  .md\:-ml-192 {
    margin-left: -192px !important
  }

  .md\:-mt-224 {
    margin-top: -224px !important
  }

  .md\:-mr-224 {
    margin-right: -224px !important
  }

  .md\:-mb-224 {
    margin-bottom: -224px !important
  }

  .md\:-ml-224 {
    margin-left: -224px !important
  }

  .md\:-mt-256 {
    margin-top: -256px !important
  }

  .md\:-mr-256 {
    margin-right: -256px !important
  }

  .md\:-mb-256 {
    margin-bottom: -256px !important
  }

  .md\:-ml-256 {
    margin-left: -256px !important
  }

  .md\:-mt-320 {
    margin-top: -320px !important
  }

  .md\:-mr-320 {
    margin-right: -320px !important
  }

  .md\:-mb-320 {
    margin-bottom: -320px !important
  }

  .md\:-ml-320 {
    margin-left: -320px !important
  }

  .md\:-mt-px {
    margin-top: -1px !important
  }

  .md\:-mr-px {
    margin-right: -1px !important
  }

  .md\:-mb-px {
    margin-bottom: -1px !important
  }

  .md\:-ml-px {
    margin-left: -1px !important
  }

  .md\:p-0 {
    padding: 0 !important
  }

  .md\:p-4 {
    padding: 4px !important
  }

  .md\:p-6 {
    padding: 6px !important
  }

  .md\:p-8 {
    padding: 8px !important
  }

  .md\:p-12 {
    padding: 12px !important
  }

  .md\:p-16 {
    padding: 16px !important
  }

  .md\:p-20 {
    padding: 20px !important
  }

  .md\:p-24 {
    padding: 24px !important
  }

  .md\:p-28 {
    padding: 28px !important
  }

  .md\:p-32 {
    padding: 32px !important
  }

  .md\:p-36 {
    padding: 36px !important
  }

  .md\:p-40 {
    padding: 40px !important
  }

  .md\:p-44 {
    padding: 44px !important
  }

  .md\:p-48 {
    padding: 48px !important
  }

  .md\:p-52 {
    padding: 52px !important
  }

  .md\:p-56 {
    padding: 56px !important
  }

  .md\:p-60 {
    padding: 60px !important
  }

  .md\:p-64 {
    padding: 64px !important
  }

  .md\:p-68 {
    padding: 68px !important
  }

  .md\:p-72 {
    padding: 72px !important
  }

  .md\:p-76 {
    padding: 76px !important
  }

  .md\:p-80 {
    padding: 80px !important
  }

  .md\:p-84 {
    padding: 84px !important
  }

  .md\:p-88 {
    padding: 88px !important
  }

  .md\:p-92 {
    padding: 92px !important
  }

  .md\:p-96 {
    padding: 96px !important
  }

  .md\:p-128 {
    padding: 128px !important
  }

  .md\:p-160 {
    padding: 160px !important
  }

  .md\:p-192 {
    padding: 192px !important
  }

  .md\:p-224 {
    padding: 224px !important
  }

  .md\:p-256 {
    padding: 256px !important
  }

  .md\:p-320 {
    padding: 320px !important
  }

  .md\:p-px {
    padding: 1px !important
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .md\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important
  }

  .md\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important
  }

  .md\:py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important
  }

  .md\:px-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .md\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important
  }

  .md\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .md\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important
  }

  .md\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .md\:py-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
  }

  .md\:px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .md\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important
  }

  .md\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .md\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
  }

  .md\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important
  }

  .md\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important
  }

  .md\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important
  }

  .md\:py-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important
  }

  .md\:px-32 {
    padding-left: 32px !important;
    padding-right: 32px !important
  }

  .md\:py-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important
  }

  .md\:px-36 {
    padding-left: 36px !important;
    padding-right: 36px !important
  }

  .md\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important
  }

  .md\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .md\:py-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important
  }

  .md\:px-44 {
    padding-left: 44px !important;
    padding-right: 44px !important
  }

  .md\:py-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important
  }

  .md\:px-48 {
    padding-left: 48px !important;
    padding-right: 48px !important
  }

  .md\:py-52 {
    padding-top: 52px !important;
    padding-bottom: 52px !important
  }

  .md\:px-52 {
    padding-left: 52px !important;
    padding-right: 52px !important
  }

  .md\:py-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important
  }

  .md\:px-56 {
    padding-left: 56px !important;
    padding-right: 56px !important
  }

  .md\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important
  }

  .md\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .md\:py-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important
  }

  .md\:px-64 {
    padding-left: 64px !important;
    padding-right: 64px !important
  }

  .md\:py-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important
  }

  .md\:px-68 {
    padding-left: 68px !important;
    padding-right: 68px !important
  }

  .md\:py-72 {
    padding-top: 72px !important;
    padding-bottom: 72px !important
  }

  .md\:px-72 {
    padding-left: 72px !important;
    padding-right: 72px !important
  }

  .md\:py-76 {
    padding-top: 76px !important;
    padding-bottom: 76px !important
  }

  .md\:px-76 {
    padding-left: 76px !important;
    padding-right: 76px !important
  }

  .md\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important
  }

  .md\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .md\:py-84 {
    padding-top: 84px !important;
    padding-bottom: 84px !important
  }

  .md\:px-84 {
    padding-left: 84px !important;
    padding-right: 84px !important
  }

  .md\:py-88 {
    padding-top: 88px !important;
    padding-bottom: 88px !important
  }

  .md\:px-88 {
    padding-left: 88px !important;
    padding-right: 88px !important
  }

  .md\:py-92 {
    padding-top: 92px !important;
    padding-bottom: 92px !important
  }

  .md\:px-92 {
    padding-left: 92px !important;
    padding-right: 92px !important
  }

  .md\:py-96 {
    padding-top: 96px !important;
    padding-bottom: 96px !important
  }

  .md\:px-96 {
    padding-left: 96px !important;
    padding-right: 96px !important
  }

  .md\:py-128 {
    padding-top: 128px !important;
    padding-bottom: 128px !important
  }

  .md\:px-128 {
    padding-left: 128px !important;
    padding-right: 128px !important
  }

  .md\:py-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important
  }

  .md\:px-160 {
    padding-left: 160px !important;
    padding-right: 160px !important
  }

  .md\:py-192 {
    padding-top: 192px !important;
    padding-bottom: 192px !important
  }

  .md\:px-192 {
    padding-left: 192px !important;
    padding-right: 192px !important
  }

  .md\:py-224 {
    padding-top: 224px !important;
    padding-bottom: 224px !important
  }

  .md\:px-224 {
    padding-left: 224px !important;
    padding-right: 224px !important
  }

  .md\:py-256 {
    padding-top: 256px !important;
    padding-bottom: 256px !important
  }

  .md\:px-256 {
    padding-left: 256px !important;
    padding-right: 256px !important
  }

  .md\:py-320 {
    padding-top: 320px !important;
    padding-bottom: 320px !important
  }

  .md\:px-320 {
    padding-left: 320px !important;
    padding-right: 320px !important
  }

  .md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important
  }

  .md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
  }

  .md\:pt-0 {
    padding-top: 0 !important
  }

  .md\:pr-0 {
    padding-right: 0 !important
  }

  .md\:pb-0 {
    padding-bottom: 0 !important
  }

  .md\:pl-0 {
    padding-left: 0 !important
  }

  .md\:pt-4 {
    padding-top: 4px !important
  }

  .md\:pr-4 {
    padding-right: 4px !important
  }

  .md\:pb-4 {
    padding-bottom: 4px !important
  }

  .md\:pl-4 {
    padding-left: 4px !important
  }

  .md\:pt-6 {
    padding-top: 6px !important
  }

  .md\:pr-6 {
    padding-right: 6px !important
  }

  .md\:pb-6 {
    padding-bottom: 6px !important
  }

  .md\:pl-6 {
    padding-left: 6px !important
  }

  .md\:pt-8 {
    padding-top: 8px !important
  }

  .md\:pr-8 {
    padding-right: 8px !important
  }

  .md\:pb-8 {
    padding-bottom: 8px !important
  }

  .md\:pl-8 {
    padding-left: 8px !important
  }

  .md\:pt-12 {
    padding-top: 12px !important
  }

  .md\:pr-12 {
    padding-right: 12px !important
  }

  .md\:pb-12 {
    padding-bottom: 12px !important
  }

  .md\:pl-12 {
    padding-left: 12px !important
  }

  .md\:pt-16 {
    padding-top: 16px !important
  }

  .md\:pr-16 {
    padding-right: 16px !important
  }

  .md\:pb-16 {
    padding-bottom: 16px !important
  }

  .md\:pl-16 {
    padding-left: 16px !important
  }

  .md\:pt-20 {
    padding-top: 20px !important
  }

  .md\:pr-20 {
    padding-right: 20px !important
  }

  .md\:pb-20 {
    padding-bottom: 20px !important
  }

  .md\:pl-20 {
    padding-left: 20px !important
  }

  .md\:pt-24 {
    padding-top: 24px !important
  }

  .md\:pr-24 {
    padding-right: 24px !important
  }

  .md\:pb-24 {
    padding-bottom: 24px !important
  }

  .md\:pl-24 {
    padding-left: 24px !important
  }

  .md\:pt-28 {
    padding-top: 28px !important
  }

  .md\:pr-28 {
    padding-right: 28px !important
  }

  .md\:pb-28 {
    padding-bottom: 28px !important
  }

  .md\:pl-28 {
    padding-left: 28px !important
  }

  .md\:pt-32 {
    padding-top: 32px !important
  }

  .md\:pr-32 {
    padding-right: 32px !important
  }

  .md\:pb-32 {
    padding-bottom: 32px !important
  }

  .md\:pl-32 {
    padding-left: 32px !important
  }

  .md\:pt-36 {
    padding-top: 36px !important
  }

  .md\:pr-36 {
    padding-right: 36px !important
  }

  .md\:pb-36 {
    padding-bottom: 36px !important
  }

  .md\:pl-36 {
    padding-left: 36px !important
  }

  .md\:pt-40 {
    padding-top: 40px !important
  }

  .md\:pr-40 {
    padding-right: 40px !important
  }

  .md\:pb-40 {
    padding-bottom: 40px !important
  }

  .md\:pl-40 {
    padding-left: 40px !important
  }

  .md\:pt-44 {
    padding-top: 44px !important
  }

  .md\:pr-44 {
    padding-right: 44px !important
  }

  .md\:pb-44 {
    padding-bottom: 44px !important
  }

  .md\:pl-44 {
    padding-left: 44px !important
  }

  .md\:pt-48 {
    padding-top: 48px !important
  }

  .md\:pr-48 {
    padding-right: 48px !important
  }

  .md\:pb-48 {
    padding-bottom: 48px !important
  }

  .md\:pl-48 {
    padding-left: 48px !important
  }

  .md\:pt-52 {
    padding-top: 52px !important
  }

  .md\:pr-52 {
    padding-right: 52px !important
  }

  .md\:pb-52 {
    padding-bottom: 52px !important
  }

  .md\:pl-52 {
    padding-left: 52px !important
  }

  .md\:pt-56 {
    padding-top: 56px !important
  }

  .md\:pr-56 {
    padding-right: 56px !important
  }

  .md\:pb-56 {
    padding-bottom: 56px !important
  }

  .md\:pl-56 {
    padding-left: 56px !important
  }

  .md\:pt-60 {
    padding-top: 60px !important
  }

  .md\:pr-60 {
    padding-right: 60px !important
  }

  .md\:pb-60 {
    padding-bottom: 60px !important
  }

  .md\:pl-60 {
    padding-left: 60px !important
  }

  .md\:pt-64 {
    padding-top: 64px !important
  }

  .md\:pr-64 {
    padding-right: 64px !important
  }

  .md\:pb-64 {
    padding-bottom: 64px !important
  }

  .md\:pl-64 {
    padding-left: 64px !important
  }

  .md\:pt-68 {
    padding-top: 68px !important
  }

  .md\:pr-68 {
    padding-right: 68px !important
  }

  .md\:pb-68 {
    padding-bottom: 68px !important
  }

  .md\:pl-68 {
    padding-left: 68px !important
  }

  .md\:pt-72 {
    padding-top: 72px !important
  }

  .md\:pr-72 {
    padding-right: 72px !important
  }

  .md\:pb-72 {
    padding-bottom: 72px !important
  }

  .md\:pl-72 {
    padding-left: 72px !important
  }

  .md\:pt-76 {
    padding-top: 76px !important
  }

  .md\:pr-76 {
    padding-right: 76px !important
  }

  .md\:pb-76 {
    padding-bottom: 76px !important
  }

  .md\:pl-76 {
    padding-left: 76px !important
  }

  .md\:pt-80 {
    padding-top: 80px !important
  }

  .md\:pr-80 {
    padding-right: 80px !important
  }

  .md\:pb-80 {
    padding-bottom: 80px !important
  }

  .md\:pl-80 {
    padding-left: 80px !important
  }

  .md\:pt-84 {
    padding-top: 84px !important
  }

  .md\:pr-84 {
    padding-right: 84px !important
  }

  .md\:pb-84 {
    padding-bottom: 84px !important
  }

  .md\:pl-84 {
    padding-left: 84px !important
  }

  .md\:pt-88 {
    padding-top: 88px !important
  }

  .md\:pr-88 {
    padding-right: 88px !important
  }

  .md\:pb-88 {
    padding-bottom: 88px !important
  }

  .md\:pl-88 {
    padding-left: 88px !important
  }

  .md\:pt-92 {
    padding-top: 92px !important
  }

  .md\:pr-92 {
    padding-right: 92px !important
  }

  .md\:pb-92 {
    padding-bottom: 92px !important
  }

  .md\:pl-92 {
    padding-left: 92px !important
  }

  .md\:pt-96 {
    padding-top: 96px !important
  }

  .md\:pr-96 {
    padding-right: 96px !important
  }

  .md\:pb-96 {
    padding-bottom: 96px !important
  }

  .md\:pl-96 {
    padding-left: 96px !important
  }

  .md\:pt-128 {
    padding-top: 128px !important
  }

  .md\:pr-128 {
    padding-right: 128px !important
  }

  .md\:pb-128 {
    padding-bottom: 128px !important
  }

  .md\:pl-128 {
    padding-left: 128px !important
  }

  .md\:pt-160 {
    padding-top: 160px !important
  }

  .md\:pr-160 {
    padding-right: 160px !important
  }

  .md\:pb-160 {
    padding-bottom: 160px !important
  }

  .md\:pl-160 {
    padding-left: 160px !important
  }

  .md\:pt-192 {
    padding-top: 192px !important
  }

  .md\:pr-192 {
    padding-right: 192px !important
  }

  .md\:pb-192 {
    padding-bottom: 192px !important
  }

  .md\:pl-192 {
    padding-left: 192px !important
  }

  .md\:pt-224 {
    padding-top: 224px !important
  }

  .md\:pr-224 {
    padding-right: 224px !important
  }

  .md\:pb-224 {
    padding-bottom: 224px !important
  }

  .md\:pl-224 {
    padding-left: 224px !important
  }

  .md\:pt-256 {
    padding-top: 256px !important
  }

  .md\:pr-256 {
    padding-right: 256px !important
  }

  .md\:pb-256 {
    padding-bottom: 256px !important
  }

  .md\:pl-256 {
    padding-left: 256px !important
  }

  .md\:pt-320 {
    padding-top: 320px !important
  }

  .md\:pr-320 {
    padding-right: 320px !important
  }

  .md\:pb-320 {
    padding-bottom: 320px !important
  }

  .md\:pl-320 {
    padding-left: 320px !important
  }

  .md\:pt-px {
    padding-top: 1px !important
  }

  .md\:pr-px {
    padding-right: 1px !important
  }

  .md\:pb-px {
    padding-bottom: 1px !important
  }

  .md\:pl-px {
    padding-left: 1px !important
  }

  .md\:flex-1 {
    flex: 1 1 0% !important
  }

  .md\:flex-auto {
    flex: 1 1 auto !important
  }

  .md\:flex-initial {
    flex: 0 1 auto !important
  }

  .md\:flex-none {
    flex: none !important
  }

  .md\:items-start {
    align-items: flex-start !important
  }

  .md\:items-end {
    align-items: flex-end !important
  }

  .md\:items-center {
    align-items: center !important
  }

  .md\:items-baseline {
    align-items: baseline !important
  }

  .md\:items-stretch {
    align-items: stretch !important
  }

  .md\:flex-row {
    flex-direction: row !important
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .md\:flex-col {
    flex-direction: column !important
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .md\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .md\:flex-grow {
    flex-grow: 1 !important
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .md\:flex-shrink {
    flex-shrink: 1 !important
  }

  .md\:flex-wrap {
    flex-wrap: wrap !important
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .md\:justify-start {
    justify-content: flex-start !important
  }

  .md\:justify-end {
    justify-content: flex-end !important
  }

  .md\:justify-center {
    justify-content: center !important
  }

  .md\:justify-between {
    justify-content: space-between !important
  }

  .md\:justify-around {
    justify-content: space-around !important
  }

  .md\:justify-evenly {
    justify-content: space-evenly !important
  }

  .md\:text-xs {
    font-size: 0.75rem !important
  }

  .md\:text-sm {
    font-size: 0.875rem !important
  }

  .md\:text-base {
    font-size: 1rem !important
  }

  .md\:text-lg {
    font-size: 1.125rem !important
  }

  .md\:text-xl {
    font-size: 1.25rem !important
  }

  .md\:text-2xl {
    font-size: 1.5rem !important
  }

  .md\:text-3xl {
    font-size: 1.875rem !important
  }

  .md\:text-4xl {
    font-size: 2.25rem !important
  }

  .md\:text-5xl {
    font-size: 3rem !important
  }

  .md\:text-6xl {
    font-size: 4rem !important
  }

  .md\:font-hairline {
    font-weight: 100 !important
  }

  .md\:font-thin {
    font-weight: 200 !important
  }

  .md\:font-light {
    font-weight: 300 !important
  }

  .md\:font-normal {
    font-weight: 400 !important
  }

  .md\:font-medium {
    font-weight: 500 !important
  }

  .md\:font-semibold {
    font-weight: 600 !important
  }

  .md\:font-bold {
    font-weight: 700 !important
  }

  .md\:font-extrabold {
    font-weight: 800 !important
  }

  .md\:font-black {
    font-weight: 900 !important
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .md\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .md\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .md\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .md\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .md\:text-left {
    text-align: left !important
  }

  .md\:text-center {
    text-align: center !important
  }

  .md\:text-right {
    text-align: right !important
  }

  .md\:text-justify {
    text-align: justify !important
  }

  .md\:text-transparent {
    color: transparent !important
  }

  .md\:text-current {
    color: currentColor !important
  }

  .md\:text-black {
    color: #000 !important
  }

  .md\:text-white {
    color: #fff !important
  }

  .md\:text-gray-100 {
    color: #f7fafc !important
  }

  .md\:text-gray-200 {
    color: #edf2f7 !important
  }

  .md\:text-gray-300 {
    color: #e2e8f0 !important
  }

  .md\:text-gray-400 {
    color: #cbd5e0 !important
  }

  .md\:text-gray-500 {
    color: #a0aec0 !important
  }

  .md\:text-gray-600 {
    color: #718096 !important
  }

  .md\:text-gray-700 {
    color: #4a5568 !important
  }

  .md\:text-gray-800 {
    color: #2d3748 !important
  }

  .md\:text-gray-900 {
    color: #1a202c !important
  }

  .md\:text-red-100 {
    color: #fff5f5 !important
  }

  .md\:text-red-200 {
    color: #fed7d7 !important
  }

  .md\:text-red-300 {
    color: #feb2b2 !important
  }

  .md\:text-red-400 {
    color: #fc8181 !important
  }

  .md\:text-red-500 {
    color: #f56565 !important
  }

  .md\:text-red-600 {
    color: #e53e3e !important
  }

  .md\:text-red-700 {
    color: #c53030 !important
  }

  .md\:text-red-800 {
    color: #9b2c2c !important
  }

  .md\:text-red-900 {
    color: #742a2a !important
  }

  .md\:text-orange-100 {
    color: #fffaf0 !important
  }

  .md\:text-orange-200 {
    color: #feebc8 !important
  }

  .md\:text-orange-300 {
    color: #fbd38d !important
  }

  .md\:text-orange-400 {
    color: #f6ad55 !important
  }

  .md\:text-orange-500 {
    color: #ed8936 !important
  }

  .md\:text-orange-600 {
    color: #dd6b20 !important
  }

  .md\:text-orange-700 {
    color: #c05621 !important
  }

  .md\:text-orange-800 {
    color: #9c4221 !important
  }

  .md\:text-orange-900 {
    color: #7b341e !important
  }

  .md\:text-yellow-100 {
    color: #fffff0 !important
  }

  .md\:text-yellow-200 {
    color: #fefcbf !important
  }

  .md\:text-yellow-300 {
    color: #faf089 !important
  }

  .md\:text-yellow-400 {
    color: #f6e05e !important
  }

  .md\:text-yellow-500 {
    color: #ecc94b !important
  }

  .md\:text-yellow-600 {
    color: #d69e2e !important
  }

  .md\:text-yellow-700 {
    color: #b7791f !important
  }

  .md\:text-yellow-800 {
    color: #975a16 !important
  }

  .md\:text-yellow-900 {
    color: #744210 !important
  }

  .md\:text-green-100 {
    color: #f0fff4 !important
  }

  .md\:text-green-200 {
    color: #c6f6d5 !important
  }

  .md\:text-green-300 {
    color: #9ae6b4 !important
  }

  .md\:text-green-400 {
    color: #68d391 !important
  }

  .md\:text-green-500 {
    color: #48bb78 !important
  }

  .md\:text-green-600 {
    color: #38a169 !important
  }

  .md\:text-green-700 {
    color: #2f855a !important
  }

  .md\:text-green-800 {
    color: #276749 !important
  }

  .md\:text-green-900 {
    color: #22543d !important
  }

  .md\:text-teal-100 {
    color: #e6fffa !important
  }

  .md\:text-teal-200 {
    color: #b2f5ea !important
  }

  .md\:text-teal-300 {
    color: #81e6d9 !important
  }

  .md\:text-teal-400 {
    color: #4fd1c5 !important
  }

  .md\:text-teal-500 {
    color: #38b2ac !important
  }

  .md\:text-teal-600 {
    color: #319795 !important
  }

  .md\:text-teal-700 {
    color: #2c7a7b !important
  }

  .md\:text-teal-800 {
    color: #285e61 !important
  }

  .md\:text-teal-900 {
    color: #234e52 !important
  }

  .md\:text-blue-100 {
    color: #ebf8ff !important
  }

  .md\:text-blue-200 {
    color: #bee3f8 !important
  }

  .md\:text-blue-300 {
    color: #90cdf4 !important
  }

  .md\:text-blue-400 {
    color: #63b3ed !important
  }

  .md\:text-blue-500 {
    color: #4299e1 !important
  }

  .md\:text-blue-600 {
    color: #3182ce !important
  }

  .md\:text-blue-700 {
    color: #2b6cb0 !important
  }

  .md\:text-blue-800 {
    color: #2c5282 !important
  }

  .md\:text-blue-900 {
    color: #2a4365 !important
  }

  .md\:text-indigo-100 {
    color: #ebf4ff !important
  }

  .md\:text-indigo-200 {
    color: #c3dafe !important
  }

  .md\:text-indigo-300 {
    color: #a3bffa !important
  }

  .md\:text-indigo-400 {
    color: #7f9cf5 !important
  }

  .md\:text-indigo-500 {
    color: #667eea !important
  }

  .md\:text-indigo-600 {
    color: #5a67d8 !important
  }

  .md\:text-indigo-700 {
    color: #4c51bf !important
  }

  .md\:text-indigo-800 {
    color: #434190 !important
  }

  .md\:text-indigo-900 {
    color: #3c366b !important
  }

  .md\:text-purple-100 {
    color: #faf5ff !important
  }

  .md\:text-purple-200 {
    color: #e9d8fd !important
  }

  .md\:text-purple-300 {
    color: #d6bcfa !important
  }

  .md\:text-purple-400 {
    color: #b794f4 !important
  }

  .md\:text-purple-500 {
    color: #9f7aea !important
  }

  .md\:text-purple-600 {
    color: #805ad5 !important
  }

  .md\:text-purple-700 {
    color: #6b46c1 !important
  }

  .md\:text-purple-800 {
    color: #553c9a !important
  }

  .md\:text-purple-900 {
    color: #44337a !important
  }

  .md\:text-pink-100 {
    color: #fff5f7 !important
  }

  .md\:text-pink-200 {
    color: #fed7e2 !important
  }

  .md\:text-pink-300 {
    color: #fbb6ce !important
  }

  .md\:text-pink-400 {
    color: #f687b3 !important
  }

  .md\:text-pink-500 {
    color: #ed64a6 !important
  }

  .md\:text-pink-600 {
    color: #d53f8c !important
  }

  .md\:text-pink-700 {
    color: #b83280 !important
  }

  .md\:text-pink-800 {
    color: #97266d !important
  }

  .md\:text-pink-900 {
    color: #702459 !important
  }

  .md\:hover\:text-transparent:hover {
    color: transparent !important
  }

  .md\:hover\:text-current:hover {
    color: currentColor !important
  }

  .md\:hover\:text-black:hover {
    color: #000 !important
  }

  .md\:hover\:text-white:hover {
    color: #fff !important
  }

  .md\:hover\:text-gray-100:hover {
    color: #f7fafc !important
  }

  .md\:hover\:text-gray-200:hover {
    color: #edf2f7 !important
  }

  .md\:hover\:text-gray-300:hover {
    color: #e2e8f0 !important
  }

  .md\:hover\:text-gray-400:hover {
    color: #cbd5e0 !important
  }

  .md\:hover\:text-gray-500:hover {
    color: #a0aec0 !important
  }

  .md\:hover\:text-gray-600:hover {
    color: #718096 !important
  }

  .md\:hover\:text-gray-700:hover {
    color: #4a5568 !important
  }

  .md\:hover\:text-gray-800:hover {
    color: #2d3748 !important
  }

  .md\:hover\:text-gray-900:hover {
    color: #1a202c !important
  }

  .md\:hover\:text-red-100:hover {
    color: #fff5f5 !important
  }

  .md\:hover\:text-red-200:hover {
    color: #fed7d7 !important
  }

  .md\:hover\:text-red-300:hover {
    color: #feb2b2 !important
  }

  .md\:hover\:text-red-400:hover {
    color: #fc8181 !important
  }

  .md\:hover\:text-red-500:hover {
    color: #f56565 !important
  }

  .md\:hover\:text-red-600:hover {
    color: #e53e3e !important
  }

  .md\:hover\:text-red-700:hover {
    color: #c53030 !important
  }

  .md\:hover\:text-red-800:hover {
    color: #9b2c2c !important
  }

  .md\:hover\:text-red-900:hover {
    color: #742a2a !important
  }

  .md\:hover\:text-orange-100:hover {
    color: #fffaf0 !important
  }

  .md\:hover\:text-orange-200:hover {
    color: #feebc8 !important
  }

  .md\:hover\:text-orange-300:hover {
    color: #fbd38d !important
  }

  .md\:hover\:text-orange-400:hover {
    color: #f6ad55 !important
  }

  .md\:hover\:text-orange-500:hover {
    color: #ed8936 !important
  }

  .md\:hover\:text-orange-600:hover {
    color: #dd6b20 !important
  }

  .md\:hover\:text-orange-700:hover {
    color: #c05621 !important
  }

  .md\:hover\:text-orange-800:hover {
    color: #9c4221 !important
  }

  .md\:hover\:text-orange-900:hover {
    color: #7b341e !important
  }

  .md\:hover\:text-yellow-100:hover {
    color: #fffff0 !important
  }

  .md\:hover\:text-yellow-200:hover {
    color: #fefcbf !important
  }

  .md\:hover\:text-yellow-300:hover {
    color: #faf089 !important
  }

  .md\:hover\:text-yellow-400:hover {
    color: #f6e05e !important
  }

  .md\:hover\:text-yellow-500:hover {
    color: #ecc94b !important
  }

  .md\:hover\:text-yellow-600:hover {
    color: #d69e2e !important
  }

  .md\:hover\:text-yellow-700:hover {
    color: #b7791f !important
  }

  .md\:hover\:text-yellow-800:hover {
    color: #975a16 !important
  }

  .md\:hover\:text-yellow-900:hover {
    color: #744210 !important
  }

  .md\:hover\:text-green-100:hover {
    color: #f0fff4 !important
  }

  .md\:hover\:text-green-200:hover {
    color: #c6f6d5 !important
  }

  .md\:hover\:text-green-300:hover {
    color: #9ae6b4 !important
  }

  .md\:hover\:text-green-400:hover {
    color: #68d391 !important
  }

  .md\:hover\:text-green-500:hover {
    color: #48bb78 !important
  }

  .md\:hover\:text-green-600:hover {
    color: #38a169 !important
  }

  .md\:hover\:text-green-700:hover {
    color: #2f855a !important
  }

  .md\:hover\:text-green-800:hover {
    color: #276749 !important
  }

  .md\:hover\:text-green-900:hover {
    color: #22543d !important
  }

  .md\:hover\:text-teal-100:hover {
    color: #e6fffa !important
  }

  .md\:hover\:text-teal-200:hover {
    color: #b2f5ea !important
  }

  .md\:hover\:text-teal-300:hover {
    color: #81e6d9 !important
  }

  .md\:hover\:text-teal-400:hover {
    color: #4fd1c5 !important
  }

  .md\:hover\:text-teal-500:hover {
    color: #38b2ac !important
  }

  .md\:hover\:text-teal-600:hover {
    color: #319795 !important
  }

  .md\:hover\:text-teal-700:hover {
    color: #2c7a7b !important
  }

  .md\:hover\:text-teal-800:hover {
    color: #285e61 !important
  }

  .md\:hover\:text-teal-900:hover {
    color: #234e52 !important
  }

  .md\:hover\:text-blue-100:hover {
    color: #ebf8ff !important
  }

  .md\:hover\:text-blue-200:hover {
    color: #bee3f8 !important
  }

  .md\:hover\:text-blue-300:hover {
    color: #90cdf4 !important
  }

  .md\:hover\:text-blue-400:hover {
    color: #63b3ed !important
  }

  .md\:hover\:text-blue-500:hover {
    color: #4299e1 !important
  }

  .md\:hover\:text-blue-600:hover {
    color: #3182ce !important
  }

  .md\:hover\:text-blue-700:hover {
    color: #2b6cb0 !important
  }

  .md\:hover\:text-blue-800:hover {
    color: #2c5282 !important
  }

  .md\:hover\:text-blue-900:hover {
    color: #2a4365 !important
  }

  .md\:hover\:text-indigo-100:hover {
    color: #ebf4ff !important
  }

  .md\:hover\:text-indigo-200:hover {
    color: #c3dafe !important
  }

  .md\:hover\:text-indigo-300:hover {
    color: #a3bffa !important
  }

  .md\:hover\:text-indigo-400:hover {
    color: #7f9cf5 !important
  }

  .md\:hover\:text-indigo-500:hover {
    color: #667eea !important
  }

  .md\:hover\:text-indigo-600:hover {
    color: #5a67d8 !important
  }

  .md\:hover\:text-indigo-700:hover {
    color: #4c51bf !important
  }

  .md\:hover\:text-indigo-800:hover {
    color: #434190 !important
  }

  .md\:hover\:text-indigo-900:hover {
    color: #3c366b !important
  }

  .md\:hover\:text-purple-100:hover {
    color: #faf5ff !important
  }

  .md\:hover\:text-purple-200:hover {
    color: #e9d8fd !important
  }

  .md\:hover\:text-purple-300:hover {
    color: #d6bcfa !important
  }

  .md\:hover\:text-purple-400:hover {
    color: #b794f4 !important
  }

  .md\:hover\:text-purple-500:hover {
    color: #9f7aea !important
  }

  .md\:hover\:text-purple-600:hover {
    color: #805ad5 !important
  }

  .md\:hover\:text-purple-700:hover {
    color: #6b46c1 !important
  }

  .md\:hover\:text-purple-800:hover {
    color: #553c9a !important
  }

  .md\:hover\:text-purple-900:hover {
    color: #44337a !important
  }

  .md\:hover\:text-pink-100:hover {
    color: #fff5f7 !important
  }

  .md\:hover\:text-pink-200:hover {
    color: #fed7e2 !important
  }

  .md\:hover\:text-pink-300:hover {
    color: #fbb6ce !important
  }

  .md\:hover\:text-pink-400:hover {
    color: #f687b3 !important
  }

  .md\:hover\:text-pink-500:hover {
    color: #ed64a6 !important
  }

  .md\:hover\:text-pink-600:hover {
    color: #d53f8c !important
  }

  .md\:hover\:text-pink-700:hover {
    color: #b83280 !important
  }

  .md\:hover\:text-pink-800:hover {
    color: #97266d !important
  }

  .md\:hover\:text-pink-900:hover {
    color: #702459 !important
  }

  .md\:focus\:text-transparent:focus {
    color: transparent !important
  }

  .md\:focus\:text-current:focus {
    color: currentColor !important
  }

  .md\:focus\:text-black:focus {
    color: #000 !important
  }

  .md\:focus\:text-white:focus {
    color: #fff !important
  }

  .md\:focus\:text-gray-100:focus {
    color: #f7fafc !important
  }

  .md\:focus\:text-gray-200:focus {
    color: #edf2f7 !important
  }

  .md\:focus\:text-gray-300:focus {
    color: #e2e8f0 !important
  }

  .md\:focus\:text-gray-400:focus {
    color: #cbd5e0 !important
  }

  .md\:focus\:text-gray-500:focus {
    color: #a0aec0 !important
  }

  .md\:focus\:text-gray-600:focus {
    color: #718096 !important
  }

  .md\:focus\:text-gray-700:focus {
    color: #4a5568 !important
  }

  .md\:focus\:text-gray-800:focus {
    color: #2d3748 !important
  }

  .md\:focus\:text-gray-900:focus {
    color: #1a202c !important
  }

  .md\:focus\:text-red-100:focus {
    color: #fff5f5 !important
  }

  .md\:focus\:text-red-200:focus {
    color: #fed7d7 !important
  }

  .md\:focus\:text-red-300:focus {
    color: #feb2b2 !important
  }

  .md\:focus\:text-red-400:focus {
    color: #fc8181 !important
  }

  .md\:focus\:text-red-500:focus {
    color: #f56565 !important
  }

  .md\:focus\:text-red-600:focus {
    color: #e53e3e !important
  }

  .md\:focus\:text-red-700:focus {
    color: #c53030 !important
  }

  .md\:focus\:text-red-800:focus {
    color: #9b2c2c !important
  }

  .md\:focus\:text-red-900:focus {
    color: #742a2a !important
  }

  .md\:focus\:text-orange-100:focus {
    color: #fffaf0 !important
  }

  .md\:focus\:text-orange-200:focus {
    color: #feebc8 !important
  }

  .md\:focus\:text-orange-300:focus {
    color: #fbd38d !important
  }

  .md\:focus\:text-orange-400:focus {
    color: #f6ad55 !important
  }

  .md\:focus\:text-orange-500:focus {
    color: #ed8936 !important
  }

  .md\:focus\:text-orange-600:focus {
    color: #dd6b20 !important
  }

  .md\:focus\:text-orange-700:focus {
    color: #c05621 !important
  }

  .md\:focus\:text-orange-800:focus {
    color: #9c4221 !important
  }

  .md\:focus\:text-orange-900:focus {
    color: #7b341e !important
  }

  .md\:focus\:text-yellow-100:focus {
    color: #fffff0 !important
  }

  .md\:focus\:text-yellow-200:focus {
    color: #fefcbf !important
  }

  .md\:focus\:text-yellow-300:focus {
    color: #faf089 !important
  }

  .md\:focus\:text-yellow-400:focus {
    color: #f6e05e !important
  }

  .md\:focus\:text-yellow-500:focus {
    color: #ecc94b !important
  }

  .md\:focus\:text-yellow-600:focus {
    color: #d69e2e !important
  }

  .md\:focus\:text-yellow-700:focus {
    color: #b7791f !important
  }

  .md\:focus\:text-yellow-800:focus {
    color: #975a16 !important
  }

  .md\:focus\:text-yellow-900:focus {
    color: #744210 !important
  }

  .md\:focus\:text-green-100:focus {
    color: #f0fff4 !important
  }

  .md\:focus\:text-green-200:focus {
    color: #c6f6d5 !important
  }

  .md\:focus\:text-green-300:focus {
    color: #9ae6b4 !important
  }

  .md\:focus\:text-green-400:focus {
    color: #68d391 !important
  }

  .md\:focus\:text-green-500:focus {
    color: #48bb78 !important
  }

  .md\:focus\:text-green-600:focus {
    color: #38a169 !important
  }

  .md\:focus\:text-green-700:focus {
    color: #2f855a !important
  }

  .md\:focus\:text-green-800:focus {
    color: #276749 !important
  }

  .md\:focus\:text-green-900:focus {
    color: #22543d !important
  }

  .md\:focus\:text-teal-100:focus {
    color: #e6fffa !important
  }

  .md\:focus\:text-teal-200:focus {
    color: #b2f5ea !important
  }

  .md\:focus\:text-teal-300:focus {
    color: #81e6d9 !important
  }

  .md\:focus\:text-teal-400:focus {
    color: #4fd1c5 !important
  }

  .md\:focus\:text-teal-500:focus {
    color: #38b2ac !important
  }

  .md\:focus\:text-teal-600:focus {
    color: #319795 !important
  }

  .md\:focus\:text-teal-700:focus {
    color: #2c7a7b !important
  }

  .md\:focus\:text-teal-800:focus {
    color: #285e61 !important
  }

  .md\:focus\:text-teal-900:focus {
    color: #234e52 !important
  }

  .md\:focus\:text-blue-100:focus {
    color: #ebf8ff !important
  }

  .md\:focus\:text-blue-200:focus {
    color: #bee3f8 !important
  }

  .md\:focus\:text-blue-300:focus {
    color: #90cdf4 !important
  }

  .md\:focus\:text-blue-400:focus {
    color: #63b3ed !important
  }

  .md\:focus\:text-blue-500:focus {
    color: #4299e1 !important
  }

  .md\:focus\:text-blue-600:focus {
    color: #3182ce !important
  }

  .md\:focus\:text-blue-700:focus {
    color: #2b6cb0 !important
  }

  .md\:focus\:text-blue-800:focus {
    color: #2c5282 !important
  }

  .md\:focus\:text-blue-900:focus {
    color: #2a4365 !important
  }

  .md\:focus\:text-indigo-100:focus {
    color: #ebf4ff !important
  }

  .md\:focus\:text-indigo-200:focus {
    color: #c3dafe !important
  }

  .md\:focus\:text-indigo-300:focus {
    color: #a3bffa !important
  }

  .md\:focus\:text-indigo-400:focus {
    color: #7f9cf5 !important
  }

  .md\:focus\:text-indigo-500:focus {
    color: #667eea !important
  }

  .md\:focus\:text-indigo-600:focus {
    color: #5a67d8 !important
  }

  .md\:focus\:text-indigo-700:focus {
    color: #4c51bf !important
  }

  .md\:focus\:text-indigo-800:focus {
    color: #434190 !important
  }

  .md\:focus\:text-indigo-900:focus {
    color: #3c366b !important
  }

  .md\:focus\:text-purple-100:focus {
    color: #faf5ff !important
  }

  .md\:focus\:text-purple-200:focus {
    color: #e9d8fd !important
  }

  .md\:focus\:text-purple-300:focus {
    color: #d6bcfa !important
  }

  .md\:focus\:text-purple-400:focus {
    color: #b794f4 !important
  }

  .md\:focus\:text-purple-500:focus {
    color: #9f7aea !important
  }

  .md\:focus\:text-purple-600:focus {
    color: #805ad5 !important
  }

  .md\:focus\:text-purple-700:focus {
    color: #6b46c1 !important
  }

  .md\:focus\:text-purple-800:focus {
    color: #553c9a !important
  }

  .md\:focus\:text-purple-900:focus {
    color: #44337a !important
  }

  .md\:focus\:text-pink-100:focus {
    color: #fff5f7 !important
  }

  .md\:focus\:text-pink-200:focus {
    color: #fed7e2 !important
  }

  .md\:focus\:text-pink-300:focus {
    color: #fbb6ce !important
  }

  .md\:focus\:text-pink-400:focus {
    color: #f687b3 !important
  }

  .md\:focus\:text-pink-500:focus {
    color: #ed64a6 !important
  }

  .md\:focus\:text-pink-600:focus {
    color: #d53f8c !important
  }

  .md\:focus\:text-pink-700:focus {
    color: #b83280 !important
  }

  .md\:focus\:text-pink-800:focus {
    color: #97266d !important
  }

  .md\:focus\:text-pink-900:focus {
    color: #702459 !important
  }

  .md\:bg-transparent {
    background-color: transparent !important
  }

  .md\:bg-current {
    background-color: currentColor !important
  }

  .md\:bg-black {
    background-color: #000 !important
  }

  .md\:bg-white {
    background-color: #fff !important
  }

  .md\:bg-gray-100 {
    background-color: #f7fafc !important
  }

  .md\:bg-gray-200 {
    background-color: #edf2f7 !important
  }

  .md\:bg-gray-300 {
    background-color: #e2e8f0 !important
  }

  .md\:bg-gray-400 {
    background-color: #cbd5e0 !important
  }

  .md\:bg-gray-500 {
    background-color: #a0aec0 !important
  }

  .md\:bg-gray-600 {
    background-color: #718096 !important
  }

  .md\:bg-gray-700 {
    background-color: #4a5568 !important
  }

  .md\:bg-gray-800 {
    background-color: #2d3748 !important
  }

  .md\:bg-gray-900 {
    background-color: #1a202c !important
  }

  .md\:bg-red-100 {
    background-color: #fff5f5 !important
  }

  .md\:bg-red-200 {
    background-color: #fed7d7 !important
  }

  .md\:bg-red-300 {
    background-color: #feb2b2 !important
  }

  .md\:bg-red-400 {
    background-color: #fc8181 !important
  }

  .md\:bg-red-500 {
    background-color: #f56565 !important
  }

  .md\:bg-red-600 {
    background-color: #e53e3e !important
  }

  .md\:bg-red-700 {
    background-color: #c53030 !important
  }

  .md\:bg-red-800 {
    background-color: #9b2c2c !important
  }

  .md\:bg-red-900 {
    background-color: #742a2a !important
  }

  .md\:bg-orange-100 {
    background-color: #fffaf0 !important
  }

  .md\:bg-orange-200 {
    background-color: #feebc8 !important
  }

  .md\:bg-orange-300 {
    background-color: #fbd38d !important
  }

  .md\:bg-orange-400 {
    background-color: #f6ad55 !important
  }

  .md\:bg-orange-500 {
    background-color: #ed8936 !important
  }

  .md\:bg-orange-600 {
    background-color: #dd6b20 !important
  }

  .md\:bg-orange-700 {
    background-color: #c05621 !important
  }

  .md\:bg-orange-800 {
    background-color: #9c4221 !important
  }

  .md\:bg-orange-900 {
    background-color: #7b341e !important
  }

  .md\:bg-yellow-100 {
    background-color: #fffff0 !important
  }

  .md\:bg-yellow-200 {
    background-color: #fefcbf !important
  }

  .md\:bg-yellow-300 {
    background-color: #faf089 !important
  }

  .md\:bg-yellow-400 {
    background-color: #f6e05e !important
  }

  .md\:bg-yellow-500 {
    background-color: #ecc94b !important
  }

  .md\:bg-yellow-600 {
    background-color: #d69e2e !important
  }

  .md\:bg-yellow-700 {
    background-color: #b7791f !important
  }

  .md\:bg-yellow-800 {
    background-color: #975a16 !important
  }

  .md\:bg-yellow-900 {
    background-color: #744210 !important
  }

  .md\:bg-green-100 {
    background-color: #f0fff4 !important
  }

  .md\:bg-green-200 {
    background-color: #c6f6d5 !important
  }

  .md\:bg-green-300 {
    background-color: #9ae6b4 !important
  }

  .md\:bg-green-400 {
    background-color: #68d391 !important
  }

  .md\:bg-green-500 {
    background-color: #48bb78 !important
  }

  .md\:bg-green-600 {
    background-color: #38a169 !important
  }

  .md\:bg-green-700 {
    background-color: #2f855a !important
  }

  .md\:bg-green-800 {
    background-color: #276749 !important
  }

  .md\:bg-green-900 {
    background-color: #22543d !important
  }

  .md\:bg-teal-100 {
    background-color: #e6fffa !important
  }

  .md\:bg-teal-200 {
    background-color: #b2f5ea !important
  }

  .md\:bg-teal-300 {
    background-color: #81e6d9 !important
  }

  .md\:bg-teal-400 {
    background-color: #4fd1c5 !important
  }

  .md\:bg-teal-500 {
    background-color: #38b2ac !important
  }

  .md\:bg-teal-600 {
    background-color: #319795 !important
  }

  .md\:bg-teal-700 {
    background-color: #2c7a7b !important
  }

  .md\:bg-teal-800 {
    background-color: #285e61 !important
  }

  .md\:bg-teal-900 {
    background-color: #234e52 !important
  }

  .md\:bg-blue-100 {
    background-color: #ebf8ff !important
  }

  .md\:bg-blue-200 {
    background-color: #bee3f8 !important
  }

  .md\:bg-blue-300 {
    background-color: #90cdf4 !important
  }

  .md\:bg-blue-400 {
    background-color: #63b3ed !important
  }

  .md\:bg-blue-500 {
    background-color: #4299e1 !important
  }

  .md\:bg-blue-600 {
    background-color: #3182ce !important
  }

  .md\:bg-blue-700 {
    background-color: #2b6cb0 !important
  }

  .md\:bg-blue-800 {
    background-color: #2c5282 !important
  }

  .md\:bg-blue-900 {
    background-color: #2a4365 !important
  }

  .md\:bg-indigo-100 {
    background-color: #ebf4ff !important
  }

  .md\:bg-indigo-200 {
    background-color: #c3dafe !important
  }

  .md\:bg-indigo-300 {
    background-color: #a3bffa !important
  }

  .md\:bg-indigo-400 {
    background-color: #7f9cf5 !important
  }

  .md\:bg-indigo-500 {
    background-color: #667eea !important
  }

  .md\:bg-indigo-600 {
    background-color: #5a67d8 !important
  }

  .md\:bg-indigo-700 {
    background-color: #4c51bf !important
  }

  .md\:bg-indigo-800 {
    background-color: #434190 !important
  }

  .md\:bg-indigo-900 {
    background-color: #3c366b !important
  }

  .md\:bg-purple-100 {
    background-color: #faf5ff !important
  }

  .md\:bg-purple-200 {
    background-color: #e9d8fd !important
  }

  .md\:bg-purple-300 {
    background-color: #d6bcfa !important
  }

  .md\:bg-purple-400 {
    background-color: #b794f4 !important
  }

  .md\:bg-purple-500 {
    background-color: #9f7aea !important
  }

  .md\:bg-purple-600 {
    background-color: #805ad5 !important
  }

  .md\:bg-purple-700 {
    background-color: #6b46c1 !important
  }

  .md\:bg-purple-800 {
    background-color: #553c9a !important
  }

  .md\:bg-purple-900 {
    background-color: #44337a !important
  }

  .md\:bg-pink-100 {
    background-color: #fff5f7 !important
  }

  .md\:bg-pink-200 {
    background-color: #fed7e2 !important
  }

  .md\:bg-pink-300 {
    background-color: #fbb6ce !important
  }

  .md\:bg-pink-400 {
    background-color: #f687b3 !important
  }

  .md\:bg-pink-500 {
    background-color: #ed64a6 !important
  }

  .md\:bg-pink-600 {
    background-color: #d53f8c !important
  }

  .md\:bg-pink-700 {
    background-color: #b83280 !important
  }

  .md\:bg-pink-800 {
    background-color: #97266d !important
  }

  .md\:bg-pink-900 {
    background-color: #702459 !important
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent !important
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor !important
  }

  .md\:hover\:bg-black:hover {
    background-color: #000 !important
  }

  .md\:hover\:bg-white:hover {
    background-color: #fff !important
  }

  .md\:hover\:bg-gray-100:hover {
    background-color: #f7fafc !important
  }

  .md\:hover\:bg-gray-200:hover {
    background-color: #edf2f7 !important
  }

  .md\:hover\:bg-gray-300:hover {
    background-color: #e2e8f0 !important
  }

  .md\:hover\:bg-gray-400:hover {
    background-color: #cbd5e0 !important
  }

  .md\:hover\:bg-gray-500:hover {
    background-color: #a0aec0 !important
  }

  .md\:hover\:bg-gray-600:hover {
    background-color: #718096 !important
  }

  .md\:hover\:bg-gray-700:hover {
    background-color: #4a5568 !important
  }

  .md\:hover\:bg-gray-800:hover {
    background-color: #2d3748 !important
  }

  .md\:hover\:bg-gray-900:hover {
    background-color: #1a202c !important
  }

  .md\:hover\:bg-red-100:hover {
    background-color: #fff5f5 !important
  }

  .md\:hover\:bg-red-200:hover {
    background-color: #fed7d7 !important
  }

  .md\:hover\:bg-red-300:hover {
    background-color: #feb2b2 !important
  }

  .md\:hover\:bg-red-400:hover {
    background-color: #fc8181 !important
  }

  .md\:hover\:bg-red-500:hover {
    background-color: #f56565 !important
  }

  .md\:hover\:bg-red-600:hover {
    background-color: #e53e3e !important
  }

  .md\:hover\:bg-red-700:hover {
    background-color: #c53030 !important
  }

  .md\:hover\:bg-red-800:hover {
    background-color: #9b2c2c !important
  }

  .md\:hover\:bg-red-900:hover {
    background-color: #742a2a !important
  }

  .md\:hover\:bg-orange-100:hover {
    background-color: #fffaf0 !important
  }

  .md\:hover\:bg-orange-200:hover {
    background-color: #feebc8 !important
  }

  .md\:hover\:bg-orange-300:hover {
    background-color: #fbd38d !important
  }

  .md\:hover\:bg-orange-400:hover {
    background-color: #f6ad55 !important
  }

  .md\:hover\:bg-orange-500:hover {
    background-color: #ed8936 !important
  }

  .md\:hover\:bg-orange-600:hover {
    background-color: #dd6b20 !important
  }

  .md\:hover\:bg-orange-700:hover {
    background-color: #c05621 !important
  }

  .md\:hover\:bg-orange-800:hover {
    background-color: #9c4221 !important
  }

  .md\:hover\:bg-orange-900:hover {
    background-color: #7b341e !important
  }

  .md\:hover\:bg-yellow-100:hover {
    background-color: #fffff0 !important
  }

  .md\:hover\:bg-yellow-200:hover {
    background-color: #fefcbf !important
  }

  .md\:hover\:bg-yellow-300:hover {
    background-color: #faf089 !important
  }

  .md\:hover\:bg-yellow-400:hover {
    background-color: #f6e05e !important
  }

  .md\:hover\:bg-yellow-500:hover {
    background-color: #ecc94b !important
  }

  .md\:hover\:bg-yellow-600:hover {
    background-color: #d69e2e !important
  }

  .md\:hover\:bg-yellow-700:hover {
    background-color: #b7791f !important
  }

  .md\:hover\:bg-yellow-800:hover {
    background-color: #975a16 !important
  }

  .md\:hover\:bg-yellow-900:hover {
    background-color: #744210 !important
  }

  .md\:hover\:bg-green-100:hover {
    background-color: #f0fff4 !important
  }

  .md\:hover\:bg-green-200:hover {
    background-color: #c6f6d5 !important
  }

  .md\:hover\:bg-green-300:hover {
    background-color: #9ae6b4 !important
  }

  .md\:hover\:bg-green-400:hover {
    background-color: #68d391 !important
  }

  .md\:hover\:bg-green-500:hover {
    background-color: #48bb78 !important
  }

  .md\:hover\:bg-green-600:hover {
    background-color: #38a169 !important
  }

  .md\:hover\:bg-green-700:hover {
    background-color: #2f855a !important
  }

  .md\:hover\:bg-green-800:hover {
    background-color: #276749 !important
  }

  .md\:hover\:bg-green-900:hover {
    background-color: #22543d !important
  }

  .md\:hover\:bg-teal-100:hover {
    background-color: #e6fffa !important
  }

  .md\:hover\:bg-teal-200:hover {
    background-color: #b2f5ea !important
  }

  .md\:hover\:bg-teal-300:hover {
    background-color: #81e6d9 !important
  }

  .md\:hover\:bg-teal-400:hover {
    background-color: #4fd1c5 !important
  }

  .md\:hover\:bg-teal-500:hover {
    background-color: #38b2ac !important
  }

  .md\:hover\:bg-teal-600:hover {
    background-color: #319795 !important
  }

  .md\:hover\:bg-teal-700:hover {
    background-color: #2c7a7b !important
  }

  .md\:hover\:bg-teal-800:hover {
    background-color: #285e61 !important
  }

  .md\:hover\:bg-teal-900:hover {
    background-color: #234e52 !important
  }

  .md\:hover\:bg-blue-100:hover {
    background-color: #ebf8ff !important
  }

  .md\:hover\:bg-blue-200:hover {
    background-color: #bee3f8 !important
  }

  .md\:hover\:bg-blue-300:hover {
    background-color: #90cdf4 !important
  }

  .md\:hover\:bg-blue-400:hover {
    background-color: #63b3ed !important
  }

  .md\:hover\:bg-blue-500:hover {
    background-color: #4299e1 !important
  }

  .md\:hover\:bg-blue-600:hover {
    background-color: #3182ce !important
  }

  .md\:hover\:bg-blue-700:hover {
    background-color: #2b6cb0 !important
  }

  .md\:hover\:bg-blue-800:hover {
    background-color: #2c5282 !important
  }

  .md\:hover\:bg-blue-900:hover {
    background-color: #2a4365 !important
  }

  .md\:hover\:bg-indigo-100:hover {
    background-color: #ebf4ff !important
  }

  .md\:hover\:bg-indigo-200:hover {
    background-color: #c3dafe !important
  }

  .md\:hover\:bg-indigo-300:hover {
    background-color: #a3bffa !important
  }

  .md\:hover\:bg-indigo-400:hover {
    background-color: #7f9cf5 !important
  }

  .md\:hover\:bg-indigo-500:hover {
    background-color: #667eea !important
  }

  .md\:hover\:bg-indigo-600:hover {
    background-color: #5a67d8 !important
  }

  .md\:hover\:bg-indigo-700:hover {
    background-color: #4c51bf !important
  }

  .md\:hover\:bg-indigo-800:hover {
    background-color: #434190 !important
  }

  .md\:hover\:bg-indigo-900:hover {
    background-color: #3c366b !important
  }

  .md\:hover\:bg-purple-100:hover {
    background-color: #faf5ff !important
  }

  .md\:hover\:bg-purple-200:hover {
    background-color: #e9d8fd !important
  }

  .md\:hover\:bg-purple-300:hover {
    background-color: #d6bcfa !important
  }

  .md\:hover\:bg-purple-400:hover {
    background-color: #b794f4 !important
  }

  .md\:hover\:bg-purple-500:hover {
    background-color: #9f7aea !important
  }

  .md\:hover\:bg-purple-600:hover {
    background-color: #805ad5 !important
  }

  .md\:hover\:bg-purple-700:hover {
    background-color: #6b46c1 !important
  }

  .md\:hover\:bg-purple-800:hover {
    background-color: #553c9a !important
  }

  .md\:hover\:bg-purple-900:hover {
    background-color: #44337a !important
  }

  .md\:hover\:bg-pink-100:hover {
    background-color: #fff5f7 !important
  }

  .md\:hover\:bg-pink-200:hover {
    background-color: #fed7e2 !important
  }

  .md\:hover\:bg-pink-300:hover {
    background-color: #fbb6ce !important
  }

  .md\:hover\:bg-pink-400:hover {
    background-color: #f687b3 !important
  }

  .md\:hover\:bg-pink-500:hover {
    background-color: #ed64a6 !important
  }

  .md\:hover\:bg-pink-600:hover {
    background-color: #d53f8c !important
  }

  .md\:hover\:bg-pink-700:hover {
    background-color: #b83280 !important
  }

  .md\:hover\:bg-pink-800:hover {
    background-color: #97266d !important
  }

  .md\:hover\:bg-pink-900:hover {
    background-color: #702459 !important
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent !important
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor !important
  }

  .md\:focus\:bg-black:focus {
    background-color: #000 !important
  }

  .md\:focus\:bg-white:focus {
    background-color: #fff !important
  }

  .md\:focus\:bg-gray-100:focus {
    background-color: #f7fafc !important
  }

  .md\:focus\:bg-gray-200:focus {
    background-color: #edf2f7 !important
  }

  .md\:focus\:bg-gray-300:focus {
    background-color: #e2e8f0 !important
  }

  .md\:focus\:bg-gray-400:focus {
    background-color: #cbd5e0 !important
  }

  .md\:focus\:bg-gray-500:focus {
    background-color: #a0aec0 !important
  }

  .md\:focus\:bg-gray-600:focus {
    background-color: #718096 !important
  }

  .md\:focus\:bg-gray-700:focus {
    background-color: #4a5568 !important
  }

  .md\:focus\:bg-gray-800:focus {
    background-color: #2d3748 !important
  }

  .md\:focus\:bg-gray-900:focus {
    background-color: #1a202c !important
  }

  .md\:focus\:bg-red-100:focus {
    background-color: #fff5f5 !important
  }

  .md\:focus\:bg-red-200:focus {
    background-color: #fed7d7 !important
  }

  .md\:focus\:bg-red-300:focus {
    background-color: #feb2b2 !important
  }

  .md\:focus\:bg-red-400:focus {
    background-color: #fc8181 !important
  }

  .md\:focus\:bg-red-500:focus {
    background-color: #f56565 !important
  }

  .md\:focus\:bg-red-600:focus {
    background-color: #e53e3e !important
  }

  .md\:focus\:bg-red-700:focus {
    background-color: #c53030 !important
  }

  .md\:focus\:bg-red-800:focus {
    background-color: #9b2c2c !important
  }

  .md\:focus\:bg-red-900:focus {
    background-color: #742a2a !important
  }

  .md\:focus\:bg-orange-100:focus {
    background-color: #fffaf0 !important
  }

  .md\:focus\:bg-orange-200:focus {
    background-color: #feebc8 !important
  }

  .md\:focus\:bg-orange-300:focus {
    background-color: #fbd38d !important
  }

  .md\:focus\:bg-orange-400:focus {
    background-color: #f6ad55 !important
  }

  .md\:focus\:bg-orange-500:focus {
    background-color: #ed8936 !important
  }

  .md\:focus\:bg-orange-600:focus {
    background-color: #dd6b20 !important
  }

  .md\:focus\:bg-orange-700:focus {
    background-color: #c05621 !important
  }

  .md\:focus\:bg-orange-800:focus {
    background-color: #9c4221 !important
  }

  .md\:focus\:bg-orange-900:focus {
    background-color: #7b341e !important
  }

  .md\:focus\:bg-yellow-100:focus {
    background-color: #fffff0 !important
  }

  .md\:focus\:bg-yellow-200:focus {
    background-color: #fefcbf !important
  }

  .md\:focus\:bg-yellow-300:focus {
    background-color: #faf089 !important
  }

  .md\:focus\:bg-yellow-400:focus {
    background-color: #f6e05e !important
  }

  .md\:focus\:bg-yellow-500:focus {
    background-color: #ecc94b !important
  }

  .md\:focus\:bg-yellow-600:focus {
    background-color: #d69e2e !important
  }

  .md\:focus\:bg-yellow-700:focus {
    background-color: #b7791f !important
  }

  .md\:focus\:bg-yellow-800:focus {
    background-color: #975a16 !important
  }

  .md\:focus\:bg-yellow-900:focus {
    background-color: #744210 !important
  }

  .md\:focus\:bg-green-100:focus {
    background-color: #f0fff4 !important
  }

  .md\:focus\:bg-green-200:focus {
    background-color: #c6f6d5 !important
  }

  .md\:focus\:bg-green-300:focus {
    background-color: #9ae6b4 !important
  }

  .md\:focus\:bg-green-400:focus {
    background-color: #68d391 !important
  }

  .md\:focus\:bg-green-500:focus {
    background-color: #48bb78 !important
  }

  .md\:focus\:bg-green-600:focus {
    background-color: #38a169 !important
  }

  .md\:focus\:bg-green-700:focus {
    background-color: #2f855a !important
  }

  .md\:focus\:bg-green-800:focus {
    background-color: #276749 !important
  }

  .md\:focus\:bg-green-900:focus {
    background-color: #22543d !important
  }

  .md\:focus\:bg-teal-100:focus {
    background-color: #e6fffa !important
  }

  .md\:focus\:bg-teal-200:focus {
    background-color: #b2f5ea !important
  }

  .md\:focus\:bg-teal-300:focus {
    background-color: #81e6d9 !important
  }

  .md\:focus\:bg-teal-400:focus {
    background-color: #4fd1c5 !important
  }

  .md\:focus\:bg-teal-500:focus {
    background-color: #38b2ac !important
  }

  .md\:focus\:bg-teal-600:focus {
    background-color: #319795 !important
  }

  .md\:focus\:bg-teal-700:focus {
    background-color: #2c7a7b !important
  }

  .md\:focus\:bg-teal-800:focus {
    background-color: #285e61 !important
  }

  .md\:focus\:bg-teal-900:focus {
    background-color: #234e52 !important
  }

  .md\:focus\:bg-blue-100:focus {
    background-color: #ebf8ff !important
  }

  .md\:focus\:bg-blue-200:focus {
    background-color: #bee3f8 !important
  }

  .md\:focus\:bg-blue-300:focus {
    background-color: #90cdf4 !important
  }

  .md\:focus\:bg-blue-400:focus {
    background-color: #63b3ed !important
  }

  .md\:focus\:bg-blue-500:focus {
    background-color: #4299e1 !important
  }

  .md\:focus\:bg-blue-600:focus {
    background-color: #3182ce !important
  }

  .md\:focus\:bg-blue-700:focus {
    background-color: #2b6cb0 !important
  }

  .md\:focus\:bg-blue-800:focus {
    background-color: #2c5282 !important
  }

  .md\:focus\:bg-blue-900:focus {
    background-color: #2a4365 !important
  }

  .md\:focus\:bg-indigo-100:focus {
    background-color: #ebf4ff !important
  }

  .md\:focus\:bg-indigo-200:focus {
    background-color: #c3dafe !important
  }

  .md\:focus\:bg-indigo-300:focus {
    background-color: #a3bffa !important
  }

  .md\:focus\:bg-indigo-400:focus {
    background-color: #7f9cf5 !important
  }

  .md\:focus\:bg-indigo-500:focus {
    background-color: #667eea !important
  }

  .md\:focus\:bg-indigo-600:focus {
    background-color: #5a67d8 !important
  }

  .md\:focus\:bg-indigo-700:focus {
    background-color: #4c51bf !important
  }

  .md\:focus\:bg-indigo-800:focus {
    background-color: #434190 !important
  }

  .md\:focus\:bg-indigo-900:focus {
    background-color: #3c366b !important
  }

  .md\:focus\:bg-purple-100:focus {
    background-color: #faf5ff !important
  }

  .md\:focus\:bg-purple-200:focus {
    background-color: #e9d8fd !important
  }

  .md\:focus\:bg-purple-300:focus {
    background-color: #d6bcfa !important
  }

  .md\:focus\:bg-purple-400:focus {
    background-color: #b794f4 !important
  }

  .md\:focus\:bg-purple-500:focus {
    background-color: #9f7aea !important
  }

  .md\:focus\:bg-purple-600:focus {
    background-color: #805ad5 !important
  }

  .md\:focus\:bg-purple-700:focus {
    background-color: #6b46c1 !important
  }

  .md\:focus\:bg-purple-800:focus {
    background-color: #553c9a !important
  }

  .md\:focus\:bg-purple-900:focus {
    background-color: #44337a !important
  }

  .md\:focus\:bg-pink-100:focus {
    background-color: #fff5f7 !important
  }

  .md\:focus\:bg-pink-200:focus {
    background-color: #fed7e2 !important
  }

  .md\:focus\:bg-pink-300:focus {
    background-color: #fbb6ce !important
  }

  .md\:focus\:bg-pink-400:focus {
    background-color: #f687b3 !important
  }

  .md\:focus\:bg-pink-500:focus {
    background-color: #ed64a6 !important
  }

  .md\:focus\:bg-pink-600:focus {
    background-color: #d53f8c !important
  }

  .md\:focus\:bg-pink-700:focus {
    background-color: #b83280 !important
  }

  .md\:focus\:bg-pink-800:focus {
    background-color: #97266d !important
  }

  .md\:focus\:bg-pink-900:focus {
    background-color: #702459 !important
  }

  .md\:cursor-auto {
    cursor: auto !important
  }

  .md\:cursor-default {
    cursor: default !important
  }

  .md\:cursor-pointer {
    cursor: pointer !important
  }

  .md\:cursor-wait {
    cursor: wait !important
  }

  .md\:cursor-text {
    cursor: text !important
  }

  .md\:cursor-move {
    cursor: move !important
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed !important
  }

  .md\:h-0 {
    height: 0 !important
  }

  .md\:h-4 {
    height: 4px !important
  }

  .md\:h-6 {
    height: 6px !important
  }

  .md\:h-8 {
    height: 8px !important
  }

  .md\:h-12 {
    height: 12px !important
  }

  .md\:h-16 {
    height: 16px !important
  }

  .md\:h-20 {
    height: 20px !important
  }

  .md\:h-24 {
    height: 24px !important
  }

  .md\:h-28 {
    height: 28px !important
  }

  .md\:h-32 {
    height: 32px !important
  }

  .md\:h-36 {
    height: 36px !important
  }

  .md\:h-40 {
    height: 40px !important
  }

  .md\:h-44 {
    height: 44px !important
  }

  .md\:h-48 {
    height: 48px !important
  }

  .md\:h-52 {
    height: 52px !important
  }

  .md\:h-56 {
    height: 56px !important
  }

  .md\:h-60 {
    height: 60px !important
  }

  .md\:h-64 {
    height: 64px !important
  }

  .md\:h-68 {
    height: 68px !important
  }

  .md\:h-72 {
    height: 72px !important
  }

  .md\:h-76 {
    height: 76px !important
  }

  .md\:h-80 {
    height: 80px !important
  }

  .md\:h-84 {
    height: 84px !important
  }

  .md\:h-88 {
    height: 88px !important
  }

  .md\:h-92 {
    height: 92px !important
  }

  .md\:h-96 {
    height: 96px !important
  }

  .md\:h-128 {
    height: 128px !important
  }

  .md\:h-160 {
    height: 160px !important
  }

  .md\:h-192 {
    height: 192px !important
  }

  .md\:h-224 {
    height: 224px !important
  }

  .md\:h-256 {
    height: 256px !important
  }

  .md\:h-320 {
    height: 320px !important
  }

  .md\:h-auto {
    height: auto !important
  }

  .md\:h-px {
    height: 1px !important
  }

  .md\:h-full {
    height: 100% !important
  }

  .md\:h-screen {
    height: 100vh !important
  }

  .md\:w-0 {
    width: 0 !important
  }

  .md\:w-4 {
    width: 4px !important
  }

  .md\:w-6 {
    width: 6px !important
  }

  .md\:w-8 {
    width: 8px !important
  }

  .md\:w-12 {
    width: 12px !important
  }

  .md\:w-16 {
    width: 16px !important
  }

  .md\:w-20 {
    width: 20px !important
  }

  .md\:w-24 {
    width: 24px !important
  }

  .md\:w-28 {
    width: 28px !important
  }

  .md\:w-32 {
    width: 32px !important
  }

  .md\:w-36 {
    width: 36px !important
  }

  .md\:w-40 {
    width: 40px !important
  }

  .md\:w-44 {
    width: 44px !important
  }

  .md\:w-48 {
    width: 48px !important
  }

  .md\:w-52 {
    width: 52px !important
  }

  .md\:w-56 {
    width: 56px !important
  }

  .md\:w-60 {
    width: 60px !important
  }

  .md\:w-64 {
    width: 64px !important
  }

  .md\:w-68 {
    width: 68px !important
  }

  .md\:w-72 {
    width: 72px !important
  }

  .md\:w-76 {
    width: 76px !important
  }

  .md\:w-80 {
    width: 80px !important
  }

  .md\:w-84 {
    width: 84px !important
  }

  .md\:w-88 {
    width: 88px !important
  }

  .md\:w-92 {
    width: 92px !important
  }

  .md\:w-96 {
    width: 96px !important
  }

  .md\:w-128 {
    width: 128px !important
  }

  .md\:w-160 {
    width: 160px !important
  }

  .md\:w-192 {
    width: 192px !important
  }

  .md\:w-224 {
    width: 224px !important
  }

  .md\:w-256 {
    width: 256px !important
  }

  .md\:w-320 {
    width: 320px !important
  }

  .md\:w-auto {
    width: auto !important
  }

  .md\:w-px {
    width: 1px !important
  }

  .md\:w-1\/2 {
    width: 50% !important
  }

  .md\:w-1\/3 {
    width: 33.333333% !important
  }

  .md\:w-2\/3 {
    width: 66.666667% !important
  }

  .md\:w-1\/4 {
    width: 25% !important
  }

  .md\:w-2\/4 {
    width: 50% !important
  }

  .md\:w-3\/4 {
    width: 75% !important
  }

  .md\:w-1\/5 {
    width: 20% !important
  }

  .md\:w-2\/5 {
    width: 40% !important
  }

  .md\:w-3\/5 {
    width: 60% !important
  }

  .md\:w-4\/5 {
    width: 80% !important
  }

  .md\:w-1\/6 {
    width: 16.666667% !important
  }

  .md\:w-2\/6 {
    width: 33.333333% !important
  }

  .md\:w-3\/6 {
    width: 50% !important
  }

  .md\:w-4\/6 {
    width: 66.666667% !important
  }

  .md\:w-5\/6 {
    width: 83.333333% !important
  }

  .md\:w-1\/12 {
    width: 8.333333% !important
  }

  .md\:w-2\/12 {
    width: 16.666667% !important
  }

  .md\:w-3\/12 {
    width: 25% !important
  }

  .md\:w-4\/12 {
    width: 33.333333% !important
  }

  .md\:w-5\/12 {
    width: 41.666667% !important
  }

  .md\:w-6\/12 {
    width: 50% !important
  }

  .md\:w-7\/12 {
    width: 58.333333% !important
  }

  .md\:w-8\/12 {
    width: 66.666667% !important
  }

  .md\:w-9\/12 {
    width: 75% !important
  }

  .md\:w-10\/12 {
    width: 83.333333% !important
  }

  .md\:w-11\/12 {
    width: 91.666667% !important
  }

  .md\:w-full {
    width: 100% !important
  }

  .md\:w-screen {
    width: 100vw !important
  }

  .md\:max-h-full {
    max-height: 100% !important
  }

  .md\:max-h-screen {
    max-height: 100vh !important
  }

  .md\:max-w-none {
    max-width: none !important
  }

  .md\:max-w-xs {
    max-width: 20rem !important
  }

  .md\:max-w-sm {
    max-width: 24rem !important
  }

  .md\:max-w-md {
    max-width: 28rem !important
  }

  .md\:max-w-lg {
    max-width: 32rem !important
  }

  .md\:max-w-xl {
    max-width: 36rem !important
  }

  .md\:max-w-2xl {
    max-width: 42rem !important
  }

  .md\:max-w-3xl {
    max-width: 48rem !important
  }

  .md\:max-w-4xl {
    max-width: 56rem !important
  }

  .md\:max-w-5xl {
    max-width: 64rem !important
  }

  .md\:max-w-6xl {
    max-width: 72rem !important
  }

  .md\:max-w-full {
    max-width: 100% !important
  }

  .md\:max-w-screen-sm {
    max-width: 640px !important
  }

  .md\:max-w-screen-md {
    max-width: 768px !important
  }

  .md\:max-w-screen-lg {
    max-width: 1024px !important
  }

  .md\:max-w-screen-xl {
    max-width: 1280px !important
  }

  .md\:min-h-0 {
    min-height: 0 !important
  }

  .md\:min-h-full {
    min-height: 100% !important
  }

  .md\:min-h-screen {
    min-height: 100vh !important
  }

  .md\:min-w-0 {
    min-width: 0 !important
  }

  .md\:min-w-full {
    min-width: 100% !important
  }

  .md\:overflow-auto {
    overflow: auto !important
  }

  .md\:overflow-hidden {
    overflow: hidden !important
  }

  .md\:overflow-visible {
    overflow: visible !important
  }

  .md\:overflow-scroll {
    overflow: scroll !important
  }

  .md\:overflow-x-auto {
    overflow-x: auto !important
  }

  .md\:overflow-y-auto {
    overflow-y: auto !important
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .md\:static {
    position: static !important
  }

  .md\:fixed {
    position: fixed !important
  }

  .md\:absolute {
    position: absolute !important
  }

  .md\:relative {
    position: relative !important
  }

  .md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important
  }

  .md\:z-0 {
    z-index: 0 !important
  }

  .md\:z-10 {
    z-index: 10 !important
  }

  .md\:z-20 {
    z-index: 20 !important
  }

  .md\:z-30 {
    z-index: 30 !important
  }

  .md\:z-40 {
    z-index: 40 !important
  }

  .md\:z-50 {
    z-index: 50 !important
  }

  .md\:z-auto {
    z-index: auto !important
  }

  .md\:block {
    display: block !important
  }

  .md\:inline-block {
    display: inline-block !important
  }

  .md\:inline {
    display: inline !important
  }

  .md\:flex {
    display: flex !important
  }

  .md\:inline-flex {
    display: inline-flex !important
  }

  .md\:table {
    display: table !important
  }

  .md\:table-caption {
    display: table-caption !important
  }

  .md\:table-cell {
    display: table-cell !important
  }

  .md\:table-column {
    display: table-column !important
  }

  .md\:table-column-group {
    display: table-column-group !important
  }

  .md\:table-footer-group {
    display: table-footer-group !important
  }

  .md\:table-header-group {
    display: table-header-group !important
  }

  .md\:table-row-group {
    display: table-row-group !important
  }

  .md\:table-row {
    display: table-row !important
  }

  .md\:flow-root {
    display: flow-root !important
  }

  .md\:grid {
    display: grid !important
  }

  .md\:inline-grid {
    display: inline-grid !important
  }

  .md\:contents {
    display: contents !important
  }

  .md\:hidden {
    display: none !important
  }

  .md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important
  }

  .md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important
  }

  .md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important
  }

  .md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important
  }

  .md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important
  }

  .md\:inset-x-auto {
    right: auto !important;
    left: auto !important
  }

  .md\:top-0 {
    top: 0 !important
  }

  .md\:right-0 {
    right: 0 !important
  }

  .md\:bottom-0 {
    bottom: 0 !important
  }

  .md\:left-0 {
    left: 0 !important
  }

  .md\:top-auto {
    top: auto !important
  }

  .md\:right-auto {
    right: auto !important
  }

  .md\:bottom-auto {
    bottom: auto !important
  }

  .md\:left-auto {
    left: auto !important
  }
}

@media (min-width: 1024px) {
  .lg\:m-0 {
    margin: 0 !important
  }

  .lg\:m-4 {
    margin: 4px !important
  }

  .lg\:m-6 {
    margin: 6px !important
  }

  .lg\:m-8 {
    margin: 8px !important
  }

  .lg\:m-12 {
    margin: 12px !important
  }

  .lg\:m-16 {
    margin: 16px !important
  }

  .lg\:m-20 {
    margin: 20px !important
  }

  .lg\:m-24 {
    margin: 24px !important
  }

  .lg\:m-28 {
    margin: 28px !important
  }

  .lg\:m-32 {
    margin: 32px !important
  }

  .lg\:m-36 {
    margin: 36px !important
  }

  .lg\:m-40 {
    margin: 40px !important
  }

  .lg\:m-44 {
    margin: 44px !important
  }

  .lg\:m-48 {
    margin: 48px !important
  }

  .lg\:m-52 {
    margin: 52px !important
  }

  .lg\:m-56 {
    margin: 56px !important
  }

  .lg\:m-60 {
    margin: 60px !important
  }

  .lg\:m-64 {
    margin: 64px !important
  }

  .lg\:m-68 {
    margin: 68px !important
  }

  .lg\:m-72 {
    margin: 72px !important
  }

  .lg\:m-76 {
    margin: 76px !important
  }

  .lg\:m-80 {
    margin: 80px !important
  }

  .lg\:m-84 {
    margin: 84px !important
  }

  .lg\:m-88 {
    margin: 88px !important
  }

  .lg\:m-92 {
    margin: 92px !important
  }

  .lg\:m-96 {
    margin: 96px !important
  }

  .lg\:m-128 {
    margin: 128px !important
  }

  .lg\:m-160 {
    margin: 160px !important
  }

  .lg\:m-192 {
    margin: 192px !important
  }

  .lg\:m-224 {
    margin: 224px !important
  }

  .lg\:m-256 {
    margin: 256px !important
  }

  .lg\:m-320 {
    margin: 320px !important
  }

  .lg\:m-auto {
    margin: auto !important
  }

  .lg\:m-px {
    margin: 1px !important
  }

  .lg\:-m-4 {
    margin: -4px !important
  }

  .lg\:-m-6 {
    margin: -6px !important
  }

  .lg\:-m-8 {
    margin: -8px !important
  }

  .lg\:-m-12 {
    margin: -12px !important
  }

  .lg\:-m-16 {
    margin: -16px !important
  }

  .lg\:-m-20 {
    margin: -20px !important
  }

  .lg\:-m-24 {
    margin: -24px !important
  }

  .lg\:-m-28 {
    margin: -28px !important
  }

  .lg\:-m-32 {
    margin: -32px !important
  }

  .lg\:-m-36 {
    margin: -36px !important
  }

  .lg\:-m-40 {
    margin: -40px !important
  }

  .lg\:-m-44 {
    margin: -44px !important
  }

  .lg\:-m-48 {
    margin: -48px !important
  }

  .lg\:-m-52 {
    margin: -52px !important
  }

  .lg\:-m-56 {
    margin: -56px !important
  }

  .lg\:-m-60 {
    margin: -60px !important
  }

  .lg\:-m-64 {
    margin: -64px !important
  }

  .lg\:-m-68 {
    margin: -68px !important
  }

  .lg\:-m-72 {
    margin: -72px !important
  }

  .lg\:-m-76 {
    margin: -76px !important
  }

  .lg\:-m-80 {
    margin: -80px !important
  }

  .lg\:-m-84 {
    margin: -84px !important
  }

  .lg\:-m-88 {
    margin: -88px !important
  }

  .lg\:-m-92 {
    margin: -92px !important
  }

  .lg\:-m-96 {
    margin: -96px !important
  }

  .lg\:-m-128 {
    margin: -128px !important
  }

  .lg\:-m-160 {
    margin: -160px !important
  }

  .lg\:-m-192 {
    margin: -192px !important
  }

  .lg\:-m-224 {
    margin: -224px !important
  }

  .lg\:-m-256 {
    margin: -256px !important
  }

  .lg\:-m-320 {
    margin: -320px !important
  }

  .lg\:-m-px {
    margin: -1px !important
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .lg\:my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important
  }

  .lg\:mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important
  }

  .lg\:my-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important
  }

  .lg\:mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .lg\:my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important
  }

  .lg\:mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .lg\:my-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important
  }

  .lg\:mx-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .lg\:my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important
  }

  .lg\:mx-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .lg\:my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
  }

  .lg\:mx-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .lg\:my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important
  }

  .lg\:mx-24 {
    margin-left: 24px !important;
    margin-right: 24px !important
  }

  .lg\:my-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important
  }

  .lg\:mx-28 {
    margin-left: 28px !important;
    margin-right: 28px !important
  }

  .lg\:my-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important
  }

  .lg\:mx-32 {
    margin-left: 32px !important;
    margin-right: 32px !important
  }

  .lg\:my-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important
  }

  .lg\:mx-36 {
    margin-left: 36px !important;
    margin-right: 36px !important
  }

  .lg\:my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important
  }

  .lg\:mx-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .lg\:my-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important
  }

  .lg\:mx-44 {
    margin-left: 44px !important;
    margin-right: 44px !important
  }

  .lg\:my-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important
  }

  .lg\:mx-48 {
    margin-left: 48px !important;
    margin-right: 48px !important
  }

  .lg\:my-52 {
    margin-top: 52px !important;
    margin-bottom: 52px !important
  }

  .lg\:mx-52 {
    margin-left: 52px !important;
    margin-right: 52px !important
  }

  .lg\:my-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important
  }

  .lg\:mx-56 {
    margin-left: 56px !important;
    margin-right: 56px !important
  }

  .lg\:my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important
  }

  .lg\:mx-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .lg\:my-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important
  }

  .lg\:mx-64 {
    margin-left: 64px !important;
    margin-right: 64px !important
  }

  .lg\:my-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important
  }

  .lg\:mx-68 {
    margin-left: 68px !important;
    margin-right: 68px !important
  }

  .lg\:my-72 {
    margin-top: 72px !important;
    margin-bottom: 72px !important
  }

  .lg\:mx-72 {
    margin-left: 72px !important;
    margin-right: 72px !important
  }

  .lg\:my-76 {
    margin-top: 76px !important;
    margin-bottom: 76px !important
  }

  .lg\:mx-76 {
    margin-left: 76px !important;
    margin-right: 76px !important
  }

  .lg\:my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important
  }

  .lg\:mx-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .lg\:my-84 {
    margin-top: 84px !important;
    margin-bottom: 84px !important
  }

  .lg\:mx-84 {
    margin-left: 84px !important;
    margin-right: 84px !important
  }

  .lg\:my-88 {
    margin-top: 88px !important;
    margin-bottom: 88px !important
  }

  .lg\:mx-88 {
    margin-left: 88px !important;
    margin-right: 88px !important
  }

  .lg\:my-92 {
    margin-top: 92px !important;
    margin-bottom: 92px !important
  }

  .lg\:mx-92 {
    margin-left: 92px !important;
    margin-right: 92px !important
  }

  .lg\:my-96 {
    margin-top: 96px !important;
    margin-bottom: 96px !important
  }

  .lg\:mx-96 {
    margin-left: 96px !important;
    margin-right: 96px !important
  }

  .lg\:my-128 {
    margin-top: 128px !important;
    margin-bottom: 128px !important
  }

  .lg\:mx-128 {
    margin-left: 128px !important;
    margin-right: 128px !important
  }

  .lg\:my-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important
  }

  .lg\:mx-160 {
    margin-left: 160px !important;
    margin-right: 160px !important
  }

  .lg\:my-192 {
    margin-top: 192px !important;
    margin-bottom: 192px !important
  }

  .lg\:mx-192 {
    margin-left: 192px !important;
    margin-right: 192px !important
  }

  .lg\:my-224 {
    margin-top: 224px !important;
    margin-bottom: 224px !important
  }

  .lg\:mx-224 {
    margin-left: 224px !important;
    margin-right: 224px !important
  }

  .lg\:my-256 {
    margin-top: 256px !important;
    margin-bottom: 256px !important
  }

  .lg\:mx-256 {
    margin-left: 256px !important;
    margin-right: 256px !important
  }

  .lg\:my-320 {
    margin-top: 320px !important;
    margin-bottom: 320px !important
  }

  .lg\:mx-320 {
    margin-left: 320px !important;
    margin-right: 320px !important
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .lg\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important
  }

  .lg\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
  }

  .lg\:-my-4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important
  }

  .lg\:-mx-4 {
    margin-left: -4px !important;
    margin-right: -4px !important
  }

  .lg\:-my-6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important
  }

  .lg\:-mx-6 {
    margin-left: -6px !important;
    margin-right: -6px !important
  }

  .lg\:-my-8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important
  }

  .lg\:-mx-8 {
    margin-left: -8px !important;
    margin-right: -8px !important
  }

  .lg\:-my-12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important
  }

  .lg\:-mx-12 {
    margin-left: -12px !important;
    margin-right: -12px !important
  }

  .lg\:-my-16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important
  }

  .lg\:-mx-16 {
    margin-left: -16px !important;
    margin-right: -16px !important
  }

  .lg\:-my-20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important
  }

  .lg\:-mx-20 {
    margin-left: -20px !important;
    margin-right: -20px !important
  }

  .lg\:-my-24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important
  }

  .lg\:-mx-24 {
    margin-left: -24px !important;
    margin-right: -24px !important
  }

  .lg\:-my-28 {
    margin-top: -28px !important;
    margin-bottom: -28px !important
  }

  .lg\:-mx-28 {
    margin-left: -28px !important;
    margin-right: -28px !important
  }

  .lg\:-my-32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important
  }

  .lg\:-mx-32 {
    margin-left: -32px !important;
    margin-right: -32px !important
  }

  .lg\:-my-36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important
  }

  .lg\:-mx-36 {
    margin-left: -36px !important;
    margin-right: -36px !important
  }

  .lg\:-my-40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important
  }

  .lg\:-mx-40 {
    margin-left: -40px !important;
    margin-right: -40px !important
  }

  .lg\:-my-44 {
    margin-top: -44px !important;
    margin-bottom: -44px !important
  }

  .lg\:-mx-44 {
    margin-left: -44px !important;
    margin-right: -44px !important
  }

  .lg\:-my-48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important
  }

  .lg\:-mx-48 {
    margin-left: -48px !important;
    margin-right: -48px !important
  }

  .lg\:-my-52 {
    margin-top: -52px !important;
    margin-bottom: -52px !important
  }

  .lg\:-mx-52 {
    margin-left: -52px !important;
    margin-right: -52px !important
  }

  .lg\:-my-56 {
    margin-top: -56px !important;
    margin-bottom: -56px !important
  }

  .lg\:-mx-56 {
    margin-left: -56px !important;
    margin-right: -56px !important
  }

  .lg\:-my-60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important
  }

  .lg\:-mx-60 {
    margin-left: -60px !important;
    margin-right: -60px !important
  }

  .lg\:-my-64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important
  }

  .lg\:-mx-64 {
    margin-left: -64px !important;
    margin-right: -64px !important
  }

  .lg\:-my-68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important
  }

  .lg\:-mx-68 {
    margin-left: -68px !important;
    margin-right: -68px !important
  }

  .lg\:-my-72 {
    margin-top: -72px !important;
    margin-bottom: -72px !important
  }

  .lg\:-mx-72 {
    margin-left: -72px !important;
    margin-right: -72px !important
  }

  .lg\:-my-76 {
    margin-top: -76px !important;
    margin-bottom: -76px !important
  }

  .lg\:-mx-76 {
    margin-left: -76px !important;
    margin-right: -76px !important
  }

  .lg\:-my-80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important
  }

  .lg\:-mx-80 {
    margin-left: -80px !important;
    margin-right: -80px !important
  }

  .lg\:-my-84 {
    margin-top: -84px !important;
    margin-bottom: -84px !important
  }

  .lg\:-mx-84 {
    margin-left: -84px !important;
    margin-right: -84px !important
  }

  .lg\:-my-88 {
    margin-top: -88px !important;
    margin-bottom: -88px !important
  }

  .lg\:-mx-88 {
    margin-left: -88px !important;
    margin-right: -88px !important
  }

  .lg\:-my-92 {
    margin-top: -92px !important;
    margin-bottom: -92px !important
  }

  .lg\:-mx-92 {
    margin-left: -92px !important;
    margin-right: -92px !important
  }

  .lg\:-my-96 {
    margin-top: -96px !important;
    margin-bottom: -96px !important
  }

  .lg\:-mx-96 {
    margin-left: -96px !important;
    margin-right: -96px !important
  }

  .lg\:-my-128 {
    margin-top: -128px !important;
    margin-bottom: -128px !important
  }

  .lg\:-mx-128 {
    margin-left: -128px !important;
    margin-right: -128px !important
  }

  .lg\:-my-160 {
    margin-top: -160px !important;
    margin-bottom: -160px !important
  }

  .lg\:-mx-160 {
    margin-left: -160px !important;
    margin-right: -160px !important
  }

  .lg\:-my-192 {
    margin-top: -192px !important;
    margin-bottom: -192px !important
  }

  .lg\:-mx-192 {
    margin-left: -192px !important;
    margin-right: -192px !important
  }

  .lg\:-my-224 {
    margin-top: -224px !important;
    margin-bottom: -224px !important
  }

  .lg\:-mx-224 {
    margin-left: -224px !important;
    margin-right: -224px !important
  }

  .lg\:-my-256 {
    margin-top: -256px !important;
    margin-bottom: -256px !important
  }

  .lg\:-mx-256 {
    margin-left: -256px !important;
    margin-right: -256px !important
  }

  .lg\:-my-320 {
    margin-top: -320px !important;
    margin-bottom: -320px !important
  }

  .lg\:-mx-320 {
    margin-left: -320px !important;
    margin-right: -320px !important
  }

  .lg\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important
  }

  .lg\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important
  }

  .lg\:mt-0 {
    margin-top: 0 !important
  }

  .lg\:mr-0 {
    margin-right: 0 !important
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important
  }

  .lg\:ml-0 {
    margin-left: 0 !important
  }

  .lg\:mt-4 {
    margin-top: 4px !important
  }

  .lg\:mr-4 {
    margin-right: 4px !important
  }

  .lg\:mb-4 {
    margin-bottom: 4px !important
  }

  .lg\:ml-4 {
    margin-left: 4px !important
  }

  .lg\:mt-6 {
    margin-top: 6px !important
  }

  .lg\:mr-6 {
    margin-right: 6px !important
  }

  .lg\:mb-6 {
    margin-bottom: 6px !important
  }

  .lg\:ml-6 {
    margin-left: 6px !important
  }

  .lg\:mt-8 {
    margin-top: 8px !important
  }

  .lg\:mr-8 {
    margin-right: 8px !important
  }

  .lg\:mb-8 {
    margin-bottom: 8px !important
  }

  .lg\:ml-8 {
    margin-left: 8px !important
  }

  .lg\:mt-12 {
    margin-top: 12px !important
  }

  .lg\:mr-12 {
    margin-right: 12px !important
  }

  .lg\:mb-12 {
    margin-bottom: 12px !important
  }

  .lg\:ml-12 {
    margin-left: 12px !important
  }

  .lg\:mt-16 {
    margin-top: 16px !important
  }

  .lg\:mr-16 {
    margin-right: 16px !important
  }

  .lg\:mb-16 {
    margin-bottom: 16px !important
  }

  .lg\:ml-16 {
    margin-left: 16px !important
  }

  .lg\:mt-20 {
    margin-top: 20px !important
  }

  .lg\:mr-20 {
    margin-right: 20px !important
  }

  .lg\:mb-20 {
    margin-bottom: 20px !important
  }

  .lg\:ml-20 {
    margin-left: 20px !important
  }

  .lg\:mt-24 {
    margin-top: 24px !important
  }

  .lg\:mr-24 {
    margin-right: 24px !important
  }

  .lg\:mb-24 {
    margin-bottom: 24px !important
  }

  .lg\:ml-24 {
    margin-left: 24px !important
  }

  .lg\:mt-28 {
    margin-top: 28px !important
  }

  .lg\:mr-28 {
    margin-right: 28px !important
  }

  .lg\:mb-28 {
    margin-bottom: 28px !important
  }

  .lg\:ml-28 {
    margin-left: 28px !important
  }

  .lg\:mt-32 {
    margin-top: 32px !important
  }

  .lg\:mr-32 {
    margin-right: 32px !important
  }

  .lg\:mb-32 {
    margin-bottom: 32px !important
  }

  .lg\:ml-32 {
    margin-left: 32px !important
  }

  .lg\:mt-36 {
    margin-top: 36px !important
  }

  .lg\:mr-36 {
    margin-right: 36px !important
  }

  .lg\:mb-36 {
    margin-bottom: 36px !important
  }

  .lg\:ml-36 {
    margin-left: 36px !important
  }

  .lg\:mt-40 {
    margin-top: 40px !important
  }

  .lg\:mr-40 {
    margin-right: 40px !important
  }

  .lg\:mb-40 {
    margin-bottom: 40px !important
  }

  .lg\:ml-40 {
    margin-left: 40px !important
  }

  .lg\:mt-44 {
    margin-top: 44px !important
  }

  .lg\:mr-44 {
    margin-right: 44px !important
  }

  .lg\:mb-44 {
    margin-bottom: 44px !important
  }

  .lg\:ml-44 {
    margin-left: 44px !important
  }

  .lg\:mt-48 {
    margin-top: 48px !important
  }

  .lg\:mr-48 {
    margin-right: 48px !important
  }

  .lg\:mb-48 {
    margin-bottom: 48px !important
  }

  .lg\:ml-48 {
    margin-left: 48px !important
  }

  .lg\:mt-52 {
    margin-top: 52px !important
  }

  .lg\:mr-52 {
    margin-right: 52px !important
  }

  .lg\:mb-52 {
    margin-bottom: 52px !important
  }

  .lg\:ml-52 {
    margin-left: 52px !important
  }

  .lg\:mt-56 {
    margin-top: 56px !important
  }

  .lg\:mr-56 {
    margin-right: 56px !important
  }

  .lg\:mb-56 {
    margin-bottom: 56px !important
  }

  .lg\:ml-56 {
    margin-left: 56px !important
  }

  .lg\:mt-60 {
    margin-top: 60px !important
  }

  .lg\:mr-60 {
    margin-right: 60px !important
  }

  .lg\:mb-60 {
    margin-bottom: 60px !important
  }

  .lg\:ml-60 {
    margin-left: 60px !important
  }

  .lg\:mt-64 {
    margin-top: 64px !important
  }

  .lg\:mr-64 {
    margin-right: 64px !important
  }

  .lg\:mb-64 {
    margin-bottom: 64px !important
  }

  .lg\:ml-64 {
    margin-left: 64px !important
  }

  .lg\:mt-68 {
    margin-top: 68px !important
  }

  .lg\:mr-68 {
    margin-right: 68px !important
  }

  .lg\:mb-68 {
    margin-bottom: 68px !important
  }

  .lg\:ml-68 {
    margin-left: 68px !important
  }

  .lg\:mt-72 {
    margin-top: 72px !important
  }

  .lg\:mr-72 {
    margin-right: 72px !important
  }

  .lg\:mb-72 {
    margin-bottom: 72px !important
  }

  .lg\:ml-72 {
    margin-left: 72px !important
  }

  .lg\:mt-76 {
    margin-top: 76px !important
  }

  .lg\:mr-76 {
    margin-right: 76px !important
  }

  .lg\:mb-76 {
    margin-bottom: 76px !important
  }

  .lg\:ml-76 {
    margin-left: 76px !important
  }

  .lg\:mt-80 {
    margin-top: 80px !important
  }

  .lg\:mr-80 {
    margin-right: 80px !important
  }

  .lg\:mb-80 {
    margin-bottom: 80px !important
  }

  .lg\:ml-80 {
    margin-left: 80px !important
  }

  .lg\:mt-84 {
    margin-top: 84px !important
  }

  .lg\:mr-84 {
    margin-right: 84px !important
  }

  .lg\:mb-84 {
    margin-bottom: 84px !important
  }

  .lg\:ml-84 {
    margin-left: 84px !important
  }

  .lg\:mt-88 {
    margin-top: 88px !important
  }

  .lg\:mr-88 {
    margin-right: 88px !important
  }

  .lg\:mb-88 {
    margin-bottom: 88px !important
  }

  .lg\:ml-88 {
    margin-left: 88px !important
  }

  .lg\:mt-92 {
    margin-top: 92px !important
  }

  .lg\:mr-92 {
    margin-right: 92px !important
  }

  .lg\:mb-92 {
    margin-bottom: 92px !important
  }

  .lg\:ml-92 {
    margin-left: 92px !important
  }

  .lg\:mt-96 {
    margin-top: 96px !important
  }

  .lg\:mr-96 {
    margin-right: 96px !important
  }

  .lg\:mb-96 {
    margin-bottom: 96px !important
  }

  .lg\:ml-96 {
    margin-left: 96px !important
  }

  .lg\:mt-128 {
    margin-top: 128px !important
  }

  .lg\:mr-128 {
    margin-right: 128px !important
  }

  .lg\:mb-128 {
    margin-bottom: 128px !important
  }

  .lg\:ml-128 {
    margin-left: 128px !important
  }

  .lg\:mt-160 {
    margin-top: 160px !important
  }

  .lg\:mr-160 {
    margin-right: 160px !important
  }

  .lg\:mb-160 {
    margin-bottom: 160px !important
  }

  .lg\:ml-160 {
    margin-left: 160px !important
  }

  .lg\:mt-192 {
    margin-top: 192px !important
  }

  .lg\:mr-192 {
    margin-right: 192px !important
  }

  .lg\:mb-192 {
    margin-bottom: 192px !important
  }

  .lg\:ml-192 {
    margin-left: 192px !important
  }

  .lg\:mt-224 {
    margin-top: 224px !important
  }

  .lg\:mr-224 {
    margin-right: 224px !important
  }

  .lg\:mb-224 {
    margin-bottom: 224px !important
  }

  .lg\:ml-224 {
    margin-left: 224px !important
  }

  .lg\:mt-256 {
    margin-top: 256px !important
  }

  .lg\:mr-256 {
    margin-right: 256px !important
  }

  .lg\:mb-256 {
    margin-bottom: 256px !important
  }

  .lg\:ml-256 {
    margin-left: 256px !important
  }

  .lg\:mt-320 {
    margin-top: 320px !important
  }

  .lg\:mr-320 {
    margin-right: 320px !important
  }

  .lg\:mb-320 {
    margin-bottom: 320px !important
  }

  .lg\:ml-320 {
    margin-left: 320px !important
  }

  .lg\:mt-auto {
    margin-top: auto !important
  }

  .lg\:mr-auto {
    margin-right: auto !important
  }

  .lg\:mb-auto {
    margin-bottom: auto !important
  }

  .lg\:ml-auto {
    margin-left: auto !important
  }

  .lg\:mt-px {
    margin-top: 1px !important
  }

  .lg\:mr-px {
    margin-right: 1px !important
  }

  .lg\:mb-px {
    margin-bottom: 1px !important
  }

  .lg\:ml-px {
    margin-left: 1px !important
  }

  .lg\:-mt-4 {
    margin-top: -4px !important
  }

  .lg\:-mr-4 {
    margin-right: -4px !important
  }

  .lg\:-mb-4 {
    margin-bottom: -4px !important
  }

  .lg\:-ml-4 {
    margin-left: -4px !important
  }

  .lg\:-mt-6 {
    margin-top: -6px !important
  }

  .lg\:-mr-6 {
    margin-right: -6px !important
  }

  .lg\:-mb-6 {
    margin-bottom: -6px !important
  }

  .lg\:-ml-6 {
    margin-left: -6px !important
  }

  .lg\:-mt-8 {
    margin-top: -8px !important
  }

  .lg\:-mr-8 {
    margin-right: -8px !important
  }

  .lg\:-mb-8 {
    margin-bottom: -8px !important
  }

  .lg\:-ml-8 {
    margin-left: -8px !important
  }

  .lg\:-mt-12 {
    margin-top: -12px !important
  }

  .lg\:-mr-12 {
    margin-right: -12px !important
  }

  .lg\:-mb-12 {
    margin-bottom: -12px !important
  }

  .lg\:-ml-12 {
    margin-left: -12px !important
  }

  .lg\:-mt-16 {
    margin-top: -16px !important
  }

  .lg\:-mr-16 {
    margin-right: -16px !important
  }

  .lg\:-mb-16 {
    margin-bottom: -16px !important
  }

  .lg\:-ml-16 {
    margin-left: -16px !important
  }

  .lg\:-mt-20 {
    margin-top: -20px !important
  }

  .lg\:-mr-20 {
    margin-right: -20px !important
  }

  .lg\:-mb-20 {
    margin-bottom: -20px !important
  }

  .lg\:-ml-20 {
    margin-left: -20px !important
  }

  .lg\:-mt-24 {
    margin-top: -24px !important
  }

  .lg\:-mr-24 {
    margin-right: -24px !important
  }

  .lg\:-mb-24 {
    margin-bottom: -24px !important
  }

  .lg\:-ml-24 {
    margin-left: -24px !important
  }

  .lg\:-mt-28 {
    margin-top: -28px !important
  }

  .lg\:-mr-28 {
    margin-right: -28px !important
  }

  .lg\:-mb-28 {
    margin-bottom: -28px !important
  }

  .lg\:-ml-28 {
    margin-left: -28px !important
  }

  .lg\:-mt-32 {
    margin-top: -32px !important
  }

  .lg\:-mr-32 {
    margin-right: -32px !important
  }

  .lg\:-mb-32 {
    margin-bottom: -32px !important
  }

  .lg\:-ml-32 {
    margin-left: -32px !important
  }

  .lg\:-mt-36 {
    margin-top: -36px !important
  }

  .lg\:-mr-36 {
    margin-right: -36px !important
  }

  .lg\:-mb-36 {
    margin-bottom: -36px !important
  }

  .lg\:-ml-36 {
    margin-left: -36px !important
  }

  .lg\:-mt-40 {
    margin-top: -40px !important
  }

  .lg\:-mr-40 {
    margin-right: -40px !important
  }

  .lg\:-mb-40 {
    margin-bottom: -40px !important
  }

  .lg\:-ml-40 {
    margin-left: -40px !important
  }

  .lg\:-mt-44 {
    margin-top: -44px !important
  }

  .lg\:-mr-44 {
    margin-right: -44px !important
  }

  .lg\:-mb-44 {
    margin-bottom: -44px !important
  }

  .lg\:-ml-44 {
    margin-left: -44px !important
  }

  .lg\:-mt-48 {
    margin-top: -48px !important
  }

  .lg\:-mr-48 {
    margin-right: -48px !important
  }

  .lg\:-mb-48 {
    margin-bottom: -48px !important
  }

  .lg\:-ml-48 {
    margin-left: -48px !important
  }

  .lg\:-mt-52 {
    margin-top: -52px !important
  }

  .lg\:-mr-52 {
    margin-right: -52px !important
  }

  .lg\:-mb-52 {
    margin-bottom: -52px !important
  }

  .lg\:-ml-52 {
    margin-left: -52px !important
  }

  .lg\:-mt-56 {
    margin-top: -56px !important
  }

  .lg\:-mr-56 {
    margin-right: -56px !important
  }

  .lg\:-mb-56 {
    margin-bottom: -56px !important
  }

  .lg\:-ml-56 {
    margin-left: -56px !important
  }

  .lg\:-mt-60 {
    margin-top: -60px !important
  }

  .lg\:-mr-60 {
    margin-right: -60px !important
  }

  .lg\:-mb-60 {
    margin-bottom: -60px !important
  }

  .lg\:-ml-60 {
    margin-left: -60px !important
  }

  .lg\:-mt-64 {
    margin-top: -64px !important
  }

  .lg\:-mr-64 {
    margin-right: -64px !important
  }

  .lg\:-mb-64 {
    margin-bottom: -64px !important
  }

  .lg\:-ml-64 {
    margin-left: -64px !important
  }

  .lg\:-mt-68 {
    margin-top: -68px !important
  }

  .lg\:-mr-68 {
    margin-right: -68px !important
  }

  .lg\:-mb-68 {
    margin-bottom: -68px !important
  }

  .lg\:-ml-68 {
    margin-left: -68px !important
  }

  .lg\:-mt-72 {
    margin-top: -72px !important
  }

  .lg\:-mr-72 {
    margin-right: -72px !important
  }

  .lg\:-mb-72 {
    margin-bottom: -72px !important
  }

  .lg\:-ml-72 {
    margin-left: -72px !important
  }

  .lg\:-mt-76 {
    margin-top: -76px !important
  }

  .lg\:-mr-76 {
    margin-right: -76px !important
  }

  .lg\:-mb-76 {
    margin-bottom: -76px !important
  }

  .lg\:-ml-76 {
    margin-left: -76px !important
  }

  .lg\:-mt-80 {
    margin-top: -80px !important
  }

  .lg\:-mr-80 {
    margin-right: -80px !important
  }

  .lg\:-mb-80 {
    margin-bottom: -80px !important
  }

  .lg\:-ml-80 {
    margin-left: -80px !important
  }

  .lg\:-mt-84 {
    margin-top: -84px !important
  }

  .lg\:-mr-84 {
    margin-right: -84px !important
  }

  .lg\:-mb-84 {
    margin-bottom: -84px !important
  }

  .lg\:-ml-84 {
    margin-left: -84px !important
  }

  .lg\:-mt-88 {
    margin-top: -88px !important
  }

  .lg\:-mr-88 {
    margin-right: -88px !important
  }

  .lg\:-mb-88 {
    margin-bottom: -88px !important
  }

  .lg\:-ml-88 {
    margin-left: -88px !important
  }

  .lg\:-mt-92 {
    margin-top: -92px !important
  }

  .lg\:-mr-92 {
    margin-right: -92px !important
  }

  .lg\:-mb-92 {
    margin-bottom: -92px !important
  }

  .lg\:-ml-92 {
    margin-left: -92px !important
  }

  .lg\:-mt-96 {
    margin-top: -96px !important
  }

  .lg\:-mr-96 {
    margin-right: -96px !important
  }

  .lg\:-mb-96 {
    margin-bottom: -96px !important
  }

  .lg\:-ml-96 {
    margin-left: -96px !important
  }

  .lg\:-mt-128 {
    margin-top: -128px !important
  }

  .lg\:-mr-128 {
    margin-right: -128px !important
  }

  .lg\:-mb-128 {
    margin-bottom: -128px !important
  }

  .lg\:-ml-128 {
    margin-left: -128px !important
  }

  .lg\:-mt-160 {
    margin-top: -160px !important
  }

  .lg\:-mr-160 {
    margin-right: -160px !important
  }

  .lg\:-mb-160 {
    margin-bottom: -160px !important
  }

  .lg\:-ml-160 {
    margin-left: -160px !important
  }

  .lg\:-mt-192 {
    margin-top: -192px !important
  }

  .lg\:-mr-192 {
    margin-right: -192px !important
  }

  .lg\:-mb-192 {
    margin-bottom: -192px !important
  }

  .lg\:-ml-192 {
    margin-left: -192px !important
  }

  .lg\:-mt-224 {
    margin-top: -224px !important
  }

  .lg\:-mr-224 {
    margin-right: -224px !important
  }

  .lg\:-mb-224 {
    margin-bottom: -224px !important
  }

  .lg\:-ml-224 {
    margin-left: -224px !important
  }

  .lg\:-mt-256 {
    margin-top: -256px !important
  }

  .lg\:-mr-256 {
    margin-right: -256px !important
  }

  .lg\:-mb-256 {
    margin-bottom: -256px !important
  }

  .lg\:-ml-256 {
    margin-left: -256px !important
  }

  .lg\:-mt-320 {
    margin-top: -320px !important
  }

  .lg\:-mr-320 {
    margin-right: -320px !important
  }

  .lg\:-mb-320 {
    margin-bottom: -320px !important
  }

  .lg\:-ml-320 {
    margin-left: -320px !important
  }

  .lg\:-mt-px {
    margin-top: -1px !important
  }

  .lg\:-mr-px {
    margin-right: -1px !important
  }

  .lg\:-mb-px {
    margin-bottom: -1px !important
  }

  .lg\:-ml-px {
    margin-left: -1px !important
  }

  .lg\:p-0 {
    padding: 0 !important
  }

  .lg\:p-4 {
    padding: 4px !important
  }

  .lg\:p-6 {
    padding: 6px !important
  }

  .lg\:p-8 {
    padding: 8px !important
  }

  .lg\:p-12 {
    padding: 12px !important
  }

  .lg\:p-16 {
    padding: 16px !important
  }

  .lg\:p-20 {
    padding: 20px !important
  }

  .lg\:p-24 {
    padding: 24px !important
  }

  .lg\:p-28 {
    padding: 28px !important
  }

  .lg\:p-32 {
    padding: 32px !important
  }

  .lg\:p-36 {
    padding: 36px !important
  }

  .lg\:p-40 {
    padding: 40px !important
  }

  .lg\:p-44 {
    padding: 44px !important
  }

  .lg\:p-48 {
    padding: 48px !important
  }

  .lg\:p-52 {
    padding: 52px !important
  }

  .lg\:p-56 {
    padding: 56px !important
  }

  .lg\:p-60 {
    padding: 60px !important
  }

  .lg\:p-64 {
    padding: 64px !important
  }

  .lg\:p-68 {
    padding: 68px !important
  }

  .lg\:p-72 {
    padding: 72px !important
  }

  .lg\:p-76 {
    padding: 76px !important
  }

  .lg\:p-80 {
    padding: 80px !important
  }

  .lg\:p-84 {
    padding: 84px !important
  }

  .lg\:p-88 {
    padding: 88px !important
  }

  .lg\:p-92 {
    padding: 92px !important
  }

  .lg\:p-96 {
    padding: 96px !important
  }

  .lg\:p-128 {
    padding: 128px !important
  }

  .lg\:p-160 {
    padding: 160px !important
  }

  .lg\:p-192 {
    padding: 192px !important
  }

  .lg\:p-224 {
    padding: 224px !important
  }

  .lg\:p-256 {
    padding: 256px !important
  }

  .lg\:p-320 {
    padding: 320px !important
  }

  .lg\:p-px {
    padding: 1px !important
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .lg\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important
  }

  .lg\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important
  }

  .lg\:py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important
  }

  .lg\:px-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .lg\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important
  }

  .lg\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .lg\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important
  }

  .lg\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .lg\:py-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
  }

  .lg\:px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .lg\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important
  }

  .lg\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .lg\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
  }

  .lg\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important
  }

  .lg\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important
  }

  .lg\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important
  }

  .lg\:py-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important
  }

  .lg\:px-32 {
    padding-left: 32px !important;
    padding-right: 32px !important
  }

  .lg\:py-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important
  }

  .lg\:px-36 {
    padding-left: 36px !important;
    padding-right: 36px !important
  }

  .lg\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important
  }

  .lg\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .lg\:py-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important
  }

  .lg\:px-44 {
    padding-left: 44px !important;
    padding-right: 44px !important
  }

  .lg\:py-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important
  }

  .lg\:px-48 {
    padding-left: 48px !important;
    padding-right: 48px !important
  }

  .lg\:py-52 {
    padding-top: 52px !important;
    padding-bottom: 52px !important
  }

  .lg\:px-52 {
    padding-left: 52px !important;
    padding-right: 52px !important
  }

  .lg\:py-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important
  }

  .lg\:px-56 {
    padding-left: 56px !important;
    padding-right: 56px !important
  }

  .lg\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important
  }

  .lg\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .lg\:py-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important
  }

  .lg\:px-64 {
    padding-left: 64px !important;
    padding-right: 64px !important
  }

  .lg\:py-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important
  }

  .lg\:px-68 {
    padding-left: 68px !important;
    padding-right: 68px !important
  }

  .lg\:py-72 {
    padding-top: 72px !important;
    padding-bottom: 72px !important
  }

  .lg\:px-72 {
    padding-left: 72px !important;
    padding-right: 72px !important
  }

  .lg\:py-76 {
    padding-top: 76px !important;
    padding-bottom: 76px !important
  }

  .lg\:px-76 {
    padding-left: 76px !important;
    padding-right: 76px !important
  }

  .lg\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important
  }

  .lg\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .lg\:py-84 {
    padding-top: 84px !important;
    padding-bottom: 84px !important
  }

  .lg\:px-84 {
    padding-left: 84px !important;
    padding-right: 84px !important
  }

  .lg\:py-88 {
    padding-top: 88px !important;
    padding-bottom: 88px !important
  }

  .lg\:px-88 {
    padding-left: 88px !important;
    padding-right: 88px !important
  }

  .lg\:py-92 {
    padding-top: 92px !important;
    padding-bottom: 92px !important
  }

  .lg\:px-92 {
    padding-left: 92px !important;
    padding-right: 92px !important
  }

  .lg\:py-96 {
    padding-top: 96px !important;
    padding-bottom: 96px !important
  }

  .lg\:px-96 {
    padding-left: 96px !important;
    padding-right: 96px !important
  }

  .lg\:py-128 {
    padding-top: 128px !important;
    padding-bottom: 128px !important
  }

  .lg\:px-128 {
    padding-left: 128px !important;
    padding-right: 128px !important
  }

  .lg\:py-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important
  }

  .lg\:px-160 {
    padding-left: 160px !important;
    padding-right: 160px !important
  }

  .lg\:py-192 {
    padding-top: 192px !important;
    padding-bottom: 192px !important
  }

  .lg\:px-192 {
    padding-left: 192px !important;
    padding-right: 192px !important
  }

  .lg\:py-224 {
    padding-top: 224px !important;
    padding-bottom: 224px !important
  }

  .lg\:px-224 {
    padding-left: 224px !important;
    padding-right: 224px !important
  }

  .lg\:py-256 {
    padding-top: 256px !important;
    padding-bottom: 256px !important
  }

  .lg\:px-256 {
    padding-left: 256px !important;
    padding-right: 256px !important
  }

  .lg\:py-320 {
    padding-top: 320px !important;
    padding-bottom: 320px !important
  }

  .lg\:px-320 {
    padding-left: 320px !important;
    padding-right: 320px !important
  }

  .lg\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important
  }

  .lg\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
  }

  .lg\:pt-0 {
    padding-top: 0 !important
  }

  .lg\:pr-0 {
    padding-right: 0 !important
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important
  }

  .lg\:pl-0 {
    padding-left: 0 !important
  }

  .lg\:pt-4 {
    padding-top: 4px !important
  }

  .lg\:pr-4 {
    padding-right: 4px !important
  }

  .lg\:pb-4 {
    padding-bottom: 4px !important
  }

  .lg\:pl-4 {
    padding-left: 4px !important
  }

  .lg\:pt-6 {
    padding-top: 6px !important
  }

  .lg\:pr-6 {
    padding-right: 6px !important
  }

  .lg\:pb-6 {
    padding-bottom: 6px !important
  }

  .lg\:pl-6 {
    padding-left: 6px !important
  }

  .lg\:pt-8 {
    padding-top: 8px !important
  }

  .lg\:pr-8 {
    padding-right: 8px !important
  }

  .lg\:pb-8 {
    padding-bottom: 8px !important
  }

  .lg\:pl-8 {
    padding-left: 8px !important
  }

  .lg\:pt-12 {
    padding-top: 12px !important
  }

  .lg\:pr-12 {
    padding-right: 12px !important
  }

  .lg\:pb-12 {
    padding-bottom: 12px !important
  }

  .lg\:pl-12 {
    padding-left: 12px !important
  }

  .lg\:pt-16 {
    padding-top: 16px !important
  }

  .lg\:pr-16 {
    padding-right: 16px !important
  }

  .lg\:pb-16 {
    padding-bottom: 16px !important
  }

  .lg\:pl-16 {
    padding-left: 16px !important
  }

  .lg\:pt-20 {
    padding-top: 20px !important
  }

  .lg\:pr-20 {
    padding-right: 20px !important
  }

  .lg\:pb-20 {
    padding-bottom: 20px !important
  }

  .lg\:pl-20 {
    padding-left: 20px !important
  }

  .lg\:pt-24 {
    padding-top: 24px !important
  }

  .lg\:pr-24 {
    padding-right: 24px !important
  }

  .lg\:pb-24 {
    padding-bottom: 24px !important
  }

  .lg\:pl-24 {
    padding-left: 24px !important
  }

  .lg\:pt-28 {
    padding-top: 28px !important
  }

  .lg\:pr-28 {
    padding-right: 28px !important
  }

  .lg\:pb-28 {
    padding-bottom: 28px !important
  }

  .lg\:pl-28 {
    padding-left: 28px !important
  }

  .lg\:pt-32 {
    padding-top: 32px !important
  }

  .lg\:pr-32 {
    padding-right: 32px !important
  }

  .lg\:pb-32 {
    padding-bottom: 32px !important
  }

  .lg\:pl-32 {
    padding-left: 32px !important
  }

  .lg\:pt-36 {
    padding-top: 36px !important
  }

  .lg\:pr-36 {
    padding-right: 36px !important
  }

  .lg\:pb-36 {
    padding-bottom: 36px !important
  }

  .lg\:pl-36 {
    padding-left: 36px !important
  }

  .lg\:pt-40 {
    padding-top: 40px !important
  }

  .lg\:pr-40 {
    padding-right: 40px !important
  }

  .lg\:pb-40 {
    padding-bottom: 40px !important
  }

  .lg\:pl-40 {
    padding-left: 40px !important
  }

  .lg\:pt-44 {
    padding-top: 44px !important
  }

  .lg\:pr-44 {
    padding-right: 44px !important
  }

  .lg\:pb-44 {
    padding-bottom: 44px !important
  }

  .lg\:pl-44 {
    padding-left: 44px !important
  }

  .lg\:pt-48 {
    padding-top: 48px !important
  }

  .lg\:pr-48 {
    padding-right: 48px !important
  }

  .lg\:pb-48 {
    padding-bottom: 48px !important
  }

  .lg\:pl-48 {
    padding-left: 48px !important
  }

  .lg\:pt-52 {
    padding-top: 52px !important
  }

  .lg\:pr-52 {
    padding-right: 52px !important
  }

  .lg\:pb-52 {
    padding-bottom: 52px !important
  }

  .lg\:pl-52 {
    padding-left: 52px !important
  }

  .lg\:pt-56 {
    padding-top: 56px !important
  }

  .lg\:pr-56 {
    padding-right: 56px !important
  }

  .lg\:pb-56 {
    padding-bottom: 56px !important
  }

  .lg\:pl-56 {
    padding-left: 56px !important
  }

  .lg\:pt-60 {
    padding-top: 60px !important
  }

  .lg\:pr-60 {
    padding-right: 60px !important
  }

  .lg\:pb-60 {
    padding-bottom: 60px !important
  }

  .lg\:pl-60 {
    padding-left: 60px !important
  }

  .lg\:pt-64 {
    padding-top: 64px !important
  }

  .lg\:pr-64 {
    padding-right: 64px !important
  }

  .lg\:pb-64 {
    padding-bottom: 64px !important
  }

  .lg\:pl-64 {
    padding-left: 64px !important
  }

  .lg\:pt-68 {
    padding-top: 68px !important
  }

  .lg\:pr-68 {
    padding-right: 68px !important
  }

  .lg\:pb-68 {
    padding-bottom: 68px !important
  }

  .lg\:pl-68 {
    padding-left: 68px !important
  }

  .lg\:pt-72 {
    padding-top: 72px !important
  }

  .lg\:pr-72 {
    padding-right: 72px !important
  }

  .lg\:pb-72 {
    padding-bottom: 72px !important
  }

  .lg\:pl-72 {
    padding-left: 72px !important
  }

  .lg\:pt-76 {
    padding-top: 76px !important
  }

  .lg\:pr-76 {
    padding-right: 76px !important
  }

  .lg\:pb-76 {
    padding-bottom: 76px !important
  }

  .lg\:pl-76 {
    padding-left: 76px !important
  }

  .lg\:pt-80 {
    padding-top: 80px !important
  }

  .lg\:pr-80 {
    padding-right: 80px !important
  }

  .lg\:pb-80 {
    padding-bottom: 80px !important
  }

  .lg\:pl-80 {
    padding-left: 80px !important
  }

  .lg\:pt-84 {
    padding-top: 84px !important
  }

  .lg\:pr-84 {
    padding-right: 84px !important
  }

  .lg\:pb-84 {
    padding-bottom: 84px !important
  }

  .lg\:pl-84 {
    padding-left: 84px !important
  }

  .lg\:pt-88 {
    padding-top: 88px !important
  }

  .lg\:pr-88 {
    padding-right: 88px !important
  }

  .lg\:pb-88 {
    padding-bottom: 88px !important
  }

  .lg\:pl-88 {
    padding-left: 88px !important
  }

  .lg\:pt-92 {
    padding-top: 92px !important
  }

  .lg\:pr-92 {
    padding-right: 92px !important
  }

  .lg\:pb-92 {
    padding-bottom: 92px !important
  }

  .lg\:pl-92 {
    padding-left: 92px !important
  }

  .lg\:pt-96 {
    padding-top: 96px !important
  }

  .lg\:pr-96 {
    padding-right: 96px !important
  }

  .lg\:pb-96 {
    padding-bottom: 96px !important
  }

  .lg\:pl-96 {
    padding-left: 96px !important
  }

  .lg\:pt-128 {
    padding-top: 128px !important
  }

  .lg\:pr-128 {
    padding-right: 128px !important
  }

  .lg\:pb-128 {
    padding-bottom: 128px !important
  }

  .lg\:pl-128 {
    padding-left: 128px !important
  }

  .lg\:pt-160 {
    padding-top: 160px !important
  }

  .lg\:pr-160 {
    padding-right: 160px !important
  }

  .lg\:pb-160 {
    padding-bottom: 160px !important
  }

  .lg\:pl-160 {
    padding-left: 160px !important
  }

  .lg\:pt-192 {
    padding-top: 192px !important
  }

  .lg\:pr-192 {
    padding-right: 192px !important
  }

  .lg\:pb-192 {
    padding-bottom: 192px !important
  }

  .lg\:pl-192 {
    padding-left: 192px !important
  }

  .lg\:pt-224 {
    padding-top: 224px !important
  }

  .lg\:pr-224 {
    padding-right: 224px !important
  }

  .lg\:pb-224 {
    padding-bottom: 224px !important
  }

  .lg\:pl-224 {
    padding-left: 224px !important
  }

  .lg\:pt-256 {
    padding-top: 256px !important
  }

  .lg\:pr-256 {
    padding-right: 256px !important
  }

  .lg\:pb-256 {
    padding-bottom: 256px !important
  }

  .lg\:pl-256 {
    padding-left: 256px !important
  }

  .lg\:pt-320 {
    padding-top: 320px !important
  }

  .lg\:pr-320 {
    padding-right: 320px !important
  }

  .lg\:pb-320 {
    padding-bottom: 320px !important
  }

  .lg\:pl-320 {
    padding-left: 320px !important
  }

  .lg\:pt-px {
    padding-top: 1px !important
  }

  .lg\:pr-px {
    padding-right: 1px !important
  }

  .lg\:pb-px {
    padding-bottom: 1px !important
  }

  .lg\:pl-px {
    padding-left: 1px !important
  }

  .lg\:flex-1 {
    flex: 1 1 0% !important
  }

  .lg\:flex-auto {
    flex: 1 1 auto !important
  }

  .lg\:flex-initial {
    flex: 0 1 auto !important
  }

  .lg\:flex-none {
    flex: none !important
  }

  .lg\:items-start {
    align-items: flex-start !important
  }

  .lg\:items-end {
    align-items: flex-end !important
  }

  .lg\:items-center {
    align-items: center !important
  }

  .lg\:items-baseline {
    align-items: baseline !important
  }

  .lg\:items-stretch {
    align-items: stretch !important
  }

  .lg\:flex-row {
    flex-direction: row !important
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .lg\:flex-col {
    flex-direction: column !important
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .lg\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .lg\:flex-grow {
    flex-grow: 1 !important
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .lg\:flex-shrink {
    flex-shrink: 1 !important
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .lg\:justify-start {
    justify-content: flex-start !important
  }

  .lg\:justify-end {
    justify-content: flex-end !important
  }

  .lg\:justify-center {
    justify-content: center !important
  }

  .lg\:justify-between {
    justify-content: space-between !important
  }

  .lg\:justify-around {
    justify-content: space-around !important
  }

  .lg\:justify-evenly {
    justify-content: space-evenly !important
  }

  .lg\:text-xs {
    font-size: 0.75rem !important
  }

  .lg\:text-sm {
    font-size: 0.875rem !important
  }

  .lg\:text-base {
    font-size: 1rem !important
  }

  .lg\:text-lg {
    font-size: 1.125rem !important
  }

  .lg\:text-xl {
    font-size: 1.25rem !important
  }

  .lg\:text-2xl {
    font-size: 1.5rem !important
  }

  .lg\:text-3xl {
    font-size: 1.875rem !important
  }

  .lg\:text-4xl {
    font-size: 2.25rem !important
  }

  .lg\:text-5xl {
    font-size: 3rem !important
  }

  .lg\:text-6xl {
    font-size: 4rem !important
  }

  .lg\:font-hairline {
    font-weight: 100 !important
  }

  .lg\:font-thin {
    font-weight: 200 !important
  }

  .lg\:font-light {
    font-weight: 300 !important
  }

  .lg\:font-normal {
    font-weight: 400 !important
  }

  .lg\:font-medium {
    font-weight: 500 !important
  }

  .lg\:font-semibold {
    font-weight: 600 !important
  }

  .lg\:font-bold {
    font-weight: 700 !important
  }

  .lg\:font-extrabold {
    font-weight: 800 !important
  }

  .lg\:font-black {
    font-weight: 900 !important
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .lg\:text-left {
    text-align: left !important
  }

  .lg\:text-center {
    text-align: center !important
  }

  .lg\:text-right {
    text-align: right !important
  }

  .lg\:text-justify {
    text-align: justify !important
  }

  .lg\:text-transparent {
    color: transparent !important
  }

  .lg\:text-current {
    color: currentColor !important
  }

  .lg\:text-black {
    color: #000 !important
  }

  .lg\:text-white {
    color: #fff !important
  }

  .lg\:text-gray-100 {
    color: #f7fafc !important
  }

  .lg\:text-gray-200 {
    color: #edf2f7 !important
  }

  .lg\:text-gray-300 {
    color: #e2e8f0 !important
  }

  .lg\:text-gray-400 {
    color: #cbd5e0 !important
  }

  .lg\:text-gray-500 {
    color: #a0aec0 !important
  }

  .lg\:text-gray-600 {
    color: #718096 !important
  }

  .lg\:text-gray-700 {
    color: #4a5568 !important
  }

  .lg\:text-gray-800 {
    color: #2d3748 !important
  }

  .lg\:text-gray-900 {
    color: #1a202c !important
  }

  .lg\:text-red-100 {
    color: #fff5f5 !important
  }

  .lg\:text-red-200 {
    color: #fed7d7 !important
  }

  .lg\:text-red-300 {
    color: #feb2b2 !important
  }

  .lg\:text-red-400 {
    color: #fc8181 !important
  }

  .lg\:text-red-500 {
    color: #f56565 !important
  }

  .lg\:text-red-600 {
    color: #e53e3e !important
  }

  .lg\:text-red-700 {
    color: #c53030 !important
  }

  .lg\:text-red-800 {
    color: #9b2c2c !important
  }

  .lg\:text-red-900 {
    color: #742a2a !important
  }

  .lg\:text-orange-100 {
    color: #fffaf0 !important
  }

  .lg\:text-orange-200 {
    color: #feebc8 !important
  }

  .lg\:text-orange-300 {
    color: #fbd38d !important
  }

  .lg\:text-orange-400 {
    color: #f6ad55 !important
  }

  .lg\:text-orange-500 {
    color: #ed8936 !important
  }

  .lg\:text-orange-600 {
    color: #dd6b20 !important
  }

  .lg\:text-orange-700 {
    color: #c05621 !important
  }

  .lg\:text-orange-800 {
    color: #9c4221 !important
  }

  .lg\:text-orange-900 {
    color: #7b341e !important
  }

  .lg\:text-yellow-100 {
    color: #fffff0 !important
  }

  .lg\:text-yellow-200 {
    color: #fefcbf !important
  }

  .lg\:text-yellow-300 {
    color: #faf089 !important
  }

  .lg\:text-yellow-400 {
    color: #f6e05e !important
  }

  .lg\:text-yellow-500 {
    color: #ecc94b !important
  }

  .lg\:text-yellow-600 {
    color: #d69e2e !important
  }

  .lg\:text-yellow-700 {
    color: #b7791f !important
  }

  .lg\:text-yellow-800 {
    color: #975a16 !important
  }

  .lg\:text-yellow-900 {
    color: #744210 !important
  }

  .lg\:text-green-100 {
    color: #f0fff4 !important
  }

  .lg\:text-green-200 {
    color: #c6f6d5 !important
  }

  .lg\:text-green-300 {
    color: #9ae6b4 !important
  }

  .lg\:text-green-400 {
    color: #68d391 !important
  }

  .lg\:text-green-500 {
    color: #48bb78 !important
  }

  .lg\:text-green-600 {
    color: #38a169 !important
  }

  .lg\:text-green-700 {
    color: #2f855a !important
  }

  .lg\:text-green-800 {
    color: #276749 !important
  }

  .lg\:text-green-900 {
    color: #22543d !important
  }

  .lg\:text-teal-100 {
    color: #e6fffa !important
  }

  .lg\:text-teal-200 {
    color: #b2f5ea !important
  }

  .lg\:text-teal-300 {
    color: #81e6d9 !important
  }

  .lg\:text-teal-400 {
    color: #4fd1c5 !important
  }

  .lg\:text-teal-500 {
    color: #38b2ac !important
  }

  .lg\:text-teal-600 {
    color: #319795 !important
  }

  .lg\:text-teal-700 {
    color: #2c7a7b !important
  }

  .lg\:text-teal-800 {
    color: #285e61 !important
  }

  .lg\:text-teal-900 {
    color: #234e52 !important
  }

  .lg\:text-blue-100 {
    color: #ebf8ff !important
  }

  .lg\:text-blue-200 {
    color: #bee3f8 !important
  }

  .lg\:text-blue-300 {
    color: #90cdf4 !important
  }

  .lg\:text-blue-400 {
    color: #63b3ed !important
  }

  .lg\:text-blue-500 {
    color: #4299e1 !important
  }

  .lg\:text-blue-600 {
    color: #3182ce !important
  }

  .lg\:text-blue-700 {
    color: #2b6cb0 !important
  }

  .lg\:text-blue-800 {
    color: #2c5282 !important
  }

  .lg\:text-blue-900 {
    color: #2a4365 !important
  }

  .lg\:text-indigo-100 {
    color: #ebf4ff !important
  }

  .lg\:text-indigo-200 {
    color: #c3dafe !important
  }

  .lg\:text-indigo-300 {
    color: #a3bffa !important
  }

  .lg\:text-indigo-400 {
    color: #7f9cf5 !important
  }

  .lg\:text-indigo-500 {
    color: #667eea !important
  }

  .lg\:text-indigo-600 {
    color: #5a67d8 !important
  }

  .lg\:text-indigo-700 {
    color: #4c51bf !important
  }

  .lg\:text-indigo-800 {
    color: #434190 !important
  }

  .lg\:text-indigo-900 {
    color: #3c366b !important
  }

  .lg\:text-purple-100 {
    color: #faf5ff !important
  }

  .lg\:text-purple-200 {
    color: #e9d8fd !important
  }

  .lg\:text-purple-300 {
    color: #d6bcfa !important
  }

  .lg\:text-purple-400 {
    color: #b794f4 !important
  }

  .lg\:text-purple-500 {
    color: #9f7aea !important
  }

  .lg\:text-purple-600 {
    color: #805ad5 !important
  }

  .lg\:text-purple-700 {
    color: #6b46c1 !important
  }

  .lg\:text-purple-800 {
    color: #553c9a !important
  }

  .lg\:text-purple-900 {
    color: #44337a !important
  }

  .lg\:text-pink-100 {
    color: #fff5f7 !important
  }

  .lg\:text-pink-200 {
    color: #fed7e2 !important
  }

  .lg\:text-pink-300 {
    color: #fbb6ce !important
  }

  .lg\:text-pink-400 {
    color: #f687b3 !important
  }

  .lg\:text-pink-500 {
    color: #ed64a6 !important
  }

  .lg\:text-pink-600 {
    color: #d53f8c !important
  }

  .lg\:text-pink-700 {
    color: #b83280 !important
  }

  .lg\:text-pink-800 {
    color: #97266d !important
  }

  .lg\:text-pink-900 {
    color: #702459 !important
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent !important
  }

  .lg\:hover\:text-current:hover {
    color: currentColor !important
  }

  .lg\:hover\:text-black:hover {
    color: #000 !important
  }

  .lg\:hover\:text-white:hover {
    color: #fff !important
  }

  .lg\:hover\:text-gray-100:hover {
    color: #f7fafc !important
  }

  .lg\:hover\:text-gray-200:hover {
    color: #edf2f7 !important
  }

  .lg\:hover\:text-gray-300:hover {
    color: #e2e8f0 !important
  }

  .lg\:hover\:text-gray-400:hover {
    color: #cbd5e0 !important
  }

  .lg\:hover\:text-gray-500:hover {
    color: #a0aec0 !important
  }

  .lg\:hover\:text-gray-600:hover {
    color: #718096 !important
  }

  .lg\:hover\:text-gray-700:hover {
    color: #4a5568 !important
  }

  .lg\:hover\:text-gray-800:hover {
    color: #2d3748 !important
  }

  .lg\:hover\:text-gray-900:hover {
    color: #1a202c !important
  }

  .lg\:hover\:text-red-100:hover {
    color: #fff5f5 !important
  }

  .lg\:hover\:text-red-200:hover {
    color: #fed7d7 !important
  }

  .lg\:hover\:text-red-300:hover {
    color: #feb2b2 !important
  }

  .lg\:hover\:text-red-400:hover {
    color: #fc8181 !important
  }

  .lg\:hover\:text-red-500:hover {
    color: #f56565 !important
  }

  .lg\:hover\:text-red-600:hover {
    color: #e53e3e !important
  }

  .lg\:hover\:text-red-700:hover {
    color: #c53030 !important
  }

  .lg\:hover\:text-red-800:hover {
    color: #9b2c2c !important
  }

  .lg\:hover\:text-red-900:hover {
    color: #742a2a !important
  }

  .lg\:hover\:text-orange-100:hover {
    color: #fffaf0 !important
  }

  .lg\:hover\:text-orange-200:hover {
    color: #feebc8 !important
  }

  .lg\:hover\:text-orange-300:hover {
    color: #fbd38d !important
  }

  .lg\:hover\:text-orange-400:hover {
    color: #f6ad55 !important
  }

  .lg\:hover\:text-orange-500:hover {
    color: #ed8936 !important
  }

  .lg\:hover\:text-orange-600:hover {
    color: #dd6b20 !important
  }

  .lg\:hover\:text-orange-700:hover {
    color: #c05621 !important
  }

  .lg\:hover\:text-orange-800:hover {
    color: #9c4221 !important
  }

  .lg\:hover\:text-orange-900:hover {
    color: #7b341e !important
  }

  .lg\:hover\:text-yellow-100:hover {
    color: #fffff0 !important
  }

  .lg\:hover\:text-yellow-200:hover {
    color: #fefcbf !important
  }

  .lg\:hover\:text-yellow-300:hover {
    color: #faf089 !important
  }

  .lg\:hover\:text-yellow-400:hover {
    color: #f6e05e !important
  }

  .lg\:hover\:text-yellow-500:hover {
    color: #ecc94b !important
  }

  .lg\:hover\:text-yellow-600:hover {
    color: #d69e2e !important
  }

  .lg\:hover\:text-yellow-700:hover {
    color: #b7791f !important
  }

  .lg\:hover\:text-yellow-800:hover {
    color: #975a16 !important
  }

  .lg\:hover\:text-yellow-900:hover {
    color: #744210 !important
  }

  .lg\:hover\:text-green-100:hover {
    color: #f0fff4 !important
  }

  .lg\:hover\:text-green-200:hover {
    color: #c6f6d5 !important
  }

  .lg\:hover\:text-green-300:hover {
    color: #9ae6b4 !important
  }

  .lg\:hover\:text-green-400:hover {
    color: #68d391 !important
  }

  .lg\:hover\:text-green-500:hover {
    color: #48bb78 !important
  }

  .lg\:hover\:text-green-600:hover {
    color: #38a169 !important
  }

  .lg\:hover\:text-green-700:hover {
    color: #2f855a !important
  }

  .lg\:hover\:text-green-800:hover {
    color: #276749 !important
  }

  .lg\:hover\:text-green-900:hover {
    color: #22543d !important
  }

  .lg\:hover\:text-teal-100:hover {
    color: #e6fffa !important
  }

  .lg\:hover\:text-teal-200:hover {
    color: #b2f5ea !important
  }

  .lg\:hover\:text-teal-300:hover {
    color: #81e6d9 !important
  }

  .lg\:hover\:text-teal-400:hover {
    color: #4fd1c5 !important
  }

  .lg\:hover\:text-teal-500:hover {
    color: #38b2ac !important
  }

  .lg\:hover\:text-teal-600:hover {
    color: #319795 !important
  }

  .lg\:hover\:text-teal-700:hover {
    color: #2c7a7b !important
  }

  .lg\:hover\:text-teal-800:hover {
    color: #285e61 !important
  }

  .lg\:hover\:text-teal-900:hover {
    color: #234e52 !important
  }

  .lg\:hover\:text-blue-100:hover {
    color: #ebf8ff !important
  }

  .lg\:hover\:text-blue-200:hover {
    color: #bee3f8 !important
  }

  .lg\:hover\:text-blue-300:hover {
    color: #90cdf4 !important
  }

  .lg\:hover\:text-blue-400:hover {
    color: #63b3ed !important
  }

  .lg\:hover\:text-blue-500:hover {
    color: #4299e1 !important
  }

  .lg\:hover\:text-blue-600:hover {
    color: #3182ce !important
  }

  .lg\:hover\:text-blue-700:hover {
    color: #2b6cb0 !important
  }

  .lg\:hover\:text-blue-800:hover {
    color: #2c5282 !important
  }

  .lg\:hover\:text-blue-900:hover {
    color: #2a4365 !important
  }

  .lg\:hover\:text-indigo-100:hover {
    color: #ebf4ff !important
  }

  .lg\:hover\:text-indigo-200:hover {
    color: #c3dafe !important
  }

  .lg\:hover\:text-indigo-300:hover {
    color: #a3bffa !important
  }

  .lg\:hover\:text-indigo-400:hover {
    color: #7f9cf5 !important
  }

  .lg\:hover\:text-indigo-500:hover {
    color: #667eea !important
  }

  .lg\:hover\:text-indigo-600:hover {
    color: #5a67d8 !important
  }

  .lg\:hover\:text-indigo-700:hover {
    color: #4c51bf !important
  }

  .lg\:hover\:text-indigo-800:hover {
    color: #434190 !important
  }

  .lg\:hover\:text-indigo-900:hover {
    color: #3c366b !important
  }

  .lg\:hover\:text-purple-100:hover {
    color: #faf5ff !important
  }

  .lg\:hover\:text-purple-200:hover {
    color: #e9d8fd !important
  }

  .lg\:hover\:text-purple-300:hover {
    color: #d6bcfa !important
  }

  .lg\:hover\:text-purple-400:hover {
    color: #b794f4 !important
  }

  .lg\:hover\:text-purple-500:hover {
    color: #9f7aea !important
  }

  .lg\:hover\:text-purple-600:hover {
    color: #805ad5 !important
  }

  .lg\:hover\:text-purple-700:hover {
    color: #6b46c1 !important
  }

  .lg\:hover\:text-purple-800:hover {
    color: #553c9a !important
  }

  .lg\:hover\:text-purple-900:hover {
    color: #44337a !important
  }

  .lg\:hover\:text-pink-100:hover {
    color: #fff5f7 !important
  }

  .lg\:hover\:text-pink-200:hover {
    color: #fed7e2 !important
  }

  .lg\:hover\:text-pink-300:hover {
    color: #fbb6ce !important
  }

  .lg\:hover\:text-pink-400:hover {
    color: #f687b3 !important
  }

  .lg\:hover\:text-pink-500:hover {
    color: #ed64a6 !important
  }

  .lg\:hover\:text-pink-600:hover {
    color: #d53f8c !important
  }

  .lg\:hover\:text-pink-700:hover {
    color: #b83280 !important
  }

  .lg\:hover\:text-pink-800:hover {
    color: #97266d !important
  }

  .lg\:hover\:text-pink-900:hover {
    color: #702459 !important
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent !important
  }

  .lg\:focus\:text-current:focus {
    color: currentColor !important
  }

  .lg\:focus\:text-black:focus {
    color: #000 !important
  }

  .lg\:focus\:text-white:focus {
    color: #fff !important
  }

  .lg\:focus\:text-gray-100:focus {
    color: #f7fafc !important
  }

  .lg\:focus\:text-gray-200:focus {
    color: #edf2f7 !important
  }

  .lg\:focus\:text-gray-300:focus {
    color: #e2e8f0 !important
  }

  .lg\:focus\:text-gray-400:focus {
    color: #cbd5e0 !important
  }

  .lg\:focus\:text-gray-500:focus {
    color: #a0aec0 !important
  }

  .lg\:focus\:text-gray-600:focus {
    color: #718096 !important
  }

  .lg\:focus\:text-gray-700:focus {
    color: #4a5568 !important
  }

  .lg\:focus\:text-gray-800:focus {
    color: #2d3748 !important
  }

  .lg\:focus\:text-gray-900:focus {
    color: #1a202c !important
  }

  .lg\:focus\:text-red-100:focus {
    color: #fff5f5 !important
  }

  .lg\:focus\:text-red-200:focus {
    color: #fed7d7 !important
  }

  .lg\:focus\:text-red-300:focus {
    color: #feb2b2 !important
  }

  .lg\:focus\:text-red-400:focus {
    color: #fc8181 !important
  }

  .lg\:focus\:text-red-500:focus {
    color: #f56565 !important
  }

  .lg\:focus\:text-red-600:focus {
    color: #e53e3e !important
  }

  .lg\:focus\:text-red-700:focus {
    color: #c53030 !important
  }

  .lg\:focus\:text-red-800:focus {
    color: #9b2c2c !important
  }

  .lg\:focus\:text-red-900:focus {
    color: #742a2a !important
  }

  .lg\:focus\:text-orange-100:focus {
    color: #fffaf0 !important
  }

  .lg\:focus\:text-orange-200:focus {
    color: #feebc8 !important
  }

  .lg\:focus\:text-orange-300:focus {
    color: #fbd38d !important
  }

  .lg\:focus\:text-orange-400:focus {
    color: #f6ad55 !important
  }

  .lg\:focus\:text-orange-500:focus {
    color: #ed8936 !important
  }

  .lg\:focus\:text-orange-600:focus {
    color: #dd6b20 !important
  }

  .lg\:focus\:text-orange-700:focus {
    color: #c05621 !important
  }

  .lg\:focus\:text-orange-800:focus {
    color: #9c4221 !important
  }

  .lg\:focus\:text-orange-900:focus {
    color: #7b341e !important
  }

  .lg\:focus\:text-yellow-100:focus {
    color: #fffff0 !important
  }

  .lg\:focus\:text-yellow-200:focus {
    color: #fefcbf !important
  }

  .lg\:focus\:text-yellow-300:focus {
    color: #faf089 !important
  }

  .lg\:focus\:text-yellow-400:focus {
    color: #f6e05e !important
  }

  .lg\:focus\:text-yellow-500:focus {
    color: #ecc94b !important
  }

  .lg\:focus\:text-yellow-600:focus {
    color: #d69e2e !important
  }

  .lg\:focus\:text-yellow-700:focus {
    color: #b7791f !important
  }

  .lg\:focus\:text-yellow-800:focus {
    color: #975a16 !important
  }

  .lg\:focus\:text-yellow-900:focus {
    color: #744210 !important
  }

  .lg\:focus\:text-green-100:focus {
    color: #f0fff4 !important
  }

  .lg\:focus\:text-green-200:focus {
    color: #c6f6d5 !important
  }

  .lg\:focus\:text-green-300:focus {
    color: #9ae6b4 !important
  }

  .lg\:focus\:text-green-400:focus {
    color: #68d391 !important
  }

  .lg\:focus\:text-green-500:focus {
    color: #48bb78 !important
  }

  .lg\:focus\:text-green-600:focus {
    color: #38a169 !important
  }

  .lg\:focus\:text-green-700:focus {
    color: #2f855a !important
  }

  .lg\:focus\:text-green-800:focus {
    color: #276749 !important
  }

  .lg\:focus\:text-green-900:focus {
    color: #22543d !important
  }

  .lg\:focus\:text-teal-100:focus {
    color: #e6fffa !important
  }

  .lg\:focus\:text-teal-200:focus {
    color: #b2f5ea !important
  }

  .lg\:focus\:text-teal-300:focus {
    color: #81e6d9 !important
  }

  .lg\:focus\:text-teal-400:focus {
    color: #4fd1c5 !important
  }

  .lg\:focus\:text-teal-500:focus {
    color: #38b2ac !important
  }

  .lg\:focus\:text-teal-600:focus {
    color: #319795 !important
  }

  .lg\:focus\:text-teal-700:focus {
    color: #2c7a7b !important
  }

  .lg\:focus\:text-teal-800:focus {
    color: #285e61 !important
  }

  .lg\:focus\:text-teal-900:focus {
    color: #234e52 !important
  }

  .lg\:focus\:text-blue-100:focus {
    color: #ebf8ff !important
  }

  .lg\:focus\:text-blue-200:focus {
    color: #bee3f8 !important
  }

  .lg\:focus\:text-blue-300:focus {
    color: #90cdf4 !important
  }

  .lg\:focus\:text-blue-400:focus {
    color: #63b3ed !important
  }

  .lg\:focus\:text-blue-500:focus {
    color: #4299e1 !important
  }

  .lg\:focus\:text-blue-600:focus {
    color: #3182ce !important
  }

  .lg\:focus\:text-blue-700:focus {
    color: #2b6cb0 !important
  }

  .lg\:focus\:text-blue-800:focus {
    color: #2c5282 !important
  }

  .lg\:focus\:text-blue-900:focus {
    color: #2a4365 !important
  }

  .lg\:focus\:text-indigo-100:focus {
    color: #ebf4ff !important
  }

  .lg\:focus\:text-indigo-200:focus {
    color: #c3dafe !important
  }

  .lg\:focus\:text-indigo-300:focus {
    color: #a3bffa !important
  }

  .lg\:focus\:text-indigo-400:focus {
    color: #7f9cf5 !important
  }

  .lg\:focus\:text-indigo-500:focus {
    color: #667eea !important
  }

  .lg\:focus\:text-indigo-600:focus {
    color: #5a67d8 !important
  }

  .lg\:focus\:text-indigo-700:focus {
    color: #4c51bf !important
  }

  .lg\:focus\:text-indigo-800:focus {
    color: #434190 !important
  }

  .lg\:focus\:text-indigo-900:focus {
    color: #3c366b !important
  }

  .lg\:focus\:text-purple-100:focus {
    color: #faf5ff !important
  }

  .lg\:focus\:text-purple-200:focus {
    color: #e9d8fd !important
  }

  .lg\:focus\:text-purple-300:focus {
    color: #d6bcfa !important
  }

  .lg\:focus\:text-purple-400:focus {
    color: #b794f4 !important
  }

  .lg\:focus\:text-purple-500:focus {
    color: #9f7aea !important
  }

  .lg\:focus\:text-purple-600:focus {
    color: #805ad5 !important
  }

  .lg\:focus\:text-purple-700:focus {
    color: #6b46c1 !important
  }

  .lg\:focus\:text-purple-800:focus {
    color: #553c9a !important
  }

  .lg\:focus\:text-purple-900:focus {
    color: #44337a !important
  }

  .lg\:focus\:text-pink-100:focus {
    color: #fff5f7 !important
  }

  .lg\:focus\:text-pink-200:focus {
    color: #fed7e2 !important
  }

  .lg\:focus\:text-pink-300:focus {
    color: #fbb6ce !important
  }

  .lg\:focus\:text-pink-400:focus {
    color: #f687b3 !important
  }

  .lg\:focus\:text-pink-500:focus {
    color: #ed64a6 !important
  }

  .lg\:focus\:text-pink-600:focus {
    color: #d53f8c !important
  }

  .lg\:focus\:text-pink-700:focus {
    color: #b83280 !important
  }

  .lg\:focus\:text-pink-800:focus {
    color: #97266d !important
  }

  .lg\:focus\:text-pink-900:focus {
    color: #702459 !important
  }

  .lg\:bg-transparent {
    background-color: transparent !important
  }

  .lg\:bg-current {
    background-color: currentColor !important
  }

  .lg\:bg-black {
    background-color: #000 !important
  }

  .lg\:bg-white {
    background-color: #fff !important
  }

  .lg\:bg-gray-100 {
    background-color: #f7fafc !important
  }

  .lg\:bg-gray-200 {
    background-color: #edf2f7 !important
  }

  .lg\:bg-gray-300 {
    background-color: #e2e8f0 !important
  }

  .lg\:bg-gray-400 {
    background-color: #cbd5e0 !important
  }

  .lg\:bg-gray-500 {
    background-color: #a0aec0 !important
  }

  .lg\:bg-gray-600 {
    background-color: #718096 !important
  }

  .lg\:bg-gray-700 {
    background-color: #4a5568 !important
  }

  .lg\:bg-gray-800 {
    background-color: #2d3748 !important
  }

  .lg\:bg-gray-900 {
    background-color: #1a202c !important
  }

  .lg\:bg-red-100 {
    background-color: #fff5f5 !important
  }

  .lg\:bg-red-200 {
    background-color: #fed7d7 !important
  }

  .lg\:bg-red-300 {
    background-color: #feb2b2 !important
  }

  .lg\:bg-red-400 {
    background-color: #fc8181 !important
  }

  .lg\:bg-red-500 {
    background-color: #f56565 !important
  }

  .lg\:bg-red-600 {
    background-color: #e53e3e !important
  }

  .lg\:bg-red-700 {
    background-color: #c53030 !important
  }

  .lg\:bg-red-800 {
    background-color: #9b2c2c !important
  }

  .lg\:bg-red-900 {
    background-color: #742a2a !important
  }

  .lg\:bg-orange-100 {
    background-color: #fffaf0 !important
  }

  .lg\:bg-orange-200 {
    background-color: #feebc8 !important
  }

  .lg\:bg-orange-300 {
    background-color: #fbd38d !important
  }

  .lg\:bg-orange-400 {
    background-color: #f6ad55 !important
  }

  .lg\:bg-orange-500 {
    background-color: #ed8936 !important
  }

  .lg\:bg-orange-600 {
    background-color: #dd6b20 !important
  }

  .lg\:bg-orange-700 {
    background-color: #c05621 !important
  }

  .lg\:bg-orange-800 {
    background-color: #9c4221 !important
  }

  .lg\:bg-orange-900 {
    background-color: #7b341e !important
  }

  .lg\:bg-yellow-100 {
    background-color: #fffff0 !important
  }

  .lg\:bg-yellow-200 {
    background-color: #fefcbf !important
  }

  .lg\:bg-yellow-300 {
    background-color: #faf089 !important
  }

  .lg\:bg-yellow-400 {
    background-color: #f6e05e !important
  }

  .lg\:bg-yellow-500 {
    background-color: #ecc94b !important
  }

  .lg\:bg-yellow-600 {
    background-color: #d69e2e !important
  }

  .lg\:bg-yellow-700 {
    background-color: #b7791f !important
  }

  .lg\:bg-yellow-800 {
    background-color: #975a16 !important
  }

  .lg\:bg-yellow-900 {
    background-color: #744210 !important
  }

  .lg\:bg-green-100 {
    background-color: #f0fff4 !important
  }

  .lg\:bg-green-200 {
    background-color: #c6f6d5 !important
  }

  .lg\:bg-green-300 {
    background-color: #9ae6b4 !important
  }

  .lg\:bg-green-400 {
    background-color: #68d391 !important
  }

  .lg\:bg-green-500 {
    background-color: #48bb78 !important
  }

  .lg\:bg-green-600 {
    background-color: #38a169 !important
  }

  .lg\:bg-green-700 {
    background-color: #2f855a !important
  }

  .lg\:bg-green-800 {
    background-color: #276749 !important
  }

  .lg\:bg-green-900 {
    background-color: #22543d !important
  }

  .lg\:bg-teal-100 {
    background-color: #e6fffa !important
  }

  .lg\:bg-teal-200 {
    background-color: #b2f5ea !important
  }

  .lg\:bg-teal-300 {
    background-color: #81e6d9 !important
  }

  .lg\:bg-teal-400 {
    background-color: #4fd1c5 !important
  }

  .lg\:bg-teal-500 {
    background-color: #38b2ac !important
  }

  .lg\:bg-teal-600 {
    background-color: #319795 !important
  }

  .lg\:bg-teal-700 {
    background-color: #2c7a7b !important
  }

  .lg\:bg-teal-800 {
    background-color: #285e61 !important
  }

  .lg\:bg-teal-900 {
    background-color: #234e52 !important
  }

  .lg\:bg-blue-100 {
    background-color: #ebf8ff !important
  }

  .lg\:bg-blue-200 {
    background-color: #bee3f8 !important
  }

  .lg\:bg-blue-300 {
    background-color: #90cdf4 !important
  }

  .lg\:bg-blue-400 {
    background-color: #63b3ed !important
  }

  .lg\:bg-blue-500 {
    background-color: #4299e1 !important
  }

  .lg\:bg-blue-600 {
    background-color: #3182ce !important
  }

  .lg\:bg-blue-700 {
    background-color: #2b6cb0 !important
  }

  .lg\:bg-blue-800 {
    background-color: #2c5282 !important
  }

  .lg\:bg-blue-900 {
    background-color: #2a4365 !important
  }

  .lg\:bg-indigo-100 {
    background-color: #ebf4ff !important
  }

  .lg\:bg-indigo-200 {
    background-color: #c3dafe !important
  }

  .lg\:bg-indigo-300 {
    background-color: #a3bffa !important
  }

  .lg\:bg-indigo-400 {
    background-color: #7f9cf5 !important
  }

  .lg\:bg-indigo-500 {
    background-color: #667eea !important
  }

  .lg\:bg-indigo-600 {
    background-color: #5a67d8 !important
  }

  .lg\:bg-indigo-700 {
    background-color: #4c51bf !important
  }

  .lg\:bg-indigo-800 {
    background-color: #434190 !important
  }

  .lg\:bg-indigo-900 {
    background-color: #3c366b !important
  }

  .lg\:bg-purple-100 {
    background-color: #faf5ff !important
  }

  .lg\:bg-purple-200 {
    background-color: #e9d8fd !important
  }

  .lg\:bg-purple-300 {
    background-color: #d6bcfa !important
  }

  .lg\:bg-purple-400 {
    background-color: #b794f4 !important
  }

  .lg\:bg-purple-500 {
    background-color: #9f7aea !important
  }

  .lg\:bg-purple-600 {
    background-color: #805ad5 !important
  }

  .lg\:bg-purple-700 {
    background-color: #6b46c1 !important
  }

  .lg\:bg-purple-800 {
    background-color: #553c9a !important
  }

  .lg\:bg-purple-900 {
    background-color: #44337a !important
  }

  .lg\:bg-pink-100 {
    background-color: #fff5f7 !important
  }

  .lg\:bg-pink-200 {
    background-color: #fed7e2 !important
  }

  .lg\:bg-pink-300 {
    background-color: #fbb6ce !important
  }

  .lg\:bg-pink-400 {
    background-color: #f687b3 !important
  }

  .lg\:bg-pink-500 {
    background-color: #ed64a6 !important
  }

  .lg\:bg-pink-600 {
    background-color: #d53f8c !important
  }

  .lg\:bg-pink-700 {
    background-color: #b83280 !important
  }

  .lg\:bg-pink-800 {
    background-color: #97266d !important
  }

  .lg\:bg-pink-900 {
    background-color: #702459 !important
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent !important
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor !important
  }

  .lg\:hover\:bg-black:hover {
    background-color: #000 !important
  }

  .lg\:hover\:bg-white:hover {
    background-color: #fff !important
  }

  .lg\:hover\:bg-gray-100:hover {
    background-color: #f7fafc !important
  }

  .lg\:hover\:bg-gray-200:hover {
    background-color: #edf2f7 !important
  }

  .lg\:hover\:bg-gray-300:hover {
    background-color: #e2e8f0 !important
  }

  .lg\:hover\:bg-gray-400:hover {
    background-color: #cbd5e0 !important
  }

  .lg\:hover\:bg-gray-500:hover {
    background-color: #a0aec0 !important
  }

  .lg\:hover\:bg-gray-600:hover {
    background-color: #718096 !important
  }

  .lg\:hover\:bg-gray-700:hover {
    background-color: #4a5568 !important
  }

  .lg\:hover\:bg-gray-800:hover {
    background-color: #2d3748 !important
  }

  .lg\:hover\:bg-gray-900:hover {
    background-color: #1a202c !important
  }

  .lg\:hover\:bg-red-100:hover {
    background-color: #fff5f5 !important
  }

  .lg\:hover\:bg-red-200:hover {
    background-color: #fed7d7 !important
  }

  .lg\:hover\:bg-red-300:hover {
    background-color: #feb2b2 !important
  }

  .lg\:hover\:bg-red-400:hover {
    background-color: #fc8181 !important
  }

  .lg\:hover\:bg-red-500:hover {
    background-color: #f56565 !important
  }

  .lg\:hover\:bg-red-600:hover {
    background-color: #e53e3e !important
  }

  .lg\:hover\:bg-red-700:hover {
    background-color: #c53030 !important
  }

  .lg\:hover\:bg-red-800:hover {
    background-color: #9b2c2c !important
  }

  .lg\:hover\:bg-red-900:hover {
    background-color: #742a2a !important
  }

  .lg\:hover\:bg-orange-100:hover {
    background-color: #fffaf0 !important
  }

  .lg\:hover\:bg-orange-200:hover {
    background-color: #feebc8 !important
  }

  .lg\:hover\:bg-orange-300:hover {
    background-color: #fbd38d !important
  }

  .lg\:hover\:bg-orange-400:hover {
    background-color: #f6ad55 !important
  }

  .lg\:hover\:bg-orange-500:hover {
    background-color: #ed8936 !important
  }

  .lg\:hover\:bg-orange-600:hover {
    background-color: #dd6b20 !important
  }

  .lg\:hover\:bg-orange-700:hover {
    background-color: #c05621 !important
  }

  .lg\:hover\:bg-orange-800:hover {
    background-color: #9c4221 !important
  }

  .lg\:hover\:bg-orange-900:hover {
    background-color: #7b341e !important
  }

  .lg\:hover\:bg-yellow-100:hover {
    background-color: #fffff0 !important
  }

  .lg\:hover\:bg-yellow-200:hover {
    background-color: #fefcbf !important
  }

  .lg\:hover\:bg-yellow-300:hover {
    background-color: #faf089 !important
  }

  .lg\:hover\:bg-yellow-400:hover {
    background-color: #f6e05e !important
  }

  .lg\:hover\:bg-yellow-500:hover {
    background-color: #ecc94b !important
  }

  .lg\:hover\:bg-yellow-600:hover {
    background-color: #d69e2e !important
  }

  .lg\:hover\:bg-yellow-700:hover {
    background-color: #b7791f !important
  }

  .lg\:hover\:bg-yellow-800:hover {
    background-color: #975a16 !important
  }

  .lg\:hover\:bg-yellow-900:hover {
    background-color: #744210 !important
  }

  .lg\:hover\:bg-green-100:hover {
    background-color: #f0fff4 !important
  }

  .lg\:hover\:bg-green-200:hover {
    background-color: #c6f6d5 !important
  }

  .lg\:hover\:bg-green-300:hover {
    background-color: #9ae6b4 !important
  }

  .lg\:hover\:bg-green-400:hover {
    background-color: #68d391 !important
  }

  .lg\:hover\:bg-green-500:hover {
    background-color: #48bb78 !important
  }

  .lg\:hover\:bg-green-600:hover {
    background-color: #38a169 !important
  }

  .lg\:hover\:bg-green-700:hover {
    background-color: #2f855a !important
  }

  .lg\:hover\:bg-green-800:hover {
    background-color: #276749 !important
  }

  .lg\:hover\:bg-green-900:hover {
    background-color: #22543d !important
  }

  .lg\:hover\:bg-teal-100:hover {
    background-color: #e6fffa !important
  }

  .lg\:hover\:bg-teal-200:hover {
    background-color: #b2f5ea !important
  }

  .lg\:hover\:bg-teal-300:hover {
    background-color: #81e6d9 !important
  }

  .lg\:hover\:bg-teal-400:hover {
    background-color: #4fd1c5 !important
  }

  .lg\:hover\:bg-teal-500:hover {
    background-color: #38b2ac !important
  }

  .lg\:hover\:bg-teal-600:hover {
    background-color: #319795 !important
  }

  .lg\:hover\:bg-teal-700:hover {
    background-color: #2c7a7b !important
  }

  .lg\:hover\:bg-teal-800:hover {
    background-color: #285e61 !important
  }

  .lg\:hover\:bg-teal-900:hover {
    background-color: #234e52 !important
  }

  .lg\:hover\:bg-blue-100:hover {
    background-color: #ebf8ff !important
  }

  .lg\:hover\:bg-blue-200:hover {
    background-color: #bee3f8 !important
  }

  .lg\:hover\:bg-blue-300:hover {
    background-color: #90cdf4 !important
  }

  .lg\:hover\:bg-blue-400:hover {
    background-color: #63b3ed !important
  }

  .lg\:hover\:bg-blue-500:hover {
    background-color: #4299e1 !important
  }

  .lg\:hover\:bg-blue-600:hover {
    background-color: #3182ce !important
  }

  .lg\:hover\:bg-blue-700:hover {
    background-color: #2b6cb0 !important
  }

  .lg\:hover\:bg-blue-800:hover {
    background-color: #2c5282 !important
  }

  .lg\:hover\:bg-blue-900:hover {
    background-color: #2a4365 !important
  }

  .lg\:hover\:bg-indigo-100:hover {
    background-color: #ebf4ff !important
  }

  .lg\:hover\:bg-indigo-200:hover {
    background-color: #c3dafe !important
  }

  .lg\:hover\:bg-indigo-300:hover {
    background-color: #a3bffa !important
  }

  .lg\:hover\:bg-indigo-400:hover {
    background-color: #7f9cf5 !important
  }

  .lg\:hover\:bg-indigo-500:hover {
    background-color: #667eea !important
  }

  .lg\:hover\:bg-indigo-600:hover {
    background-color: #5a67d8 !important
  }

  .lg\:hover\:bg-indigo-700:hover {
    background-color: #4c51bf !important
  }

  .lg\:hover\:bg-indigo-800:hover {
    background-color: #434190 !important
  }

  .lg\:hover\:bg-indigo-900:hover {
    background-color: #3c366b !important
  }

  .lg\:hover\:bg-purple-100:hover {
    background-color: #faf5ff !important
  }

  .lg\:hover\:bg-purple-200:hover {
    background-color: #e9d8fd !important
  }

  .lg\:hover\:bg-purple-300:hover {
    background-color: #d6bcfa !important
  }

  .lg\:hover\:bg-purple-400:hover {
    background-color: #b794f4 !important
  }

  .lg\:hover\:bg-purple-500:hover {
    background-color: #9f7aea !important
  }

  .lg\:hover\:bg-purple-600:hover {
    background-color: #805ad5 !important
  }

  .lg\:hover\:bg-purple-700:hover {
    background-color: #6b46c1 !important
  }

  .lg\:hover\:bg-purple-800:hover {
    background-color: #553c9a !important
  }

  .lg\:hover\:bg-purple-900:hover {
    background-color: #44337a !important
  }

  .lg\:hover\:bg-pink-100:hover {
    background-color: #fff5f7 !important
  }

  .lg\:hover\:bg-pink-200:hover {
    background-color: #fed7e2 !important
  }

  .lg\:hover\:bg-pink-300:hover {
    background-color: #fbb6ce !important
  }

  .lg\:hover\:bg-pink-400:hover {
    background-color: #f687b3 !important
  }

  .lg\:hover\:bg-pink-500:hover {
    background-color: #ed64a6 !important
  }

  .lg\:hover\:bg-pink-600:hover {
    background-color: #d53f8c !important
  }

  .lg\:hover\:bg-pink-700:hover {
    background-color: #b83280 !important
  }

  .lg\:hover\:bg-pink-800:hover {
    background-color: #97266d !important
  }

  .lg\:hover\:bg-pink-900:hover {
    background-color: #702459 !important
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent !important
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor !important
  }

  .lg\:focus\:bg-black:focus {
    background-color: #000 !important
  }

  .lg\:focus\:bg-white:focus {
    background-color: #fff !important
  }

  .lg\:focus\:bg-gray-100:focus {
    background-color: #f7fafc !important
  }

  .lg\:focus\:bg-gray-200:focus {
    background-color: #edf2f7 !important
  }

  .lg\:focus\:bg-gray-300:focus {
    background-color: #e2e8f0 !important
  }

  .lg\:focus\:bg-gray-400:focus {
    background-color: #cbd5e0 !important
  }

  .lg\:focus\:bg-gray-500:focus {
    background-color: #a0aec0 !important
  }

  .lg\:focus\:bg-gray-600:focus {
    background-color: #718096 !important
  }

  .lg\:focus\:bg-gray-700:focus {
    background-color: #4a5568 !important
  }

  .lg\:focus\:bg-gray-800:focus {
    background-color: #2d3748 !important
  }

  .lg\:focus\:bg-gray-900:focus {
    background-color: #1a202c !important
  }

  .lg\:focus\:bg-red-100:focus {
    background-color: #fff5f5 !important
  }

  .lg\:focus\:bg-red-200:focus {
    background-color: #fed7d7 !important
  }

  .lg\:focus\:bg-red-300:focus {
    background-color: #feb2b2 !important
  }

  .lg\:focus\:bg-red-400:focus {
    background-color: #fc8181 !important
  }

  .lg\:focus\:bg-red-500:focus {
    background-color: #f56565 !important
  }

  .lg\:focus\:bg-red-600:focus {
    background-color: #e53e3e !important
  }

  .lg\:focus\:bg-red-700:focus {
    background-color: #c53030 !important
  }

  .lg\:focus\:bg-red-800:focus {
    background-color: #9b2c2c !important
  }

  .lg\:focus\:bg-red-900:focus {
    background-color: #742a2a !important
  }

  .lg\:focus\:bg-orange-100:focus {
    background-color: #fffaf0 !important
  }

  .lg\:focus\:bg-orange-200:focus {
    background-color: #feebc8 !important
  }

  .lg\:focus\:bg-orange-300:focus {
    background-color: #fbd38d !important
  }

  .lg\:focus\:bg-orange-400:focus {
    background-color: #f6ad55 !important
  }

  .lg\:focus\:bg-orange-500:focus {
    background-color: #ed8936 !important
  }

  .lg\:focus\:bg-orange-600:focus {
    background-color: #dd6b20 !important
  }

  .lg\:focus\:bg-orange-700:focus {
    background-color: #c05621 !important
  }

  .lg\:focus\:bg-orange-800:focus {
    background-color: #9c4221 !important
  }

  .lg\:focus\:bg-orange-900:focus {
    background-color: #7b341e !important
  }

  .lg\:focus\:bg-yellow-100:focus {
    background-color: #fffff0 !important
  }

  .lg\:focus\:bg-yellow-200:focus {
    background-color: #fefcbf !important
  }

  .lg\:focus\:bg-yellow-300:focus {
    background-color: #faf089 !important
  }

  .lg\:focus\:bg-yellow-400:focus {
    background-color: #f6e05e !important
  }

  .lg\:focus\:bg-yellow-500:focus {
    background-color: #ecc94b !important
  }

  .lg\:focus\:bg-yellow-600:focus {
    background-color: #d69e2e !important
  }

  .lg\:focus\:bg-yellow-700:focus {
    background-color: #b7791f !important
  }

  .lg\:focus\:bg-yellow-800:focus {
    background-color: #975a16 !important
  }

  .lg\:focus\:bg-yellow-900:focus {
    background-color: #744210 !important
  }

  .lg\:focus\:bg-green-100:focus {
    background-color: #f0fff4 !important
  }

  .lg\:focus\:bg-green-200:focus {
    background-color: #c6f6d5 !important
  }

  .lg\:focus\:bg-green-300:focus {
    background-color: #9ae6b4 !important
  }

  .lg\:focus\:bg-green-400:focus {
    background-color: #68d391 !important
  }

  .lg\:focus\:bg-green-500:focus {
    background-color: #48bb78 !important
  }

  .lg\:focus\:bg-green-600:focus {
    background-color: #38a169 !important
  }

  .lg\:focus\:bg-green-700:focus {
    background-color: #2f855a !important
  }

  .lg\:focus\:bg-green-800:focus {
    background-color: #276749 !important
  }

  .lg\:focus\:bg-green-900:focus {
    background-color: #22543d !important
  }

  .lg\:focus\:bg-teal-100:focus {
    background-color: #e6fffa !important
  }

  .lg\:focus\:bg-teal-200:focus {
    background-color: #b2f5ea !important
  }

  .lg\:focus\:bg-teal-300:focus {
    background-color: #81e6d9 !important
  }

  .lg\:focus\:bg-teal-400:focus {
    background-color: #4fd1c5 !important
  }

  .lg\:focus\:bg-teal-500:focus {
    background-color: #38b2ac !important
  }

  .lg\:focus\:bg-teal-600:focus {
    background-color: #319795 !important
  }

  .lg\:focus\:bg-teal-700:focus {
    background-color: #2c7a7b !important
  }

  .lg\:focus\:bg-teal-800:focus {
    background-color: #285e61 !important
  }

  .lg\:focus\:bg-teal-900:focus {
    background-color: #234e52 !important
  }

  .lg\:focus\:bg-blue-100:focus {
    background-color: #ebf8ff !important
  }

  .lg\:focus\:bg-blue-200:focus {
    background-color: #bee3f8 !important
  }

  .lg\:focus\:bg-blue-300:focus {
    background-color: #90cdf4 !important
  }

  .lg\:focus\:bg-blue-400:focus {
    background-color: #63b3ed !important
  }

  .lg\:focus\:bg-blue-500:focus {
    background-color: #4299e1 !important
  }

  .lg\:focus\:bg-blue-600:focus {
    background-color: #3182ce !important
  }

  .lg\:focus\:bg-blue-700:focus {
    background-color: #2b6cb0 !important
  }

  .lg\:focus\:bg-blue-800:focus {
    background-color: #2c5282 !important
  }

  .lg\:focus\:bg-blue-900:focus {
    background-color: #2a4365 !important
  }

  .lg\:focus\:bg-indigo-100:focus {
    background-color: #ebf4ff !important
  }

  .lg\:focus\:bg-indigo-200:focus {
    background-color: #c3dafe !important
  }

  .lg\:focus\:bg-indigo-300:focus {
    background-color: #a3bffa !important
  }

  .lg\:focus\:bg-indigo-400:focus {
    background-color: #7f9cf5 !important
  }

  .lg\:focus\:bg-indigo-500:focus {
    background-color: #667eea !important
  }

  .lg\:focus\:bg-indigo-600:focus {
    background-color: #5a67d8 !important
  }

  .lg\:focus\:bg-indigo-700:focus {
    background-color: #4c51bf !important
  }

  .lg\:focus\:bg-indigo-800:focus {
    background-color: #434190 !important
  }

  .lg\:focus\:bg-indigo-900:focus {
    background-color: #3c366b !important
  }

  .lg\:focus\:bg-purple-100:focus {
    background-color: #faf5ff !important
  }

  .lg\:focus\:bg-purple-200:focus {
    background-color: #e9d8fd !important
  }

  .lg\:focus\:bg-purple-300:focus {
    background-color: #d6bcfa !important
  }

  .lg\:focus\:bg-purple-400:focus {
    background-color: #b794f4 !important
  }

  .lg\:focus\:bg-purple-500:focus {
    background-color: #9f7aea !important
  }

  .lg\:focus\:bg-purple-600:focus {
    background-color: #805ad5 !important
  }

  .lg\:focus\:bg-purple-700:focus {
    background-color: #6b46c1 !important
  }

  .lg\:focus\:bg-purple-800:focus {
    background-color: #553c9a !important
  }

  .lg\:focus\:bg-purple-900:focus {
    background-color: #44337a !important
  }

  .lg\:focus\:bg-pink-100:focus {
    background-color: #fff5f7 !important
  }

  .lg\:focus\:bg-pink-200:focus {
    background-color: #fed7e2 !important
  }

  .lg\:focus\:bg-pink-300:focus {
    background-color: #fbb6ce !important
  }

  .lg\:focus\:bg-pink-400:focus {
    background-color: #f687b3 !important
  }

  .lg\:focus\:bg-pink-500:focus {
    background-color: #ed64a6 !important
  }

  .lg\:focus\:bg-pink-600:focus {
    background-color: #d53f8c !important
  }

  .lg\:focus\:bg-pink-700:focus {
    background-color: #b83280 !important
  }

  .lg\:focus\:bg-pink-800:focus {
    background-color: #97266d !important
  }

  .lg\:focus\:bg-pink-900:focus {
    background-color: #702459 !important
  }

  .lg\:cursor-auto {
    cursor: auto !important
  }

  .lg\:cursor-default {
    cursor: default !important
  }

  .lg\:cursor-pointer {
    cursor: pointer !important
  }

  .lg\:cursor-wait {
    cursor: wait !important
  }

  .lg\:cursor-text {
    cursor: text !important
  }

  .lg\:cursor-move {
    cursor: move !important
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed !important
  }

  .lg\:h-0 {
    height: 0 !important
  }

  .lg\:h-4 {
    height: 4px !important
  }

  .lg\:h-6 {
    height: 6px !important
  }

  .lg\:h-8 {
    height: 8px !important
  }

  .lg\:h-12 {
    height: 12px !important
  }

  .lg\:h-16 {
    height: 16px !important
  }

  .lg\:h-20 {
    height: 20px !important
  }

  .lg\:h-24 {
    height: 24px !important
  }

  .lg\:h-28 {
    height: 28px !important
  }

  .lg\:h-32 {
    height: 32px !important
  }

  .lg\:h-36 {
    height: 36px !important
  }

  .lg\:h-40 {
    height: 40px !important
  }

  .lg\:h-44 {
    height: 44px !important
  }

  .lg\:h-48 {
    height: 48px !important
  }

  .lg\:h-52 {
    height: 52px !important
  }

  .lg\:h-56 {
    height: 56px !important
  }

  .lg\:h-60 {
    height: 60px !important
  }

  .lg\:h-64 {
    height: 64px !important
  }

  .lg\:h-68 {
    height: 68px !important
  }

  .lg\:h-72 {
    height: 72px !important
  }

  .lg\:h-76 {
    height: 76px !important
  }

  .lg\:h-80 {
    height: 80px !important
  }

  .lg\:h-84 {
    height: 84px !important
  }

  .lg\:h-88 {
    height: 88px !important
  }

  .lg\:h-92 {
    height: 92px !important
  }

  .lg\:h-96 {
    height: 96px !important
  }

  .lg\:h-128 {
    height: 128px !important
  }

  .lg\:h-160 {
    height: 160px !important
  }

  .lg\:h-192 {
    height: 192px !important
  }

  .lg\:h-224 {
    height: 224px !important
  }

  .lg\:h-256 {
    height: 256px !important
  }

  .lg\:h-320 {
    height: 320px !important
  }

  .lg\:h-auto {
    height: auto !important
  }

  .lg\:h-px {
    height: 1px !important
  }

  .lg\:h-full {
    height: 100% !important
  }

  .lg\:h-screen {
    height: 100vh !important
  }

  .lg\:w-0 {
    width: 0 !important
  }

  .lg\:w-4 {
    width: 4px !important
  }

  .lg\:w-6 {
    width: 6px !important
  }

  .lg\:w-8 {
    width: 8px !important
  }

  .lg\:w-12 {
    width: 12px !important
  }

  .lg\:w-16 {
    width: 16px !important
  }

  .lg\:w-20 {
    width: 20px !important
  }

  .lg\:w-24 {
    width: 24px !important
  }

  .lg\:w-28 {
    width: 28px !important
  }

  .lg\:w-32 {
    width: 32px !important
  }

  .lg\:w-36 {
    width: 36px !important
  }

  .lg\:w-40 {
    width: 40px !important
  }

  .lg\:w-44 {
    width: 44px !important
  }

  .lg\:w-48 {
    width: 48px !important
  }

  .lg\:w-52 {
    width: 52px !important
  }

  .lg\:w-56 {
    width: 56px !important
  }

  .lg\:w-60 {
    width: 60px !important
  }

  .lg\:w-64 {
    width: 64px !important
  }

  .lg\:w-68 {
    width: 68px !important
  }

  .lg\:w-72 {
    width: 72px !important
  }

  .lg\:w-76 {
    width: 76px !important
  }

  .lg\:w-80 {
    width: 80px !important
  }

  .lg\:w-84 {
    width: 84px !important
  }

  .lg\:w-88 {
    width: 88px !important
  }

  .lg\:w-92 {
    width: 92px !important
  }

  .lg\:w-96 {
    width: 96px !important
  }

  .lg\:w-128 {
    width: 128px !important
  }

  .lg\:w-160 {
    width: 160px !important
  }

  .lg\:w-192 {
    width: 192px !important
  }

  .lg\:w-224 {
    width: 224px !important
  }

  .lg\:w-256 {
    width: 256px !important
  }

  .lg\:w-320 {
    width: 320px !important
  }

  .lg\:w-auto {
    width: auto !important
  }

  .lg\:w-px {
    width: 1px !important
  }

  .lg\:w-1\/2 {
    width: 50% !important
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important
  }

  .lg\:w-1\/4 {
    width: 25% !important
  }

  .lg\:w-2\/4 {
    width: 50% !important
  }

  .lg\:w-3\/4 {
    width: 75% !important
  }

  .lg\:w-1\/5 {
    width: 20% !important
  }

  .lg\:w-2\/5 {
    width: 40% !important
  }

  .lg\:w-3\/5 {
    width: 60% !important
  }

  .lg\:w-4\/5 {
    width: 80% !important
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important
  }

  .lg\:w-3\/6 {
    width: 50% !important
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important
  }

  .lg\:w-2\/12 {
    width: 16.666667% !important
  }

  .lg\:w-3\/12 {
    width: 25% !important
  }

  .lg\:w-4\/12 {
    width: 33.333333% !important
  }

  .lg\:w-5\/12 {
    width: 41.666667% !important
  }

  .lg\:w-6\/12 {
    width: 50% !important
  }

  .lg\:w-7\/12 {
    width: 58.333333% !important
  }

  .lg\:w-8\/12 {
    width: 66.666667% !important
  }

  .lg\:w-9\/12 {
    width: 75% !important
  }

  .lg\:w-10\/12 {
    width: 83.333333% !important
  }

  .lg\:w-11\/12 {
    width: 91.666667% !important
  }

  .lg\:w-full {
    width: 100% !important
  }

  .lg\:w-screen {
    width: 100vw !important
  }

  .lg\:max-h-full {
    max-height: 100% !important
  }

  .lg\:max-h-screen {
    max-height: 100vh !important
  }

  .lg\:max-w-none {
    max-width: none !important
  }

  .lg\:max-w-xs {
    max-width: 20rem !important
  }

  .lg\:max-w-sm {
    max-width: 24rem !important
  }

  .lg\:max-w-md {
    max-width: 28rem !important
  }

  .lg\:max-w-lg {
    max-width: 32rem !important
  }

  .lg\:max-w-xl {
    max-width: 36rem !important
  }

  .lg\:max-w-2xl {
    max-width: 42rem !important
  }

  .lg\:max-w-3xl {
    max-width: 48rem !important
  }

  .lg\:max-w-4xl {
    max-width: 56rem !important
  }

  .lg\:max-w-5xl {
    max-width: 64rem !important
  }

  .lg\:max-w-6xl {
    max-width: 72rem !important
  }

  .lg\:max-w-full {
    max-width: 100% !important
  }

  .lg\:max-w-screen-sm {
    max-width: 640px !important
  }

  .lg\:max-w-screen-md {
    max-width: 768px !important
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px !important
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px !important
  }

  .lg\:min-h-0 {
    min-height: 0 !important
  }

  .lg\:min-h-full {
    min-height: 100% !important
  }

  .lg\:min-h-screen {
    min-height: 100vh !important
  }

  .lg\:min-w-0 {
    min-width: 0 !important
  }

  .lg\:min-w-full {
    min-width: 100% !important
  }

  .lg\:overflow-auto {
    overflow: auto !important
  }

  .lg\:overflow-hidden {
    overflow: hidden !important
  }

  .lg\:overflow-visible {
    overflow: visible !important
  }

  .lg\:overflow-scroll {
    overflow: scroll !important
  }

  .lg\:overflow-x-auto {
    overflow-x: auto !important
  }

  .lg\:overflow-y-auto {
    overflow-y: auto !important
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .lg\:static {
    position: static !important
  }

  .lg\:fixed {
    position: fixed !important
  }

  .lg\:absolute {
    position: absolute !important
  }

  .lg\:relative {
    position: relative !important
  }

  .lg\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important
  }

  .lg\:z-0 {
    z-index: 0 !important
  }

  .lg\:z-10 {
    z-index: 10 !important
  }

  .lg\:z-20 {
    z-index: 20 !important
  }

  .lg\:z-30 {
    z-index: 30 !important
  }

  .lg\:z-40 {
    z-index: 40 !important
  }

  .lg\:z-50 {
    z-index: 50 !important
  }

  .lg\:z-auto {
    z-index: auto !important
  }

  .lg\:block {
    display: block !important
  }

  .lg\:inline-block {
    display: inline-block !important
  }

  .lg\:inline {
    display: inline !important
  }

  .lg\:flex {
    display: flex !important
  }

  .lg\:inline-flex {
    display: inline-flex !important
  }

  .lg\:table {
    display: table !important
  }

  .lg\:table-caption {
    display: table-caption !important
  }

  .lg\:table-cell {
    display: table-cell !important
  }

  .lg\:table-column {
    display: table-column !important
  }

  .lg\:table-column-group {
    display: table-column-group !important
  }

  .lg\:table-footer-group {
    display: table-footer-group !important
  }

  .lg\:table-header-group {
    display: table-header-group !important
  }

  .lg\:table-row-group {
    display: table-row-group !important
  }

  .lg\:table-row {
    display: table-row !important
  }

  .lg\:flow-root {
    display: flow-root !important
  }

  .lg\:grid {
    display: grid !important
  }

  .lg\:inline-grid {
    display: inline-grid !important
  }

  .lg\:contents {
    display: contents !important
  }

  .lg\:hidden {
    display: none !important
  }

  .lg\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important
  }

  .lg\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important
  }

  .lg\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important
  }

  .lg\:inset-x-0 {
    right: 0 !important;
    left: 0 !important
  }

  .lg\:inset-y-auto {
    top: auto !important;
    bottom: auto !important
  }

  .lg\:inset-x-auto {
    right: auto !important;
    left: auto !important
  }

  .lg\:top-0 {
    top: 0 !important
  }

  .lg\:right-0 {
    right: 0 !important
  }

  .lg\:bottom-0 {
    bottom: 0 !important
  }

  .lg\:left-0 {
    left: 0 !important
  }

  .lg\:top-auto {
    top: auto !important
  }

  .lg\:right-auto {
    right: auto !important
  }

  .lg\:bottom-auto {
    bottom: auto !important
  }

  .lg\:left-auto {
    left: auto !important
  }
}

@media (min-width: 1280px) {
  .xl\:m-0 {
    margin: 0 !important
  }

  .xl\:m-4 {
    margin: 4px !important
  }

  .xl\:m-6 {
    margin: 6px !important
  }

  .xl\:m-8 {
    margin: 8px !important
  }

  .xl\:m-12 {
    margin: 12px !important
  }

  .xl\:m-16 {
    margin: 16px !important
  }

  .xl\:m-20 {
    margin: 20px !important
  }

  .xl\:m-24 {
    margin: 24px !important
  }

  .xl\:m-28 {
    margin: 28px !important
  }

  .xl\:m-32 {
    margin: 32px !important
  }

  .xl\:m-36 {
    margin: 36px !important
  }

  .xl\:m-40 {
    margin: 40px !important
  }

  .xl\:m-44 {
    margin: 44px !important
  }

  .xl\:m-48 {
    margin: 48px !important
  }

  .xl\:m-52 {
    margin: 52px !important
  }

  .xl\:m-56 {
    margin: 56px !important
  }

  .xl\:m-60 {
    margin: 60px !important
  }

  .xl\:m-64 {
    margin: 64px !important
  }

  .xl\:m-68 {
    margin: 68px !important
  }

  .xl\:m-72 {
    margin: 72px !important
  }

  .xl\:m-76 {
    margin: 76px !important
  }

  .xl\:m-80 {
    margin: 80px !important
  }

  .xl\:m-84 {
    margin: 84px !important
  }

  .xl\:m-88 {
    margin: 88px !important
  }

  .xl\:m-92 {
    margin: 92px !important
  }

  .xl\:m-96 {
    margin: 96px !important
  }

  .xl\:m-128 {
    margin: 128px !important
  }

  .xl\:m-160 {
    margin: 160px !important
  }

  .xl\:m-192 {
    margin: 192px !important
  }

  .xl\:m-224 {
    margin: 224px !important
  }

  .xl\:m-256 {
    margin: 256px !important
  }

  .xl\:m-320 {
    margin: 320px !important
  }

  .xl\:m-auto {
    margin: auto !important
  }

  .xl\:m-px {
    margin: 1px !important
  }

  .xl\:-m-4 {
    margin: -4px !important
  }

  .xl\:-m-6 {
    margin: -6px !important
  }

  .xl\:-m-8 {
    margin: -8px !important
  }

  .xl\:-m-12 {
    margin: -12px !important
  }

  .xl\:-m-16 {
    margin: -16px !important
  }

  .xl\:-m-20 {
    margin: -20px !important
  }

  .xl\:-m-24 {
    margin: -24px !important
  }

  .xl\:-m-28 {
    margin: -28px !important
  }

  .xl\:-m-32 {
    margin: -32px !important
  }

  .xl\:-m-36 {
    margin: -36px !important
  }

  .xl\:-m-40 {
    margin: -40px !important
  }

  .xl\:-m-44 {
    margin: -44px !important
  }

  .xl\:-m-48 {
    margin: -48px !important
  }

  .xl\:-m-52 {
    margin: -52px !important
  }

  .xl\:-m-56 {
    margin: -56px !important
  }

  .xl\:-m-60 {
    margin: -60px !important
  }

  .xl\:-m-64 {
    margin: -64px !important
  }

  .xl\:-m-68 {
    margin: -68px !important
  }

  .xl\:-m-72 {
    margin: -72px !important
  }

  .xl\:-m-76 {
    margin: -76px !important
  }

  .xl\:-m-80 {
    margin: -80px !important
  }

  .xl\:-m-84 {
    margin: -84px !important
  }

  .xl\:-m-88 {
    margin: -88px !important
  }

  .xl\:-m-92 {
    margin: -92px !important
  }

  .xl\:-m-96 {
    margin: -96px !important
  }

  .xl\:-m-128 {
    margin: -128px !important
  }

  .xl\:-m-160 {
    margin: -160px !important
  }

  .xl\:-m-192 {
    margin: -192px !important
  }

  .xl\:-m-224 {
    margin: -224px !important
  }

  .xl\:-m-256 {
    margin: -256px !important
  }

  .xl\:-m-320 {
    margin: -320px !important
  }

  .xl\:-m-px {
    margin: -1px !important
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .xl\:my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important
  }

  .xl\:mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important
  }

  .xl\:my-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important
  }

  .xl\:mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important
  }

  .xl\:my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important
  }

  .xl\:mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important
  }

  .xl\:my-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important
  }

  .xl\:mx-12 {
    margin-left: 12px !important;
    margin-right: 12px !important
  }

  .xl\:my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important
  }

  .xl\:mx-16 {
    margin-left: 16px !important;
    margin-right: 16px !important
  }

  .xl\:my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
  }

  .xl\:mx-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
  }

  .xl\:my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important
  }

  .xl\:mx-24 {
    margin-left: 24px !important;
    margin-right: 24px !important
  }

  .xl\:my-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important
  }

  .xl\:mx-28 {
    margin-left: 28px !important;
    margin-right: 28px !important
  }

  .xl\:my-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important
  }

  .xl\:mx-32 {
    margin-left: 32px !important;
    margin-right: 32px !important
  }

  .xl\:my-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important
  }

  .xl\:mx-36 {
    margin-left: 36px !important;
    margin-right: 36px !important
  }

  .xl\:my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important
  }

  .xl\:mx-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
  }

  .xl\:my-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important
  }

  .xl\:mx-44 {
    margin-left: 44px !important;
    margin-right: 44px !important
  }

  .xl\:my-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important
  }

  .xl\:mx-48 {
    margin-left: 48px !important;
    margin-right: 48px !important
  }

  .xl\:my-52 {
    margin-top: 52px !important;
    margin-bottom: 52px !important
  }

  .xl\:mx-52 {
    margin-left: 52px !important;
    margin-right: 52px !important
  }

  .xl\:my-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important
  }

  .xl\:mx-56 {
    margin-left: 56px !important;
    margin-right: 56px !important
  }

  .xl\:my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important
  }

  .xl\:mx-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
  }

  .xl\:my-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important
  }

  .xl\:mx-64 {
    margin-left: 64px !important;
    margin-right: 64px !important
  }

  .xl\:my-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important
  }

  .xl\:mx-68 {
    margin-left: 68px !important;
    margin-right: 68px !important
  }

  .xl\:my-72 {
    margin-top: 72px !important;
    margin-bottom: 72px !important
  }

  .xl\:mx-72 {
    margin-left: 72px !important;
    margin-right: 72px !important
  }

  .xl\:my-76 {
    margin-top: 76px !important;
    margin-bottom: 76px !important
  }

  .xl\:mx-76 {
    margin-left: 76px !important;
    margin-right: 76px !important
  }

  .xl\:my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important
  }

  .xl\:mx-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
  }

  .xl\:my-84 {
    margin-top: 84px !important;
    margin-bottom: 84px !important
  }

  .xl\:mx-84 {
    margin-left: 84px !important;
    margin-right: 84px !important
  }

  .xl\:my-88 {
    margin-top: 88px !important;
    margin-bottom: 88px !important
  }

  .xl\:mx-88 {
    margin-left: 88px !important;
    margin-right: 88px !important
  }

  .xl\:my-92 {
    margin-top: 92px !important;
    margin-bottom: 92px !important
  }

  .xl\:mx-92 {
    margin-left: 92px !important;
    margin-right: 92px !important
  }

  .xl\:my-96 {
    margin-top: 96px !important;
    margin-bottom: 96px !important
  }

  .xl\:mx-96 {
    margin-left: 96px !important;
    margin-right: 96px !important
  }

  .xl\:my-128 {
    margin-top: 128px !important;
    margin-bottom: 128px !important
  }

  .xl\:mx-128 {
    margin-left: 128px !important;
    margin-right: 128px !important
  }

  .xl\:my-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important
  }

  .xl\:mx-160 {
    margin-left: 160px !important;
    margin-right: 160px !important
  }

  .xl\:my-192 {
    margin-top: 192px !important;
    margin-bottom: 192px !important
  }

  .xl\:mx-192 {
    margin-left: 192px !important;
    margin-right: 192px !important
  }

  .xl\:my-224 {
    margin-top: 224px !important;
    margin-bottom: 224px !important
  }

  .xl\:mx-224 {
    margin-left: 224px !important;
    margin-right: 224px !important
  }

  .xl\:my-256 {
    margin-top: 256px !important;
    margin-bottom: 256px !important
  }

  .xl\:mx-256 {
    margin-left: 256px !important;
    margin-right: 256px !important
  }

  .xl\:my-320 {
    margin-top: 320px !important;
    margin-bottom: 320px !important
  }

  .xl\:mx-320 {
    margin-left: 320px !important;
    margin-right: 320px !important
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .xl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important
  }

  .xl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
  }

  .xl\:-my-4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important
  }

  .xl\:-mx-4 {
    margin-left: -4px !important;
    margin-right: -4px !important
  }

  .xl\:-my-6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important
  }

  .xl\:-mx-6 {
    margin-left: -6px !important;
    margin-right: -6px !important
  }

  .xl\:-my-8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important
  }

  .xl\:-mx-8 {
    margin-left: -8px !important;
    margin-right: -8px !important
  }

  .xl\:-my-12 {
    margin-top: -12px !important;
    margin-bottom: -12px !important
  }

  .xl\:-mx-12 {
    margin-left: -12px !important;
    margin-right: -12px !important
  }

  .xl\:-my-16 {
    margin-top: -16px !important;
    margin-bottom: -16px !important
  }

  .xl\:-mx-16 {
    margin-left: -16px !important;
    margin-right: -16px !important
  }

  .xl\:-my-20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important
  }

  .xl\:-mx-20 {
    margin-left: -20px !important;
    margin-right: -20px !important
  }

  .xl\:-my-24 {
    margin-top: -24px !important;
    margin-bottom: -24px !important
  }

  .xl\:-mx-24 {
    margin-left: -24px !important;
    margin-right: -24px !important
  }

  .xl\:-my-28 {
    margin-top: -28px !important;
    margin-bottom: -28px !important
  }

  .xl\:-mx-28 {
    margin-left: -28px !important;
    margin-right: -28px !important
  }

  .xl\:-my-32 {
    margin-top: -32px !important;
    margin-bottom: -32px !important
  }

  .xl\:-mx-32 {
    margin-left: -32px !important;
    margin-right: -32px !important
  }

  .xl\:-my-36 {
    margin-top: -36px !important;
    margin-bottom: -36px !important
  }

  .xl\:-mx-36 {
    margin-left: -36px !important;
    margin-right: -36px !important
  }

  .xl\:-my-40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important
  }

  .xl\:-mx-40 {
    margin-left: -40px !important;
    margin-right: -40px !important
  }

  .xl\:-my-44 {
    margin-top: -44px !important;
    margin-bottom: -44px !important
  }

  .xl\:-mx-44 {
    margin-left: -44px !important;
    margin-right: -44px !important
  }

  .xl\:-my-48 {
    margin-top: -48px !important;
    margin-bottom: -48px !important
  }

  .xl\:-mx-48 {
    margin-left: -48px !important;
    margin-right: -48px !important
  }

  .xl\:-my-52 {
    margin-top: -52px !important;
    margin-bottom: -52px !important
  }

  .xl\:-mx-52 {
    margin-left: -52px !important;
    margin-right: -52px !important
  }

  .xl\:-my-56 {
    margin-top: -56px !important;
    margin-bottom: -56px !important
  }

  .xl\:-mx-56 {
    margin-left: -56px !important;
    margin-right: -56px !important
  }

  .xl\:-my-60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important
  }

  .xl\:-mx-60 {
    margin-left: -60px !important;
    margin-right: -60px !important
  }

  .xl\:-my-64 {
    margin-top: -64px !important;
    margin-bottom: -64px !important
  }

  .xl\:-mx-64 {
    margin-left: -64px !important;
    margin-right: -64px !important
  }

  .xl\:-my-68 {
    margin-top: -68px !important;
    margin-bottom: -68px !important
  }

  .xl\:-mx-68 {
    margin-left: -68px !important;
    margin-right: -68px !important
  }

  .xl\:-my-72 {
    margin-top: -72px !important;
    margin-bottom: -72px !important
  }

  .xl\:-mx-72 {
    margin-left: -72px !important;
    margin-right: -72px !important
  }

  .xl\:-my-76 {
    margin-top: -76px !important;
    margin-bottom: -76px !important
  }

  .xl\:-mx-76 {
    margin-left: -76px !important;
    margin-right: -76px !important
  }

  .xl\:-my-80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important
  }

  .xl\:-mx-80 {
    margin-left: -80px !important;
    margin-right: -80px !important
  }

  .xl\:-my-84 {
    margin-top: -84px !important;
    margin-bottom: -84px !important
  }

  .xl\:-mx-84 {
    margin-left: -84px !important;
    margin-right: -84px !important
  }

  .xl\:-my-88 {
    margin-top: -88px !important;
    margin-bottom: -88px !important
  }

  .xl\:-mx-88 {
    margin-left: -88px !important;
    margin-right: -88px !important
  }

  .xl\:-my-92 {
    margin-top: -92px !important;
    margin-bottom: -92px !important
  }

  .xl\:-mx-92 {
    margin-left: -92px !important;
    margin-right: -92px !important
  }

  .xl\:-my-96 {
    margin-top: -96px !important;
    margin-bottom: -96px !important
  }

  .xl\:-mx-96 {
    margin-left: -96px !important;
    margin-right: -96px !important
  }

  .xl\:-my-128 {
    margin-top: -128px !important;
    margin-bottom: -128px !important
  }

  .xl\:-mx-128 {
    margin-left: -128px !important;
    margin-right: -128px !important
  }

  .xl\:-my-160 {
    margin-top: -160px !important;
    margin-bottom: -160px !important
  }

  .xl\:-mx-160 {
    margin-left: -160px !important;
    margin-right: -160px !important
  }

  .xl\:-my-192 {
    margin-top: -192px !important;
    margin-bottom: -192px !important
  }

  .xl\:-mx-192 {
    margin-left: -192px !important;
    margin-right: -192px !important
  }

  .xl\:-my-224 {
    margin-top: -224px !important;
    margin-bottom: -224px !important
  }

  .xl\:-mx-224 {
    margin-left: -224px !important;
    margin-right: -224px !important
  }

  .xl\:-my-256 {
    margin-top: -256px !important;
    margin-bottom: -256px !important
  }

  .xl\:-mx-256 {
    margin-left: -256px !important;
    margin-right: -256px !important
  }

  .xl\:-my-320 {
    margin-top: -320px !important;
    margin-bottom: -320px !important
  }

  .xl\:-mx-320 {
    margin-left: -320px !important;
    margin-right: -320px !important
  }

  .xl\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important
  }

  .xl\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important
  }

  .xl\:mt-0 {
    margin-top: 0 !important
  }

  .xl\:mr-0 {
    margin-right: 0 !important
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important
  }

  .xl\:ml-0 {
    margin-left: 0 !important
  }

  .xl\:mt-4 {
    margin-top: 4px !important
  }

  .xl\:mr-4 {
    margin-right: 4px !important
  }

  .xl\:mb-4 {
    margin-bottom: 4px !important
  }

  .xl\:ml-4 {
    margin-left: 4px !important
  }

  .xl\:mt-6 {
    margin-top: 6px !important
  }

  .xl\:mr-6 {
    margin-right: 6px !important
  }

  .xl\:mb-6 {
    margin-bottom: 6px !important
  }

  .xl\:ml-6 {
    margin-left: 6px !important
  }

  .xl\:mt-8 {
    margin-top: 8px !important
  }

  .xl\:mr-8 {
    margin-right: 8px !important
  }

  .xl\:mb-8 {
    margin-bottom: 8px !important
  }

  .xl\:ml-8 {
    margin-left: 8px !important
  }

  .xl\:mt-12 {
    margin-top: 12px !important
  }

  .xl\:mr-12 {
    margin-right: 12px !important
  }

  .xl\:mb-12 {
    margin-bottom: 12px !important
  }

  .xl\:ml-12 {
    margin-left: 12px !important
  }

  .xl\:mt-16 {
    margin-top: 16px !important
  }

  .xl\:mr-16 {
    margin-right: 16px !important
  }

  .xl\:mb-16 {
    margin-bottom: 16px !important
  }

  .xl\:ml-16 {
    margin-left: 16px !important
  }

  .xl\:mt-20 {
    margin-top: 20px !important
  }

  .xl\:mr-20 {
    margin-right: 20px !important
  }

  .xl\:mb-20 {
    margin-bottom: 20px !important
  }

  .xl\:ml-20 {
    margin-left: 20px !important
  }

  .xl\:mt-24 {
    margin-top: 24px !important
  }

  .xl\:mr-24 {
    margin-right: 24px !important
  }

  .xl\:mb-24 {
    margin-bottom: 24px !important
  }

  .xl\:ml-24 {
    margin-left: 24px !important
  }

  .xl\:mt-28 {
    margin-top: 28px !important
  }

  .xl\:mr-28 {
    margin-right: 28px !important
  }

  .xl\:mb-28 {
    margin-bottom: 28px !important
  }

  .xl\:ml-28 {
    margin-left: 28px !important
  }

  .xl\:mt-32 {
    margin-top: 32px !important
  }

  .xl\:mr-32 {
    margin-right: 32px !important
  }

  .xl\:mb-32 {
    margin-bottom: 32px !important
  }

  .xl\:ml-32 {
    margin-left: 32px !important
  }

  .xl\:mt-36 {
    margin-top: 36px !important
  }

  .xl\:mr-36 {
    margin-right: 36px !important
  }

  .xl\:mb-36 {
    margin-bottom: 36px !important
  }

  .xl\:ml-36 {
    margin-left: 36px !important
  }

  .xl\:mt-40 {
    margin-top: 40px !important
  }

  .xl\:mr-40 {
    margin-right: 40px !important
  }

  .xl\:mb-40 {
    margin-bottom: 40px !important
  }

  .xl\:ml-40 {
    margin-left: 40px !important
  }

  .xl\:mt-44 {
    margin-top: 44px !important
  }

  .xl\:mr-44 {
    margin-right: 44px !important
  }

  .xl\:mb-44 {
    margin-bottom: 44px !important
  }

  .xl\:ml-44 {
    margin-left: 44px !important
  }

  .xl\:mt-48 {
    margin-top: 48px !important
  }

  .xl\:mr-48 {
    margin-right: 48px !important
  }

  .xl\:mb-48 {
    margin-bottom: 48px !important
  }

  .xl\:ml-48 {
    margin-left: 48px !important
  }

  .xl\:mt-52 {
    margin-top: 52px !important
  }

  .xl\:mr-52 {
    margin-right: 52px !important
  }

  .xl\:mb-52 {
    margin-bottom: 52px !important
  }

  .xl\:ml-52 {
    margin-left: 52px !important
  }

  .xl\:mt-56 {
    margin-top: 56px !important
  }

  .xl\:mr-56 {
    margin-right: 56px !important
  }

  .xl\:mb-56 {
    margin-bottom: 56px !important
  }

  .xl\:ml-56 {
    margin-left: 56px !important
  }

  .xl\:mt-60 {
    margin-top: 60px !important
  }

  .xl\:mr-60 {
    margin-right: 60px !important
  }

  .xl\:mb-60 {
    margin-bottom: 60px !important
  }

  .xl\:ml-60 {
    margin-left: 60px !important
  }

  .xl\:mt-64 {
    margin-top: 64px !important
  }

  .xl\:mr-64 {
    margin-right: 64px !important
  }

  .xl\:mb-64 {
    margin-bottom: 64px !important
  }

  .xl\:ml-64 {
    margin-left: 64px !important
  }

  .xl\:mt-68 {
    margin-top: 68px !important
  }

  .xl\:mr-68 {
    margin-right: 68px !important
  }

  .xl\:mb-68 {
    margin-bottom: 68px !important
  }

  .xl\:ml-68 {
    margin-left: 68px !important
  }

  .xl\:mt-72 {
    margin-top: 72px !important
  }

  .xl\:mr-72 {
    margin-right: 72px !important
  }

  .xl\:mb-72 {
    margin-bottom: 72px !important
  }

  .xl\:ml-72 {
    margin-left: 72px !important
  }

  .xl\:mt-76 {
    margin-top: 76px !important
  }

  .xl\:mr-76 {
    margin-right: 76px !important
  }

  .xl\:mb-76 {
    margin-bottom: 76px !important
  }

  .xl\:ml-76 {
    margin-left: 76px !important
  }

  .xl\:mt-80 {
    margin-top: 80px !important
  }

  .xl\:mr-80 {
    margin-right: 80px !important
  }

  .xl\:mb-80 {
    margin-bottom: 80px !important
  }

  .xl\:ml-80 {
    margin-left: 80px !important
  }

  .xl\:mt-84 {
    margin-top: 84px !important
  }

  .xl\:mr-84 {
    margin-right: 84px !important
  }

  .xl\:mb-84 {
    margin-bottom: 84px !important
  }

  .xl\:ml-84 {
    margin-left: 84px !important
  }

  .xl\:mt-88 {
    margin-top: 88px !important
  }

  .xl\:mr-88 {
    margin-right: 88px !important
  }

  .xl\:mb-88 {
    margin-bottom: 88px !important
  }

  .xl\:ml-88 {
    margin-left: 88px !important
  }

  .xl\:mt-92 {
    margin-top: 92px !important
  }

  .xl\:mr-92 {
    margin-right: 92px !important
  }

  .xl\:mb-92 {
    margin-bottom: 92px !important
  }

  .xl\:ml-92 {
    margin-left: 92px !important
  }

  .xl\:mt-96 {
    margin-top: 96px !important
  }

  .xl\:mr-96 {
    margin-right: 96px !important
  }

  .xl\:mb-96 {
    margin-bottom: 96px !important
  }

  .xl\:ml-96 {
    margin-left: 96px !important
  }

  .xl\:mt-128 {
    margin-top: 128px !important
  }

  .xl\:mr-128 {
    margin-right: 128px !important
  }

  .xl\:mb-128 {
    margin-bottom: 128px !important
  }

  .xl\:ml-128 {
    margin-left: 128px !important
  }

  .xl\:mt-160 {
    margin-top: 160px !important
  }

  .xl\:mr-160 {
    margin-right: 160px !important
  }

  .xl\:mb-160 {
    margin-bottom: 160px !important
  }

  .xl\:ml-160 {
    margin-left: 160px !important
  }

  .xl\:mt-192 {
    margin-top: 192px !important
  }

  .xl\:mr-192 {
    margin-right: 192px !important
  }

  .xl\:mb-192 {
    margin-bottom: 192px !important
  }

  .xl\:ml-192 {
    margin-left: 192px !important
  }

  .xl\:mt-224 {
    margin-top: 224px !important
  }

  .xl\:mr-224 {
    margin-right: 224px !important
  }

  .xl\:mb-224 {
    margin-bottom: 224px !important
  }

  .xl\:ml-224 {
    margin-left: 224px !important
  }

  .xl\:mt-256 {
    margin-top: 256px !important
  }

  .xl\:mr-256 {
    margin-right: 256px !important
  }

  .xl\:mb-256 {
    margin-bottom: 256px !important
  }

  .xl\:ml-256 {
    margin-left: 256px !important
  }

  .xl\:mt-320 {
    margin-top: 320px !important
  }

  .xl\:mr-320 {
    margin-right: 320px !important
  }

  .xl\:mb-320 {
    margin-bottom: 320px !important
  }

  .xl\:ml-320 {
    margin-left: 320px !important
  }

  .xl\:mt-auto {
    margin-top: auto !important
  }

  .xl\:mr-auto {
    margin-right: auto !important
  }

  .xl\:mb-auto {
    margin-bottom: auto !important
  }

  .xl\:ml-auto {
    margin-left: auto !important
  }

  .xl\:mt-px {
    margin-top: 1px !important
  }

  .xl\:mr-px {
    margin-right: 1px !important
  }

  .xl\:mb-px {
    margin-bottom: 1px !important
  }

  .xl\:ml-px {
    margin-left: 1px !important
  }

  .xl\:-mt-4 {
    margin-top: -4px !important
  }

  .xl\:-mr-4 {
    margin-right: -4px !important
  }

  .xl\:-mb-4 {
    margin-bottom: -4px !important
  }

  .xl\:-ml-4 {
    margin-left: -4px !important
  }

  .xl\:-mt-6 {
    margin-top: -6px !important
  }

  .xl\:-mr-6 {
    margin-right: -6px !important
  }

  .xl\:-mb-6 {
    margin-bottom: -6px !important
  }

  .xl\:-ml-6 {
    margin-left: -6px !important
  }

  .xl\:-mt-8 {
    margin-top: -8px !important
  }

  .xl\:-mr-8 {
    margin-right: -8px !important
  }

  .xl\:-mb-8 {
    margin-bottom: -8px !important
  }

  .xl\:-ml-8 {
    margin-left: -8px !important
  }

  .xl\:-mt-12 {
    margin-top: -12px !important
  }

  .xl\:-mr-12 {
    margin-right: -12px !important
  }

  .xl\:-mb-12 {
    margin-bottom: -12px !important
  }

  .xl\:-ml-12 {
    margin-left: -12px !important
  }

  .xl\:-mt-16 {
    margin-top: -16px !important
  }

  .xl\:-mr-16 {
    margin-right: -16px !important
  }

  .xl\:-mb-16 {
    margin-bottom: -16px !important
  }

  .xl\:-ml-16 {
    margin-left: -16px !important
  }

  .xl\:-mt-20 {
    margin-top: -20px !important
  }

  .xl\:-mr-20 {
    margin-right: -20px !important
  }

  .xl\:-mb-20 {
    margin-bottom: -20px !important
  }

  .xl\:-ml-20 {
    margin-left: -20px !important
  }

  .xl\:-mt-24 {
    margin-top: -24px !important
  }

  .xl\:-mr-24 {
    margin-right: -24px !important
  }

  .xl\:-mb-24 {
    margin-bottom: -24px !important
  }

  .xl\:-ml-24 {
    margin-left: -24px !important
  }

  .xl\:-mt-28 {
    margin-top: -28px !important
  }

  .xl\:-mr-28 {
    margin-right: -28px !important
  }

  .xl\:-mb-28 {
    margin-bottom: -28px !important
  }

  .xl\:-ml-28 {
    margin-left: -28px !important
  }

  .xl\:-mt-32 {
    margin-top: -32px !important
  }

  .xl\:-mr-32 {
    margin-right: -32px !important
  }

  .xl\:-mb-32 {
    margin-bottom: -32px !important
  }

  .xl\:-ml-32 {
    margin-left: -32px !important
  }

  .xl\:-mt-36 {
    margin-top: -36px !important
  }

  .xl\:-mr-36 {
    margin-right: -36px !important
  }

  .xl\:-mb-36 {
    margin-bottom: -36px !important
  }

  .xl\:-ml-36 {
    margin-left: -36px !important
  }

  .xl\:-mt-40 {
    margin-top: -40px !important
  }

  .xl\:-mr-40 {
    margin-right: -40px !important
  }

  .xl\:-mb-40 {
    margin-bottom: -40px !important
  }

  .xl\:-ml-40 {
    margin-left: -40px !important
  }

  .xl\:-mt-44 {
    margin-top: -44px !important
  }

  .xl\:-mr-44 {
    margin-right: -44px !important
  }

  .xl\:-mb-44 {
    margin-bottom: -44px !important
  }

  .xl\:-ml-44 {
    margin-left: -44px !important
  }

  .xl\:-mt-48 {
    margin-top: -48px !important
  }

  .xl\:-mr-48 {
    margin-right: -48px !important
  }

  .xl\:-mb-48 {
    margin-bottom: -48px !important
  }

  .xl\:-ml-48 {
    margin-left: -48px !important
  }

  .xl\:-mt-52 {
    margin-top: -52px !important
  }

  .xl\:-mr-52 {
    margin-right: -52px !important
  }

  .xl\:-mb-52 {
    margin-bottom: -52px !important
  }

  .xl\:-ml-52 {
    margin-left: -52px !important
  }

  .xl\:-mt-56 {
    margin-top: -56px !important
  }

  .xl\:-mr-56 {
    margin-right: -56px !important
  }

  .xl\:-mb-56 {
    margin-bottom: -56px !important
  }

  .xl\:-ml-56 {
    margin-left: -56px !important
  }

  .xl\:-mt-60 {
    margin-top: -60px !important
  }

  .xl\:-mr-60 {
    margin-right: -60px !important
  }

  .xl\:-mb-60 {
    margin-bottom: -60px !important
  }

  .xl\:-ml-60 {
    margin-left: -60px !important
  }

  .xl\:-mt-64 {
    margin-top: -64px !important
  }

  .xl\:-mr-64 {
    margin-right: -64px !important
  }

  .xl\:-mb-64 {
    margin-bottom: -64px !important
  }

  .xl\:-ml-64 {
    margin-left: -64px !important
  }

  .xl\:-mt-68 {
    margin-top: -68px !important
  }

  .xl\:-mr-68 {
    margin-right: -68px !important
  }

  .xl\:-mb-68 {
    margin-bottom: -68px !important
  }

  .xl\:-ml-68 {
    margin-left: -68px !important
  }

  .xl\:-mt-72 {
    margin-top: -72px !important
  }

  .xl\:-mr-72 {
    margin-right: -72px !important
  }

  .xl\:-mb-72 {
    margin-bottom: -72px !important
  }

  .xl\:-ml-72 {
    margin-left: -72px !important
  }

  .xl\:-mt-76 {
    margin-top: -76px !important
  }

  .xl\:-mr-76 {
    margin-right: -76px !important
  }

  .xl\:-mb-76 {
    margin-bottom: -76px !important
  }

  .xl\:-ml-76 {
    margin-left: -76px !important
  }

  .xl\:-mt-80 {
    margin-top: -80px !important
  }

  .xl\:-mr-80 {
    margin-right: -80px !important
  }

  .xl\:-mb-80 {
    margin-bottom: -80px !important
  }

  .xl\:-ml-80 {
    margin-left: -80px !important
  }

  .xl\:-mt-84 {
    margin-top: -84px !important
  }

  .xl\:-mr-84 {
    margin-right: -84px !important
  }

  .xl\:-mb-84 {
    margin-bottom: -84px !important
  }

  .xl\:-ml-84 {
    margin-left: -84px !important
  }

  .xl\:-mt-88 {
    margin-top: -88px !important
  }

  .xl\:-mr-88 {
    margin-right: -88px !important
  }

  .xl\:-mb-88 {
    margin-bottom: -88px !important
  }

  .xl\:-ml-88 {
    margin-left: -88px !important
  }

  .xl\:-mt-92 {
    margin-top: -92px !important
  }

  .xl\:-mr-92 {
    margin-right: -92px !important
  }

  .xl\:-mb-92 {
    margin-bottom: -92px !important
  }

  .xl\:-ml-92 {
    margin-left: -92px !important
  }

  .xl\:-mt-96 {
    margin-top: -96px !important
  }

  .xl\:-mr-96 {
    margin-right: -96px !important
  }

  .xl\:-mb-96 {
    margin-bottom: -96px !important
  }

  .xl\:-ml-96 {
    margin-left: -96px !important
  }

  .xl\:-mt-128 {
    margin-top: -128px !important
  }

  .xl\:-mr-128 {
    margin-right: -128px !important
  }

  .xl\:-mb-128 {
    margin-bottom: -128px !important
  }

  .xl\:-ml-128 {
    margin-left: -128px !important
  }

  .xl\:-mt-160 {
    margin-top: -160px !important
  }

  .xl\:-mr-160 {
    margin-right: -160px !important
  }

  .xl\:-mb-160 {
    margin-bottom: -160px !important
  }

  .xl\:-ml-160 {
    margin-left: -160px !important
  }

  .xl\:-mt-192 {
    margin-top: -192px !important
  }

  .xl\:-mr-192 {
    margin-right: -192px !important
  }

  .xl\:-mb-192 {
    margin-bottom: -192px !important
  }

  .xl\:-ml-192 {
    margin-left: -192px !important
  }

  .xl\:-mt-224 {
    margin-top: -224px !important
  }

  .xl\:-mr-224 {
    margin-right: -224px !important
  }

  .xl\:-mb-224 {
    margin-bottom: -224px !important
  }

  .xl\:-ml-224 {
    margin-left: -224px !important
  }

  .xl\:-mt-256 {
    margin-top: -256px !important
  }

  .xl\:-mr-256 {
    margin-right: -256px !important
  }

  .xl\:-mb-256 {
    margin-bottom: -256px !important
  }

  .xl\:-ml-256 {
    margin-left: -256px !important
  }

  .xl\:-mt-320 {
    margin-top: -320px !important
  }

  .xl\:-mr-320 {
    margin-right: -320px !important
  }

  .xl\:-mb-320 {
    margin-bottom: -320px !important
  }

  .xl\:-ml-320 {
    margin-left: -320px !important
  }

  .xl\:-mt-px {
    margin-top: -1px !important
  }

  .xl\:-mr-px {
    margin-right: -1px !important
  }

  .xl\:-mb-px {
    margin-bottom: -1px !important
  }

  .xl\:-ml-px {
    margin-left: -1px !important
  }

  .xl\:p-0 {
    padding: 0 !important
  }

  .xl\:p-4 {
    padding: 4px !important
  }

  .xl\:p-6 {
    padding: 6px !important
  }

  .xl\:p-8 {
    padding: 8px !important
  }

  .xl\:p-12 {
    padding: 12px !important
  }

  .xl\:p-16 {
    padding: 16px !important
  }

  .xl\:p-20 {
    padding: 20px !important
  }

  .xl\:p-24 {
    padding: 24px !important
  }

  .xl\:p-28 {
    padding: 28px !important
  }

  .xl\:p-32 {
    padding: 32px !important
  }

  .xl\:p-36 {
    padding: 36px !important
  }

  .xl\:p-40 {
    padding: 40px !important
  }

  .xl\:p-44 {
    padding: 44px !important
  }

  .xl\:p-48 {
    padding: 48px !important
  }

  .xl\:p-52 {
    padding: 52px !important
  }

  .xl\:p-56 {
    padding: 56px !important
  }

  .xl\:p-60 {
    padding: 60px !important
  }

  .xl\:p-64 {
    padding: 64px !important
  }

  .xl\:p-68 {
    padding: 68px !important
  }

  .xl\:p-72 {
    padding: 72px !important
  }

  .xl\:p-76 {
    padding: 76px !important
  }

  .xl\:p-80 {
    padding: 80px !important
  }

  .xl\:p-84 {
    padding: 84px !important
  }

  .xl\:p-88 {
    padding: 88px !important
  }

  .xl\:p-92 {
    padding: 92px !important
  }

  .xl\:p-96 {
    padding: 96px !important
  }

  .xl\:p-128 {
    padding: 128px !important
  }

  .xl\:p-160 {
    padding: 160px !important
  }

  .xl\:p-192 {
    padding: 192px !important
  }

  .xl\:p-224 {
    padding: 224px !important
  }

  .xl\:p-256 {
    padding: 256px !important
  }

  .xl\:p-320 {
    padding: 320px !important
  }

  .xl\:p-px {
    padding: 1px !important
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .xl\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important
  }

  .xl\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important
  }

  .xl\:py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important
  }

  .xl\:px-6 {
    padding-left: 6px !important;
    padding-right: 6px !important
  }

  .xl\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important
  }

  .xl\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important
  }

  .xl\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important
  }

  .xl\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important
  }

  .xl\:py-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
  }

  .xl\:px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important
  }

  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important
  }

  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
  }

  .xl\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
  }

  .xl\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important
  }

  .xl\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important
  }

  .xl\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important
  }

  .xl\:py-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important
  }

  .xl\:px-32 {
    padding-left: 32px !important;
    padding-right: 32px !important
  }

  .xl\:py-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important
  }

  .xl\:px-36 {
    padding-left: 36px !important;
    padding-right: 36px !important
  }

  .xl\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important
  }

  .xl\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
  }

  .xl\:py-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important
  }

  .xl\:px-44 {
    padding-left: 44px !important;
    padding-right: 44px !important
  }

  .xl\:py-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important
  }

  .xl\:px-48 {
    padding-left: 48px !important;
    padding-right: 48px !important
  }

  .xl\:py-52 {
    padding-top: 52px !important;
    padding-bottom: 52px !important
  }

  .xl\:px-52 {
    padding-left: 52px !important;
    padding-right: 52px !important
  }

  .xl\:py-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important
  }

  .xl\:px-56 {
    padding-left: 56px !important;
    padding-right: 56px !important
  }

  .xl\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important
  }

  .xl\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
  }

  .xl\:py-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important
  }

  .xl\:px-64 {
    padding-left: 64px !important;
    padding-right: 64px !important
  }

  .xl\:py-68 {
    padding-top: 68px !important;
    padding-bottom: 68px !important
  }

  .xl\:px-68 {
    padding-left: 68px !important;
    padding-right: 68px !important
  }

  .xl\:py-72 {
    padding-top: 72px !important;
    padding-bottom: 72px !important
  }

  .xl\:px-72 {
    padding-left: 72px !important;
    padding-right: 72px !important
  }

  .xl\:py-76 {
    padding-top: 76px !important;
    padding-bottom: 76px !important
  }

  .xl\:px-76 {
    padding-left: 76px !important;
    padding-right: 76px !important
  }

  .xl\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important
  }

  .xl\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
  }

  .xl\:py-84 {
    padding-top: 84px !important;
    padding-bottom: 84px !important
  }

  .xl\:px-84 {
    padding-left: 84px !important;
    padding-right: 84px !important
  }

  .xl\:py-88 {
    padding-top: 88px !important;
    padding-bottom: 88px !important
  }

  .xl\:px-88 {
    padding-left: 88px !important;
    padding-right: 88px !important
  }

  .xl\:py-92 {
    padding-top: 92px !important;
    padding-bottom: 92px !important
  }

  .xl\:px-92 {
    padding-left: 92px !important;
    padding-right: 92px !important
  }

  .xl\:py-96 {
    padding-top: 96px !important;
    padding-bottom: 96px !important
  }

  .xl\:px-96 {
    padding-left: 96px !important;
    padding-right: 96px !important
  }

  .xl\:py-128 {
    padding-top: 128px !important;
    padding-bottom: 128px !important
  }

  .xl\:px-128 {
    padding-left: 128px !important;
    padding-right: 128px !important
  }

  .xl\:py-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important
  }

  .xl\:px-160 {
    padding-left: 160px !important;
    padding-right: 160px !important
  }

  .xl\:py-192 {
    padding-top: 192px !important;
    padding-bottom: 192px !important
  }

  .xl\:px-192 {
    padding-left: 192px !important;
    padding-right: 192px !important
  }

  .xl\:py-224 {
    padding-top: 224px !important;
    padding-bottom: 224px !important
  }

  .xl\:px-224 {
    padding-left: 224px !important;
    padding-right: 224px !important
  }

  .xl\:py-256 {
    padding-top: 256px !important;
    padding-bottom: 256px !important
  }

  .xl\:px-256 {
    padding-left: 256px !important;
    padding-right: 256px !important
  }

  .xl\:py-320 {
    padding-top: 320px !important;
    padding-bottom: 320px !important
  }

  .xl\:px-320 {
    padding-left: 320px !important;
    padding-right: 320px !important
  }

  .xl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important
  }

  .xl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
  }

  .xl\:pt-0 {
    padding-top: 0 !important
  }

  .xl\:pr-0 {
    padding-right: 0 !important
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important
  }

  .xl\:pl-0 {
    padding-left: 0 !important
  }

  .xl\:pt-4 {
    padding-top: 4px !important
  }

  .xl\:pr-4 {
    padding-right: 4px !important
  }

  .xl\:pb-4 {
    padding-bottom: 4px !important
  }

  .xl\:pl-4 {
    padding-left: 4px !important
  }

  .xl\:pt-6 {
    padding-top: 6px !important
  }

  .xl\:pr-6 {
    padding-right: 6px !important
  }

  .xl\:pb-6 {
    padding-bottom: 6px !important
  }

  .xl\:pl-6 {
    padding-left: 6px !important
  }

  .xl\:pt-8 {
    padding-top: 8px !important
  }

  .xl\:pr-8 {
    padding-right: 8px !important
  }

  .xl\:pb-8 {
    padding-bottom: 8px !important
  }

  .xl\:pl-8 {
    padding-left: 8px !important
  }

  .xl\:pt-12 {
    padding-top: 12px !important
  }

  .xl\:pr-12 {
    padding-right: 12px !important
  }

  .xl\:pb-12 {
    padding-bottom: 12px !important
  }

  .xl\:pl-12 {
    padding-left: 12px !important
  }

  .xl\:pt-16 {
    padding-top: 16px !important
  }

  .xl\:pr-16 {
    padding-right: 16px !important
  }

  .xl\:pb-16 {
    padding-bottom: 16px !important
  }

  .xl\:pl-16 {
    padding-left: 16px !important
  }

  .xl\:pt-20 {
    padding-top: 20px !important
  }

  .xl\:pr-20 {
    padding-right: 20px !important
  }

  .xl\:pb-20 {
    padding-bottom: 20px !important
  }

  .xl\:pl-20 {
    padding-left: 20px !important
  }

  .xl\:pt-24 {
    padding-top: 24px !important
  }

  .xl\:pr-24 {
    padding-right: 24px !important
  }

  .xl\:pb-24 {
    padding-bottom: 24px !important
  }

  .xl\:pl-24 {
    padding-left: 24px !important
  }

  .xl\:pt-28 {
    padding-top: 28px !important
  }

  .xl\:pr-28 {
    padding-right: 28px !important
  }

  .xl\:pb-28 {
    padding-bottom: 28px !important
  }

  .xl\:pl-28 {
    padding-left: 28px !important
  }

  .xl\:pt-32 {
    padding-top: 32px !important
  }

  .xl\:pr-32 {
    padding-right: 32px !important
  }

  .xl\:pb-32 {
    padding-bottom: 32px !important
  }

  .xl\:pl-32 {
    padding-left: 32px !important
  }

  .xl\:pt-36 {
    padding-top: 36px !important
  }

  .xl\:pr-36 {
    padding-right: 36px !important
  }

  .xl\:pb-36 {
    padding-bottom: 36px !important
  }

  .xl\:pl-36 {
    padding-left: 36px !important
  }

  .xl\:pt-40 {
    padding-top: 40px !important
  }

  .xl\:pr-40 {
    padding-right: 40px !important
  }

  .xl\:pb-40 {
    padding-bottom: 40px !important
  }

  .xl\:pl-40 {
    padding-left: 40px !important
  }

  .xl\:pt-44 {
    padding-top: 44px !important
  }

  .xl\:pr-44 {
    padding-right: 44px !important
  }

  .xl\:pb-44 {
    padding-bottom: 44px !important
  }

  .xl\:pl-44 {
    padding-left: 44px !important
  }

  .xl\:pt-48 {
    padding-top: 48px !important
  }

  .xl\:pr-48 {
    padding-right: 48px !important
  }

  .xl\:pb-48 {
    padding-bottom: 48px !important
  }

  .xl\:pl-48 {
    padding-left: 48px !important
  }

  .xl\:pt-52 {
    padding-top: 52px !important
  }

  .xl\:pr-52 {
    padding-right: 52px !important
  }

  .xl\:pb-52 {
    padding-bottom: 52px !important
  }

  .xl\:pl-52 {
    padding-left: 52px !important
  }

  .xl\:pt-56 {
    padding-top: 56px !important
  }

  .xl\:pr-56 {
    padding-right: 56px !important
  }

  .xl\:pb-56 {
    padding-bottom: 56px !important
  }

  .xl\:pl-56 {
    padding-left: 56px !important
  }

  .xl\:pt-60 {
    padding-top: 60px !important
  }

  .xl\:pr-60 {
    padding-right: 60px !important
  }

  .xl\:pb-60 {
    padding-bottom: 60px !important
  }

  .xl\:pl-60 {
    padding-left: 60px !important
  }

  .xl\:pt-64 {
    padding-top: 64px !important
  }

  .xl\:pr-64 {
    padding-right: 64px !important
  }

  .xl\:pb-64 {
    padding-bottom: 64px !important
  }

  .xl\:pl-64 {
    padding-left: 64px !important
  }

  .xl\:pt-68 {
    padding-top: 68px !important
  }

  .xl\:pr-68 {
    padding-right: 68px !important
  }

  .xl\:pb-68 {
    padding-bottom: 68px !important
  }

  .xl\:pl-68 {
    padding-left: 68px !important
  }

  .xl\:pt-72 {
    padding-top: 72px !important
  }

  .xl\:pr-72 {
    padding-right: 72px !important
  }

  .xl\:pb-72 {
    padding-bottom: 72px !important
  }

  .xl\:pl-72 {
    padding-left: 72px !important
  }

  .xl\:pt-76 {
    padding-top: 76px !important
  }

  .xl\:pr-76 {
    padding-right: 76px !important
  }

  .xl\:pb-76 {
    padding-bottom: 76px !important
  }

  .xl\:pl-76 {
    padding-left: 76px !important
  }

  .xl\:pt-80 {
    padding-top: 80px !important
  }

  .xl\:pr-80 {
    padding-right: 80px !important
  }

  .xl\:pb-80 {
    padding-bottom: 80px !important
  }

  .xl\:pl-80 {
    padding-left: 80px !important
  }

  .xl\:pt-84 {
    padding-top: 84px !important
  }

  .xl\:pr-84 {
    padding-right: 84px !important
  }

  .xl\:pb-84 {
    padding-bottom: 84px !important
  }

  .xl\:pl-84 {
    padding-left: 84px !important
  }

  .xl\:pt-88 {
    padding-top: 88px !important
  }

  .xl\:pr-88 {
    padding-right: 88px !important
  }

  .xl\:pb-88 {
    padding-bottom: 88px !important
  }

  .xl\:pl-88 {
    padding-left: 88px !important
  }

  .xl\:pt-92 {
    padding-top: 92px !important
  }

  .xl\:pr-92 {
    padding-right: 92px !important
  }

  .xl\:pb-92 {
    padding-bottom: 92px !important
  }

  .xl\:pl-92 {
    padding-left: 92px !important
  }

  .xl\:pt-96 {
    padding-top: 96px !important
  }

  .xl\:pr-96 {
    padding-right: 96px !important
  }

  .xl\:pb-96 {
    padding-bottom: 96px !important
  }

  .xl\:pl-96 {
    padding-left: 96px !important
  }

  .xl\:pt-128 {
    padding-top: 128px !important
  }

  .xl\:pr-128 {
    padding-right: 128px !important
  }

  .xl\:pb-128 {
    padding-bottom: 128px !important
  }

  .xl\:pl-128 {
    padding-left: 128px !important
  }

  .xl\:pt-160 {
    padding-top: 160px !important
  }

  .xl\:pr-160 {
    padding-right: 160px !important
  }

  .xl\:pb-160 {
    padding-bottom: 160px !important
  }

  .xl\:pl-160 {
    padding-left: 160px !important
  }

  .xl\:pt-192 {
    padding-top: 192px !important
  }

  .xl\:pr-192 {
    padding-right: 192px !important
  }

  .xl\:pb-192 {
    padding-bottom: 192px !important
  }

  .xl\:pl-192 {
    padding-left: 192px !important
  }

  .xl\:pt-224 {
    padding-top: 224px !important
  }

  .xl\:pr-224 {
    padding-right: 224px !important
  }

  .xl\:pb-224 {
    padding-bottom: 224px !important
  }

  .xl\:pl-224 {
    padding-left: 224px !important
  }

  .xl\:pt-256 {
    padding-top: 256px !important
  }

  .xl\:pr-256 {
    padding-right: 256px !important
  }

  .xl\:pb-256 {
    padding-bottom: 256px !important
  }

  .xl\:pl-256 {
    padding-left: 256px !important
  }

  .xl\:pt-320 {
    padding-top: 320px !important
  }

  .xl\:pr-320 {
    padding-right: 320px !important
  }

  .xl\:pb-320 {
    padding-bottom: 320px !important
  }

  .xl\:pl-320 {
    padding-left: 320px !important
  }

  .xl\:pt-px {
    padding-top: 1px !important
  }

  .xl\:pr-px {
    padding-right: 1px !important
  }

  .xl\:pb-px {
    padding-bottom: 1px !important
  }

  .xl\:pl-px {
    padding-left: 1px !important
  }

  .xl\:flex-1 {
    flex: 1 1 0% !important
  }

  .xl\:flex-auto {
    flex: 1 1 auto !important
  }

  .xl\:flex-initial {
    flex: 0 1 auto !important
  }

  .xl\:flex-none {
    flex: none !important
  }

  .xl\:items-start {
    align-items: flex-start !important
  }

  .xl\:items-end {
    align-items: flex-end !important
  }

  .xl\:items-center {
    align-items: center !important
  }

  .xl\:items-baseline {
    align-items: baseline !important
  }

  .xl\:items-stretch {
    align-items: stretch !important
  }

  .xl\:flex-row {
    flex-direction: row !important
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .xl\:flex-col {
    flex-direction: column !important
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .xl\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .xl\:flex-grow {
    flex-grow: 1 !important
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .xl\:flex-shrink {
    flex-shrink: 1 !important
  }

  .xl\:flex-wrap {
    flex-wrap: wrap !important
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .xl\:justify-start {
    justify-content: flex-start !important
  }

  .xl\:justify-end {
    justify-content: flex-end !important
  }

  .xl\:justify-center {
    justify-content: center !important
  }

  .xl\:justify-between {
    justify-content: space-between !important
  }

  .xl\:justify-around {
    justify-content: space-around !important
  }

  .xl\:justify-evenly {
    justify-content: space-evenly !important
  }

  .xl\:text-xs {
    font-size: 0.75rem !important
  }

  .xl\:text-sm {
    font-size: 0.875rem !important
  }

  .xl\:text-base {
    font-size: 1rem !important
  }

  .xl\:text-lg {
    font-size: 1.125rem !important
  }

  .xl\:text-xl {
    font-size: 1.25rem !important
  }

  .xl\:text-2xl {
    font-size: 1.5rem !important
  }

  .xl\:text-3xl {
    font-size: 1.875rem !important
  }

  .xl\:text-4xl {
    font-size: 2.25rem !important
  }

  .xl\:text-5xl {
    font-size: 3rem !important
  }

  .xl\:text-6xl {
    font-size: 4rem !important
  }

  .xl\:font-hairline {
    font-weight: 100 !important
  }

  .xl\:font-thin {
    font-weight: 200 !important
  }

  .xl\:font-light {
    font-weight: 300 !important
  }

  .xl\:font-normal {
    font-weight: 400 !important
  }

  .xl\:font-medium {
    font-weight: 500 !important
  }

  .xl\:font-semibold {
    font-weight: 600 !important
  }

  .xl\:font-bold {
    font-weight: 700 !important
  }

  .xl\:font-extrabold {
    font-weight: 800 !important
  }

  .xl\:font-black {
    font-weight: 900 !important
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .xl\:text-left {
    text-align: left !important
  }

  .xl\:text-center {
    text-align: center !important
  }

  .xl\:text-right {
    text-align: right !important
  }

  .xl\:text-justify {
    text-align: justify !important
  }

  .xl\:text-transparent {
    color: transparent !important
  }

  .xl\:text-current {
    color: currentColor !important
  }

  .xl\:text-black {
    color: #000 !important
  }

  .xl\:text-white {
    color: #fff !important
  }

  .xl\:text-gray-100 {
    color: #f7fafc !important
  }

  .xl\:text-gray-200 {
    color: #edf2f7 !important
  }

  .xl\:text-gray-300 {
    color: #e2e8f0 !important
  }

  .xl\:text-gray-400 {
    color: #cbd5e0 !important
  }

  .xl\:text-gray-500 {
    color: #a0aec0 !important
  }

  .xl\:text-gray-600 {
    color: #718096 !important
  }

  .xl\:text-gray-700 {
    color: #4a5568 !important
  }

  .xl\:text-gray-800 {
    color: #2d3748 !important
  }

  .xl\:text-gray-900 {
    color: #1a202c !important
  }

  .xl\:text-red-100 {
    color: #fff5f5 !important
  }

  .xl\:text-red-200 {
    color: #fed7d7 !important
  }

  .xl\:text-red-300 {
    color: #feb2b2 !important
  }

  .xl\:text-red-400 {
    color: #fc8181 !important
  }

  .xl\:text-red-500 {
    color: #f56565 !important
  }

  .xl\:text-red-600 {
    color: #e53e3e !important
  }

  .xl\:text-red-700 {
    color: #c53030 !important
  }

  .xl\:text-red-800 {
    color: #9b2c2c !important
  }

  .xl\:text-red-900 {
    color: #742a2a !important
  }

  .xl\:text-orange-100 {
    color: #fffaf0 !important
  }

  .xl\:text-orange-200 {
    color: #feebc8 !important
  }

  .xl\:text-orange-300 {
    color: #fbd38d !important
  }

  .xl\:text-orange-400 {
    color: #f6ad55 !important
  }

  .xl\:text-orange-500 {
    color: #ed8936 !important
  }

  .xl\:text-orange-600 {
    color: #dd6b20 !important
  }

  .xl\:text-orange-700 {
    color: #c05621 !important
  }

  .xl\:text-orange-800 {
    color: #9c4221 !important
  }

  .xl\:text-orange-900 {
    color: #7b341e !important
  }

  .xl\:text-yellow-100 {
    color: #fffff0 !important
  }

  .xl\:text-yellow-200 {
    color: #fefcbf !important
  }

  .xl\:text-yellow-300 {
    color: #faf089 !important
  }

  .xl\:text-yellow-400 {
    color: #f6e05e !important
  }

  .xl\:text-yellow-500 {
    color: #ecc94b !important
  }

  .xl\:text-yellow-600 {
    color: #d69e2e !important
  }

  .xl\:text-yellow-700 {
    color: #b7791f !important
  }

  .xl\:text-yellow-800 {
    color: #975a16 !important
  }

  .xl\:text-yellow-900 {
    color: #744210 !important
  }

  .xl\:text-green-100 {
    color: #f0fff4 !important
  }

  .xl\:text-green-200 {
    color: #c6f6d5 !important
  }

  .xl\:text-green-300 {
    color: #9ae6b4 !important
  }

  .xl\:text-green-400 {
    color: #68d391 !important
  }

  .xl\:text-green-500 {
    color: #48bb78 !important
  }

  .xl\:text-green-600 {
    color: #38a169 !important
  }

  .xl\:text-green-700 {
    color: #2f855a !important
  }

  .xl\:text-green-800 {
    color: #276749 !important
  }

  .xl\:text-green-900 {
    color: #22543d !important
  }

  .xl\:text-teal-100 {
    color: #e6fffa !important
  }

  .xl\:text-teal-200 {
    color: #b2f5ea !important
  }

  .xl\:text-teal-300 {
    color: #81e6d9 !important
  }

  .xl\:text-teal-400 {
    color: #4fd1c5 !important
  }

  .xl\:text-teal-500 {
    color: #38b2ac !important
  }

  .xl\:text-teal-600 {
    color: #319795 !important
  }

  .xl\:text-teal-700 {
    color: #2c7a7b !important
  }

  .xl\:text-teal-800 {
    color: #285e61 !important
  }

  .xl\:text-teal-900 {
    color: #234e52 !important
  }

  .xl\:text-blue-100 {
    color: #ebf8ff !important
  }

  .xl\:text-blue-200 {
    color: #bee3f8 !important
  }

  .xl\:text-blue-300 {
    color: #90cdf4 !important
  }

  .xl\:text-blue-400 {
    color: #63b3ed !important
  }

  .xl\:text-blue-500 {
    color: #4299e1 !important
  }

  .xl\:text-blue-600 {
    color: #3182ce !important
  }

  .xl\:text-blue-700 {
    color: #2b6cb0 !important
  }

  .xl\:text-blue-800 {
    color: #2c5282 !important
  }

  .xl\:text-blue-900 {
    color: #2a4365 !important
  }

  .xl\:text-indigo-100 {
    color: #ebf4ff !important
  }

  .xl\:text-indigo-200 {
    color: #c3dafe !important
  }

  .xl\:text-indigo-300 {
    color: #a3bffa !important
  }

  .xl\:text-indigo-400 {
    color: #7f9cf5 !important
  }

  .xl\:text-indigo-500 {
    color: #667eea !important
  }

  .xl\:text-indigo-600 {
    color: #5a67d8 !important
  }

  .xl\:text-indigo-700 {
    color: #4c51bf !important
  }

  .xl\:text-indigo-800 {
    color: #434190 !important
  }

  .xl\:text-indigo-900 {
    color: #3c366b !important
  }

  .xl\:text-purple-100 {
    color: #faf5ff !important
  }

  .xl\:text-purple-200 {
    color: #e9d8fd !important
  }

  .xl\:text-purple-300 {
    color: #d6bcfa !important
  }

  .xl\:text-purple-400 {
    color: #b794f4 !important
  }

  .xl\:text-purple-500 {
    color: #9f7aea !important
  }

  .xl\:text-purple-600 {
    color: #805ad5 !important
  }

  .xl\:text-purple-700 {
    color: #6b46c1 !important
  }

  .xl\:text-purple-800 {
    color: #553c9a !important
  }

  .xl\:text-purple-900 {
    color: #44337a !important
  }

  .xl\:text-pink-100 {
    color: #fff5f7 !important
  }

  .xl\:text-pink-200 {
    color: #fed7e2 !important
  }

  .xl\:text-pink-300 {
    color: #fbb6ce !important
  }

  .xl\:text-pink-400 {
    color: #f687b3 !important
  }

  .xl\:text-pink-500 {
    color: #ed64a6 !important
  }

  .xl\:text-pink-600 {
    color: #d53f8c !important
  }

  .xl\:text-pink-700 {
    color: #b83280 !important
  }

  .xl\:text-pink-800 {
    color: #97266d !important
  }

  .xl\:text-pink-900 {
    color: #702459 !important
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent !important
  }

  .xl\:hover\:text-current:hover {
    color: currentColor !important
  }

  .xl\:hover\:text-black:hover {
    color: #000 !important
  }

  .xl\:hover\:text-white:hover {
    color: #fff !important
  }

  .xl\:hover\:text-gray-100:hover {
    color: #f7fafc !important
  }

  .xl\:hover\:text-gray-200:hover {
    color: #edf2f7 !important
  }

  .xl\:hover\:text-gray-300:hover {
    color: #e2e8f0 !important
  }

  .xl\:hover\:text-gray-400:hover {
    color: #cbd5e0 !important
  }

  .xl\:hover\:text-gray-500:hover {
    color: #a0aec0 !important
  }

  .xl\:hover\:text-gray-600:hover {
    color: #718096 !important
  }

  .xl\:hover\:text-gray-700:hover {
    color: #4a5568 !important
  }

  .xl\:hover\:text-gray-800:hover {
    color: #2d3748 !important
  }

  .xl\:hover\:text-gray-900:hover {
    color: #1a202c !important
  }

  .xl\:hover\:text-red-100:hover {
    color: #fff5f5 !important
  }

  .xl\:hover\:text-red-200:hover {
    color: #fed7d7 !important
  }

  .xl\:hover\:text-red-300:hover {
    color: #feb2b2 !important
  }

  .xl\:hover\:text-red-400:hover {
    color: #fc8181 !important
  }

  .xl\:hover\:text-red-500:hover {
    color: #f56565 !important
  }

  .xl\:hover\:text-red-600:hover {
    color: #e53e3e !important
  }

  .xl\:hover\:text-red-700:hover {
    color: #c53030 !important
  }

  .xl\:hover\:text-red-800:hover {
    color: #9b2c2c !important
  }

  .xl\:hover\:text-red-900:hover {
    color: #742a2a !important
  }

  .xl\:hover\:text-orange-100:hover {
    color: #fffaf0 !important
  }

  .xl\:hover\:text-orange-200:hover {
    color: #feebc8 !important
  }

  .xl\:hover\:text-orange-300:hover {
    color: #fbd38d !important
  }

  .xl\:hover\:text-orange-400:hover {
    color: #f6ad55 !important
  }

  .xl\:hover\:text-orange-500:hover {
    color: #ed8936 !important
  }

  .xl\:hover\:text-orange-600:hover {
    color: #dd6b20 !important
  }

  .xl\:hover\:text-orange-700:hover {
    color: #c05621 !important
  }

  .xl\:hover\:text-orange-800:hover {
    color: #9c4221 !important
  }

  .xl\:hover\:text-orange-900:hover {
    color: #7b341e !important
  }

  .xl\:hover\:text-yellow-100:hover {
    color: #fffff0 !important
  }

  .xl\:hover\:text-yellow-200:hover {
    color: #fefcbf !important
  }

  .xl\:hover\:text-yellow-300:hover {
    color: #faf089 !important
  }

  .xl\:hover\:text-yellow-400:hover {
    color: #f6e05e !important
  }

  .xl\:hover\:text-yellow-500:hover {
    color: #ecc94b !important
  }

  .xl\:hover\:text-yellow-600:hover {
    color: #d69e2e !important
  }

  .xl\:hover\:text-yellow-700:hover {
    color: #b7791f !important
  }

  .xl\:hover\:text-yellow-800:hover {
    color: #975a16 !important
  }

  .xl\:hover\:text-yellow-900:hover {
    color: #744210 !important
  }

  .xl\:hover\:text-green-100:hover {
    color: #f0fff4 !important
  }

  .xl\:hover\:text-green-200:hover {
    color: #c6f6d5 !important
  }

  .xl\:hover\:text-green-300:hover {
    color: #9ae6b4 !important
  }

  .xl\:hover\:text-green-400:hover {
    color: #68d391 !important
  }

  .xl\:hover\:text-green-500:hover {
    color: #48bb78 !important
  }

  .xl\:hover\:text-green-600:hover {
    color: #38a169 !important
  }

  .xl\:hover\:text-green-700:hover {
    color: #2f855a !important
  }

  .xl\:hover\:text-green-800:hover {
    color: #276749 !important
  }

  .xl\:hover\:text-green-900:hover {
    color: #22543d !important
  }

  .xl\:hover\:text-teal-100:hover {
    color: #e6fffa !important
  }

  .xl\:hover\:text-teal-200:hover {
    color: #b2f5ea !important
  }

  .xl\:hover\:text-teal-300:hover {
    color: #81e6d9 !important
  }

  .xl\:hover\:text-teal-400:hover {
    color: #4fd1c5 !important
  }

  .xl\:hover\:text-teal-500:hover {
    color: #38b2ac !important
  }

  .xl\:hover\:text-teal-600:hover {
    color: #319795 !important
  }

  .xl\:hover\:text-teal-700:hover {
    color: #2c7a7b !important
  }

  .xl\:hover\:text-teal-800:hover {
    color: #285e61 !important
  }

  .xl\:hover\:text-teal-900:hover {
    color: #234e52 !important
  }

  .xl\:hover\:text-blue-100:hover {
    color: #ebf8ff !important
  }

  .xl\:hover\:text-blue-200:hover {
    color: #bee3f8 !important
  }

  .xl\:hover\:text-blue-300:hover {
    color: #90cdf4 !important
  }

  .xl\:hover\:text-blue-400:hover {
    color: #63b3ed !important
  }

  .xl\:hover\:text-blue-500:hover {
    color: #4299e1 !important
  }

  .xl\:hover\:text-blue-600:hover {
    color: #3182ce !important
  }

  .xl\:hover\:text-blue-700:hover {
    color: #2b6cb0 !important
  }

  .xl\:hover\:text-blue-800:hover {
    color: #2c5282 !important
  }

  .xl\:hover\:text-blue-900:hover {
    color: #2a4365 !important
  }

  .xl\:hover\:text-indigo-100:hover {
    color: #ebf4ff !important
  }

  .xl\:hover\:text-indigo-200:hover {
    color: #c3dafe !important
  }

  .xl\:hover\:text-indigo-300:hover {
    color: #a3bffa !important
  }

  .xl\:hover\:text-indigo-400:hover {
    color: #7f9cf5 !important
  }

  .xl\:hover\:text-indigo-500:hover {
    color: #667eea !important
  }

  .xl\:hover\:text-indigo-600:hover {
    color: #5a67d8 !important
  }

  .xl\:hover\:text-indigo-700:hover {
    color: #4c51bf !important
  }

  .xl\:hover\:text-indigo-800:hover {
    color: #434190 !important
  }

  .xl\:hover\:text-indigo-900:hover {
    color: #3c366b !important
  }

  .xl\:hover\:text-purple-100:hover {
    color: #faf5ff !important
  }

  .xl\:hover\:text-purple-200:hover {
    color: #e9d8fd !important
  }

  .xl\:hover\:text-purple-300:hover {
    color: #d6bcfa !important
  }

  .xl\:hover\:text-purple-400:hover {
    color: #b794f4 !important
  }

  .xl\:hover\:text-purple-500:hover {
    color: #9f7aea !important
  }

  .xl\:hover\:text-purple-600:hover {
    color: #805ad5 !important
  }

  .xl\:hover\:text-purple-700:hover {
    color: #6b46c1 !important
  }

  .xl\:hover\:text-purple-800:hover {
    color: #553c9a !important
  }

  .xl\:hover\:text-purple-900:hover {
    color: #44337a !important
  }

  .xl\:hover\:text-pink-100:hover {
    color: #fff5f7 !important
  }

  .xl\:hover\:text-pink-200:hover {
    color: #fed7e2 !important
  }

  .xl\:hover\:text-pink-300:hover {
    color: #fbb6ce !important
  }

  .xl\:hover\:text-pink-400:hover {
    color: #f687b3 !important
  }

  .xl\:hover\:text-pink-500:hover {
    color: #ed64a6 !important
  }

  .xl\:hover\:text-pink-600:hover {
    color: #d53f8c !important
  }

  .xl\:hover\:text-pink-700:hover {
    color: #b83280 !important
  }

  .xl\:hover\:text-pink-800:hover {
    color: #97266d !important
  }

  .xl\:hover\:text-pink-900:hover {
    color: #702459 !important
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent !important
  }

  .xl\:focus\:text-current:focus {
    color: currentColor !important
  }

  .xl\:focus\:text-black:focus {
    color: #000 !important
  }

  .xl\:focus\:text-white:focus {
    color: #fff !important
  }

  .xl\:focus\:text-gray-100:focus {
    color: #f7fafc !important
  }

  .xl\:focus\:text-gray-200:focus {
    color: #edf2f7 !important
  }

  .xl\:focus\:text-gray-300:focus {
    color: #e2e8f0 !important
  }

  .xl\:focus\:text-gray-400:focus {
    color: #cbd5e0 !important
  }

  .xl\:focus\:text-gray-500:focus {
    color: #a0aec0 !important
  }

  .xl\:focus\:text-gray-600:focus {
    color: #718096 !important
  }

  .xl\:focus\:text-gray-700:focus {
    color: #4a5568 !important
  }

  .xl\:focus\:text-gray-800:focus {
    color: #2d3748 !important
  }

  .xl\:focus\:text-gray-900:focus {
    color: #1a202c !important
  }

  .xl\:focus\:text-red-100:focus {
    color: #fff5f5 !important
  }

  .xl\:focus\:text-red-200:focus {
    color: #fed7d7 !important
  }

  .xl\:focus\:text-red-300:focus {
    color: #feb2b2 !important
  }

  .xl\:focus\:text-red-400:focus {
    color: #fc8181 !important
  }

  .xl\:focus\:text-red-500:focus {
    color: #f56565 !important
  }

  .xl\:focus\:text-red-600:focus {
    color: #e53e3e !important
  }

  .xl\:focus\:text-red-700:focus {
    color: #c53030 !important
  }

  .xl\:focus\:text-red-800:focus {
    color: #9b2c2c !important
  }

  .xl\:focus\:text-red-900:focus {
    color: #742a2a !important
  }

  .xl\:focus\:text-orange-100:focus {
    color: #fffaf0 !important
  }

  .xl\:focus\:text-orange-200:focus {
    color: #feebc8 !important
  }

  .xl\:focus\:text-orange-300:focus {
    color: #fbd38d !important
  }

  .xl\:focus\:text-orange-400:focus {
    color: #f6ad55 !important
  }

  .xl\:focus\:text-orange-500:focus {
    color: #ed8936 !important
  }

  .xl\:focus\:text-orange-600:focus {
    color: #dd6b20 !important
  }

  .xl\:focus\:text-orange-700:focus {
    color: #c05621 !important
  }

  .xl\:focus\:text-orange-800:focus {
    color: #9c4221 !important
  }

  .xl\:focus\:text-orange-900:focus {
    color: #7b341e !important
  }

  .xl\:focus\:text-yellow-100:focus {
    color: #fffff0 !important
  }

  .xl\:focus\:text-yellow-200:focus {
    color: #fefcbf !important
  }

  .xl\:focus\:text-yellow-300:focus {
    color: #faf089 !important
  }

  .xl\:focus\:text-yellow-400:focus {
    color: #f6e05e !important
  }

  .xl\:focus\:text-yellow-500:focus {
    color: #ecc94b !important
  }

  .xl\:focus\:text-yellow-600:focus {
    color: #d69e2e !important
  }

  .xl\:focus\:text-yellow-700:focus {
    color: #b7791f !important
  }

  .xl\:focus\:text-yellow-800:focus {
    color: #975a16 !important
  }

  .xl\:focus\:text-yellow-900:focus {
    color: #744210 !important
  }

  .xl\:focus\:text-green-100:focus {
    color: #f0fff4 !important
  }

  .xl\:focus\:text-green-200:focus {
    color: #c6f6d5 !important
  }

  .xl\:focus\:text-green-300:focus {
    color: #9ae6b4 !important
  }

  .xl\:focus\:text-green-400:focus {
    color: #68d391 !important
  }

  .xl\:focus\:text-green-500:focus {
    color: #48bb78 !important
  }

  .xl\:focus\:text-green-600:focus {
    color: #38a169 !important
  }

  .xl\:focus\:text-green-700:focus {
    color: #2f855a !important
  }

  .xl\:focus\:text-green-800:focus {
    color: #276749 !important
  }

  .xl\:focus\:text-green-900:focus {
    color: #22543d !important
  }

  .xl\:focus\:text-teal-100:focus {
    color: #e6fffa !important
  }

  .xl\:focus\:text-teal-200:focus {
    color: #b2f5ea !important
  }

  .xl\:focus\:text-teal-300:focus {
    color: #81e6d9 !important
  }

  .xl\:focus\:text-teal-400:focus {
    color: #4fd1c5 !important
  }

  .xl\:focus\:text-teal-500:focus {
    color: #38b2ac !important
  }

  .xl\:focus\:text-teal-600:focus {
    color: #319795 !important
  }

  .xl\:focus\:text-teal-700:focus {
    color: #2c7a7b !important
  }

  .xl\:focus\:text-teal-800:focus {
    color: #285e61 !important
  }

  .xl\:focus\:text-teal-900:focus {
    color: #234e52 !important
  }

  .xl\:focus\:text-blue-100:focus {
    color: #ebf8ff !important
  }

  .xl\:focus\:text-blue-200:focus {
    color: #bee3f8 !important
  }

  .xl\:focus\:text-blue-300:focus {
    color: #90cdf4 !important
  }

  .xl\:focus\:text-blue-400:focus {
    color: #63b3ed !important
  }

  .xl\:focus\:text-blue-500:focus {
    color: #4299e1 !important
  }

  .xl\:focus\:text-blue-600:focus {
    color: #3182ce !important
  }

  .xl\:focus\:text-blue-700:focus {
    color: #2b6cb0 !important
  }

  .xl\:focus\:text-blue-800:focus {
    color: #2c5282 !important
  }

  .xl\:focus\:text-blue-900:focus {
    color: #2a4365 !important
  }

  .xl\:focus\:text-indigo-100:focus {
    color: #ebf4ff !important
  }

  .xl\:focus\:text-indigo-200:focus {
    color: #c3dafe !important
  }

  .xl\:focus\:text-indigo-300:focus {
    color: #a3bffa !important
  }

  .xl\:focus\:text-indigo-400:focus {
    color: #7f9cf5 !important
  }

  .xl\:focus\:text-indigo-500:focus {
    color: #667eea !important
  }

  .xl\:focus\:text-indigo-600:focus {
    color: #5a67d8 !important
  }

  .xl\:focus\:text-indigo-700:focus {
    color: #4c51bf !important
  }

  .xl\:focus\:text-indigo-800:focus {
    color: #434190 !important
  }

  .xl\:focus\:text-indigo-900:focus {
    color: #3c366b !important
  }

  .xl\:focus\:text-purple-100:focus {
    color: #faf5ff !important
  }

  .xl\:focus\:text-purple-200:focus {
    color: #e9d8fd !important
  }

  .xl\:focus\:text-purple-300:focus {
    color: #d6bcfa !important
  }

  .xl\:focus\:text-purple-400:focus {
    color: #b794f4 !important
  }

  .xl\:focus\:text-purple-500:focus {
    color: #9f7aea !important
  }

  .xl\:focus\:text-purple-600:focus {
    color: #805ad5 !important
  }

  .xl\:focus\:text-purple-700:focus {
    color: #6b46c1 !important
  }

  .xl\:focus\:text-purple-800:focus {
    color: #553c9a !important
  }

  .xl\:focus\:text-purple-900:focus {
    color: #44337a !important
  }

  .xl\:focus\:text-pink-100:focus {
    color: #fff5f7 !important
  }

  .xl\:focus\:text-pink-200:focus {
    color: #fed7e2 !important
  }

  .xl\:focus\:text-pink-300:focus {
    color: #fbb6ce !important
  }

  .xl\:focus\:text-pink-400:focus {
    color: #f687b3 !important
  }

  .xl\:focus\:text-pink-500:focus {
    color: #ed64a6 !important
  }

  .xl\:focus\:text-pink-600:focus {
    color: #d53f8c !important
  }

  .xl\:focus\:text-pink-700:focus {
    color: #b83280 !important
  }

  .xl\:focus\:text-pink-800:focus {
    color: #97266d !important
  }

  .xl\:focus\:text-pink-900:focus {
    color: #702459 !important
  }

  .xl\:bg-transparent {
    background-color: transparent !important
  }

  .xl\:bg-current {
    background-color: currentColor !important
  }

  .xl\:bg-black {
    background-color: #000 !important
  }

  .xl\:bg-white {
    background-color: #fff !important
  }

  .xl\:bg-gray-100 {
    background-color: #f7fafc !important
  }

  .xl\:bg-gray-200 {
    background-color: #edf2f7 !important
  }

  .xl\:bg-gray-300 {
    background-color: #e2e8f0 !important
  }

  .xl\:bg-gray-400 {
    background-color: #cbd5e0 !important
  }

  .xl\:bg-gray-500 {
    background-color: #a0aec0 !important
  }

  .xl\:bg-gray-600 {
    background-color: #718096 !important
  }

  .xl\:bg-gray-700 {
    background-color: #4a5568 !important
  }

  .xl\:bg-gray-800 {
    background-color: #2d3748 !important
  }

  .xl\:bg-gray-900 {
    background-color: #1a202c !important
  }

  .xl\:bg-red-100 {
    background-color: #fff5f5 !important
  }

  .xl\:bg-red-200 {
    background-color: #fed7d7 !important
  }

  .xl\:bg-red-300 {
    background-color: #feb2b2 !important
  }

  .xl\:bg-red-400 {
    background-color: #fc8181 !important
  }

  .xl\:bg-red-500 {
    background-color: #f56565 !important
  }

  .xl\:bg-red-600 {
    background-color: #e53e3e !important
  }

  .xl\:bg-red-700 {
    background-color: #c53030 !important
  }

  .xl\:bg-red-800 {
    background-color: #9b2c2c !important
  }

  .xl\:bg-red-900 {
    background-color: #742a2a !important
  }

  .xl\:bg-orange-100 {
    background-color: #fffaf0 !important
  }

  .xl\:bg-orange-200 {
    background-color: #feebc8 !important
  }

  .xl\:bg-orange-300 {
    background-color: #fbd38d !important
  }

  .xl\:bg-orange-400 {
    background-color: #f6ad55 !important
  }

  .xl\:bg-orange-500 {
    background-color: #ed8936 !important
  }

  .xl\:bg-orange-600 {
    background-color: #dd6b20 !important
  }

  .xl\:bg-orange-700 {
    background-color: #c05621 !important
  }

  .xl\:bg-orange-800 {
    background-color: #9c4221 !important
  }

  .xl\:bg-orange-900 {
    background-color: #7b341e !important
  }

  .xl\:bg-yellow-100 {
    background-color: #fffff0 !important
  }

  .xl\:bg-yellow-200 {
    background-color: #fefcbf !important
  }

  .xl\:bg-yellow-300 {
    background-color: #faf089 !important
  }

  .xl\:bg-yellow-400 {
    background-color: #f6e05e !important
  }

  .xl\:bg-yellow-500 {
    background-color: #ecc94b !important
  }

  .xl\:bg-yellow-600 {
    background-color: #d69e2e !important
  }

  .xl\:bg-yellow-700 {
    background-color: #b7791f !important
  }

  .xl\:bg-yellow-800 {
    background-color: #975a16 !important
  }

  .xl\:bg-yellow-900 {
    background-color: #744210 !important
  }

  .xl\:bg-green-100 {
    background-color: #f0fff4 !important
  }

  .xl\:bg-green-200 {
    background-color: #c6f6d5 !important
  }

  .xl\:bg-green-300 {
    background-color: #9ae6b4 !important
  }

  .xl\:bg-green-400 {
    background-color: #68d391 !important
  }

  .xl\:bg-green-500 {
    background-color: #48bb78 !important
  }

  .xl\:bg-green-600 {
    background-color: #38a169 !important
  }

  .xl\:bg-green-700 {
    background-color: #2f855a !important
  }

  .xl\:bg-green-800 {
    background-color: #276749 !important
  }

  .xl\:bg-green-900 {
    background-color: #22543d !important
  }

  .xl\:bg-teal-100 {
    background-color: #e6fffa !important
  }

  .xl\:bg-teal-200 {
    background-color: #b2f5ea !important
  }

  .xl\:bg-teal-300 {
    background-color: #81e6d9 !important
  }

  .xl\:bg-teal-400 {
    background-color: #4fd1c5 !important
  }

  .xl\:bg-teal-500 {
    background-color: #38b2ac !important
  }

  .xl\:bg-teal-600 {
    background-color: #319795 !important
  }

  .xl\:bg-teal-700 {
    background-color: #2c7a7b !important
  }

  .xl\:bg-teal-800 {
    background-color: #285e61 !important
  }

  .xl\:bg-teal-900 {
    background-color: #234e52 !important
  }

  .xl\:bg-blue-100 {
    background-color: #ebf8ff !important
  }

  .xl\:bg-blue-200 {
    background-color: #bee3f8 !important
  }

  .xl\:bg-blue-300 {
    background-color: #90cdf4 !important
  }

  .xl\:bg-blue-400 {
    background-color: #63b3ed !important
  }

  .xl\:bg-blue-500 {
    background-color: #4299e1 !important
  }

  .xl\:bg-blue-600 {
    background-color: #3182ce !important
  }

  .xl\:bg-blue-700 {
    background-color: #2b6cb0 !important
  }

  .xl\:bg-blue-800 {
    background-color: #2c5282 !important
  }

  .xl\:bg-blue-900 {
    background-color: #2a4365 !important
  }

  .xl\:bg-indigo-100 {
    background-color: #ebf4ff !important
  }

  .xl\:bg-indigo-200 {
    background-color: #c3dafe !important
  }

  .xl\:bg-indigo-300 {
    background-color: #a3bffa !important
  }

  .xl\:bg-indigo-400 {
    background-color: #7f9cf5 !important
  }

  .xl\:bg-indigo-500 {
    background-color: #667eea !important
  }

  .xl\:bg-indigo-600 {
    background-color: #5a67d8 !important
  }

  .xl\:bg-indigo-700 {
    background-color: #4c51bf !important
  }

  .xl\:bg-indigo-800 {
    background-color: #434190 !important
  }

  .xl\:bg-indigo-900 {
    background-color: #3c366b !important
  }

  .xl\:bg-purple-100 {
    background-color: #faf5ff !important
  }

  .xl\:bg-purple-200 {
    background-color: #e9d8fd !important
  }

  .xl\:bg-purple-300 {
    background-color: #d6bcfa !important
  }

  .xl\:bg-purple-400 {
    background-color: #b794f4 !important
  }

  .xl\:bg-purple-500 {
    background-color: #9f7aea !important
  }

  .xl\:bg-purple-600 {
    background-color: #805ad5 !important
  }

  .xl\:bg-purple-700 {
    background-color: #6b46c1 !important
  }

  .xl\:bg-purple-800 {
    background-color: #553c9a !important
  }

  .xl\:bg-purple-900 {
    background-color: #44337a !important
  }

  .xl\:bg-pink-100 {
    background-color: #fff5f7 !important
  }

  .xl\:bg-pink-200 {
    background-color: #fed7e2 !important
  }

  .xl\:bg-pink-300 {
    background-color: #fbb6ce !important
  }

  .xl\:bg-pink-400 {
    background-color: #f687b3 !important
  }

  .xl\:bg-pink-500 {
    background-color: #ed64a6 !important
  }

  .xl\:bg-pink-600 {
    background-color: #d53f8c !important
  }

  .xl\:bg-pink-700 {
    background-color: #b83280 !important
  }

  .xl\:bg-pink-800 {
    background-color: #97266d !important
  }

  .xl\:bg-pink-900 {
    background-color: #702459 !important
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent !important
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor !important
  }

  .xl\:hover\:bg-black:hover {
    background-color: #000 !important
  }

  .xl\:hover\:bg-white:hover {
    background-color: #fff !important
  }

  .xl\:hover\:bg-gray-100:hover {
    background-color: #f7fafc !important
  }

  .xl\:hover\:bg-gray-200:hover {
    background-color: #edf2f7 !important
  }

  .xl\:hover\:bg-gray-300:hover {
    background-color: #e2e8f0 !important
  }

  .xl\:hover\:bg-gray-400:hover {
    background-color: #cbd5e0 !important
  }

  .xl\:hover\:bg-gray-500:hover {
    background-color: #a0aec0 !important
  }

  .xl\:hover\:bg-gray-600:hover {
    background-color: #718096 !important
  }

  .xl\:hover\:bg-gray-700:hover {
    background-color: #4a5568 !important
  }

  .xl\:hover\:bg-gray-800:hover {
    background-color: #2d3748 !important
  }

  .xl\:hover\:bg-gray-900:hover {
    background-color: #1a202c !important
  }

  .xl\:hover\:bg-red-100:hover {
    background-color: #fff5f5 !important
  }

  .xl\:hover\:bg-red-200:hover {
    background-color: #fed7d7 !important
  }

  .xl\:hover\:bg-red-300:hover {
    background-color: #feb2b2 !important
  }

  .xl\:hover\:bg-red-400:hover {
    background-color: #fc8181 !important
  }

  .xl\:hover\:bg-red-500:hover {
    background-color: #f56565 !important
  }

  .xl\:hover\:bg-red-600:hover {
    background-color: #e53e3e !important
  }

  .xl\:hover\:bg-red-700:hover {
    background-color: #c53030 !important
  }

  .xl\:hover\:bg-red-800:hover {
    background-color: #9b2c2c !important
  }

  .xl\:hover\:bg-red-900:hover {
    background-color: #742a2a !important
  }

  .xl\:hover\:bg-orange-100:hover {
    background-color: #fffaf0 !important
  }

  .xl\:hover\:bg-orange-200:hover {
    background-color: #feebc8 !important
  }

  .xl\:hover\:bg-orange-300:hover {
    background-color: #fbd38d !important
  }

  .xl\:hover\:bg-orange-400:hover {
    background-color: #f6ad55 !important
  }

  .xl\:hover\:bg-orange-500:hover {
    background-color: #ed8936 !important
  }

  .xl\:hover\:bg-orange-600:hover {
    background-color: #dd6b20 !important
  }

  .xl\:hover\:bg-orange-700:hover {
    background-color: #c05621 !important
  }

  .xl\:hover\:bg-orange-800:hover {
    background-color: #9c4221 !important
  }

  .xl\:hover\:bg-orange-900:hover {
    background-color: #7b341e !important
  }

  .xl\:hover\:bg-yellow-100:hover {
    background-color: #fffff0 !important
  }

  .xl\:hover\:bg-yellow-200:hover {
    background-color: #fefcbf !important
  }

  .xl\:hover\:bg-yellow-300:hover {
    background-color: #faf089 !important
  }

  .xl\:hover\:bg-yellow-400:hover {
    background-color: #f6e05e !important
  }

  .xl\:hover\:bg-yellow-500:hover {
    background-color: #ecc94b !important
  }

  .xl\:hover\:bg-yellow-600:hover {
    background-color: #d69e2e !important
  }

  .xl\:hover\:bg-yellow-700:hover {
    background-color: #b7791f !important
  }

  .xl\:hover\:bg-yellow-800:hover {
    background-color: #975a16 !important
  }

  .xl\:hover\:bg-yellow-900:hover {
    background-color: #744210 !important
  }

  .xl\:hover\:bg-green-100:hover {
    background-color: #f0fff4 !important
  }

  .xl\:hover\:bg-green-200:hover {
    background-color: #c6f6d5 !important
  }

  .xl\:hover\:bg-green-300:hover {
    background-color: #9ae6b4 !important
  }

  .xl\:hover\:bg-green-400:hover {
    background-color: #68d391 !important
  }

  .xl\:hover\:bg-green-500:hover {
    background-color: #48bb78 !important
  }

  .xl\:hover\:bg-green-600:hover {
    background-color: #38a169 !important
  }

  .xl\:hover\:bg-green-700:hover {
    background-color: #2f855a !important
  }

  .xl\:hover\:bg-green-800:hover {
    background-color: #276749 !important
  }

  .xl\:hover\:bg-green-900:hover {
    background-color: #22543d !important
  }

  .xl\:hover\:bg-teal-100:hover {
    background-color: #e6fffa !important
  }

  .xl\:hover\:bg-teal-200:hover {
    background-color: #b2f5ea !important
  }

  .xl\:hover\:bg-teal-300:hover {
    background-color: #81e6d9 !important
  }

  .xl\:hover\:bg-teal-400:hover {
    background-color: #4fd1c5 !important
  }

  .xl\:hover\:bg-teal-500:hover {
    background-color: #38b2ac !important
  }

  .xl\:hover\:bg-teal-600:hover {
    background-color: #319795 !important
  }

  .xl\:hover\:bg-teal-700:hover {
    background-color: #2c7a7b !important
  }

  .xl\:hover\:bg-teal-800:hover {
    background-color: #285e61 !important
  }

  .xl\:hover\:bg-teal-900:hover {
    background-color: #234e52 !important
  }

  .xl\:hover\:bg-blue-100:hover {
    background-color: #ebf8ff !important
  }

  .xl\:hover\:bg-blue-200:hover {
    background-color: #bee3f8 !important
  }

  .xl\:hover\:bg-blue-300:hover {
    background-color: #90cdf4 !important
  }

  .xl\:hover\:bg-blue-400:hover {
    background-color: #63b3ed !important
  }

  .xl\:hover\:bg-blue-500:hover {
    background-color: #4299e1 !important
  }

  .xl\:hover\:bg-blue-600:hover {
    background-color: #3182ce !important
  }

  .xl\:hover\:bg-blue-700:hover {
    background-color: #2b6cb0 !important
  }

  .xl\:hover\:bg-blue-800:hover {
    background-color: #2c5282 !important
  }

  .xl\:hover\:bg-blue-900:hover {
    background-color: #2a4365 !important
  }

  .xl\:hover\:bg-indigo-100:hover {
    background-color: #ebf4ff !important
  }

  .xl\:hover\:bg-indigo-200:hover {
    background-color: #c3dafe !important
  }

  .xl\:hover\:bg-indigo-300:hover {
    background-color: #a3bffa !important
  }

  .xl\:hover\:bg-indigo-400:hover {
    background-color: #7f9cf5 !important
  }

  .xl\:hover\:bg-indigo-500:hover {
    background-color: #667eea !important
  }

  .xl\:hover\:bg-indigo-600:hover {
    background-color: #5a67d8 !important
  }

  .xl\:hover\:bg-indigo-700:hover {
    background-color: #4c51bf !important
  }

  .xl\:hover\:bg-indigo-800:hover {
    background-color: #434190 !important
  }

  .xl\:hover\:bg-indigo-900:hover {
    background-color: #3c366b !important
  }

  .xl\:hover\:bg-purple-100:hover {
    background-color: #faf5ff !important
  }

  .xl\:hover\:bg-purple-200:hover {
    background-color: #e9d8fd !important
  }

  .xl\:hover\:bg-purple-300:hover {
    background-color: #d6bcfa !important
  }

  .xl\:hover\:bg-purple-400:hover {
    background-color: #b794f4 !important
  }

  .xl\:hover\:bg-purple-500:hover {
    background-color: #9f7aea !important
  }

  .xl\:hover\:bg-purple-600:hover {
    background-color: #805ad5 !important
  }

  .xl\:hover\:bg-purple-700:hover {
    background-color: #6b46c1 !important
  }

  .xl\:hover\:bg-purple-800:hover {
    background-color: #553c9a !important
  }

  .xl\:hover\:bg-purple-900:hover {
    background-color: #44337a !important
  }

  .xl\:hover\:bg-pink-100:hover {
    background-color: #fff5f7 !important
  }

  .xl\:hover\:bg-pink-200:hover {
    background-color: #fed7e2 !important
  }

  .xl\:hover\:bg-pink-300:hover {
    background-color: #fbb6ce !important
  }

  .xl\:hover\:bg-pink-400:hover {
    background-color: #f687b3 !important
  }

  .xl\:hover\:bg-pink-500:hover {
    background-color: #ed64a6 !important
  }

  .xl\:hover\:bg-pink-600:hover {
    background-color: #d53f8c !important
  }

  .xl\:hover\:bg-pink-700:hover {
    background-color: #b83280 !important
  }

  .xl\:hover\:bg-pink-800:hover {
    background-color: #97266d !important
  }

  .xl\:hover\:bg-pink-900:hover {
    background-color: #702459 !important
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent !important
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor !important
  }

  .xl\:focus\:bg-black:focus {
    background-color: #000 !important
  }

  .xl\:focus\:bg-white:focus {
    background-color: #fff !important
  }

  .xl\:focus\:bg-gray-100:focus {
    background-color: #f7fafc !important
  }

  .xl\:focus\:bg-gray-200:focus {
    background-color: #edf2f7 !important
  }

  .xl\:focus\:bg-gray-300:focus {
    background-color: #e2e8f0 !important
  }

  .xl\:focus\:bg-gray-400:focus {
    background-color: #cbd5e0 !important
  }

  .xl\:focus\:bg-gray-500:focus {
    background-color: #a0aec0 !important
  }

  .xl\:focus\:bg-gray-600:focus {
    background-color: #718096 !important
  }

  .xl\:focus\:bg-gray-700:focus {
    background-color: #4a5568 !important
  }

  .xl\:focus\:bg-gray-800:focus {
    background-color: #2d3748 !important
  }

  .xl\:focus\:bg-gray-900:focus {
    background-color: #1a202c !important
  }

  .xl\:focus\:bg-red-100:focus {
    background-color: #fff5f5 !important
  }

  .xl\:focus\:bg-red-200:focus {
    background-color: #fed7d7 !important
  }

  .xl\:focus\:bg-red-300:focus {
    background-color: #feb2b2 !important
  }

  .xl\:focus\:bg-red-400:focus {
    background-color: #fc8181 !important
  }

  .xl\:focus\:bg-red-500:focus {
    background-color: #f56565 !important
  }

  .xl\:focus\:bg-red-600:focus {
    background-color: #e53e3e !important
  }

  .xl\:focus\:bg-red-700:focus {
    background-color: #c53030 !important
  }

  .xl\:focus\:bg-red-800:focus {
    background-color: #9b2c2c !important
  }

  .xl\:focus\:bg-red-900:focus {
    background-color: #742a2a !important
  }

  .xl\:focus\:bg-orange-100:focus {
    background-color: #fffaf0 !important
  }

  .xl\:focus\:bg-orange-200:focus {
    background-color: #feebc8 !important
  }

  .xl\:focus\:bg-orange-300:focus {
    background-color: #fbd38d !important
  }

  .xl\:focus\:bg-orange-400:focus {
    background-color: #f6ad55 !important
  }

  .xl\:focus\:bg-orange-500:focus {
    background-color: #ed8936 !important
  }

  .xl\:focus\:bg-orange-600:focus {
    background-color: #dd6b20 !important
  }

  .xl\:focus\:bg-orange-700:focus {
    background-color: #c05621 !important
  }

  .xl\:focus\:bg-orange-800:focus {
    background-color: #9c4221 !important
  }

  .xl\:focus\:bg-orange-900:focus {
    background-color: #7b341e !important
  }

  .xl\:focus\:bg-yellow-100:focus {
    background-color: #fffff0 !important
  }

  .xl\:focus\:bg-yellow-200:focus {
    background-color: #fefcbf !important
  }

  .xl\:focus\:bg-yellow-300:focus {
    background-color: #faf089 !important
  }

  .xl\:focus\:bg-yellow-400:focus {
    background-color: #f6e05e !important
  }

  .xl\:focus\:bg-yellow-500:focus {
    background-color: #ecc94b !important
  }

  .xl\:focus\:bg-yellow-600:focus {
    background-color: #d69e2e !important
  }

  .xl\:focus\:bg-yellow-700:focus {
    background-color: #b7791f !important
  }

  .xl\:focus\:bg-yellow-800:focus {
    background-color: #975a16 !important
  }

  .xl\:focus\:bg-yellow-900:focus {
    background-color: #744210 !important
  }

  .xl\:focus\:bg-green-100:focus {
    background-color: #f0fff4 !important
  }

  .xl\:focus\:bg-green-200:focus {
    background-color: #c6f6d5 !important
  }

  .xl\:focus\:bg-green-300:focus {
    background-color: #9ae6b4 !important
  }

  .xl\:focus\:bg-green-400:focus {
    background-color: #68d391 !important
  }

  .xl\:focus\:bg-green-500:focus {
    background-color: #48bb78 !important
  }

  .xl\:focus\:bg-green-600:focus {
    background-color: #38a169 !important
  }

  .xl\:focus\:bg-green-700:focus {
    background-color: #2f855a !important
  }

  .xl\:focus\:bg-green-800:focus {
    background-color: #276749 !important
  }

  .xl\:focus\:bg-green-900:focus {
    background-color: #22543d !important
  }

  .xl\:focus\:bg-teal-100:focus {
    background-color: #e6fffa !important
  }

  .xl\:focus\:bg-teal-200:focus {
    background-color: #b2f5ea !important
  }

  .xl\:focus\:bg-teal-300:focus {
    background-color: #81e6d9 !important
  }

  .xl\:focus\:bg-teal-400:focus {
    background-color: #4fd1c5 !important
  }

  .xl\:focus\:bg-teal-500:focus {
    background-color: #38b2ac !important
  }

  .xl\:focus\:bg-teal-600:focus {
    background-color: #319795 !important
  }

  .xl\:focus\:bg-teal-700:focus {
    background-color: #2c7a7b !important
  }

  .xl\:focus\:bg-teal-800:focus {
    background-color: #285e61 !important
  }

  .xl\:focus\:bg-teal-900:focus {
    background-color: #234e52 !important
  }

  .xl\:focus\:bg-blue-100:focus {
    background-color: #ebf8ff !important
  }

  .xl\:focus\:bg-blue-200:focus {
    background-color: #bee3f8 !important
  }

  .xl\:focus\:bg-blue-300:focus {
    background-color: #90cdf4 !important
  }

  .xl\:focus\:bg-blue-400:focus {
    background-color: #63b3ed !important
  }

  .xl\:focus\:bg-blue-500:focus {
    background-color: #4299e1 !important
  }

  .xl\:focus\:bg-blue-600:focus {
    background-color: #3182ce !important
  }

  .xl\:focus\:bg-blue-700:focus {
    background-color: #2b6cb0 !important
  }

  .xl\:focus\:bg-blue-800:focus {
    background-color: #2c5282 !important
  }

  .xl\:focus\:bg-blue-900:focus {
    background-color: #2a4365 !important
  }

  .xl\:focus\:bg-indigo-100:focus {
    background-color: #ebf4ff !important
  }

  .xl\:focus\:bg-indigo-200:focus {
    background-color: #c3dafe !important
  }

  .xl\:focus\:bg-indigo-300:focus {
    background-color: #a3bffa !important
  }

  .xl\:focus\:bg-indigo-400:focus {
    background-color: #7f9cf5 !important
  }

  .xl\:focus\:bg-indigo-500:focus {
    background-color: #667eea !important
  }

  .xl\:focus\:bg-indigo-600:focus {
    background-color: #5a67d8 !important
  }

  .xl\:focus\:bg-indigo-700:focus {
    background-color: #4c51bf !important
  }

  .xl\:focus\:bg-indigo-800:focus {
    background-color: #434190 !important
  }

  .xl\:focus\:bg-indigo-900:focus {
    background-color: #3c366b !important
  }

  .xl\:focus\:bg-purple-100:focus {
    background-color: #faf5ff !important
  }

  .xl\:focus\:bg-purple-200:focus {
    background-color: #e9d8fd !important
  }

  .xl\:focus\:bg-purple-300:focus {
    background-color: #d6bcfa !important
  }

  .xl\:focus\:bg-purple-400:focus {
    background-color: #b794f4 !important
  }

  .xl\:focus\:bg-purple-500:focus {
    background-color: #9f7aea !important
  }

  .xl\:focus\:bg-purple-600:focus {
    background-color: #805ad5 !important
  }

  .xl\:focus\:bg-purple-700:focus {
    background-color: #6b46c1 !important
  }

  .xl\:focus\:bg-purple-800:focus {
    background-color: #553c9a !important
  }

  .xl\:focus\:bg-purple-900:focus {
    background-color: #44337a !important
  }

  .xl\:focus\:bg-pink-100:focus {
    background-color: #fff5f7 !important
  }

  .xl\:focus\:bg-pink-200:focus {
    background-color: #fed7e2 !important
  }

  .xl\:focus\:bg-pink-300:focus {
    background-color: #fbb6ce !important
  }

  .xl\:focus\:bg-pink-400:focus {
    background-color: #f687b3 !important
  }

  .xl\:focus\:bg-pink-500:focus {
    background-color: #ed64a6 !important
  }

  .xl\:focus\:bg-pink-600:focus {
    background-color: #d53f8c !important
  }

  .xl\:focus\:bg-pink-700:focus {
    background-color: #b83280 !important
  }

  .xl\:focus\:bg-pink-800:focus {
    background-color: #97266d !important
  }

  .xl\:focus\:bg-pink-900:focus {
    background-color: #702459 !important
  }

  .xl\:cursor-auto {
    cursor: auto !important
  }

  .xl\:cursor-default {
    cursor: default !important
  }

  .xl\:cursor-pointer {
    cursor: pointer !important
  }

  .xl\:cursor-wait {
    cursor: wait !important
  }

  .xl\:cursor-text {
    cursor: text !important
  }

  .xl\:cursor-move {
    cursor: move !important
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed !important
  }

  .xl\:h-0 {
    height: 0 !important
  }

  .xl\:h-4 {
    height: 4px !important
  }

  .xl\:h-6 {
    height: 6px !important
  }

  .xl\:h-8 {
    height: 8px !important
  }

  .xl\:h-12 {
    height: 12px !important
  }

  .xl\:h-16 {
    height: 16px !important
  }

  .xl\:h-20 {
    height: 20px !important
  }

  .xl\:h-24 {
    height: 24px !important
  }

  .xl\:h-28 {
    height: 28px !important
  }

  .xl\:h-32 {
    height: 32px !important
  }

  .xl\:h-36 {
    height: 36px !important
  }

  .xl\:h-40 {
    height: 40px !important
  }

  .xl\:h-44 {
    height: 44px !important
  }

  .xl\:h-48 {
    height: 48px !important
  }

  .xl\:h-52 {
    height: 52px !important
  }

  .xl\:h-56 {
    height: 56px !important
  }

  .xl\:h-60 {
    height: 60px !important
  }

  .xl\:h-64 {
    height: 64px !important
  }

  .xl\:h-68 {
    height: 68px !important
  }

  .xl\:h-72 {
    height: 72px !important
  }

  .xl\:h-76 {
    height: 76px !important
  }

  .xl\:h-80 {
    height: 80px !important
  }

  .xl\:h-84 {
    height: 84px !important
  }

  .xl\:h-88 {
    height: 88px !important
  }

  .xl\:h-92 {
    height: 92px !important
  }

  .xl\:h-96 {
    height: 96px !important
  }

  .xl\:h-128 {
    height: 128px !important
  }

  .xl\:h-160 {
    height: 160px !important
  }

  .xl\:h-192 {
    height: 192px !important
  }

  .xl\:h-224 {
    height: 224px !important
  }

  .xl\:h-256 {
    height: 256px !important
  }

  .xl\:h-320 {
    height: 320px !important
  }

  .xl\:h-auto {
    height: auto !important
  }

  .xl\:h-px {
    height: 1px !important
  }

  .xl\:h-full {
    height: 100% !important
  }

  .xl\:h-screen {
    height: 100vh !important
  }

  .xl\:w-0 {
    width: 0 !important
  }

  .xl\:w-4 {
    width: 4px !important
  }

  .xl\:w-6 {
    width: 6px !important
  }

  .xl\:w-8 {
    width: 8px !important
  }

  .xl\:w-12 {
    width: 12px !important
  }

  .xl\:w-16 {
    width: 16px !important
  }

  .xl\:w-20 {
    width: 20px !important
  }

  .xl\:w-24 {
    width: 24px !important
  }

  .xl\:w-28 {
    width: 28px !important
  }

  .xl\:w-32 {
    width: 32px !important
  }

  .xl\:w-36 {
    width: 36px !important
  }

  .xl\:w-40 {
    width: 40px !important
  }

  .xl\:w-44 {
    width: 44px !important
  }

  .xl\:w-48 {
    width: 48px !important
  }

  .xl\:w-52 {
    width: 52px !important
  }

  .xl\:w-56 {
    width: 56px !important
  }

  .xl\:w-60 {
    width: 60px !important
  }

  .xl\:w-64 {
    width: 64px !important
  }

  .xl\:w-68 {
    width: 68px !important
  }

  .xl\:w-72 {
    width: 72px !important
  }

  .xl\:w-76 {
    width: 76px !important
  }

  .xl\:w-80 {
    width: 80px !important
  }

  .xl\:w-84 {
    width: 84px !important
  }

  .xl\:w-88 {
    width: 88px !important
  }

  .xl\:w-92 {
    width: 92px !important
  }

  .xl\:w-96 {
    width: 96px !important
  }

  .xl\:w-128 {
    width: 128px !important
  }

  .xl\:w-160 {
    width: 160px !important
  }

  .xl\:w-192 {
    width: 192px !important
  }

  .xl\:w-224 {
    width: 224px !important
  }

  .xl\:w-256 {
    width: 256px !important
  }

  .xl\:w-320 {
    width: 320px !important
  }

  .xl\:w-auto {
    width: auto !important
  }

  .xl\:w-px {
    width: 1px !important
  }

  .xl\:w-1\/2 {
    width: 50% !important
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important
  }

  .xl\:w-2\/3 {
    width: 66.666667% !important
  }

  .xl\:w-1\/4 {
    width: 25% !important
  }

  .xl\:w-2\/4 {
    width: 50% !important
  }

  .xl\:w-3\/4 {
    width: 75% !important
  }

  .xl\:w-1\/5 {
    width: 20% !important
  }

  .xl\:w-2\/5 {
    width: 40% !important
  }

  .xl\:w-3\/5 {
    width: 60% !important
  }

  .xl\:w-4\/5 {
    width: 80% !important
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important
  }

  .xl\:w-3\/6 {
    width: 50% !important
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important
  }

  .xl\:w-1\/12 {
    width: 8.333333% !important
  }

  .xl\:w-2\/12 {
    width: 16.666667% !important
  }

  .xl\:w-3\/12 {
    width: 25% !important
  }

  .xl\:w-4\/12 {
    width: 33.333333% !important
  }

  .xl\:w-5\/12 {
    width: 41.666667% !important
  }

  .xl\:w-6\/12 {
    width: 50% !important
  }

  .xl\:w-7\/12 {
    width: 58.333333% !important
  }

  .xl\:w-8\/12 {
    width: 66.666667% !important
  }

  .xl\:w-9\/12 {
    width: 75% !important
  }

  .xl\:w-10\/12 {
    width: 83.333333% !important
  }

  .xl\:w-11\/12 {
    width: 91.666667% !important
  }

  .xl\:w-full {
    width: 100% !important
  }

  .xl\:w-screen {
    width: 100vw !important
  }

  .xl\:max-h-full {
    max-height: 100% !important
  }

  .xl\:max-h-screen {
    max-height: 100vh !important
  }

  .xl\:max-w-none {
    max-width: none !important
  }

  .xl\:max-w-xs {
    max-width: 20rem !important
  }

  .xl\:max-w-sm {
    max-width: 24rem !important
  }

  .xl\:max-w-md {
    max-width: 28rem !important
  }

  .xl\:max-w-lg {
    max-width: 32rem !important
  }

  .xl\:max-w-xl {
    max-width: 36rem !important
  }

  .xl\:max-w-2xl {
    max-width: 42rem !important
  }

  .xl\:max-w-3xl {
    max-width: 48rem !important
  }

  .xl\:max-w-4xl {
    max-width: 56rem !important
  }

  .xl\:max-w-5xl {
    max-width: 64rem !important
  }

  .xl\:max-w-6xl {
    max-width: 72rem !important
  }

  .xl\:max-w-full {
    max-width: 100% !important
  }

  .xl\:max-w-screen-sm {
    max-width: 640px !important
  }

  .xl\:max-w-screen-md {
    max-width: 768px !important
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px !important
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px !important
  }

  .xl\:min-h-0 {
    min-height: 0 !important
  }

  .xl\:min-h-full {
    min-height: 100% !important
  }

  .xl\:min-h-screen {
    min-height: 100vh !important
  }

  .xl\:min-w-0 {
    min-width: 0 !important
  }

  .xl\:min-w-full {
    min-width: 100% !important
  }

  .xl\:overflow-auto {
    overflow: auto !important
  }

  .xl\:overflow-hidden {
    overflow: hidden !important
  }

  .xl\:overflow-visible {
    overflow: visible !important
  }

  .xl\:overflow-scroll {
    overflow: scroll !important
  }

  .xl\:overflow-x-auto {
    overflow-x: auto !important
  }

  .xl\:overflow-y-auto {
    overflow-y: auto !important
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .xl\:static {
    position: static !important
  }

  .xl\:fixed {
    position: fixed !important
  }

  .xl\:absolute {
    position: absolute !important
  }

  .xl\:relative {
    position: relative !important
  }

  .xl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important
  }

  .xl\:z-0 {
    z-index: 0 !important
  }

  .xl\:z-10 {
    z-index: 10 !important
  }

  .xl\:z-20 {
    z-index: 20 !important
  }

  .xl\:z-30 {
    z-index: 30 !important
  }

  .xl\:z-40 {
    z-index: 40 !important
  }

  .xl\:z-50 {
    z-index: 50 !important
  }

  .xl\:z-auto {
    z-index: auto !important
  }

  .xl\:block {
    display: block !important
  }

  .xl\:inline-block {
    display: inline-block !important
  }

  .xl\:inline {
    display: inline !important
  }

  .xl\:flex {
    display: flex !important
  }

  .xl\:inline-flex {
    display: inline-flex !important
  }

  .xl\:table {
    display: table !important
  }

  .xl\:table-caption {
    display: table-caption !important
  }

  .xl\:table-cell {
    display: table-cell !important
  }

  .xl\:table-column {
    display: table-column !important
  }

  .xl\:table-column-group {
    display: table-column-group !important
  }

  .xl\:table-footer-group {
    display: table-footer-group !important
  }

  .xl\:table-header-group {
    display: table-header-group !important
  }

  .xl\:table-row-group {
    display: table-row-group !important
  }

  .xl\:table-row {
    display: table-row !important
  }

  .xl\:flow-root {
    display: flow-root !important
  }

  .xl\:grid {
    display: grid !important
  }

  .xl\:inline-grid {
    display: inline-grid !important
  }

  .xl\:contents {
    display: contents !important
  }

  .xl\:hidden {
    display: none !important
  }

  .xl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important
  }

  .xl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important
  }

  .xl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important
  }

  .xl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important
  }

  .xl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important
  }

  .xl\:inset-x-auto {
    right: auto !important;
    left: auto !important
  }

  .xl\:top-0 {
    top: 0 !important
  }

  .xl\:right-0 {
    right: 0 !important
  }

  .xl\:bottom-0 {
    bottom: 0 !important
  }

  .xl\:left-0 {
    left: 0 !important
  }

  .xl\:top-auto {
    top: auto !important
  }

  .xl\:right-auto {
    right: auto !important
  }

  .xl\:bottom-auto {
    bottom: auto !important
  }

  .xl\:left-auto {
    left: auto !important
  }
}
